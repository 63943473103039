import * as types from "./constants.jsx";

export function postNewCategory(data) {
    return {
        type: types.POST_NEW_CATEGORY,
        payload: {
            client: "default",
            request: {
                url: `/catalog/categories/add/`,
                method: "POST",
                data
            }
        }
    };
}

export function changeCategoryActivity(id) {
    return {
        type: types.POST_CHANGE_CATEGORY_ACTIVITY,
        payload: {
            client: "default",
            request: {
                url: `/catalog/categories/${id}/status/`,
                method: "POST"
            }
        }
    };
}

export function patchCategory(id, data) {
    return {
        type: types.PATCH_CATEGORY,
        payload: {
            client: "default",
            request: {
                url: `/catalog/categories/${id}/update/`,
                method: "PATCH",
                data
            }
        }
    };
}
