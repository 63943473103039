import React, {Component, Fragment} from "react"
import { Switch, Route, Redirect } from "react-router-dom"
import { connect } from "react-redux"

import Dashboard from "../../components/Dashboard/Dashboard"
import Application from "../../components/Application/Application"
import Head from "../../components/Head/Head"
import Deals from "../../components/Deals/Deals"
import Deal from "../../components/Deals/Deal/Deal"
import DealsInProgress from "../../components/DealsInProgress/DealsInProgress"
import DealInProgress from "../../components/DealsInProgress/DealInProgress/DealInProgress"
import Products from "../../components/Products/Products"
import ProductUpdate from "../../components/Products/ProductUpdate/ProductUpdate"
import Suppliers from "../../components/Suppliers/Suppliers"
import EditCustomApplication from "../../components/Application/CustomApplication/EditCustomApplication"
import AddCustomApplication from "../../components/Application/CustomApplication/AddCustomApplication"

// import ProductNew from "../../components/Products/ProductNew/ProductNew"
import Users from "../../components/Users/Users"

import { getOptions } from "../../actions/dashboardActions"
import SupplierUpdate from "../../components/Suppliers/SupplierUpdate/SupplierUpdate"
import RedirectPage from "../../components/RedirectPage/RedirectPage"

// const Container = (props) => {
//     const [loading, setLoading] = useState(true)
//
//     useEffect(() => {
//         const { getOptions } = props
//         getOptions()
//         setLoading(false)
//     }, [])
//
//     if (!localStorage.token) return <Redirect to="/auth/login" />
//
//         console.log(props)
//
//         if (loading) return null
//
//         debugger
//         const {
//             match,
//             options: {
//                 me: { role }
//             }
//         } = this.props
//
//
//         const isAdmin = role === "admin"
//         const isSeniorManager = role === "senior_manager" //all possible roles
//         const isManager = role === 'manager'
//
//         return (
//             <Fragment>
//                 <Head />
//                 <div className="page">
//                     <Switch>
//                         <Route path={match.url} exact render={() => <Redirect to="/main/tenders" />} />
//                         <Route
//                             path={`${match.url}/tenders`}
//                             exact
//                             render={() => (isAdmin || isSeniorManager ? <Dashboard /> : <Redirect to="/main/deals" />)}
//                         />
//                         <Route
//                             path={`${match.url}/tender/:id`}
//                             render={() =>
//                                 isAdmin || isSeniorManager ? <Application /> : <Redirect to="/main/deals" />
//                             }
//                         />
//                         <Route path={`${match.url}/add-custom-tender`} exact component={AddCustomApplication} />
//                         <Route path={`${match.url}/edit-custom-tender/:id`} exact component={EditCustomApplication} />
//                         <Route
//                             path={`${match.url}/users`}
//                             exact
//                             render={() => (isAdmin ? <Users /> : <Redirect to="/main/deals" />)}
//                         />
//                         <Route path={`${match.url}/deals`} component={Deals} />
//                         <Route path={`${match.url}/deal/:id`} component={Deal} />
//                         <Route path={`${match.url}/deals-in-progress`} component={DealsInProgress} />
//                         <Route path={`${match.url}/deal-in-progress/:id`} component={DealInProgress} />
//                         <Route path={`${match.url}/products`} component={Products} />
//                         <Route path={`${match.url}/product/:id`} component={ProductUpdate} />
//                         <Route path={`${match.url}/new-product`} component={ProductUpdate} />
//                         <Route path={`${match.url}/suppliers`} component={Suppliers} />
//                         <Route path={`${match.url}/supplier/:id`} component={SupplierUpdate} />
//                         <Route path={`${match.url}/new-supplier`} component={SupplierUpdate} />
//                         <Route path={`${match.url}/redirect/:id`} component={RedirectPage} />
//                         <Route render={() => <div className="page_wrapper">Страница не найдена</div>} />
//                     </Switch>
//                 </div>
//             </Fragment>
//         )
// }

class Container extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true
        }
    }



    async componentDidMount() {

        const { getOptions } = this.props
        await getOptions()
        this.setState({ loading: false })
    }

    render() {

        if (!localStorage.token) return <Redirect to="/auth/login" />

        const { loading } = this.state
        if (loading) return null

        const {
            match,
            options: {
                me: { role }
            }
        } = this.props


        const isAdmin = role === "admin"
        const isSeniorManager = role === "senior_manager" //all possible roles
        // const isManager = role === 'manager'

        return (
            <Fragment>
                <Head />
                <div className="page">
                    <Switch>
                        <Route path={match.url} exact render={() => <Redirect to="/main/tenders" />} />
                        <Route
                            path={`${match.url}/tenders`}
                            exact
                            render={() => (isAdmin || isSeniorManager ? <Dashboard /> : <Redirect to="/main/deals" />)}
                        />
                        <Route
                            path={`${match.url}/tender/:id`}
                            render={() =>
                                isAdmin || isSeniorManager ? <Application /> : <Redirect to="/main/deals" />
                            }
                        />
                        <Route path={`${match.url}/add-custom-tender`} exact component={AddCustomApplication} />
                        <Route path={`${match.url}/edit-custom-tender/:id`} exact component={EditCustomApplication} />
                        <Route
                            path={`${match.url}/users`}
                            exact
                            render={() => (isAdmin ? <Users /> : <Redirect to="/main/deals" />)}
                        />
                        <Route path={`${match.url}/deals`} component={Deals} />
                        <Route path={`${match.url}/deal/:id`} component={Deal} />
                        <Route path={`${match.url}/deals-in-progress`} component={DealsInProgress} />
                        <Route path={`${match.url}/deal-in-progress/:id`} component={DealInProgress} />
                        <Route path={`${match.url}/products`} component={Products} />
                        <Route path={`${match.url}/product/:id`} component={ProductUpdate} />
                        <Route path={`${match.url}/new-product`} component={ProductUpdate} />
                        <Route path={`${match.url}/suppliers`} component={Suppliers} />
                        <Route path={`${match.url}/supplier/:id`} component={SupplierUpdate} />
                        <Route path={`${match.url}/new-supplier`} component={SupplierUpdate} />
                        <Route path={`${match.url}/redirect/:id`} component={RedirectPage} />
                        <Route render={() => <div className="page_wrapper">Страница не найдена</div>} />
                    </Switch>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = ({ dashboard }) => {
    return {
        options: dashboard.options
    }
}

const mapDispatchToProps = {
    getOptions
}

export default connect(mapStateToProps, mapDispatchToProps)(Container)
