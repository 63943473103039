import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { postNewCategory, changeCategoryActivity, patchCategory } from "../../actions/categoriesActions";
import DialogComponent from "../HelperComponents/DialogComponent/DialogComponent";
import DefaultButton from "../HelperComponents/Buttons/DefaultButton/DefaultButton";
import { Field, reduxForm, SubmissionError } from "redux-form";
import RenderField from "../HelperComponents/RenderField/RenderField";

class CategoriesDialogs extends Component {
    state = {
        reloading: false,
        item_chars: []
    };

    blockCategory = async () => {
        // const { postDelete, doRequest, currentTender, toggleDialog } = this.props;
        const { toggleDialog, doRequest, changeCategoryActivity, currentCategoryId, activePage } = this.props;
        this.setState({ reloading: true });
        await changeCategoryActivity(currentCategoryId);
        this.setState({ reloading: false });
        doRequest(activePage);
        toggleDialog("block");
    };

    unblockCategory = async () => {
        // const { postDelete, doRequest, currentTender, toggleDialog } = this.props;
        const { toggleDialog, changeCategoryActivity, currentCategoryId, doRequest, activePage } = this.props;
        this.setState({ reloading: true });
        await changeCategoryActivity(currentCategoryId);
        this.setState({ reloading: false });
        doRequest(activePage);
        toggleDialog("unblock");
    };

    submitCategory = async data => {
        const {
            toggleDialog,
            doRequest,
            postNewCategory,
            patchCategory,
            category_marker,
            currentCategoryId,
            reset
        } = this.props;
        let res;
        this.setState({ reloading: true });
        if (category_marker === "new") {
            res = await postNewCategory(data);
            this.setState({ reloading: false });
            if (res.payload && res.payload.status && res.payload.status === 201) {
                toggleDialog("categories");
                doRequest();
                reset();
            } else {
                throw new SubmissionError({
                    ...res.error.response.data,
                    _error: res.error.response.data.detail
                });
            }
        } else {
            return patchCategory(currentCategoryId, data).then(res => {
                this.setState({ reloading: false });
                if (res.payload && res.payload.status && res.payload.status === 201) {
                    toggleDialog("categories");
                    doRequest();
                    reset();
                } else {
                    throw new SubmissionError({
                        ...res.error.response.data,
                        _error: res.error.response.data.detail
                    });
                }
            });
        }
    };

    render() {
        const {
            category_marker,
            handleSubmit,
            toggleDialog,
            openCategoriesDialog,
            openBlockDialog,
            openUnblockDialog,
            submitting,
            pristine,
            valid
        } = this.props;
        const { reloading } = this.state;
        return (
            <Fragment>
                <DialogComponent open={openCategoriesDialog} onClose={() => toggleDialog("categories")}>
                    <div className="dashboard_dialog no_select">
                        {category_marker === "new" ? <h2>Добавить категорию</h2> : <h2>Редактировать категорию</h2>}
                        <div className="dialog_description">
                            <form onSubmit={handleSubmit(this.submitCategory)}>
                                <Field name="name" type="text" label="Наименование" component={RenderField} />
                                <div className="dialog_buttons">
                                    <DefaultButton
                                        variant="outlined"
                                        onClick={() => toggleDialog("categories")}
                                        classes="cancel_btn"
                                    >
                                        Отмена
                                    </DefaultButton>
                                    <DefaultButton
                                        variant="contained"
                                        classes="confirm_btn"
                                        loading={reloading}
                                        disabled={submitting || pristine || !valid}
                                        formAction
                                    >
                                        {category_marker === "new" ? <>Добавить</> : <>Сохранить</>}
                                    </DefaultButton>
                                </div>
                            </form>
                        </div>
                    </div>
                </DialogComponent>
                <DialogComponent open={openBlockDialog} onClose={() => toggleDialog("block")}>
                    <div className="dashboard_dialog no_select">
                        <h2>Заблокировать категорию</h2>
                        <div className="dialog_description">Вы уверены, что хотите заблокировать эту категорию?</div>
                        <div className="dialog_buttons">
                            <DefaultButton
                                variant="outlined"
                                onClick={() => toggleDialog("block")}
                                classes="cancel_btn"
                            >
                                Отмена
                            </DefaultButton>
                            <DefaultButton
                                variant="contained"
                                onClick={this.blockCategory}
                                classes="confirm_btn"
                                loading={reloading}
                            >
                                Заблокировать
                            </DefaultButton>
                        </div>
                    </div>
                </DialogComponent>
                <DialogComponent open={openUnblockDialog} onClose={() => toggleDialog("unblock")}>
                    <div className="dashboard_dialog no_select">
                        <h2>Разблокировать категорию</h2>
                        <div className="dialog_description">
                            Подтвердите, что Вы хотите разблокировать эту категорию.
                        </div>
                        <div className="dialog_buttons">
                            <DefaultButton
                                variant="outlined"
                                onClick={() => toggleDialog("unblock")}
                                classes="cancel_btn"
                            >
                                Отмена
                            </DefaultButton>
                            <DefaultButton
                                variant="contained"
                                onClick={this.unblockCategory}
                                classes="confirm_btn"
                                loading={reloading}
                            >
                                Подтвердить
                            </DefaultButton>
                        </div>
                    </div>
                </DialogComponent>
            </Fragment>
        );
    }
}

const validate = values => {
    const errors = {};
    return errors;
};

CategoriesDialogs = reduxForm({
    form: "CategoriesDialogs",
    validate,
    enableReinitialize: true
})(CategoriesDialogs);

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = {
    postNewCategory,
    changeCategoryActivity,
    patchCategory
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesDialogs);
