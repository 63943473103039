import * as types from "../actions/constants"

const INITIAL_STATE = {
    deal_owners: [],
    real_deal_owners: [],
    contracts: {},
    documents: [],
    documentsGK: []
}

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.GET_OWNERS_SUCCESS:
            return {
                ...state,
                deal_owners: action.payload.data
            }
        case types.GET_ALL_OWNERS_SUCCESS:
            return {
                ...state,
                real_deal_owners: action.payload.data
            }
        case types.GET_CONTRACT_FILES_SUCCESS:
            return {
                ...state,
                contracts: action.payload.data
            }
        case types.GET_CALCULATION_DOCUMENTS_SUCCESS:
            return {
                ...state,
                documents: action.payload.data
            }
        case types.GET_FILES_GK_SUCCESS:
            return {
                ...state,
                documentsGK: action.payload.data
            }
        default:
            return state
    }
}
