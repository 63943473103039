import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import DialogComponent from "../HelperComponents/DialogComponent/DialogComponent";
import DefaultButton from "../HelperComponents/Buttons/DefaultButton/DefaultButton";

import // postDelete
"../../actions/dealsActions";

class ProductsDialogs extends Component {
    state = {
        reloading: false
    };

    blockProduct = async () => {
        // const { postDelete, doRequest, currentTender, toggleDialog } = this.props;
        const { toggleDialog, doRequest, changeProductActivity, currentProductId, activePage } = this.props;
        this.setState({ reloading: true });
        await changeProductActivity(currentProductId);
        this.setState({ reloading: false });
        doRequest(activePage);
        toggleDialog("block");
    };

    unblockProduct = async () => {
        // const { postDelete, doRequest, currentTender, toggleDialog } = this.props;
        const { toggleDialog, changeProductActivity, currentProductId, doRequest, activePage } = this.props;
        this.setState({ reloading: true });
        await changeProductActivity(currentProductId);
        this.setState({ reloading: false });
        doRequest(activePage);
        toggleDialog("unblock");
    };

    render() {
        const { toggleDialog, openBlockDialog, openUnblockDialog } = this.props;
        const { reloading } = this.state;
        return (
            <Fragment>
                <DialogComponent open={openBlockDialog} onClose={() => toggleDialog("block")}>
                    <div className="dashboard_dialog no_select">
                        <h2>Заблокировать товар</h2>
                        <div className="dialog_description">Вы уверены, что хотите заблокировать этот товар?</div>
                        <div className="dialog_buttons">
                            <DefaultButton
                                variant="outlined"
                                onClick={() => toggleDialog("block")}
                                classes="cancel_btn"
                            >
                                Отмена
                            </DefaultButton>
                            <DefaultButton
                                variant="contained"
                                onClick={this.blockProduct}
                                classes="confirm_btn"
                                loading={reloading}
                            >
                                Заблокировать
                            </DefaultButton>
                        </div>
                    </div>
                </DialogComponent>
                <DialogComponent open={openUnblockDialog} onClose={() => toggleDialog("unblock")}>
                    <div className="dashboard_dialog no_select">
                        <h2>Разблокировать товар</h2>
                        <div className="dialog_description">Подтвердите, что Вы хотите разблокировать этот товар.</div>
                        <div className="dialog_buttons">
                            <DefaultButton
                                variant="outlined"
                                onClick={() => toggleDialog("unblock")}
                                classes="cancel_btn"
                            >
                                Отмена
                            </DefaultButton>
                            <DefaultButton
                                variant="contained"
                                onClick={this.unblockProduct}
                                classes="confirm_btn"
                                loading={reloading}
                            >
                                Подтвердить
                            </DefaultButton>
                        </div>
                    </div>
                </DialogComponent>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = {
    // postDelete,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsDialogs);
