import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import AuthReducer from "./reduceAuth";
import DashboardReducer from "./reduceDashboard";
import CalculationReducer from "./reduceCalculation";
import ProductsReducer from "./reduceProducts";
import SuppliersReducer from "./reduceSuppliers";
import CustomTenders from "./reduceCustomTenders";
import ReduceDeals from "./reduceDeals";
import NotificationsDeals from "./reduceNotifications";
import { reducer as formReducer } from "redux-form";
import ShipmentReducer from "./reduceShipment";

const rootReducer = history =>
    combineReducers({
        router: connectRouter(history),
        form: formReducer,
        auth: AuthReducer,
        dashboard: DashboardReducer,
        calculation: CalculationReducer,
        products: ProductsReducer,
        suppliers: SuppliersReducer,
        customTenders: CustomTenders,
        deals: ReduceDeals,
        notifications: NotificationsDeals,
        shipment: ShipmentReducer
    });

export default rootReducer;
