import * as types from "./constants.jsx"

export function deleteFile(tender_id, document_id) {
    return {
        type: types.DELETE_FILE,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/${tender_id}/documents/${document_id}/`,
                method: "delete"
            }
        }
    }
}

export function postCalculation(id) {
    //рассчитать
    return {
        type: types.POST_CALCULATION,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/${id}/set-calculation/`,
                method: "post"
            }
        }
    }
}

export function postCheck(id, data) {
    //на проверку
    return {
        type: types.POST_CHECK,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/${id}/set-check/`,
                method: "post",
                data
            }
        }
    }
}

export function postFix(id, data) {
    //на доработку
    return {
        type: types.POST_FIX,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/${id}/set-fix/`,
                method: "post",
                data
                // {
                //      "comment": "Комментарий"
                // }
            }
        }
    }
}

export function postApprove(id, data) {
    //одобрить
    return {
        type: types.POST_APPROVE,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/${id}/set-approve/`,
                method: "post",
                data
                // {
                //     "min_auction_price": 924,
                //     "comment": "my comment",
                //     "responsible": 4, // user id
                // }
            }
        }
    }
}

export function postDelete(id) {
    //удалить
    return {
        type: types.POST_DELETE,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/${id}/set-delete/`,
                method: "post"
            }
        }
    }
}

export function postDeleteAdmin(data) {
    //удалить
    return {
        type: types.POST_DELETE,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/mass-delete/`,
                method: "post",
                data
            }
        }
    }
}

export function backToWorkAction(data) {
    //удалить
    return {
        type: types.POST_DELETE,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/back-to-work/`,
                method: "post",
                data
            }
        }
    }
}

export function postToDelete(data) {
    //на удаление
    return {
        type: types.POST_DELETE,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/set-to-delete/`,
                method: "post",
                data
            }
        }
    }
}

export function postDecline(id, data) {
    //отказать
    return {
        type: types.POST_DECLINE,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/${id}/set-decline/`,
                method: "post",
                data
                // {
                //      "comment": "Комментарий"
                // }
            }
        }
    }
}

export function addPlacedBy(id, data) {
    return {
        type: types.ADD_PLACED_BY,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/${id}/add-placed-by/`,
                method: "post",
                data
            }
        }
    }
}

export function postPost(id, data) {
    return {
        type: types.POST_POST,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/${id}/set-post/`,
                method: "post",
                data
            }
        }
    }
}

export function getBotDocuments(id, data) {
    //иные документы при размещении
    return {
        type: types.GET_BOT_DOCUMENT,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/${id}/documents/`,
                method: "get"
            }
        }
    }
}

export function postBotDocument(id, data) {
    //разместить заявку с росселторга
    return {
        type: types.POST_BOT_DOCUMENT,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/${id}/documents/`,
                method: "post",
                data
            }
        }
    }
}

export function postPostBotInvolved(id, data) {
    //разместить заявку с росселторга
    return {
        type: types.POST_POST_BOT_INVOLVED,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/${id}/set-waiting-for-placing/`,
                method: "post",
                data
            }
        }
    }
}

export function postWon(id) {
    //выиграна
    return {
        type: types.POST_WON,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/${id}/set-won/`,
                method: "post"
            }
        }
    }
}

export function postLost(id) {
    //проиграна
    return {
        type: types.POST_LOST,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/${id}/set-lost/`,
                method: "post"
            }
        }
    }
}

export function postSigned(id, data) {
    //ГК заключён
    return {
        type: types.POST_SIGNED,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/${id}/set-signed/`,
                method: "post"
            }
        }
    }
}

export function postSignedWithDocs(id, data) {
    //ГК заключён
    return {
        type: types.POST_SIGNED,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/${id}/contract-conclusion/`,
                method: "post",
                data
            }
        }
    }
}

export function getFilesGK(id) {
    //ГК заключён
    return {
        type: types.GET_FILES_GK,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/${id}/contract-documents/`,
                method: "get"
            }
        }
    }
}

export function postExecuting(id) {
    //исполнить сразу
    return {
        type: types.POST_EXECUTING,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/${id}/set-executing/`,
                method: "post"
            }
        }
    }
}

export function postExecuted(id, data) {
    //закончить
    return {
        type: types.POST_EXECUTED,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/${id}/set-executed/`,
                method: "post",
                data
                // {
                //     "contract_signed": true, // should be true, 400 if this one is false
                //     "goods_delivered": true,
                //     "documents_signed": true,
                //     "goods_paid": false,
                // }
            }
        }
    }
}

export function postClosed(id) {
    //закрыть контракт
    return {
        type: types.POST_CLOSED,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/${id}/set-closed/`,
                method: "post"
            }
        }
    }
}

export function postResponsible(id, data) {
    //назначение/смена ответственного
    return {
        type: types.POST_RESPONSIBLE,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/${id}/set-responsible/`,
                method: "post",
                data
                // {
                //     "responsible": 4  // user id
                // }
            }
        }
    }
}

export function postManager(id, data) {
    //назначение/смена исполнителя
    return {
        type: types.POST_MANAGER,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/${id}/set-manager/`,
                method: "post",
                data
                // {
                //     "manager": 4  // user id
                // }
            }
        }
    }
}

export function getOwners() {
    //список владельцев, для формирования КП
    return {
        type: types.GET_OWNERS,
        payload: {
            client: "default",
            request: {
                url: `/options/report-owners/`,
                method: "GET"
                //  [
                //      {
                //          "id": 1,
                //          "name: "Колобов Михаил Андреевич"
                //      },
                //  ]
            }
        }
    }
}

export function getAllOwners() {
    return {
        type: types.GET_ALL_OWNERS,
        payload: {
            client: "default",
            request: {
                url: `/options/real-report-owners/`,
                method: "GET"
            }
        }
    }
}

export function getExcel(pages) {
    return {
        type: types.GET_EXCEL,
        payload: {
            client: "default",
            request: {
                url: `/admin/excel-report/?token=${localStorage.getItem("token")}&pages=${pages}`,
                method: "GET"
            }
        }
    }
}

export function downloadContractFiles(id, data) {
    return {
        type: types.DOWNLOAD_CONTRACT_FILES,
        payload: {
            client: "default",
            request: {
                url: `manager/tenders/${id}/project-contracts/`,
                method: "post",
                data
            }
        }
    }
}

export function getContractFiles(id) {
    return {
        type: types.GET_CONTRACT_FILES,
        payload: {
            client: "default",
            request: {
                url: `manager/tenders/${id}/project-contracts/`,
                method: "get"
            }
        }
    }
}

export function changePlacedByBot(id, data) {
    return {
        type: types.DOWNLOAD_CONTRACT_FILES,
        payload: {
            client: "default",
            request: {
                url: `manager/tenders/${id}/edit-placed-by/`,
                method: "post",
                data
            }
        }
    }
}

export function addCommentToContracts(id, data) {
    return {
        type: types.ADD_COMMENT,
        payload: {
            client: "default",
            request: {
                url: `manager/tenders/${id}/project-contracts/add-comment/ `,
                method: "post",
                data
            }
        }
    }
}

export function changeCommentContracts(id, documentId, data) {
    return {
        type: types.CHANGE_DOCUMENT,
        payload: {
            client: "default",
            request: {
                url: `manager/tenders/${id}/project-contracts/documents/${documentId}/ `,
                method: "patch",
                data
            }
        }
    }
}

export function getCalculationDocuments(id) {
    return {
        type: types.GET_CALCULATION_DOCUMENTS,
        payload: {
            client: "default",
            request: {
                url: `manager/tenders/${id}/additional-documents/`,
                method: "get"
            }
        }
    }
}

export function createCalculationDocuments(id, data) {
    return {
        type: types.CREATE_CALCULATION_DOCUMENT,
        payload: {
            client: "default",
            request: {
                url: `manager/tenders/${id}/additional-documents/`,
                method: "post",
                data
            }
        }
    }
}

export function updateCalculationDocuments(id, docId, data) {
    return {
        type: types.UPDATE_CALCULATION_DOCUMENT,
        payload: {
            client: "default",
            request: {
                url: `manager/tenders/${id}/additional-documents/${docId}/`,
                method: "post",
                data
            }
        }
    }
}

export function deleteCalculationDocuments(id, docId) {
    return {
        type: types.DELETE_CALCULATION_DOCUMENT,
        payload: {
            client: "default",
            request: {
                url: `manager/tenders/${id}/additional-documents/${docId}/`,
                method: "delete"
            }
        }
    }
}
