import * as types from "../actions/constants";

const INITIAL_STATE = {
    shipmentData: [],
    reports: [],
    items: {},
    singleShipment: {}
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.GET_SHIPMENT_SUCCESS:
            return { ...state, shipmentData: action.payload.data };
        case types.GET_SHIPMENT_REPORTS_SUCCESS:
            return { ...state, reports: action.payload.data };
        case types.GET_REPORT_DATA_SUCCESS:
            return { ...state, items: action.payload.data };
        case types.GET_SINGLE_SHIPMENT:
            return { ...state, singleShipment: action.payload.data };
        default:
            return state;
    }
}
