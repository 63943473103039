import React, { Component } from "react";
import { connect } from "react-redux";

import { postResponsible, postManager } from "../../../actions/dealsActions";

import CompleteIcon from "../../../assets/image/complete.svg";
import clsx from "clsx";

class ManagerSelector extends Component {
    state = {
        inputValue: ""
    };

    handleSelect = async id => {
        const {
            postResponsible,
            postManager,
            doRequest,
            handleManagerSelector,
            responsible,
            currentTender
        } = this.props;
        let data = {};
        if (responsible) {
            data = {
                responsible: id
            };
            await postResponsible(currentTender, data);
        } else {
            data = {
                manager: id
            };
            await postManager(currentTender, data);
        }
        doRequest();
        handleManagerSelector();
    };

    render() {
        const { manager, users } = this.props;
        const { inputValue } = this.state;
        const managers = users
            .filter(el => el.fio)
            .filter(el => el.fio.toLowerCase().includes(inputValue.toLowerCase()));
        return (
            <div className="manager_selector">
                <div>
                    <input
                        type="text"
                        value={inputValue}
                        onChange={e => this.setState({ inputValue: e.target.value })}
                        placeholder="Введите имя менеджера"
                    />
                </div>
                <div>
                    {managers.map(({ fio, id }) => (
                        <div className="text_hover" onClick={() => this.handleSelect(id)} key={id}>
                            <p className={clsx(id === manager && "active")}>{fio}</p>
                            {id === manager && <img src={CompleteIcon} alt="chosen" />}
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        users: state.dashboard.users
    };
};

const mapDispatchToProps = {
    postManager,
    postResponsible
};

export default connect(mapStateToProps, mapDispatchToProps)(ManagerSelector);
