import * as types from "./constants.jsx";

export function getTenders(page, order, search) {
    return {
        type: types.GET_TENDERS,
        payload: {
            client: "default",
            request: {
                url: `/admin/tenders/?ordering=${order}&page=${page}&search=${search}&exclude_seven_digit=false`,
                method: "get"
            }
        }
    };
}

export function getOptions() {
    return {
        type: types.GET_OPTIONS,
        payload: {
            client: "default",
            request: {
                url: `/options/`,
                method: "get"
            }
        }
    };
}

export function getTender(id) {
    return {
        type: types.GET_TENDER,
        payload: {
            client: "default",
            request: {
                url: `/admin/tenders/${id}/`,
                method: "get"
            }
        }
    };
}

export function patchTender(id, data) {
    return {
        type: types.PATCH_TENDER,
        payload: {
            client: "default",
            request: {
                url: `/admin/tenders/${id}/update/`,
                method: "patch",
                data
            }
        }
    };
}

export function reDownloadTender(id) {
    return {
        type: types.RE_DOWNLOAD_TENDER,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/${id}/redownload-archive/`,
                method: "post"
            }
        }
    };
}

export function getManagers(value) {
    return {
        type: types.GET_MANAGERS,
        payload: {
            client: "default",
            request: {
                url: `/admin/managers/?fio=${value}`,
                method: "get"
            }
        }
    };
}

export function getDeals(page, order, search) {
    return {
        type: types.GET_DEALS,
        payload: {
            client: "default",
            request: {
                url: `/admin/tenders/?ordering=${order}&page=${page}&search=${search}&exclude_seven_digit=true`,
                method: "get"
            }
        }
    };
}

export function getActiveDeals(
    page,
    order,
    search,
    statuses = [],
    execution_status = "",
    managers = [],
    responsibles = [],
    is_important = ""
) {
    return {
        type: types.GET_ACTIVE_DEALS,
        payload: {
            client: "default",
            request: {
                url:
                    `/manager/tenders/active/?ordering=${order}&page=${page}&search=${search}` +
                    `&status=${statuses.join(",")}&execution_status=${execution_status}` +
                    `&manager=${managers.join(",")}&responsible=${responsibles.join(",")}` +
                    `&is_important=${is_important}`,
                method: "get"
            }
        }
    };
}

export function getDeal(id) {
    return {
        type: types.GET_DEAL,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/${id}/`,
                method: "get"
            }
        }
    };
}

export function calculateDeal(id) {
    return {
        type: types.CALCULATE_DEAL,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/${id}/set-calculation/`,
                method: "post"
            }
        }
    };
}

//USERS

export function getUsers() {
    return {
        type: types.GET_USERS,
        payload: {
            client: "default",
            request: {
                url: `/admin/users/list/`,
                method: "get"
            }
        }
    };
}

export function patchUser(id, data) {
    return {
        type: types.PATCH_USER,
        payload: {
            client: "default",
            request: {
                url: `/admin/users/${id}/update/`,
                method: "patch",
                data
            }
        }
    };
}

export function deleteUser(id) {
    return {
        type: types.DELETE_USER,
        payload: {
            client: "default",
            request: {
                url: `/admin/users/${id}/delete/`,
                method: "delete"
            }
        }
    };
}

export function getCountries() {
    return {
        type: types.GET_COUNTRIES,
        payload: {
            client: "default",
            request: {
                url: `/options/countries/`,
                method: "get"
            }
        }
    };
}

export function setTendersPage(page) {
    return {
        type: types.SET_TENDERS_PAGE,
        payload: page
    };
}

export function setTendersOrder(order) {
    return {
        type: types.SET_TENDERS_ORDER,
        payload: order
    };
}

export function setDealsPage(page) {
    return {
        type: types.SET_DEALS_PAGE,
        payload: page
    };
}

export function setDealsOrder(order) {
    return {
        type: types.SET_DEALS_ORDER,
        payload: order
    };
}

export function setActiveDealsPage(page) {
    return {
        type: types.SET_ACTIVE_DEALS_PAGE,
        payload: page
    };
}

export function setActiveDealsOrder(order) {
    return {
        type: types.SET_ACTIVE_DEALS_ORDER,
        payload: order
    };
}

export function addSelectedTender(data) {
    return {
        type: types.ADD_SELECTED_TENDER,
        payload: data
    };
}

export function removeSelectedTender(id) {
    return {
        type: types.REMOVE_SELECTED_TENDER,
        payload: id
    };
}

export function clearSelectedTenders() {
    return {
        type: types.CLEAR_SELECTED_TENDERS
    };
}

export function addSelectedDeal(data) {
    return {
        type: types.ADD_SELECTED_DEAL,
        payload: data
    };
}

export function removeSelectedDeal(id) {
    return {
        type: types.REMOVE_SELECTED_DEAL,
        payload: id
    };
}

export function clearSelectedDeals() {
    return {
        type: types.CLEAR_SELECTED_DEALS
    };
}

export function addSelectedActiveDeal(data) {
    return {
        type: types.ADD_SELECTED_ACTIVE_DEAL,
        payload: data
    };
}

export function removeSelectedActiveDeal(id) {
    return {
        type: types.REMOVE_SELECTED_ACTIVE_DEAL,
        payload: id
    };
}

export function clearSelectedActiveDeals() {
    return {
        type: types.CLEAR_SELECTED_ACTIVE_DEALS
    };
}

export function bulkUpdateTenders(data) {
    return {
        type: types.BULK_UPDATE_TENDERS,
        payload: {
            client: "default",
            request: {
                url: `/admin/tenders/bulk-update/`,
                method: "post",
                data
            }
        }
    };
}

export function getTendersBySearch(search) {
    return {
        type: types.GET_TENDERS_BY_SEARCH,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/search/?search=${search}`,
                method: "get"
            }
        }
    };
}

export function getCustomers(id) {
    return {
        type: types.GET_CUSTOMERS,
        payload: {
            client: "default",
            request: {
                url: `/admin/customers/list/?user=${id}`,
                method: "get"
            }
        }
    };
}

export function patchCustomers(data) {
    return {
        type: types.PATCH_CUSTOMERS,
        payload: {
            client: "default",
            request: {
                url: `/admin/users/customers/update/`,
                method: "patch",
                data
            }
        }
    };
}
