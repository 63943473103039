import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import IconButton from "@material-ui/core/IconButton"
import { Link, NavLink } from "react-router-dom"
import { withRouter } from "react-router-dom"
import ClickAwayListener from "@material-ui/core/ClickAwayListener/ClickAwayListener"
import Popper from "@material-ui/core/Popper/Popper"
import clsx from "clsx"

import { getOptions, getTendersBySearch } from "../../actions/dashboardActions"
import Notifications from "./Notifications"
import ScrollBar from "../HelperComponents/ScrollBar/ScrollBar"

import Logotype from "../../assets/image/logotype.svg"
import LogOut from "../../assets/image/logout.svg"
import ArrowIcon from "../../assets/image/arrow_down.svg"

import "./Head.scss"

class Head extends Component {
    state = {
        anchorInfoPopper: null,
        anchorSearchPopper: null,
        searchValue: ""
    }

    handleClick = () => {
        const { anchorInfoPopper } = this.state
        this.setState({ anchorInfoPopper: anchorInfoPopper ? null : document.getElementById("info_popper") })
    }

    handleSearch = e => {
        const { getTendersBySearch } = this.props
        const searchValue = e.target.value
        this.setState({ searchValue })
        if (searchValue.length >= 3) {
            getTendersBySearch(searchValue).then(() => {
                this.setState({ anchorSearchPopper: document.getElementById("search_popper") })
            })
        } else {
            this.setState({ anchorSearchPopper: null })
        }
    }

    handleOut = () => {
        const { history } = this.props
        localStorage.clear()
        history.push("/auth/login")
    }

    render() {
        const {
            options: {
                me: { role, fio, email }
            },
            deals
        } = this.props


        const { anchorInfoPopper, anchorSearchPopper, searchValue } = this.state
        const openInfoPopper = Boolean(anchorInfoPopper)
        const openSearchPopper = Boolean(anchorSearchPopper)
        return (
            <header className="app_header no_select">
                <div className="header_nav_wrapper">
                    <Link to="/main/tenders" style={{opacity: "1", color: "#5398ff"}}>NEW <img src={Logotype} alt="zakupki" /></Link>
                    {role === "admin" && (
                        <Fragment>
                            <NavLink to="/main/tenders">Заявки</NavLink>
                            <NavLink to="/main/deals">Сделки на расчёт</NavLink>
                            <NavLink to="/main/deals-in-progress">Сделки в работе</NavLink>
                            <NavLink to="/main/users">Пользователи</NavLink>
                            <NavLink to="/main/products">Товары</NavLink>
                            <NavLink to="/main/suppliers">Поставщики</NavLink>
                        </Fragment>
                    )}
                    {role === "senior_manager" && (
                        <Fragment>
                            <NavLink to="/main/tenders">Заявки</NavLink>
                            <NavLink to="/main/deals">Сделки на расчёт</NavLink>
                            <NavLink to="/main/deals-in-progress">Сделки в работе</NavLink>
                            <NavLink to="/main/products">Товары</NavLink>
                            <NavLink to="/main/suppliers">Поставщики</NavLink>
                        </Fragment>
                    )}
                    {role === "manager" && (
                        <Fragment>
                            <NavLink to="/main/deals">Сделки на расчёт</NavLink>
                            <NavLink to="/main/deals-in-progress">Сделки в работе</NavLink>
                            <NavLink to="/main/products">Товары</NavLink>
                            <NavLink to="/main/suppliers">Поставщики</NavLink>
                        </Fragment>
                    )}
                </div>
                <div className="header_controls_wrapper">
                    <p className="deals_search" id="search_popper">
                        <input
                            type="text"
                            value={searchValue}
                            onChange={this.handleSearch}
                            placeholder="Введите номер заявки"
                        />
                        <ClickAwayListener
                            onClickAway={() => openSearchPopper && this.setState({ anchorSearchPopper: false })}
                        >
                            <Popper open={openSearchPopper} anchorEl={anchorSearchPopper} placement="bottom-end">
                                {deals.length > 0 ? (
                                    <ScrollBar maxHeight="212px">
                                        <div className="deal_list">
                                            {deals.map(({ id, number, status }) => (
                                                <Link
                                                    to={`/main/${
                                                        status === "new"
                                                            ? "tender"
                                                            : status === "to_calculate"
                                                            ? "deal"
                                                            : "deal-in-progress"
                                                    }/${id}`}
                                                    key={`deal_${id}`}
                                                    onClick={() =>
                                                        this.setState({ anchorSearchPopper: false, searchValue: "" })
                                                    }
                                                >
                                                    {number}
                                                </Link>
                                            ))}
                                        </div>
                                    </ScrollBar>
                                ) : (
                                    <div className="no_notifications">Заявок не найдено</div>
                                )}
                            </Popper>
                        </ClickAwayListener>
                    </p>
                    <Notifications />
                    <ClickAwayListener onClickAway={openInfoPopper ? this.handleClick : () => true}>
                        <div className="info_wrapper">
                            <IconButton onClick={this.handleClick} id="info_popper">
                                <img
                                    className={clsx("more_icon", openInfoPopper && "active")}
                                    src={ArrowIcon}
                                    alt=">"
                                />
                            </IconButton>
                            <Popper open={openInfoPopper} anchorEl={anchorInfoPopper} placement="bottom-end">
                                <div className="info_popper">
                                    <div className="user_info">
                                        <div>{fio}</div>
                                        <p>{email}</p>
                                    </div>
                                    <hr />
                                    <div className="log_out" onClick={this.handleOut}>
                                        <img src={LogOut} alt="->" />
                                        Выйти
                                    </div>
                                </div>
                            </Popper>
                        </div>
                    </ClickAwayListener>
                </div>
            </header>
        )
    }
}

const mapStateToProps = state => {
    return {
        options: state.dashboard.options,
        deals: state.dashboard.searchedTenders
    }
}

const mapDispatchToProps = {
    getOptions,
    getTendersBySearch
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Head))
