import * as types from "../actions/constants";

const INITIAL_STATE = {
    suppliers: [],
    currentSupplier: {},
    supplierError: ""
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.GET_SUPPLIERS_LIST_SUCCESS:
            return { ...state, suppliers: action.payload.data };

        case types.GET_CURRENT_SUPPLIER_SUCCESS:
            return { ...state, currentSupplier: action.payload.data };

        case types.POST_SUPPLIER_FAIL:
            return { ...state, supplierError: action.error.response.data.name };

        case types.GET_CURRENT_SUPPLIER_FAIL:
            return { ...state, currentSupplier: {} };

        default:
            return state;
    }
}
