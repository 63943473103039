import React, { Component } from "react"
import { withRouter } from "react-router-dom"

import MultiSelectComponent from "../HelperComponents/MultiSelectComponent/MultiSelectComponent"
import SelectComponent from "../HelperComponents/SelectComponent/SelectComponent"
import { deals_statuses_list, execution_statuses, importance_List } from "../../helpers/functions"

import "./DealsInProgress.scss"

class DealsInProgressControls extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchConnectedManagers: "",
            searchResponsibleManagers: ""
        }
    }

    //adding filters to url
    handleLocation = () => {
        const {
            history,
            chosen_deals_statuses,
            chosen_execution_status,
            chosen_importance,
            chosen_connected_managers,
            chosen_responsible_managers
        } = this.props
        let queries = []
        if (chosen_deals_statuses.length > 0) {
            queries.push(encodeURIComponent(`chosen_deals_statuses=${chosen_deals_statuses.join(",")}`))
        }
        if (chosen_execution_status) {
            queries.push(encodeURIComponent(`chosen_execution_status=${chosen_execution_status}`))
        }
        if (chosen_importance) {
            queries.push(encodeURIComponent(`chosen_importance=${chosen_importance}`))
        }
        if (chosen_connected_managers.length > 0) {
            queries.push(encodeURIComponent(`chosen_connected_managers=${chosen_connected_managers.join(",")}`))
        }
        if (chosen_responsible_managers.length > 0) {
            queries.push(encodeURIComponent(`chosen_responsible_managers=${chosen_responsible_managers.join(",")}`))
        }
        history.push(`/main/deals-in-progress${queries.length && queries.length !== 0 ? "?" : ""}${queries.join("&")}`)
    }

    //deals statuses
    handleSelectChangeDS = async e => {
        const { setDealsStatuses } = this.props
        let chosen_deals_statuses = e.target.value.filter(el => el !== undefined)
        await setDealsStatuses(chosen_deals_statuses)
        this.handleLocation()
    }

    resetDS = () => {
        const { setDealsStatuses } = this.props
        setDealsStatuses([])
    }
    //deals statuses

    //execution statuses
    handleSelectChangeES = async e => {
        const { setExecutionStatuses } = this.props
        await setExecutionStatuses(e.target.value)
        this.handleLocation()
    }
    //execution statuses

    //importance
    handleImportanceChange = async e => {
        const { setImportanceStatuses } = this.props
        await setImportanceStatuses(e.target.value)
        this.handleLocation()
    }
    //importance

    //connected managers
    handleSelectChangeCM = async e => {
        const { setConnectedManagersStatuses } = this.props
        let chosen_connected_managers = e.target.value.filter(el => el !== undefined)
        await setConnectedManagersStatuses(chosen_connected_managers)
        this.handleLocation()
    }

    handleSearchCM = e => {
        this.setState({ searchConnectedManagers: e.target.value })
    }

    resetCM = () => {
        const { setConnectedManagersStatuses } = this.props
        setConnectedManagersStatuses([])
        this.setState({ searchConnectedManagers: "" })
    }
    //connected managers

    //responsible managers
    handleSelectChangeRM = async e => {
        const { setResponsibleManagersStatuses } = this.props
        let chosen_responsible_managers = e.target.value.filter(el => el !== undefined)
        await setResponsibleManagersStatuses(chosen_responsible_managers)
        this.handleLocation()
    }

    handleSearchRM = e => {
        this.setState({ searchResponsibleManagers: e.target.value })
    }

    resetRM = () => {
        const { setResponsibleManagersStatuses } = this.props
        setResponsibleManagersStatuses([])
        this.setState({ searchResponsibleManagers: "" })
    }
    //responsible managers

    resetFilters = async () => {
        const { setExecutionStatuses, setImportanceStatuses } = this.props
        await this.resetDS()
        await this.resetCM()
        await this.resetRM()
        await setExecutionStatuses("")
        await setImportanceStatuses("")
        this.handleLocation()
    }

    render() {
        const {
            role,
            usersList,
            chosen_deals_statuses,
            chosen_execution_status,
            chosen_importance,
            chosen_connected_managers,
            chosen_responsible_managers
        } = this.props
        const { searchConnectedManagers, searchResponsibleManagers } = this.state

        const connected_managers = usersList.filter(el =>
            el.toLowerCase().includes(searchConnectedManagers.toLowerCase())
        )
        const responsible_managers = usersList.filter(el =>
            el.toLowerCase().includes(searchResponsibleManagers.toLowerCase())
        )
        return (
            <div className="filters_wrapper">
                <div className="controls_panel no_select">
                    <MultiSelectComponent
                        item={chosen_deals_statuses}
                        items={deals_statuses_list}
                        handleChange={this.handleSelectChangeDS}
                        placeholder={"Статус"}
                        resetChosenUsers={this.resetDS}
                    />
                    <SelectComponent
                        item={chosen_execution_status}
                        items={execution_statuses}
                        handleChange={this.handleSelectChangeES}
                        placeholder={"Статус исполнения"}
                        useObj
                    />
                    {(role === "admin" || role === "senior_manager") && (
                        <MultiSelectComponent
                            item={chosen_connected_managers}
                            items={connected_managers}
                            handleChange={this.handleSelectChangeCM}
                            placeholder={"Привязанный"}
                            handleSearch={this.handleSearchCM}
                            searchPlaceholder={"Введите имя менеджера"}
                            searchValue={searchConnectedManagers}
                            resetChosenUsers={this.resetCM}
                        />
                    )}
                    <MultiSelectComponent
                        item={chosen_responsible_managers}
                        items={responsible_managers}
                        handleChange={this.handleSelectChangeRM}
                        placeholder={"Ответственный"}
                        handleSearch={this.handleSearchRM}
                        searchPlaceholder={"Введите имя менеджера"}
                        searchValue={searchResponsibleManagers}
                        resetChosenUsers={this.resetRM}
                    />
                    <SelectComponent
                        item={chosen_importance}
                        items={importance_List}
                        handleChange={this.handleImportanceChange}
                        placeholder={"Важная"}
                    />
                </div>
                <p onClick={() => this.resetFilters()} className="reset_filters_btn text_hover">
                    СБРОСИТЬ ФИЛЬТРЫ
                </p>
            </div>
        )
    }
}

export default withRouter(DealsInProgressControls)
