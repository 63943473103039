import React, { Component, Fragment } from "react"
import { Field, reduxForm, formValueSelector, SubmissionError } from "redux-form"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import moment from "moment"

import ErrorIcon from "@material-ui/icons/Error"
import { register } from "../../../actions/authActions"
import RenderField from "../../HelperComponents/RenderField/RenderField"
import DateField from "../../HelperComponents/RenderDatePicker/RenderDatePicker"
import DefaultButton from "../../HelperComponents/Buttons/DefaultButton/DefaultButton"
import TooltipMessage from "../../HelperComponents/TooltipMessage/TooltipMessage"

class Register extends Component {
    state = {
        loading: false
    }

    componentDidMount() {
        const { history } = this.props
        if (localStorage.token) {
            history.push("/main")
        }
    }

    submitForm = data => {
        const { register, history } = this.props
        this.setState({ loading: true })
        let body = {
            ...data
        }
        body.birthdate = moment(data.birthdate).format("YYYY-MM-DD")
        return register(body).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 201) {
                history.push("/auth/login")
            } else {
                this.setState({ loading: false })
                throw new SubmissionError({
                    ...res.error.response.data,
                    _error: res.error.response.data.detail
                })
            }
        })
    }

    render() {
        const { handleSubmit, submitting, pristine, valid, error_auth, birthdate } = this.props
        const { loading } = this.state
        return (
            <Fragment>
                <form onSubmit={handleSubmit(this.submitForm)} className="register_form">
                    <div className="auth_block_head">Создайте аккаунт</div>
                    <div className="auth_block_descriptions">Заполните поля ниже, чтобы зарегистрироваться</div>
                    <div className="personal_info_wrapper">
                        <Field name="fio" type="text" component={RenderField} label="ФИО" />
                        <Field
                            name="birthdate"
                            component={DateField}
                            label="Дата рождения"
                            disableFuture={true}
                            invalidDateMessage="Недопустимая дата рождения"
                            minDateMessage="Недопустимая дата рождения"
                            maxDateMessage="Недопустимая дата рождения"
                            invalidLabel="Недопустимая дата рождения"
                        />
                    </div>
                    <Field name="email" type="text" component={RenderField} label="Электронный адрес" />
                    <div className="passwords_wrapper">
                        <Field name="password" type="password" component={RenderField} label="Пароль" />
                        <Field
                            name="password_confirm"
                            type="password"
                            component={RenderField}
                            label="Подтвердите пароль"
                        />
                    </div>
                    <div className="auth_btn_wrapper">
                        <DefaultButton
                            variant="contained"
                            classes="sign_up_button"
                            disabled={submitting || pristine || !valid || (birthdate && !birthdate._isValid)}
                            loading={loading}
                            formAction
                        >
                            Зарегистрироваться
                        </DefaultButton>
                        {error_auth ? (
                            <TooltipMessage text={error_auth} delay={200} error position="right" classes="">
                                <ErrorIcon />
                            </TooltipMessage>
                        ) : (
                            ""
                        )}
                    </div>
                </form>
                <div className="there_is_account">
                    Уже есть аккаунт? &nbsp&nbsp&nbsp
                    <Link to="/auth/login" className="text_hover">
                        Войти
                    </Link>
                </div>
            </Fragment>
        )
    }
}

const validate = values => {
    const errors = {}
    if (!values.email) {
        errors.email = "Обязательное поле"
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i.test(values.email)) {
        errors.email = "Недопустимый электронный адрес"
    }
    if (!values.password) {
        errors.password = "Обязательное поле"
    } else if (values.password.length < 8) {
        errors.password = "Пароль должен содержать 8 или больше символов"
    }
    if (!values.birthdate) {
        errors.birthdate = "Недопустимая дата рождения"
    }
    if (!values.fio) {
        errors.fio = "Обязательное поле"
    }
    if (!values.password_confirm) {
        errors.password_confirm = "Обязательное поле"
    } else if (values.password !== values.password_confirm) {
        errors.password_confirm = "Пароли не совпадают"
    }
    return errors
}

Register = reduxForm({
    form: "RegisterForm",
    validate
})(Register)

const selector = formValueSelector("RegisterForm")

Register = connect(state => {
    const birthdate = selector(state, "birthdate")
    return {
        birthdate
    }
})(Register)

const mapDispatchToProps = {
    register
}

export default connect(null, mapDispatchToProps)(Register)
