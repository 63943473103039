import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import { Collapse } from "react-collapse"
import { Link } from "react-router-dom"
import clsx from "clsx"
import FormControl from "@material-ui/core/FormControl"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import FormGroup from "@material-ui/core/FormGroup"
import LinearProgress from "@material-ui/core/LinearProgress"
import moment from "moment"
import { Field, reduxForm, formValueSelector } from "redux-form"

import DefaultButton from "../HelperComponents/Buttons/DefaultButton/DefaultButton"
import DialogComponent from "../HelperComponents/DialogComponent/DialogComponent"
import PopperInfo from "../HelperComponents/PopperInfo/PopperInfo"
import Pagination from "../HelperComponents/Pagination/Pagination"
import { auction_types, formatNumber } from "../../helpers/functions"
import DateTimeField from "../HelperComponents/RenderDatePicker/RenderDateTimePicker"
import SelectComponent from "../HelperComponents/SelectComponent/SelectComponent"
import GenerateXLSDialog from "../GenerateXLSDialog/GenerateXLSDialog"

import {
    getTenders,
    patchTender,
    getManagers,
    setTendersPage,
    setTendersOrder,
    addSelectedTender,
    removeSelectedTender,
    clearSelectedTenders,
    bulkUpdateTenders
} from "../../actions/dashboardActions"

import "./Dashboard.scss"

import UpdateIcon from "../../assets/image/update.svg"
import SortIcon from "../../assets/image/sort.svg"
import DeleteIcon from "../../assets/image/delete.svg"
import ExpandIcon from "../../assets/image/expand.svg"
import CompleteIcon from "../../assets/image/complete.svg"
import FailedIcon from "../../assets/image/failed.svg"
import UncheckIcon from "../../assets/image/uncheck_all.svg"

class Dashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            reloading: true,
            selectedElements: [],
            radioValue: "first",
            openCalculateDialog: false,
            openBulkCalculateDialog: false,
            openDeleteDialog: false,
            openBulkDeleteDialog: false,
            currentTender: {},
            checkImportant: false,
            value: "",
            anchor: null,
            open: false,
            chosenManager: {},
            inputValue: "",
            chosen_auction_type: undefined
        }
    }

    componentDidMount() {
        this.doRequest()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.order !== this.props.order) this.doRequest()
        if (prevProps.page !== this.props.page) this.doRequest()
        if (prevState.inputValue !== this.state.inputValue) {
            this.props.setTendersPage(1)
            setTimeout(() => {
                this.doRequest()
            }, 0)
        }
    }

    // doRequest = async () => {
    //     const { getTenders, page, order } = this.props
    //     const { inputValue } = this.state
    //     this.setState({ reloading: true })
    //     const res = await getTenders(page, order, inputValue)
    //     if (res.payload.status === 200) {
    //         this.setState({ loading: false, reloading: false })
    //     }
    // }
    doRequest = async () => {
        const { getTenders, page, order } = this.props;
        const { inputValue } = this.state;
        this.setState({ reloading: true });
        try {
            const res = await getTenders(page, order, inputValue);
            if (res.payload && res.payload.status === 200) {
                this.setState({ loading: false, reloading: false });
            } else {
                console.error('Something went wrong:', res.payload);
                this.setState({ loading: false, reloading: false, error: true });
            }
        } catch (error) {
            console.error('Error during fetch operation:', error.message);
            this.setState({ loading: false, reloading: false, error: true });
        }
    };

    handleSelected = id => {
        const { selectedElements } = this.state
        if (selectedElements.includes(id)) {
            this.setState({
                selectedElements: selectedElements.filter(el => el !== id)
            })
        } else {
            this.setState({ selectedElements: [...selectedElements, id] })
        }
    }

    toggleDialog = type => {
        type === "calculate" &&
            this.setState(({ openCalculateDialog }) => ({
                openCalculateDialog: !openCalculateDialog
            }))
        type === "delete" &&
            this.setState(({ openDeleteDialog }) => ({
                openDeleteDialog: !openDeleteDialog
            }))
        type === "bulk-delete" &&
            this.setState(({ openBulkDeleteDialog }) => ({
                openBulkDeleteDialog: !openBulkDeleteDialog
            }))
        type === "bulk-calculate" &&
            this.setState(({ openBulkCalculateDialog }) => ({
                openBulkCalculateDialog: !openBulkCalculateDialog
            }))
    }

    deleteTender = async () => {
        const { patchTender } = this.props
        const { currentTender } = this.state
        this.setState({ reloading: true })
        const data = { status: "deleted" }
        await patchTender(currentTender, data)
        this.doRequest()
        this.toggleDialog("delete")
    }

    deleteTenders = async () => {
        const { bulkUpdateTenders, clearSelectedTenders } = this.props
        this.setState({ reloading: true })
        const data = { ids: Object.keys(this.props.selected), data: { status: "deleted" } }
        await bulkUpdateTenders(data)
        this.doRequest()
        clearSelectedTenders()
        this.toggleDialog("bulk-delete")
    }

    calculateTender = async values => {
        const { patchTender } = this.props
        const { currentTender, chosenManager, checkImportant, radioValue } = this.state
        this.setState({ reloading: true })

        let data = {
            is_important: checkImportant,
            custom_deadline: moment(values.deadline)
        }
        if (radioValue === "first") {
            data.status = "calculating"
            data.manager = chosenManager.id
        } else {
            data.status = "to_calculate"
            data.manager = null
        }
        await patchTender(currentTender, data)
        this.setState({
            chosenManager: {},
            radioValue: "first",
            checkImportant: false,
            value: ""
        })
        this.doRequest()
        this.toggleDialog("calculate")
    }

    bulkCalculateTenders = async () => {
        const { bulkUpdateTenders, clearSelectedTenders } = this.props
        const { chosenManager, checkImportant, radioValue, chosen_auction_type } = this.state
        this.setState({ reloading: true })

        let data = {
            is_important: checkImportant,
            type: chosen_auction_type.slice(1)
        }
        if (radioValue === "first") {
            data.status = "calculating"
            data.manager = chosenManager.id
        } else {
            data.status = "to_calculate"
            data.manager = null
        }
        await bulkUpdateTenders({ ids: Object.keys(this.props.selected), data })
        clearSelectedTenders()
        this.setState({
            chosenManager: {},
            radioValue: "first",
            checkImportant: false,
            value: ""
        })
        this.doRequest()
        this.toggleDialog("bulk-calculate")
    }

    handleInputChange = e => this.setState({ value: e.target.value, chosenManager: {} })

    handleKeyUp = async e => {
        const { getManagers } = this.props
        let value = e.target.value
        if (value.length >= 1 && value.trim()) {
            const res = await getManagers(value)
            if (res.payload && res.payload.status === 200 && res.payload.data.length > 0) {
                this.setState({
                    anchor: document.getElementById("search_input"),
                    open: true
                })
            } else {
                this.clickAway()
            }
        } else {
            this.clickAway()
        }
    }

    selectElement = chosenManager => {
        this.setState({ value: chosenManager.fio, chosenManager })
        this.clickAway()
    }

    clickAway = () => this.setState({ open: false })

    setSort = fieldName => {
        const { order, setTendersOrder } = this.props
        switch (order) {
            case fieldName:
                setTendersOrder("-" + fieldName)
                break
            case "-" + fieldName:
                setTendersOrder("")
                break
            default:
                setTendersOrder(fieldName)
                break
        }
    }

    handleSelectChangeAT = async e => {
        this.setState({ chosen_auction_type: e.target.value })
    }

    render() {
        const {
            tenders,
            managers,
            page,
            setTendersPage,
            count,
            handleSubmit,
            submitting,
            pristine,
            valid,
            deadline,
            options: {
                me: { role }
            }
        } = this.props
        const {
            loading,
            selectedElements,
            openDeleteDialog,
            openBulkDeleteDialog,
            openCalculateDialog,
            openBulkCalculateDialog,
            radioValue,
            reloading,
            checkImportant,
            value,
            anchor,
            open,
            chosenManager,
            inputValue,
            chosen_auction_type
        } = this.state

        if (loading) return <LinearProgress />
        return (
            <Fragment>
                {reloading ? <LinearProgress /> : <hr className="loader_dummy" />}
                <div className="page_wrapper applications_wrapper">
                    <div className="dashboard_head">
                        <h1>Новые заявки</h1>
                        <div>
                            <Link to="/main/add-custom-tender" className="usable_text text_hover">
                                + Добавить
                            </Link>
                            {role === "admin" && (
                                <GenerateXLSDialog />
                            )}
                        </div>
                    </div>
                    <div className="table_container">
                        <div className="table_header no_select">
                            <div className="table_row">
                                <div className="row_item"></div>
                                <div
                                    className="row_item text_hover"
                                    onClick={() => {
                                        this.setSort("number")
                                    }}
                                >
                                    <p>Номер</p>
                                    <img src={SortIcon} alt="" />
                                </div>
                                <div className="row_item">
                                    <p className="filter_by_name">
                                        <input
                                            type="text"
                                            value={inputValue}
                                            onChange={e => this.setState({ inputValue: e.target.value })}
                                            placeholder="Заказчик"
                                        />
                                    </p>
                                </div>
                                <div className="row_item">
                                    <p>Название тендера</p>
                                </div>
                                <div
                                    className="row_item text_hover"
                                    onClick={() => {
                                        this.setSort("datetime_dedline_request")
                                    }}
                                >
                                    <p>Окончание подачи заявок</p>
                                    <img src={SortIcon} alt="" />
                                </div>
                                <div
                                    className="row_item text_hover"
                                    onClick={() => {
                                        // this.setSort("handle_date")
                                        this.setSort("datetime_dedline_request")
                                    }}
                                >
                                    <p>Время проведения</p>
                                    <img src={SortIcon} alt="" />
                                </div>
                                <div className="row_item">
                                    <p>Сроки выполнения</p>
                                </div>
                                <div
                                    className="row_item text_hover"
                                    onClick={() => {
                                        this.setSort("start_price_contract")
                                    }}
                                >
                                    <p>Начальная цена контракта (лота)</p>
                                    <img src={SortIcon} alt="" />
                                </div>
                                <div className="row_item">Тип</div>
                                <div className="row_item" />
                                <div className="row_item" />
                                <div className="row_item" />
                            </div>
                        </div>
                        <div className="table_body">
                            {tenders.length > 0 ? (
                                tenders.map(
                                    ({
                                        id,
                                        has_columns_changed,
                                        archive_status,
                                        archive_status_display,
                                        columns_changed_at,
                                        status,
                                        status_display,
                                        manager,
                                        manager_assigned_at,
                                        is_important,
                                        type,
                                        type_display,
                                        data: {
                                            advantages_contract_price,
                                            archive_link,
                                            budget_code,
                                            budget_name,
                                            category,
                                            customer,
                                            customer_name,
                                            customer_full,
                                            customer_inn,
                                            date_delivery_goods,
                                            datetime_dedline_request,
                                            datetime_holding,
                                            delivery_address,
                                            e_place,
                                            end_review_request,
                                            link,
                                            name,
                                            number,
                                            reestr_number,
                                            organization_rate,
                                            organizer,
                                            organizer_full,
                                            oragnizer_inn,
                                            publication_date,
                                            publish_date,
                                            region,
                                            request_provision_size,
                                            request_type,
                                            start_price_contract,
                                            tender_curency
                                        }
                                    }) => (
                                        <Fragment key={id}>
                                            <div
                                                className={clsx(
                                                    "table_row",
                                                    selectedElements.includes(id) && "margin_for_row"
                                                )}
                                            >
                                                <div className="row_item">
                                                    <Checkbox
                                                        checked={Object.keys(this.props.selected).includes(
                                                            id.toString()
                                                        )}
                                                        onChange={() => {
                                                            if (
                                                                Object.keys(this.props.selected).includes(id.toString())
                                                            ) {
                                                                this.props.removeSelectedTender(id)
                                                            } else {
                                                                this.props.addSelectedTender({
                                                                    id,
                                                                    hasType: type !== ""
                                                                })
                                                            }
                                                        }}
                                                        color="primary"
                                                        inputProps={{"aria-label": "primary checkbox"}}
                                                    />
                                                </div>
                                                <div className="row_item usable_text">
                                                    <Link to={`/main/tender/${id}`}>
                                                        {number || reestr_number}
                                                        {archive_status === "downloaded" ? (
                                                            <img src={CompleteIcon} alt="complete"/>
                                                        ) : archive_status === "error" ? (
                                                            <img src={FailedIcon} alt="failed"/>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </Link>
                                                    {has_columns_changed && (
                                                        <p className="">
                                                            <img src={UpdateIcon} alt=""/>
                                                            Обновлено
                                                        </p>
                                                    )}
                                                </div>
                                                <div className="row_item">
                                                    <span className="overflowed_text" title={customer || customer_name}>
                                                        {customer || customer_name}
                                                    </span>
                                                </div>
                                                <div className="row_item">
                                                    <span className="overflowed_text" title={name}>
                                                        {name}
                                                    </span>
                                                </div>
                                                <div className="row_item">
                                                    {datetime_dedline_request === "-" || !moment(datetime_dedline_request).isValid()
                                                        ? datetime_dedline_request
                                                        : moment(datetime_dedline_request).format("DD/MM/YY HH:mm")}
                                                </div>
                                                <div className="row_item">
                                                    {datetime_holding && datetime_holding !== "-" && moment(datetime_holding).isValid()
                                                        ? moment(datetime_holding).format("DD/MM/YY HH:mm")
                                                        : "-"}
                                                </div>
                                                <div className="row_item">
                                                    {/*<span className="overflowed_text" title={date_delivery_goods}>*/}
                                                    {/*    {date_delivery_goods || "-"}*/}
                                                    {/*</span>*/}
                                                    <span className="overflowed_text" title={date_delivery_goods}>
                                                        {
                                                            date_delivery_goods
                                                                ? date_delivery_goods.indexOf(":")
                                                                    ? date_delivery_goods.split(":")[0]
                                                                    : date_delivery_goods
                                                                : "-"
                                                        }
                                                    </span>
                                                </div>
                                                <div className="row_item">
                                                    {formatNumber(parseFloat(start_price_contract).toFixed(2))}
                                                </div>
                                                <div className="row_item">{type || "-"}</div>
                                                <div className="row_item no_select">
                                                    {type ? (
                                                        <p
                                                            className="usable_text text_hover"
                                                            onClick={() => {
                                                                this.setState({currentTender: id})
                                                                this.toggleDialog("calculate")
                                                            }}
                                                        >
                                                            Рассчитать
                                                        </p>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                                <div className="row_item no_select">
                                                    <p
                                                        className="text_hover"
                                                        onClick={() => {
                                                            this.setState({currentTender: id})
                                                            this.toggleDialog("delete")
                                                        }}
                                                    >
                                                        <img src={DeleteIcon} alt="del"/>
                                                    </p>
                                                </div>
                                                <div
                                                    className="row_item no_select"
                                                    onClick={() => this.handleSelected(id)}
                                                >
                                                    <p className="text_hover">
                                                        <img
                                                            src={ExpandIcon}
                                                            alt=">"
                                                            className={clsx(
                                                                selectedElements.includes(id) && "transform_rotate"
                                                            )}
                                                        />
                                                    </p>
                                                </div>
                                            </div>
                                            <Collapse isOpened={selectedElements.includes(id)}>
                                                <div className="collapsed_block">
                                                    <div>
                                                        <div>
                                                            <span>Тип тендера</span>
                                                            <p title={request_type}>{request_type}</p>
                                                        </div>
                                                        <div>
                                                            <span>Категория</span>
                                                            <p>{category}</p>
                                                        </div>
                                                        <div>
                                                            <span>Сайт</span>
                                                            <p>
                                                                {link ? (
                                                                    <a
                                                                        className="text_hover"
                                                                        href={
                                                                            link.includes("http") ? link : `//${link}`
                                                                        }
                                                                        rel="noopener noreferrer"
                                                                        target="_blank"
                                                                    >
                                                                        {link}
                                                                    </a>
                                                                ) : (
                                                                    "-"
                                                                )}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div>
                                                            <span>Размер обеспечения заявки</span>
                                                            <p>
                                                                {request_provision_size
                                                                    ? formatNumber(
                                                                          parseFloat(
                                                                              request_provision_size
                                                                                  .toString()
                                                                                  .replace(",", ".")
                                                                          )
                                                                      )
                                                                    : "-"}
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <span>Размер обеспечения контракта</span>
                                                            <p>-</p>
                                                        </div>
                                                        <div>
                                                            <span>Электронная площадка</span>
                                                            <p>
                                                                {e_place ? (
                                                                    <a
                                                                        className="text_hover"
                                                                        href={
                                                                            e_place.includes("http")
                                                                                ? e_place
                                                                                : `//${e_place}`
                                                                        }
                                                                        rel="noopener noreferrer"
                                                                        target="_blank"
                                                                    >
                                                                        {e_place}
                                                                    </a>
                                                                ) : (
                                                                    "-"
                                                                )}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Collapse>
                                        </Fragment>
                                    )
                                )
                            ) : (
                                <div className="table_row">Извините, сейчас новых сделок нет</div>
                            )}
                        </div>
                        <hr />
                    </div>

                    <Pagination
                        active={page - 1}
                        pageCount={count / 50}
                        onChange={e => {
                            setTendersPage(e.selected + 1)
                            window.scrollTo(0, 100)
                        }}
                    />

                    <DialogComponent open={openCalculateDialog} onClose={() => this.toggleDialog("calculate")}>
                        <div className="dashboard_dialog no_select">
                            <h2>Рассчитать заявку</h2>
                            <br />
                            <form onSubmit={handleSubmit(this.calculateTender)}>
                                <FormControl component="fieldset">
                                    <RadioGroup
                                        value={radioValue}
                                        onChange={e => this.setState({ radioValue: e.target.value })}
                                    >
                                        <FormControlLabel
                                            value="first"
                                            control={<Radio />}
                                            label="Назначить менеджера"
                                        />
                                        <FormControlLabel value="second" control={<Radio />} label="Общий доступ" />
                                    </RadioGroup>
                                </FormControl>
                                <div className={clsx("search_input_wrapper", radioValue === "second" && "disabled")}>
                                    <input
                                        id="search_input"
                                        type="text"
                                        className="manager_input"
                                        placeholder="Введите имя менеджера"
                                        onKeyUp={this.handleKeyUp}
                                        onChange={this.handleInputChange}
                                        value={value}
                                        disabled={radioValue === "second"}
                                        autoComplete="off"
                                    />
                                    <PopperInfo
                                        open={open}
                                        anchorEl={anchor}
                                        clickAway={this.clickAway}
                                        position="bottom-start"
                                        classes="manager_popper"
                                        transition
                                    >
                                        <ul>
                                            {managers.map(el => (
                                                <li
                                                    className="text_hover"
                                                    onClick={() => this.selectElement(el)}
                                                    key={el.id}
                                                >
                                                    {el.fio}
                                                </li>
                                            ))}
                                        </ul>
                                    </PopperInfo>
                                </div>
                                <div className="deadline_and_important">
                                    <Field
                                        name="deadline"
                                        component={DateTimeField}
                                        label="Дедлайн"
                                        disablePast={true}
                                        invalidDateMessage="Недопустимый дедлайн"
                                        minDateMessage="Недопустимый дедлайн"
                                        maxDateMessage="Недопустимый дедлайн"
                                        invalidLabel="Недопустимый дедлайн"
                                    />
                                    <FormControl component="fieldset">
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={checkImportant}
                                                        onChange={() =>
                                                            this.setState({ checkImportant: !checkImportant })
                                                        }
                                                        color="primary"
                                                        inputProps={{ "aria-label": "secondary checkbox" }}
                                                    />
                                                }
                                                label="Отметить заявку как важную"
                                            />
                                        </FormGroup>
                                    </FormControl>
                                </div>
                                <div className="dialog_buttons">
                                    <DefaultButton
                                        variant="outlined"
                                        onClick={() => this.toggleDialog("calculate")}
                                        classes="cancel_btn"
                                    >
                                        Отмена
                                    </DefaultButton>
                                    <DefaultButton
                                        variant="contained"
                                        formAction
                                        classes="confirm_btn"
                                        disabled={
                                            (!chosenManager.fio && radioValue === "first") ||
                                            (deadline && !deadline._isValid) ||
                                            !valid ||
                                            submitting ||
                                            pristine
                                        }
                                        loading={reloading}
                                    >
                                        Рассчитать
                                    </DefaultButton>
                                </div>
                            </form>
                        </div>
                    </DialogComponent>
                    <DialogComponent open={openBulkCalculateDialog} onClose={() => this.toggleDialog("bulk-calculate")}>
                        <div className="dashboard_dialog no_select">
                            <h2>Рассчитать выбранные заявки</h2>
                            <br />
                            <FormControl component="fieldset">
                                <RadioGroup
                                    value={radioValue}
                                    onChange={e => this.setState({ radioValue: e.target.value })}
                                >
                                    <FormControlLabel value="first" control={<Radio />} label="Назначить менеджера" />
                                    <FormControlLabel value="second" control={<Radio />} label="Общий доступ" />
                                </RadioGroup>
                            </FormControl>
                            <div className={clsx("search_input_wrapper", radioValue === "second" && "disabled")}>
                                <input
                                    id="search_input"
                                    type="text"
                                    className="manager_input"
                                    placeholder="Введите имя менеджера"
                                    onKeyUp={this.handleKeyUp}
                                    onChange={this.handleInputChange}
                                    value={value}
                                    disabled={radioValue === "second"}
                                    autoComplete="off"
                                />
                                <PopperInfo
                                    open={open}
                                    anchorEl={anchor}
                                    clickAway={this.clickAway}
                                    position="bottom-start"
                                    classes="manager_popper"
                                    transition
                                >
                                    <ul>
                                        {managers.map(el => (
                                            <li
                                                className="text_hover"
                                                onClick={() => this.selectElement(el)}
                                                key={el.id}
                                            >
                                                {el.fio}
                                            </li>
                                        ))}
                                    </ul>
                                </PopperInfo>
                            </div>
                            <div className="deadline_and_important">
                                <SelectComponent
                                    item={chosen_auction_type}
                                    items={auction_types}
                                    handleChange={this.handleSelectChangeAT}
                                    placeholder={"Тип аукциона"}
                                />
                                <FormControl component="fieldset">
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={checkImportant}
                                                    onChange={() => this.setState({ checkImportant: !checkImportant })}
                                                    color="primary"
                                                    inputProps={{ "aria-label": "secondary checkbox" }}
                                                />
                                            }
                                            label="Отметить заявку как важную"
                                        />
                                    </FormGroup>
                                </FormControl>
                            </div>
                            <div className="dialog_buttons">
                                <DefaultButton
                                    variant="outlined"
                                    onClick={() => this.toggleDialog("bulk-calculate")}
                                    classes="cancel_btn"
                                >
                                    Отмена
                                </DefaultButton>
                                <DefaultButton
                                    variant="contained"
                                    onClick={this.bulkCalculateTenders}
                                    classes="confirm_btn"
                                    disabled={!chosen_auction_type || (!chosenManager.fio && radioValue === "first")}
                                    loading={reloading}
                                >
                                    Рассчитать
                                </DefaultButton>
                            </div>
                        </div>
                    </DialogComponent>
                    <DialogComponent open={openDeleteDialog} onClose={() => this.toggleDialog("delete")}>
                        <div className="dashboard_dialog no_select">
                            <h2>Удалить заявку</h2>
                            <div className="dialog_description">Вы уверены, что хотите удалить эту заявку?</div>
                            <div className="dialog_buttons">
                                <DefaultButton
                                    variant="outlined"
                                    onClick={() => this.toggleDialog("delete")}
                                    classes="cancel_btn"
                                >
                                    Отмена
                                </DefaultButton>
                                <DefaultButton
                                    variant="contained"
                                    onClick={this.deleteTender}
                                    classes="confirm_btn"
                                    loading={reloading}
                                >
                                    Удалить
                                </DefaultButton>
                            </div>
                        </div>
                    </DialogComponent>
                    <DialogComponent open={openBulkDeleteDialog} onClose={() => this.toggleDialog("bulk-delete")}>
                        <div className="dashboard_dialog no_select">
                            <h2>Удалить выбранные заявки</h2>
                            <div className="dialog_description">Вы уверены, что хотите удалить эти заявки?</div>
                            <div className="dialog_buttons">
                                <DefaultButton
                                    variant="outlined"
                                    onClick={() => this.toggleDialog("bulk-delete")}
                                    classes="cancel_btn"
                                >
                                    Отмена
                                </DefaultButton>
                                <DefaultButton
                                    variant="contained"
                                    onClick={this.deleteTenders}
                                    classes="confirm_btn"
                                    loading={reloading}
                                >
                                    Удалить
                                </DefaultButton>
                            </div>
                        </div>
                    </DialogComponent>
                </div>

                {Object.keys(this.props.selected).length > 0 && (
                    <div className="bulk-panel">
                        <div className="bulk-uncheck" onClick={this.props.clearSelectedTenders}>
                            <img src={UncheckIcon} alt="uncheck" />
                            <span className="bulk-uncheck-text">
                                <strong>{Object.keys(this.props.selected).length}</strong> выбрано
                            </span>
                        </div>
                        <div className="bulk-control">
                            {/*{Object.values(this.props.selected).every(obj => obj) && (*/}
                            <button className="button-primary" onClick={() => this.toggleDialog("bulk-calculate")}>
                                РАССЧИТАТЬ
                            </button>
                            {/*)}*/}

                            <button className="button-delete" onClick={() => this.toggleDialog("bulk-delete")}>
                                УДАЛИТЬ
                            </button>
                        </div>
                        <div></div>
                    </div>
                )}
            </Fragment>
        )
    }
}

const validate = values => {
    const errors = {}
    if (!values.deadline) {
        errors.deadline = "Недопустимый дедлайн"
    }
    return errors
}

Dashboard = reduxForm({
    form: "DashboardTenderForm",
    validate
})(Dashboard)

const selector = formValueSelector("DashboardTenderForm")

Dashboard = connect(state => {
    const deadline = selector(state, "deadline")
    return {
        deadline
    }
})(Dashboard)

const mapStateToProps = state => {
    return {
        tenders: state.dashboard.tenders,
        managers: state.dashboard.managers,
        page: state.dashboard.tendersPage,
        order: state.dashboard.tendersOrder,
        count: state.dashboard.tendersCount,
        selected: state.dashboard.selectedTenders,
        options: state.dashboard.options
    }
}
const mapDispatchToProps = {
    getTenders,
    patchTender,
    getManagers,
    setTendersPage,
    setTendersOrder,
    addSelectedTender,
    removeSelectedTender,
    clearSelectedTenders,
    bulkUpdateTenders
}
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
