import React, {Component} from "react"

import DialogComponent from "../HelperComponents/DialogComponent/DialogComponent"
import DefaultButton from "../HelperComponents/Buttons/DefaultButton/DefaultButton"

import CheckedIcon from "../../assets/image/checked_box.svg"
import UncheckedIcon from "../../assets/image/unchecked_box.svg"
import ExcelIcon from "../../assets/image/excel.svg"
import {API_BASE_URL} from "../../config"

class GenerateXLSDialog extends Component {
    state = {
        pages: [],
        generateDialogIsOpen: false
    }

    handlePages = page => {
        const { pages } = this.state
        if (pages.includes(page)) {
            this.setState({ pages: pages.filter(el => el !== page) })
        } else {
            this.setState({ pages: [ ...pages, page ] })
        }
    }

    downloadXLS = () => {
        const { pages } = this.state
        window.open(`${API_BASE_URL}/admin/excel-report/?token=${localStorage.getItem("token")}&pages=${pages.join(",")}`, '_blank')
        this.setState({generateDialogIsOpen: false })
    }

    render() {
        const { pages, generateDialogIsOpen } = this.state
        return (
            <>
                <div className="download_xls" onClick={() => this.setState({ generateDialogIsOpen: true })}>
                    <img src={ExcelIcon} alt="excel"/>
                    Выгрузить в xls
                </div>
                <DialogComponent
                    open={generateDialogIsOpen}
                    onClose={() => this.setState({ generateDialogIsOpen: false })}
                >
                    <div className="dashboard_dialog no_select">
                        <h2>Выгрузить в XLS</h2>
                        <div className="chosen_pages_to_generate">
                            <div onClick={() => this.handlePages("closest")}>
                                <img src={pages.includes("closest") ? CheckedIcon : UncheckedIcon} alt="check-box"/>
                                Ближайшие торги
                            </div>
                            <div onClick={() => this.handlePages("won")}>
                                <img src={pages.includes("won") ? CheckedIcon : UncheckedIcon} alt="check-box"/>
                                Выигранные
                            </div>
                            <div onClick={() => this.handlePages("other")}>
                                <img src={pages.includes("other") ? CheckedIcon : UncheckedIcon} alt="check-box"/>
                                Все заявки
                            </div>
                        </div>
                        <div className="dialog_buttons">
                            <DefaultButton
                                variant="outlined"
                                onClick={() => this.setState({ generateDialogIsOpen: false })}
                                classes="cancel_btn"
                            >
                                Отмена
                            </DefaultButton>
                            <DefaultButton
                                variant="contained"
                                onClick={this.downloadXLS}
                                classes="confirm_btn"
                            >
                                Выгрузить
                            </DefaultButton>
                        </div>
                    </div>
                </DialogComponent>
            </>
        )
    }
}


export default GenerateXLSDialog