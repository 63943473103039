import React, { Component, Fragment } from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import clsx from "clsx";

import { recoverPassword } from "../../../actions/authActions";

import DefaultButton from "../../HelperComponents/Buttons/DefaultButton/DefaultButton";

class PasswordRecoverySuccess extends Component {
    state = {
        loading: false
    };

    componentDidMount() {
        const { history } = this.props;
        if (!localStorage.getItem("email")) {
            history.push("/auth/password-recovery");
        }
        if (localStorage.token) {
            history.push("/main");
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
        localStorage.removeItem("email");
    }

    submitForm = () => {
        const { history } = this.props;
        history.push("/auth/login");
    };

    repeatPasswordRecovery = () => {
        const { recoverPassword } = this.props;
        recoverPassword(JSON.parse(localStorage.getItem("email")));
        this.setState({ loading: true });
        this.timeout = setTimeout(() => {
            this.setState({ loading: false });
        }, 10000);
    };

    render() {
        const { handleSubmit } = this.props;
        const { loading } = this.state;
        return (
            <Fragment>
                <form onSubmit={handleSubmit(this.submitForm)}>
                    <div className="auth_block_head">Восстановление пароля</div>
                    <div className="auth_block_descriptions">
                        Мы отправили новый пароль на указанный вами электронный адрес. Используйте этот пароль для
                        авторизации.
                    </div>
                    <div className="auth_btn_wrapper">
                        <DefaultButton variant="contained" classes="sign_up_button" formAction>
                            Войти
                        </DefaultButton>
                    </div>
                </form>

                <div className="no_mail">
                    Не пришло письмо? &nbsp;&nbsp;&nbsp;
                    <span
                        className={clsx("text_hover", loading && "lower_opacity")}
                        onClick={this.repeatPasswordRecovery}
                    >
                        Отправить ещё раз
                    </span>
                </div>
            </Fragment>
        );
    }
}

PasswordRecoverySuccess = reduxForm({
    form: "PasswordRecoverySuccessForm"
})(PasswordRecoverySuccess);

const mapDispatchToProps = {
    recoverPassword
};

export default connect(null, mapDispatchToProps)(PasswordRecoverySuccess);
