import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import LinearProgress from "@material-ui/core/LinearProgress"
import moment from "moment"
import clsx from "clsx"
import ClickAwayListener from "@material-ui/core/ClickAwayListener/ClickAwayListener"

import DefaultButton from "../../HelperComponents/Buttons/DefaultButton/DefaultButton"
import DialogComponent from "../../HelperComponents/DialogComponent/DialogComponent"
import Snack from "../../HelperComponents/Snack/Snack"
import ManagerSelector from "../../HelperComponents/ManagerSelector/ManagerSelector"
import Calculation from "../../Calculation/Calculation"
import DealsInProgressDialogs from "../DealsInProgressDialogs"
import RenderField from "../../HelperComponents/RenderField/RenderField"

import { formatNumber } from "../../../helpers/functions"

import { getDeal, patchTender, getUsers, getTender, reDownloadTender } from "../../../actions/dashboardActions"
import { getCategories, getCharacteristics } from "../../../actions/productsActions"
import {
    getOwners,
    downloadContractFiles,
    getContractFiles,
    addCommentToContracts,
    changeCommentContracts,
    getCalculationDocuments,
    createCalculationDocuments,
    updateCalculationDocuments,
    deleteCalculationDocuments,
    getFilesGK,
    changePlacedByBot,
    getAllOwners
} from "../../../actions/dealsActions"

import BackIcon from "../../../assets/image/back.svg"
import ArrowDownIcon from "../../../assets/image/arrow_down.svg"
import CheckIcon from "../../../assets/image/check.svg"
import DocumentIcon from "../../../assets/image/file.svg"
import FailedIcon from "../../../assets/image/failed.svg"
import ExpandIcon from "../../../assets/image/expand.svg"
import EditDateIcon from "../../../assets/image/edit_date.svg"
import EditImg from "../../../assets/image/edit.svg"

import "../../Deals/Deal/Deal.scss"
import "./DealInProgress.scss"
import { Field, formValueSelector, reduxForm, change } from "redux-form"
import DateTimeField from "../../HelperComponents/RenderDatePicker/RenderDateTimePicker"

class DealInProgress extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            reloading: true,
            selectedElements: [],
            responsibleSelector: null,
            managerSelector: null,
            openChangeTypeDialog: false,
            openChangeDeadlineDialog: false,
            openDeleteDialog: false,
            openBackDialog: false,
            openToDeleteDialog: false,
            openDeleteAdmin: false,
            openToCheckDialog: false,
            openApproveDialog: false,
            openToFixDialog: false,
            openDeclineDialog: false,
            openPostDialog: false,
            openWonDialog: false,
            openLostDialog: false,
            openSignedDialog: false,
            openExecuteDialog: false,
            openExecutedDialog: false,
            openClosedDialog: false,
            openCommercialProposal: false,
            openChangeDialog: false,

            openErrorSnack: false,
            active_tab: 0,
            openTypeControl: false,
            chosenType: "",
            chosenFiles: [],
            documentsModal: false,
            areaValue: "",
            verifiedFile: null,
            verifiedModal: false,
            calculationDocuments: [null],
            firstPlacingDialog: null,
            placedDeal: null
        }
    }

    async componentDidMount() {
        const {
            match: {
                params: { id }
            },
            getContractFiles,
            getCalculationDocuments,
            getFilesGK
        } = this.props
        this.doRequest()
        getContractFiles(id).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                this.setState({ areaValue: res && res.payload && res.payload.data && res.payload.data.comment })
            } else {
            }
        })
        getFilesGK(id)
        getCalculationDocuments(id).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                this.setState({
                    calculationDocuments:
                        res && res.payload && res.payload.data && res.payload.data.length > 0
                            ? res.payload.data.map(el => ({ ...el, downloaded: true }))
                            : [null]
                })
            } else {
            }
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            const {
                match: {
                    params: { id }
                },
                getContractFiles
            } = this.props
            this.doRequest()
            getContractFiles(id).then(res => {
                if (res.payload && res.payload.status && res.payload.status === 200) {
                    this.setState({ areaValue: res && res.payload && res.payload.data && res.payload.data.comment })
                } else {
                }
            })
        }
    }

    doRequest = async () => {
        const {
            getDeal,
            getUsers,
            getTender,
            getCategories,
            getCharacteristics,
            getOwners,
            getAllOwners,
            match: {
                params: { id }
            },
            history,
            change
        } = this.props
        this.setState({ reloading: true })
        await getUsers()
        await getCategories()
        // await getTender(this.props.match.params.id)
        await getCharacteristics()
        await getOwners()
        await getAllOwners()
        const res = await getDeal(id)
        if (id) getTender(id)
        if (res.payload && res.payload.status === 200) {
            change("deadline", res.payload.data.custom_deadline)
            this.setState({ loading: false, reloading: false })
        } else {
            history.push("/main/deals-in-progress")
        }
    }

    toggleDialog = type => {
        type === "change_deadline" &&
            this.setState(({ openChangeDeadlineDialog }) => ({
                openChangeDeadlineDialog: !openChangeDeadlineDialog
            }))
        type === "change_type" &&
            this.setState(({ openChangeTypeDialog }) => ({
                openChangeTypeDialog: !openChangeTypeDialog
            }))
        type === "delete" &&
            this.setState(({ openDeleteDialog }) => ({
                openDeleteDialog: !openDeleteDialog
            }))
        type === "back_to_work" &&
            this.setState(({ openBackDialog }) => ({
                openBackDialog: !openBackDialog
            }))

        type === "to_delete" &&
            this.setState(({ openToDeleteDialog }) => ({
                openToDeleteDialog: !openToDeleteDialog
            }))
        type === "delete_admin" &&
            this.setState(({ openDeleteAdmin }) => ({
                openDeleteAdmin: !openDeleteAdmin
            }))
        type === "to_check" &&
            this.setState(({ openToCheckDialog }) => ({
                openToCheckDialog: !openToCheckDialog
            }))
        type === "approve" &&
            this.setState(({ openApproveDialog }) => ({
                openApproveDialog: !openApproveDialog
            }))
        type === "to_fix" &&
            this.setState(({ openToFixDialog }) => ({
                openToFixDialog: !openToFixDialog
            }))
        type === "decline" &&
            this.setState(({ openDeclineDialog }) => ({
                openDeclineDialog: !openDeclineDialog
            }))
        type === "post" &&
            this.setState(({ openPostDialog }) => ({
                openPostDialog: !openPostDialog
            }))
        type === "postFirst" &&
            this.setState(({ firstPlacingDialog }) => ({
                firstPlacingDialog: !firstPlacingDialog
            }))

        type === "won" &&
            this.setState(({ openWonDialog }) => ({
                openWonDialog: !openWonDialog
            }))
        type === "lost" &&
            this.setState(({ openLostDialog }) => ({
                openLostDialog: !openLostDialog
            }))
        type === "sign" &&
            this.setState(({ openSignedDialog }) => ({
                openSignedDialog: !openSignedDialog
            }))
        type === "to_execute" &&
            this.setState(({ openExecuteDialog }) => ({
                openExecuteDialog: !openExecuteDialog
            }))
        type === "executed" &&
            this.setState(({ openExecutedDialog }) => ({
                openExecutedDialog: !openExecutedDialog
            }))
        type === "closed" &&
            this.setState(({ openClosedDialog }) => ({
                openClosedDialog: !openClosedDialog
            }))
        type === "commercial_proposal" &&
            this.setState(({ openCommercialProposal }) => ({
                openCommercialProposal: !openCommercialProposal
            }))
    }

    handleTypeControl = () => this.setState(({ openTypeControl }) => ({ openTypeControl: !openTypeControl }))

    changeTab = active_tab => {
        this.setState({ active_tab })
        active_tab === 2 && this.doRequest()
    }

    changeType = async () => {
        const {
            patchTender,
            match: {
                params: { id }
            }
        } = this.props
        const { chosenType } = this.state
        let data = {
            type: chosenType
        }
        this.handleTypeControl()
        await patchTender(id, data)
        this.doRequest()
        this.toggleDialog("change_type")
    }

    reDownloadFiles = async () => {
        const {
            reDownloadTender,
            match: {
                params: { id }
            }
        } = this.props
        const res = await reDownloadTender(id)
        if (res.error) {
            this.setState({ openErrorSnack: true })
        } else {
            this.doRequest()
        }
    }

    closeSnack = () => this.setState({ openErrorSnack: false })

    handleManagerSelector = id => {
        const { managerSelector } = this.state
        if (managerSelector === id) {
            this.setState({ managerSelector: null })
        } else {
            this.setState({ managerSelector: id })
        }
    }

    handleResponsibleSelector = id => {
        const { responsibleSelector } = this.state
        if (responsibleSelector === id) {
            this.setState({ responsibleSelector: null })
        } else {
            this.setState({ responsibleSelector: id })
        }
    }

    getButtons = () => {
        const {
            role,
            deal: { status_display, execution_status_display, is_bot_involved, placed_by }
        } = this.props

        if (status_display === "Расчёт") {
            if (role === "admin") {
                return (
                    <div className="dialog_buttons">
                        <div>
                            <DefaultButton
                                variant="outlined"
                                onClick={() => this.toggleDialog("delete")}
                                classes="delete_btn"
                            >
                                Удалить
                            </DefaultButton>
                            <DefaultButton
                                variant="contained"
                                onClick={() => this.toggleDialog("to_check")}
                                classes="calculate_btn"
                            >
                                На проверку
                            </DefaultButton>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className="dialog_buttons">
                        <div>
                            <DefaultButton
                                variant="outlined"
                                onClick={() => this.toggleDialog("to_delete")}
                                classes="delete_btn"
                            >
                                На удаление
                            </DefaultButton>
                            <DefaultButton
                                variant="contained"
                                onClick={() => this.toggleDialog("to_check")}
                                classes="calculate_btn"
                            >
                                На проверку
                            </DefaultButton>
                        </div>
                    </div>
                )
            }
        } else if (status_display === "На удаление") {
            if (role === "admin") {
                return (
                    <div className="dialog_buttons">
                        <div>
                            <DefaultButton
                                variant="outlined"
                                onClick={() => this.toggleDialog("delete_admin")}
                                classes="delete_btn"
                            >
                                Удалить
                            </DefaultButton>
                            <DefaultButton
                                variant="contained"
                                onClick={() => this.toggleDialog("back_to_work")}
                                classes="calculate_btn"
                            >
                                Вернуть в работу
                            </DefaultButton>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className="dialog_buttons">
                        <div>
                            <DefaultButton
                                variant="contained"
                                onClick={() => this.toggleDialog("back_to_work")}
                                classes="calculate_btn"
                            >
                                Вернуть в работу
                            </DefaultButton>
                        </div>
                    </div>
                )
            }
        } else if (status_display === "На проверке") {
            if (role === "admin") {
                return (
                    <div className="dialog_buttons">
                        <div>
                            <DefaultButton
                                variant="outlined"
                                onClick={() => this.toggleDialog("delete")}
                                classes="delete_btn"
                            >
                                Удалить
                            </DefaultButton>
                            <DefaultButton
                                variant="contained"
                                onClick={() => this.toggleDialog("approve")}
                                classes="calculate_btn"
                            >
                                Одобрить
                            </DefaultButton>
                        </div>
                        <div className="two_buttons">
                            <div className="remove_deal text_hover" onClick={() => this.toggleDialog("to_fix")}>
                                Отправить на доработку
                            </div>
                            <div
                                className="remove_deal text_hover red_text"
                                onClick={() => this.toggleDialog("decline")}
                            >
                                Отказаться
                            </div>
                        </div>
                    </div>
                )
            } else {
                return <Fragment />
            }
        } else if (status_display === "Отказано") {
            if (role === "admin") {
                return (
                    <div className="dialog_buttons">
                        <div>
                            <DefaultButton
                                variant="outlined"
                                onClick={() => this.toggleDialog("delete")}
                                classes="delete_btn"
                            >
                                Удалить
                            </DefaultButton>
                        </div>
                    </div>
                )
            } else {
                return <Fragment />
            }
        } else if (status_display === "Одобрена") {
            if (role === "admin") {
                return (
                    <div className="dialog_buttons">
                        <div>
                            <DefaultButton
                                variant="outlined"
                                onClick={() => this.toggleDialog("delete")}
                                classes="delete_btn"
                            >
                                Удалить
                            </DefaultButton>
                            <DefaultButton
                                variant="contained"
                                onClick={() => {
                                    this.toggleDialog("post")
                                }}
                                classes="calculate_btn"
                            >
                                Разместить
                            </DefaultButton>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className="dialog_buttons">
                        <div>
                            <DefaultButton
                                variant="contained"
                                onClick={() =>
                                    is_bot_involved ? this.toggleDialog("postFirst") : this.toggleDialog("post")
                                }
                                classes="calculate_btn"
                            >
                                Разместить
                            </DefaultButton>
                        </div>
                    </div>
                )
            }
        } else if (status_display === "Размещена") {
            if (role === "admin") {
                return (
                    <div className="dialog_buttons">
                        <div>
                            <DefaultButton
                                variant="outlined"
                                onClick={() => this.toggleDialog("delete")}
                                classes="delete_btn"
                            >
                                Удалить
                            </DefaultButton>
                            <DefaultButton
                                variant="contained"
                                onClick={() => this.toggleDialog("won")}
                                classes="calculate_btn"
                            >
                                Выиграна
                            </DefaultButton>
                            <DefaultButton
                                variant="contained"
                                onClick={() => this.toggleDialog("lost")}
                                classes="calculate_btn third_button"
                            >
                                Проиграна
                            </DefaultButton>
                        </div>
                    </div>
                )
            } else {
                return <Fragment />
            }
        } else if (status_display === "Размещение" || status_display === "Ошибка размещения") {
            if (role === "admin") {
                return (
                    <div className="dialog_buttons">
                        <div>
                            <DefaultButton
                                variant="outlined"
                                onClick={() => this.toggleDialog("delete")}
                                classes="delete_btn"
                            >
                                Удалить
                            </DefaultButton>
                        </div>
                    </div>
                )
            } else {
                return <Fragment />
            }
        } else if (status_display === "Ожидает размещения") {
            if (role === "admin") {
                return (
                    <div className="dialog_buttons">
                        <div>
                            <DefaultButton
                                variant="outlined"
                                onClick={() => this.toggleDialog("delete")}
                                classes="delete_btn"
                            >
                                Удалить
                            </DefaultButton>
                            {!placed_by && (
                                <DefaultButton
                                    variant="contained"
                                    onClick={() => {
                                        this.toggleDialog("post")
                                    }}
                                    classes="calculate_btn"
                                >
                                    Разместить
                                </DefaultButton>
                            )}
                        </div>
                    </div>
                )
            } else {
                return <Fragment />
            }
        } else if (status_display === "Проиграна") {
            if (role === "admin") {
                return (
                    <div className="dialog_buttons">
                        <div>
                            <DefaultButton
                                variant="outlined"
                                onClick={() => this.toggleDialog("delete")}
                                classes="delete_btn"
                            >
                                Удалить
                            </DefaultButton>
                        </div>
                    </div>
                )
            } else {
                return <Fragment />
            }
        } else if (status_display === "Заключение ГК") {
            if (!execution_status_display) {
                if (role === "admin") {
                    return (
                        <div className="dialog_buttons">
                            <div>
                                <DefaultButton
                                    variant="outlined"
                                    onClick={() => this.toggleDialog("delete")}
                                    classes="delete_btn"
                                >
                                    Удалить
                                </DefaultButton>
                                <DefaultButton
                                    variant="contained"
                                    onClick={() => this.toggleDialog("sign")}
                                    classes="calculate_btn"
                                >
                                    ГК заключен
                                </DefaultButton>
                            </div>
                            <div className="remove_deal text_hover" onClick={() => this.toggleDialog("to_execute")}>
                                Исполнить сразу
                            </div>
                        </div>
                    )
                } else if (role === "senior_manager") {
                    return (
                        <div className="dialog_buttons">
                            <div>
                                <DefaultButton
                                    variant="contained"
                                    onClick={() => this.toggleDialog("sign")}
                                    classes="calculate_btn"
                                >
                                    ГК заключен
                                </DefaultButton>
                            </div>
                        </div>
                    )
                } else {
                    return <Fragment />
                }
            } else {
                if (role === "admin") {
                    return (
                        <div className="dialog_buttons">
                            <div>
                                <DefaultButton
                                    variant="outlined"
                                    onClick={() => this.toggleDialog("delete")}
                                    classes="delete_btn"
                                >
                                    Удалить
                                </DefaultButton>
                                <DefaultButton
                                    variant="contained"
                                    onClick={() => this.toggleDialog("executed")}
                                    classes="calculate_btn"
                                >
                                    Закончить
                                </DefaultButton>
                            </div>
                        </div>
                    )
                } else {
                    return (
                        <div className="dialog_buttons">
                            <div>
                                <DefaultButton
                                    variant="contained"
                                    onClick={() => this.toggleDialog("executed")}
                                    classes="calculate_btn"
                                >
                                    Закончить
                                </DefaultButton>
                            </div>
                        </div>
                    )
                }
            }
        } else if (status_display === "Исполнение") {
            if (role === "admin") {
                return (
                    <div className="dialog_buttons">
                        <div>
                            <DefaultButton
                                variant="outlined"
                                onClick={() => this.toggleDialog("delete")}
                                classes="delete_btn"
                            >
                                Удалить
                            </DefaultButton>
                            <DefaultButton
                                variant="contained"
                                onClick={() => this.toggleDialog("executed")}
                                classes="calculate_btn"
                            >
                                Закончить
                            </DefaultButton>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className="dialog_buttons">
                        <div>
                            <DefaultButton
                                variant="contained"
                                onClick={() => this.toggleDialog("executed")}
                                classes="calculate_btn"
                            >
                                Закончить
                            </DefaultButton>
                        </div>
                    </div>
                )
            }
        } else if (status_display === "Исполнена") {
            if (role === "admin") {
                return (
                    <div className="dialog_buttons">
                        <div>
                            <DefaultButton
                                variant="outlined"
                                onClick={() => this.toggleDialog("delete")}
                                classes="delete_btn"
                            >
                                Удалить
                            </DefaultButton>
                            <DefaultButton
                                variant="contained"
                                onClick={() => this.toggleDialog("closed")}
                                classes="calculate_btn"
                            >
                                Закрыть контракт
                            </DefaultButton>
                        </div>
                    </div>
                )
            } else {
                return <Fragment />
            }
        } else if (status_display === "Контракт закрыт") {
            if (role === "admin") {
                return (
                    <div className="dialog_buttons">
                        <div>
                            <DefaultButton
                                variant="outlined"
                                onClick={() => this.toggleDialog("delete")}
                                classes="delete_btn"
                            >
                                Удалить
                            </DefaultButton>
                        </div>
                    </div>
                )
            } else {
                return <Fragment />
            }
        } else {
            return <Fragment />
        }
    }

    returnRole = (status_display, role) => {
        if (role === "admin") {
            return true
        } else {
            if (
                status_display === "Новый" ||
                status_display === "В работу" ||
                status_display === "Расчёт" ||
                status_display === "На проверке"
            ) {
                return true
            }
            return false
        }
    }

    getContent = () => {
        const {
            role,
            deal: {
                data: {
                    customer,
                    customer_name,
                    name,
                    start_price_contract,
                    datetime_dedline_request,
                    datetime_holding,
                    date_delivery_goods,
                    request_type,
                    category,
                    link,
                    request_provision_size,
                    e_place,
                    number,
                    reestr_number,
                },
                old_tender_deadline,
                custom_deadline,
                type,
                archive_files,
                archive_status,
                buy_total_amount,
                status_display,
                execution_status_display,
                comment,
                id,
                manager,
                manager_display,
                responsible,
                responsible_display,
                min_auction_price,
                status,
                placed_by,
                custom_document,
                bot_number
            },
            deal_owners,
            createCalculationDocuments,
            updateCalculationDocuments,
            deleteCalculationDocuments
        } = this.props
        const {
            active_tab,
            openTypeControl,
            responsibleSelector,
            managerSelector,
            calculationDocuments
        } = this.state

        if (active_tab === 0) {
            return (
                <>
                    {number && number[0] !== "А" || reestr_number && reestr_number[0] !== "A" ? (
                        <Fragment>
                            <div className="appl_first_row">
                                <div>
                                    <span>Заказчик</span>
                                    <p>{customer || customer_name}</p>
                                </div>
                                <div className="relative_div">
                                    <span>Название тендера</span>
                                    <p className="overflowed_text" title={name}>
                                        {name}
                                    </p>
                                </div>
                                <div>
                                    <span>Начальная цена контракта (лота)</span>
                                    <p>
                                        <b>{formatNumber(parseFloat(start_price_contract).toFixed(2))}</b>
                                    </p>
                                </div>
                            </div>
                            <hr />
                            <div className="appl_second_row">
                                <div>
                                    <span>Окончание подачи заявок</span>
                                    {!old_tender_deadline ? (
                                        <p>
                                            {datetime_dedline_request === "-"
                                                ? datetime_dedline_request
                                                : moment(datetime_dedline_request).format("DD/MM/YY HH:mm")}
                                        </p>
                                    ) : (
                                        <p>
                                            <span className="previous_date">
                                                {old_tender_deadline === "-"
                                                    ? old_tender_deadline
                                                    : moment(old_tender_deadline).format("DD/MM/YY HH:mm")}
                                            </span>
                                            {datetime_dedline_request === "-"
                                                ? datetime_dedline_request
                                                : moment(datetime_dedline_request).format("DD/MM/YY HH:mm")}
                                        </p>
                                    )}
                                    {custom_deadline && (
                                        <div className="deadline_date">
                                            {moment(custom_deadline).format("DD/MM/YY HH:mm")}
                                            <img
                                                src={EditDateIcon}
                                                alt="edit"
                                                className="text_hover"
                                                onClick={() => this.toggleDialog("change_deadline")}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <span>Время проведения</span>
                                    <p>
                                        {datetime_holding === "-"
                                            ? datetime_holding
                                            : moment(datetime_holding).format("DD/MM/YY HH:mm")}
                                    </p>
                                </div>
                                <div className="relative_div">
                                    <span>Сроки выполнения</span>
                                    <p className="overflowed_text" title={date_delivery_goods}>
                                        {date_delivery_goods || "-"}
                                    </p>
                                </div>
                            </div>
                            <hr />
                            <div className="appl_third_row">
                                <div>
                                    <span>Тип тендера</span>
                                    <p title={request_type}>{request_type || "-"}</p>
                                </div>
                                <div>
                                    <span>Категория</span>
                                    <p>{category || "-"}</p>
                                </div>
                                <div>
                                    <span>Сайт</span>
                                    <p>
                                        {link ? (
                                            <a
                                                className="text_hover"
                                                href={link.includes("http") ? link : `//${link}`}
                                                rel="noopener noreferrer"
                                                target="_blank"
                                            >
                                                {link}
                                            </a>
                                        ) : (
                                            "-"
                                        )}
                                    </p>
                                </div>
                            </div>
                            <hr />
                            <div className="appl_fourth_row">
                                <div>
                                    <span>Размер обеспечения заявки</span>
                                    <p>
                                        {request_provision_size
                                            ? formatNumber(
                                                  parseFloat(request_provision_size.toString().replace(",", "."))
                                              )
                                            : "-"}
                                    </p>
                                </div>
                                <div>
                                    <span>Размер обеспечения контракта</span>
                                    <p>-</p>
                                </div>
                                <div>
                                    <span>Электронная площадка</span>
                                    <p>
                                        {e_place ? (
                                            <a
                                                className="text_hover"
                                                href={e_place.includes("http") ? e_place : `//${e_place}`}
                                                rel="noopener noreferrer"
                                                target="_blank"
                                            >
                                                {e_place}
                                            </a>
                                        ) : (
                                            "-"
                                        )}
                                    </p>
                                </div>
                            </div>
                            <hr />
                            <div className="appl_fifth_row">
                                <div>
                                    <span>Себестоимость</span>
                                    <p>
                                        {buy_total_amount ? formatNumber(parseFloat(buy_total_amount).toFixed(2)) : "-"}
                                    </p>
                                </div>
                                <div>
                                    <span>Статус</span>
                                    <p
                                        style={
                                            !placed_by && status_display === "Ожидает размещения"
                                                ? { color: "#E4944B" }
                                                : {}
                                        }
                                    >
                                        {status_display}
                                    </p>
                                    {[
                                        "placed",
                                        "contract_conclusion",
                                        "lost",
                                        "executing",
                                        "executed",
                                        "contract_closed"
                                    ].includes(status) && (
                                        <p className="red_text_with_overflow">
                                            {placed_by
                                                ? deal_owners.find(el => el.id === placed_by) &&
                                                  deal_owners.find(el => el.id === placed_by).name
                                                : "-"}
                                            {bot_number && <>&nbsp|&nbsp{bot_number}</>}
                                        </p>
                                    )}
                                    {/* {(status_display === "Размещена" ||
                                        status_display === "Заключение ГК" ||
                                        status_display === "Исполнение" ||
                                        status_display === "Исполнена") && (
                                        <p className="red_text_with_overflow">
                                            {placed_by ? deal_owners.find(el => el.id === placed_by)["name"] : "-"}

                                            {bot_number && <>&nbsp|&nbsp{bot_number}</>}
                                        </p>
                                    )} */}
                                </div>
                                <div>
                                    <span>Статус исполнения</span>
                                    <p>{execution_status_display || "-"}</p>
                                </div>
                            </div>
                            <hr />
                            <div className="appl_sixth_row">
                                {[
                                    "placed",
                                    "contract_conclusion",
                                    "lost",
                                    "executing",
                                    "executed",
                                    "contract_closed"
                                ].includes(status) && (
                                    <div>
                                        <span>От кого</span>
                                        <p>
                                            {placed_by
                                                ? deal_owners.find(el => el.id === placed_by) &&
                                                  deal_owners.find(el => el.id === placed_by).name
                                                : "-"}
                                            <img
                                                src={EditImg}
                                                alt="Редактирование"
                                                style={{
                                                    width: "16px",
                                                    height: "16px",
                                                    marginLeft: "8px",
                                                    cursor: "pointer"
                                                }}
                                                onClick={() =>
                                                    this.setState({
                                                        openChangeDialog: true
                                                    })
                                                }
                                            />
                                        </p>
                                    </div>
                                )}
                                <div className="relative_div no_overflow">
                                    <span>Привязанный</span>
                                    {role === "admin" ? (
                                        <Fragment>
                                            <p onClick={() => this.handleManagerSelector(id)} className="text_hover">
                                                {manager_display || "Не назначен"}
                                                <img
                                                    src={ExpandIcon}
                                                    alt=">"
                                                    className={clsx(
                                                        "text_hover",
                                                        managerSelector === id && "transform_rotate"
                                                    )}
                                                />
                                            </p>
                                            {managerSelector === id && (
                                                <ClickAwayListener onClickAway={() => this.handleManagerSelector(id)}>
                                                    <ManagerSelector
                                                        currentTender={id}
                                                        responsible={false}
                                                        manager={manager}
                                                        doRequest={this.doRequest}
                                                        handleManagerSelector={this.handleManagerSelector}
                                                    />
                                                </ClickAwayListener>
                                            )}
                                        </Fragment>
                                    ) : (
                                        <p>{manager_display || "Не назначен"}</p>
                                    )}
                                </div>
                                <div className="relative_div no_overflow">
                                    <span>Ответственный</span>
                                    {role === "admin" ? (
                                        <Fragment>
                                            <p
                                                onClick={() => this.handleResponsibleSelector(id)}
                                                className="text_hover"
                                            >
                                                {responsible_display || "Не назначен"}
                                                <img
                                                    src={ExpandIcon}
                                                    alt=">"
                                                    className={clsx(
                                                        "text_hover",
                                                        responsibleSelector === id && "transform_rotate"
                                                    )}
                                                />
                                            </p>
                                            {responsibleSelector === id && (
                                                <ClickAwayListener
                                                    onClickAway={() => this.handleResponsibleSelector(id)}
                                                >
                                                    <ManagerSelector
                                                        currentTender={id}
                                                        responsible={true}
                                                        manager={responsible}
                                                        doRequest={this.doRequest}
                                                        handleManagerSelector={this.handleResponsibleSelector}
                                                    />
                                                </ClickAwayListener>
                                            )}
                                        </Fragment>
                                    ) : (
                                        <p>{responsible_display || "Не назначен"}</p>
                                    )}
                                </div>
                            </div>
                            <hr />
                            <div className="appl_seventh_row">
                                <div>
                                    <span>Тип</span>
                                    <p onClick={this.handleTypeControl} className="text_hover">
                                        {type || "Выберите тип"}
                                        <img src={ArrowDownIcon} alt="arrow_down" />
                                    </p>
                                    {openTypeControl && (
                                        <ClickAwayListener onClickAway={this.handleTypeControl}>
                                            <div className="types_popper">
                                                <div
                                                    className={clsx(type === "ЗК" && "current")}
                                                    onClick={() => {
                                                        this.toggleDialog("change_type")
                                                        this.setState({ chosenType: "ЗК" })
                                                    }}
                                                >
                                                    <span>ЗК</span>
                                                    {type === "ЗК" && <img src={CheckIcon} alt="!" />}
                                                </div>
                                                <div
                                                    className={clsx(type === "Ф2" && "current")}
                                                    onClick={() => {
                                                        this.toggleDialog("change_type")
                                                        this.setState({ chosenType: "Ф2" })
                                                    }}
                                                >
                                                    <span>Ф2</span>
                                                    {type === "Ф2" && <img src={CheckIcon} alt="!" />}
                                                </div>
                                                <div
                                                    className={clsx(type === "ЭА" && "current")}
                                                    onClick={() => {
                                                        this.toggleDialog("change_type")
                                                        this.setState({ chosenType: "ЭА" })
                                                    }}
                                                >
                                                    <span>ЭА</span>
                                                    {type === "ЭА" && <img src={CheckIcon} alt="!" />}
                                                </div>
                                                <div
                                                    className={clsx(type === "ОФ" && "current")}
                                                    onClick={() => {
                                                        this.toggleDialog("change_type")
                                                        this.setState({ chosenType: "ОФ" })
                                                    }}
                                                >
                                                    <span>ОФ</span>
                                                    {type === "ОФ" && <img src={CheckIcon} alt="!" />}
                                                </div>
                                            </div>
                                        </ClickAwayListener>
                                    )}
                                </div>
                                {[
                                    "approved",
                                    "declined",
                                    "placed",
                                    "contract_conclusion",
                                    "lost",
                                    "executing",
                                    "executed",
                                    "contract_closed"
                                ].includes(status) && (
                                    <div>
                                        <span>Мин. сумма по торгам223</span>
                                        <div>{min_auction_price || "-"}</div>
                                    </div>
                                )}
                                <div>
                                    <span>Комментарий</span>
                                    <div>{comment || "-"}</div>
                                </div>
                            </div>
                            <hr />

                            {this.getButtons()}
                        </Fragment>
                    ) : (
                        <Fragment>
                            <div className="appl_first_row custom">
                                <div>
                                    <span>Заказчик</span>
                                    <p>{customer || customer_name}</p>
                                </div>
                                <div className="relative_div">
                                    <span>Название тендера</span>
                                    <p className="overflowed_text" title={name}>
                                        {name}
                                    </p>
                                </div>
                                <div className="relative_div">
                                    <span>Дедлайн</span>
                                    <p>{custom_deadline ? moment(custom_deadline).format("DD/MM/YY HH:mm") : "-"}</p>
                                </div>
                            </div>
                            <hr />
                            <div className="appl_second_row custom">
                                <div>
                                    <span>От кого</span>
                                    <p>
                                        {placed_by
                                            ? deal_owners.find(el => el.id === placed_by) &&
                                              deal_owners.find(el => el.id === placed_by).name
                                            : "-"}
                                    </p>
                                </div>
                                <div>
                                    <span>Привязанный</span>
                                    <p>{manager_display || "-"}</p>
                                </div>
                                <div>
                                    <span>Ответственный</span>
                                    <p>{responsible_display || "-"}</p>
                                </div>
                            </div>
                            <hr />
                            <div className="appl_third_row custom">
                                <div>
                                    <span>Тип аукциона</span>
                                    <p>{type || "-"}</p>
                                </div>
                                <div>
                                    <span>Тип тендера</span>
                                    <p title={request_type}>{request_type || "-"}</p>
                                </div>
                            </div>
                            <hr />
                            {custom_document && (
                                <Fragment>
                                    <div className="appl_fourth_row">
                                        <div className="document_row" style={{ margin: "10px 0 0" }}>
                                            <img src={DocumentIcon} alt="document" />
                                            <a href={custom_document} rel="noopener noreferrer" target="_blank">
                                                {decodeURIComponent(custom_document.split("/")[7])}
                                            </a>
                                        </div>
                                    </div>
                                    <hr />
                                </Fragment>
                            )}
                            <div className="dialog_buttons">{this.getButtons()}</div>
                        </Fragment>
                    )}
                </>
            )
        } else if (active_tab === 1) {
            return (
                <Fragment>
                    {archive_status === "downloaded" ? (
                        <div className={role === "admin" ? "documents_admin_wrapper" : "documents_admin_wrapper"}>
                            <div className="documents_wrapper wrapper_custom">
                                {archive_files.map(({ filename, url }) => (
                                    <div className="document" key={filename}>
                                        <a href={url} download className="usable_text text_hover">
                                            <img src={DocumentIcon} alt="document" />
                                            {filename}
                                        </a>
                                    </div>
                                ))}
                            </div>
                            {this.renderDocuments()}
                        </div>
                    ) : archive_status === "error" ? (
                        <div className={role === "admin" ? "documents_admin_wrapper" : ""}>
                            <div className="download_failed">
                                <img src={FailedIcon} alt="error" />
                                Попытка скачать документы не удалась
                                <div onClick={this.reDownloadFiles} className="text_hover usable_text">
                                    Повторить попытку
                                </div>
                            </div>
                            {this.renderDocuments()}
                        </div>
                    ) : (
                        <div className={role === "admin" ? "documents_admin_wrapper" : ""}>
                            <div className="download_scheduled">В очереди на скачивание</div>
                            {this.renderDocuments()}
                        </div>
                    )}
                </Fragment>
            )
        } else {
            return (
                <>
                    <>
                        <div className="admin-documents calculation-documents">
                            <span className="admin-documents-title">Документы</span>
                            {/* {calculationDocuments && calculationDocuments.length === 0 && (
                                        <div className="document_row">
                                            <img src={DocumentIcon} alt="document" />
                                            <p>Документ не выбран</p>
                                            <input
                                                id="fileInputOne"
                                                className="fileInput"
                                                type="file"
                                                onChange={e => {
                                                    let file = e.target.files[0]
                                                    this.setState(({ chosenFiles }) => ({
                                                        chosenFiles: [...chosenFiles, file]
                                                    }))
                                                    document.getElementById("fileInputOne").value = ""
                                                }}
                                            />
                                            <div
                                                className="usable_text text_hover"
                                                onClick={() => document.getElementById("fileInputOne").click()}
                                            >
                                                ВЫБРАТЬ ФАЙЛ
                                            </div>
                                        </div>
                                    )} */}
                            {calculationDocuments &&
                                calculationDocuments.map((el, idx) => {
                                    return (
                                        <div className="document_row" key={idx}>
                                            <img src={DocumentIcon} alt="document" />
                                            {el && el.name ? (
                                                <a href={el.document} download>
                                                    {el.name}
                                                </a>
                                            ) : (
                                                <p>Документ не выбран</p>
                                            )}
                                            <input
                                                id={`fileInput${idx}`}
                                                className="fileInput"
                                                type="file"
                                                onChange={e => {
                                                    let file = e.target.files[0]
                                                    let formData = new FormData()
                                                    formData.append("document", file)
                                                    calculationDocuments &&
                                                    calculationDocuments[idx] &&
                                                    calculationDocuments[idx] !== null
                                                        ? updateCalculationDocuments(
                                                              id,
                                                              calculationDocuments[idx].id,
                                                              formData
                                                          ).then(res => {
                                                              if (
                                                                  res.payload &&
                                                                  res.payload.status &&
                                                                  res.payload.status === 200
                                                              ) {
                                                                  this.setState(({ calculationDocuments }) => ({
                                                                      calculationDocuments: [
                                                                          ...calculationDocuments.slice(0, idx),
                                                                          res.payload.data,
                                                                          ...calculationDocuments.slice(idx + 1)
                                                                      ]
                                                                  }))
                                                              } else {
                                                              }
                                                          })
                                                        : createCalculationDocuments(id, formData).then(res => {
                                                              if (
                                                                  res.payload &&
                                                                  res.payload.status &&
                                                                  res.payload.status === 201
                                                              ) {
                                                                  this.setState(({ calculationDocuments }) => ({
                                                                      calculationDocuments: [
                                                                          ...calculationDocuments.slice(0, idx),
                                                                          res.payload.data,
                                                                          ...calculationDocuments.slice(idx + 1)
                                                                      ]
                                                                  }))
                                                              } else {
                                                              }
                                                          })
                                                    document.getElementById(`fileInput${idx}`).value = ""
                                                }}
                                            />
                                            {this.returnRole(status_display, role) && (
                                                <div
                                                    className="usable_text text_hover"
                                                    onClick={() => document.getElementById(`fileInput${idx}`).click()}
                                                >
                                                    ВЫБРАТЬ ФАЙЛ
                                                </div>
                                            )}

                                            {el && this.returnRole(status_display, role) && (
                                                <div
                                                    className="delete_btn text_hover"
                                                    onClick={() =>
                                                        deleteCalculationDocuments(
                                                            id,
                                                            calculationDocuments &&
                                                                calculationDocuments[idx] &&
                                                                calculationDocuments[idx].id
                                                        ).then(res => {
                                                            if (
                                                                res.payload &&
                                                                res.payload.status &&
                                                                res.payload.status === 204
                                                            ) {
                                                                this.setState(({ calculationDocuments }) => ({
                                                                    calculationDocuments: [
                                                                        ...calculationDocuments.slice(0, idx),
                                                                        ...calculationDocuments.slice(idx + 1)
                                                                    ]
                                                                }))
                                                            } else {
                                                            }
                                                        })
                                                    }
                                                >
                                                    УДАЛИТЬ
                                                </div>
                                            )}
                                        </div>
                                    )
                                })}
                            {this.returnRole(status_display, role) && (
                                <p
                                    className="admin-documents-more"
                                    onClick={() =>
                                        this.setState(({ calculationDocuments }) => ({
                                            calculationDocuments: [...calculationDocuments, null]
                                        }))
                                    }
                                >
                                    + Добавить ещё
                                </p>
                            )}
                        </div>
                    </>
                    <Calculation
                        toggleDialog={this.toggleDialog}
                        setReloading={() => this.setState({ reloading: true })}
                        unsetReloading={() => this.setState({ reloading: false })}
                    />
                </>
            )
        }
    }

    renderDocuments = () => {
        const {
            role,
            deal: {
                status_display,
                actual_price,
            },
            contracts,
            documentsGK
        } = this.props
        const {
            chosenFiles,
            areaValue,
            verifiedFile
        } = this.state
        return (
            <>
                <div className="admin-documents-wrapper">
                    {documentsGK && documentsGK.length > 0 && (
                        <div className="admin-documents">
                            <span className="admin-documents-title">Контракт</span>
                            {documentsGK.map((el, idx) => (
                                <a href={el.document} download className="document_row" key={idx}>
                                    <img src={DocumentIcon} alt="document" />
                                    <p>{el && el.name}</p>
                                </a>
                            ))}
                            <div className="actual_price">
                                <p>
                                    Фактическая цена контракта: {actual_price || ""} ₽
                                </p>
                            </div>
                        </div>
                    )}
                    {(status_display === "Заключение ГК" ||
                        status_display === "Исполнение" ||
                        status_display === "Исполнена" ||
                        status_display === "Контракт закрыт") &&
                        documentsGK &&
                        documentsGK.length === 0 && (
                            <div
                                className={
                                    (contracts &&
                                        contracts.contract_documents &&
                                        contracts.contract_documents.length > 0) ||
                                    role === "admin"
                                        ? "admin-documents"
                                        : ""
                                }
                            >
                                {((contracts &&
                                    contracts.contract_documents &&
                                    contracts.contract_documents.length > 0) ||
                                    role === "admin") && (
                                    <span className="admin-documents-title">Проект контракта</span>
                                )}
                                {contracts &&
                                contracts.contract_documents &&
                                contracts.contract_documents.length > 0 ? (
                                    <>
                                        {contracts.contract_documents.map((el, idx) => (
                                            <a href={el.document} download className="document_row" key={idx}>
                                                <img src={DocumentIcon} alt="document" />
                                                <p>{el && el.name}</p>
                                            </a>
                                        ))}
                                        {!contracts.verified && (
                                            <>
                                                <hr />
                                                <textarea
                                                    value={areaValue}
                                                    onChange={e => this.setState({ areaValue: e.target.value })}
                                                    // onBlur={e => addCommentToContracts(id, { comment: e.target.value })}
                                                    className="documents-area"
                                                    placeholder="Комментарий"
                                                    disabled={
                                                        contracts && (contracts.comment_document || contracts.comment)
                                                    }
                                                />
                                                <div className="document_row">
                                                    <img src={DocumentIcon} alt="document" />
                                                    {contracts.comment_document ? (
                                                        <a href={contracts.comment_document} download>
                                                            {contracts.comment_document_name}
                                                        </a>
                                                    ) : (
                                                        <p>{verifiedFile ? verifiedFile.name : "Документ не выбран"}</p>
                                                    )}
                                                    {/* <p>
                                                        {contracts.comment_document
                                                            ? contracts.comment_document_name
                                                            : verifiedFile
                                                            ? verifiedFile.name
                                                            : "Документ не выбран"}
                                                    </p> */}
                                                    <input
                                                        id="fileVerified"
                                                        className="fileInput"
                                                        type="file"
                                                        onChange={e => {
                                                            let file = e.target.files[0]
                                                            this.setState({ verifiedFile: file })
                                                            document.getElementById("fileVerified").value = ""
                                                        }}
                                                    />
                                                    {contracts &&
                                                    (contracts.comment_document || contracts.comment) ? null : (
                                                        <div
                                                            className="usable_text text_hover"
                                                            onClick={() =>
                                                                document.getElementById("fileVerified").click()
                                                            }
                                                        >
                                                            ВЫБРАТЬ ФАЙЛ
                                                        </div>
                                                    )}
                                                </div>
                                                {contracts &&
                                                (contracts.comment_document || contracts.comment) ? null : (
                                                    <DefaultButton
                                                        variant="contained"
                                                        onClick={() => this.setState({ verifiedModal: true })}
                                                        classes="confirm_btn"
                                                    >
                                                        Подтвердить
                                                    </DefaultButton>
                                                )}
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {role === "admin" ? (
                                            <>
                                                {chosenFiles && chosenFiles.length === 0 && (
                                                    <div className="document_row">
                                                        <img src={DocumentIcon} alt="document" />
                                                        <p>Документ не выбран</p>
                                                        <input
                                                            id="fileInputOne"
                                                            className="fileInput"
                                                            type="file"
                                                            onChange={e => {
                                                                let file = e.target.files[0]
                                                                this.setState(({ chosenFiles }) => ({
                                                                    chosenFiles: [...chosenFiles, file]
                                                                }))
                                                                document.getElementById("fileInputOne").value = ""
                                                            }}
                                                        />
                                                        <div
                                                            className="usable_text text_hover"
                                                            onClick={() =>
                                                                document.getElementById("fileInputOne").click()
                                                            }
                                                        >
                                                            ВЫБРАТЬ ФАЙЛ
                                                        </div>
                                                    </div>
                                                )}
                                                {chosenFiles &&
                                                    chosenFiles.map((el, idx) => {
                                                        return (
                                                            <div className="document_row" key={idx}>
                                                                <img src={DocumentIcon} alt="document" />
                                                                <p>{(el && el.name) || "Документ не выбран"}</p>
                                                                <input
                                                                    id={`fileInput${idx}`}
                                                                    className="fileInput"
                                                                    type="file"
                                                                    onChange={e => {
                                                                        let file = e.target.files[0]
                                                                        // console.log(idx)
                                                                        this.setState(({ chosenFiles }) => ({
                                                                            chosenFiles: [
                                                                                ...chosenFiles.slice(0, idx),
                                                                                file,
                                                                                ...chosenFiles.slice(idx + 1)
                                                                            ]
                                                                        }))
                                                                        document.getElementById(
                                                                            `fileInput${idx}`
                                                                        ).value = ""
                                                                    }}
                                                                />
                                                                <div
                                                                    className="usable_text text_hover"
                                                                    onClick={() =>
                                                                        document
                                                                            .getElementById(`fileInput${idx}`)
                                                                            .click()
                                                                    }
                                                                >
                                                                    ВЫБРАТЬ ФАЙЛ
                                                                </div>
                                                                {el && (
                                                                    <div
                                                                        className="delete_btn text_hover"
                                                                        onClick={() =>
                                                                            this.setState(({ chosenFiles }) => ({
                                                                                chosenFiles: [
                                                                                    ...chosenFiles.slice(0, idx),
                                                                                    ...chosenFiles.slice(idx + 1)
                                                                                ]
                                                                            }))
                                                                        }
                                                                    >
                                                                        УДАЛИТЬ
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )
                                                    })}

                                                <p
                                                    className="admin-documents-more"
                                                    onClick={() =>
                                                        this.setState(({ chosenFiles }) => ({
                                                            chosenFiles: [...chosenFiles, null]
                                                        }))
                                                    }
                                                >
                                                    + Добавить ещё
                                                </p>
                                                <hr />
                                                <DefaultButton
                                                    variant="contained"
                                                    onClick={() => this.setState({ documentsModal: true })}
                                                    classes="confirm_btn"
                                                >
                                                    Прикрепить
                                                </DefaultButton>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </>
                                )}
                            </div>
                        )}
                </div>
            </>
        )
    }

    changeDeadline = async values => {
        const {
            patchTender,
            match: {
                params: { id }
            },
            getDeal
        } = this.props
        const data = {
            custom_deadline: moment(values.deadline)
        }
        this.setState({ reloading: true })
        await patchTender(id, data)
        await getDeal(id)
        this.setState({ reloading: false })
        this.toggleDialog("change_deadline")
    }

    changePlacedBy = data => {
        const {
            changePlacedByBot,
            match: {
                params: { id }
            }
        } = this.props
        let obj = {}
        if (data.numberContract) obj.bot_number = data.numberContract
        if (data.placedDeal) obj.name = data.placedDeal
        changePlacedByBot(id, obj).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                this.doRequest()
                this.setState({ openChangeDialog: false })
            } else {
            }
        })
    }

    render() {
        const {
            deal: { is_bot_involved, placed_by, status_display },
            handleSubmit,
            submitting,
            pristine,
            valid,
            downloadContractFiles,
            contracts,
            getContractFiles
        } = this.props
        const {
            loading,
            openChangeTypeDialog,
            openChangeDeadlineDialog,
            openErrorSnack,
            reloading,
            active_tab,
            openBackDialog,
            openDeleteDialog,
            openToDeleteDialog,
            openDeleteAdmin,
            openToCheckDialog,
            openApproveDialog,
            openToFixDialog,
            openDeclineDialog,
            openPostDialog,
            firstPlacingDialog,
            openWonDialog,
            openLostDialog,
            openSignedDialog,
            openExecuteDialog,
            openExecutedDialog,
            openCommercialProposal,
            openClosedDialog,
            documentsModal,
            chosenFiles,
            verifiedModal,
            verifiedFile,
            areaValue,
            openChangeDialog
        } = this.state
        if (loading) return <LinearProgress />
        const {
            history,
            deal: {
                data: { number, reestr_number },
                contract_conclusion_date,
                contract_number
            },
            match: {
                params: { id }
            },
            deadline,
            changeCommentContracts,
            addCommentToContracts,
            deal_owners
        } = this.props
        const prevQueries = history.location.state ? history.location.state.prevQueries : ""
        let owners = []
        deal_owners.forEach(el => owners.push("z" + el.name))
        return (
            <Fragment>
                {reloading ? <LinearProgress /> : <hr className="loader_dummy" />}
                <div className="page_wrapper deal_wrapper">
                    <Link to={`/main/deals-in-progress${prevQueries}`} className="text_hover usable_text">
                        <img src={BackIcon} alt="<" />
                        Назад
                    </Link>
                    <h1>Детали сделки</h1>
                    <div className="appl_number">
                        Заявка <span>№{number || reestr_number}</span>
                        {(contract_conclusion_date || contract_number) && (
                            <>
                                (
                                {`${
                                    contract_number && contract_number !== "undefined"
                                        ? contract_number + ", "
                                        : "Номер не указан, "
                                }${
                                    contract_conclusion_date
                                        ? moment(contract_conclusion_date).format("DD/MM/yyyy")
                                        : ""
                                }`}
                                )
                            </>
                        )}
                    </div>
                    <div className="tabs_wrapper">
                        <div
                            className={clsx("tab text_hover", active_tab === 0 && "active_tab")}
                            onClick={() => this.changeTab(0)}
                        >
                            Детали
                        </div>
                        <div
                            className={clsx("tab text_hover", active_tab === 1 && "active_tab")}
                            onClick={() => this.changeTab(1)}
                        >
                            Документы
                        </div>
                        <div
                            className={clsx("tab text_hover", active_tab === 2 && "active_tab")}
                            onClick={() => this.changeTab(2)}
                        >
                            Расчёт
                        </div>
                        {number && active_tab !== 2 && number[0] === "А" && (
                            <>
                                <span
                                    onClick={() => this.toggleDialog("commercial_proposal")}
                                    style={{ marginLeft: "auto" }}
                                    className="usable_text text_hover"
                                >
                                    Сформировать КП
                                </span>
                                <Link to={`/main/edit-custom-tender/${id}`} className="usable_text text_hover">
                                    Редактировать
                                </Link>
                            </>
                        )}
                        {reestr_number && active_tab !== 2 && reestr_number[0] === "А" && (
                            <>
                                <span
                                    onClick={() => this.toggleDialog("commercial_proposal")}
                                    style={{ marginLeft: "auto" }}
                                    className="usable_text text_hover"
                                >
                                    Сформировать КП
                                </span>
                                <Link to={`/main/edit-custom-tender/${id}`} className="usable_text text_hover">
                                    Редактировать
                                </Link>
                            </>
                        )}
                    </div>
                    {this.getContent()}
                    <DialogComponent open={openChangeDialog} onClose={() => this.setState({ openChangeDialog: false })}>
                        <form
                            className="dashboard_dialog no_select change_placed"
                            onSubmit={handleSubmit(this.changePlacedBy)}
                        >
                            <h2>Редактировать сделку</h2>
                            <div className="dialog_description" style={{ marginBottom: "20px" }}>
                                Выберите от кого размещается сделка и введите номер заявки с электронной площадки
                            </div>
                            {/* <SelectComponent
                                item={placedDeal}
                                items={owners}
                                handleChange={e => this.setState({ placedDeal: e.target.value })}
                                placeholder={"От кого"}
                            /> */}
                            <Field
                                type="text"
                                component={RenderField}
                                label="От кого"
                                classes=" numberContract"
                                name="placedDeal"
                            />
                            <Field
                                type="number"
                                component={RenderField}
                                label="Номер заявки"
                                classes=" numberContract"
                                name="numberContract"
                            />
                            <div className="dialog_buttons">
                                <DefaultButton
                                    variant="outlined"
                                    onClick={() => this.setState({ openChangeDialog: false })}
                                    classes="cancel_btn"
                                >
                                    Отмена
                                </DefaultButton>
                                <DefaultButton
                                    variant="contained"
                                    // onClick={this.changeType}
                                    classes="confirm_btn"
                                    loading={reloading}
                                    formAction
                                >
                                    Подтвердить
                                </DefaultButton>
                            </div>
                        </form>
                    </DialogComponent>
                    <DialogComponent open={openChangeTypeDialog} onClose={() => this.toggleDialog("change_type")}>
                        <div className="dashboard_dialog no_select">
                            <h2>Поменять тип сделки</h2>
                            <div className="dialog_description">
                                Подтвердите, что Вы хотите поменять тип этой сделки
                            </div>
                            <div className="dialog_buttons">
                                <DefaultButton
                                    variant="outlined"
                                    onClick={() => this.toggleDialog("change_type")}
                                    classes="cancel_btn"
                                >
                                    Отмена
                                </DefaultButton>
                                <DefaultButton
                                    variant="contained"
                                    onClick={this.changeType}
                                    classes="confirm_btn"
                                    loading={reloading}
                                >
                                    Подтвердить
                                </DefaultButton>
                            </div>
                        </div>
                    </DialogComponent>

                    <DialogComponent open={verifiedModal} onClose={() => this.setState({ verifiedModal: false })}>
                        <div className="dashboard_dialog no_select">
                            <h2>Подтвердить проверку</h2>
                            <div className="dialog_description">
                                Подтвердите, что документы этой сделки прошли проверку.
                            </div>
                            <div className="dialog_buttons">
                                <DefaultButton
                                    variant="outlined"
                                    onClick={() => this.setState({ verifiedModal: false })}
                                    classes="cancel_btn"
                                >
                                    Отмена
                                </DefaultButton>
                                <DefaultButton
                                    variant="contained"
                                    onClick={() => {
                                        const formData = new FormData()
                                        if (contracts && contracts.comment_document) {
                                            formData.append("document", verifiedFile)
                                            changeCommentContracts(id, contracts.id, formData).then(res => {
                                                if (res.payload && res.payload.status && res.payload.status === 200) {
                                                    this.setState({ verifiedModal: false })
                                                } else {
                                                }
                                            })
                                        } else {
                                            verifiedFile && formData.append("comment_document", verifiedFile)
                                            areaValue && formData.append("comment", areaValue)
                                            addCommentToContracts(id, formData).then(res => {
                                                if (res.payload && res.payload.status && res.payload.status === 200) {
                                                    this.setState({ verifiedModal: false })
                                                    getContractFiles(id).then(res => {
                                                        if (
                                                            res.payload &&
                                                            res.payload.status &&
                                                            res.payload.status === 200
                                                        ) {
                                                            this.setState({
                                                                areaValue:
                                                                    res &&
                                                                    res.payload &&
                                                                    res.payload.data &&
                                                                    res.payload.data.comment
                                                            })
                                                        } else {
                                                        }
                                                    })
                                                } else {
                                                }
                                            })
                                        }
                                    }}
                                    classes="confirm_btn"
                                    loading={reloading}
                                >
                                    Подтвердить
                                </DefaultButton>
                            </div>
                        </div>
                    </DialogComponent>
                    <DialogComponent open={documentsModal} onClose={() => this.setState({ documentsModal: false })}>
                        <div className="dashboard_dialog no_select">
                            <h2>Прикрепить документы</h2>
                            <div className="dialog_description">
                                Подтвердите, что Вы хотите прикрепить{" "}
                                {chosenFiles && chosenFiles.filter(el => el).length} документа для этой сделки.
                            </div>
                            <div className="dialog_buttons">
                                <DefaultButton
                                    variant="outlined"
                                    onClick={() => this.setState({ documentsModal: false })}
                                    classes="cancel_btn"
                                >
                                    Отмена
                                </DefaultButton>
                                <DefaultButton
                                    variant="contained"
                                    onClick={() => {
                                        const formData = new FormData()
                                        for (let i = 0; i < chosenFiles.length; i++) {
                                            chosenFiles[i] && formData.append("documents", chosenFiles[i])
                                        }
                                        downloadContractFiles(id, formData).then(res => {
                                            if (res.payload && res.payload.status && res.payload.status === 201) {
                                                this.setState({ documentsModal: false })
                                                getContractFiles(id)
                                            } else {
                                            }
                                        })
                                    }}
                                    classes="confirm_btn"
                                    loading={reloading}
                                >
                                    Прикрепить
                                </DefaultButton>
                            </div>
                        </div>
                    </DialogComponent>
                    <DialogComponent
                        open={openChangeDeadlineDialog}
                        onClose={() => this.toggleDialog("change_deadline")}
                    >
                        <div className="dashboard_dialog no_select">
                            <h2>Изменить дедлайн сделки</h2>
                            <br />
                            <br />
                            <form onSubmit={handleSubmit(this.changeDeadline)}>
                                <div className="deadline_and_important">
                                    <Field
                                        name="deadline"
                                        component={DateTimeField}
                                        label="Дедлайн"
                                        disablePast={true}
                                        invalidDateMessage="Недопустимый дедлайн"
                                        minDateMessage="Недопустимый дедлайн"
                                        maxDateMessage="Недопустимый дедлайн"
                                        invalidLabel="Недопустимый дедлайн"
                                    />
                                </div>
                                <div className="dialog_buttons">
                                    <DefaultButton
                                        variant="contained"
                                        formAction
                                        classes="confirm_btn"
                                        loading={reloading}
                                        disabled={(deadline && !deadline._isValid) || !valid || submitting || pristine}
                                    >
                                        Сохранить
                                    </DefaultButton>
                                </div>
                            </form>
                        </div>
                    </DialogComponent>
                    <DealsInProgressDialogs
                        currentTender={id}
                        is_bot_involved={is_bot_involved}
                        placed_by={placed_by}
                        waitingForApprove={status_display === "Ожидает размещения"}
                        doRequest={this.doRequest}
                        toggleDialog={this.toggleDialog}
                        openDeleteDialog={openDeleteDialog}
                        openBackDialog={openBackDialog}
                        openToDeleteDialog={openToDeleteDialog}
                        openDeleteAdmin={openDeleteAdmin}
                        openToCheckDialog={openToCheckDialog}
                        openApproveDialog={openApproveDialog}
                        openToFixDialog={openToFixDialog}
                        openDeclineDialog={openDeclineDialog}
                        openPostDialog={openPostDialog}
                        firstPlacingDialog={firstPlacingDialog}
                        openWonDialog={openWonDialog}
                        openLostDialog={openLostDialog}
                        openSignedDialog={openSignedDialog}
                        openExecuteDialog={openExecuteDialog}
                        openExecutedDialog={openExecutedDialog}
                        openClosedDialog={openClosedDialog}
                        openCommercialProposal={openCommercialProposal}
                    />
                    <Snack
                        open={openErrorSnack}
                        handleSnack={this.closeSnack}
                        message="Такое действие этому пользователю в этой заявке запрещено"
                        type="error"
                    />
                </div>
            </Fragment>
        )
    }
}
const validate = values => {
    const errors = {}
    // if (!values.deadline) {
    //     errors.deadline = "Недопустимый дедлайн"
    // }
    if (values.numberContract && values.numberContract.length > 5) {
        errors.numberContract = "Номер не может быть больше 5 символов"
    }
    return errors
}

DealInProgress = reduxForm({
    form: "ChangeDeadlineForm",
    validate,
    enableReinitialize: true
})(DealInProgress)

const selector = formValueSelector("ChangeDeadlineForm")

DealInProgress = connect(state => {
    const deadline = selector(state, "deadline")
    return {
        deadline
    }
})(DealInProgress)

const mapStateToProps = state => {
    return {
        deal: state.dashboard.deal,
        role: state.dashboard.options.me.role,
        deal_owners: state.deals.deal_owners,
        contracts: state.deals.contracts,
        documentsGK: state.deals.documentsGK,
        initialValues: {
            numberContract: state.dashboard.deal && state.dashboard.deal.bot_number,
            placedDeal:
                state.deals.deal_owners.find(el => el.id === state.dashboard.deal.placed_by) &&
                state.deals.deal_owners.find(el => el.id === state.dashboard.deal.placed_by).name
        }
    }
}
const mapDispatchToProps = {
    getDeal,
    getTender,
    patchTender,
    getCategories,
    getCharacteristics,
    getUsers,
    getOwners,
    change,
    reDownloadTender,
    downloadContractFiles,
    getContractFiles,
    addCommentToContracts,
    changeCommentContracts,
    getCalculationDocuments,
    createCalculationDocuments,
    updateCalculationDocuments,
    deleteCalculationDocuments,
    getFilesGK,
    getAllOwners,
    changePlacedByBot
}
export default connect(mapStateToProps, mapDispatchToProps)(DealInProgress)
