import * as types from "./constants.jsx";

export function patchSupplier(id, data) {
    return {
        type: types.PATCH_SUPPLIER,
        payload: {
            client: "default",
            request: {
                url: `/catalog/suppliers/${id}/`,
                method: "PATCH",
                data
            }
        }
    };
}
export function addSupplier(data) {
    return {
        type: types.POST_SUPPLIER,
        payload: {
            client: "default",
            request: {
                url: `/catalog/suppliers/add/`,
                method: "post",
                data
            }
        }
    };
}

export function getCurrentSupplier(id) {
    return {
        type: types.GET_CURRENT_SUPPLIER,
        payload: {
            client: "default",
            request: {
                url: `/catalog/suppliers/${id}/`,
                method: "GET"
            }
        }
    };
}

export function getSuppliers(status) {
    return {
        type: types.GET_SUPPLIERS_LIST,
        payload: {
            client: "default",
            request: {
                url: `/catalog/suppliers/${status.length > 0 ? `?is_active=${status}` : ""}`,
                method: "GET"
            }
        }
    };
}

export function changeSupplierActivity(id) {
    return {
        type: types.POST_CHANGE_SUPPLIERS_ACTIVITY,
        payload: {
            client: "default",
            request: {
                url: `/catalog/suppliers/${id}/status/`,
                method: "POST"
            }
        }
    };
}
