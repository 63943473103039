import * as types from "../actions/constants";

const INITIAL_STATE = {
    tenderTypes: []
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.GET_TENDER_TYPES_SUCCESS:
            return { ...state, tenderTypes: action.payload.data };

        default:
            return state;
    }
}
