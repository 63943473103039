import * as types from "../actions/constants";

const INITIAL_STATE = {
    productsList: {},
    categoriesList: [],
    suppliersList: [],
    characteristicsList: [],
    excelImport: {},
    certain_product: {},

    productError: "",
    productActivityError: "",
    excelImportError: ""
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.GET_PRODUCTS_SUCCESS:
            return { ...state, productsList: action.payload.data };
        case types.GET_CERTAIN_PRODUCT_SUCCESS:
            return { ...state, certain_product: action.payload.data };
        case types.GET_CERTAIN_PRODUCT_FAIL:
            return { ...state, certain_product: {} };
        case types.GET_CATEGORIES_SUCCESS:
            return { ...state, categoriesList: action.payload.data };
        case types.GET_SUPPLIERS_SUCCESS:
            return { ...state, suppliersList: action.payload.data };
        case types.GET_CHARACTERISTICS_SUCCESS:
            return { ...state, characteristicsList: action.payload.data };
        case types.POST_EXCEL_ITEM_SUCCESS:
            return { ...state, excelImport: action.payload.data, excelImportError: "" };

        case types.POST_CHANGE_PRODUCT_ACTIVITY_FAIL:
            return {
                ...state,
                productActivityError: action.error.response.data
            };
        case types.POST_EXCEL_ITEM_FAIL:
            return {
                ...state,
                excelImportError: action.error.response.data.file[0]
            };

        case types.POST_ADD_PRODUCT_FAIL:
            return {
                ...state,
                productError: action.error.response.data
            };
        default:
            return state;
    }
}
