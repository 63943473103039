import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import Popper from "@material-ui/core/Popper"
import IconButton from "@material-ui/core/IconButton"
import ClickAwayListener from "@material-ui/core/ClickAwayListener/ClickAwayListener"
import clsx from "clsx"

import {
    getNotifications,
    getNumberOfNewNotifications,
    patchNumberOfNewNotifications,
    patchNotificationStatus,
    resetNotifications
} from "../../actions/notificationsActions"
import ScrollBar from "../HelperComponents/ScrollBar/ScrollBar"

import NotificationsIcon from "../../assets/image/notifications_none_24px.svg"

import "./Head.scss"

class Notifications extends Component {
    constructor(props) {
        super(props)
        this.notificationsTimeout = undefined
        this.state = {
            anchorEl: null,
            hotUpdate: false
        }
    }

    componentDidMount() {
        this.fetchNumberOfNewNotifications().then(() => {
            this.handleNotificationsTimeout()
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.anchorEl !== this.state.anchorEl) {
            if (!this.state.anchorEl) {
                this.handleNotificationsTimeout()
            } else {
                clearTimeout(this.notificationsTimeout)
            }
        }
    }

    componentWillUnmount() {
        clearTimeout(this.notificationsTimeout)
    }

    fetchNumberOfNewNotifications = async () => {
        const { getNumberOfNewNotifications } = this.props
        return await getNumberOfNewNotifications()
    }

    fetchNotifications = async page => {
        const { getNotifications } = this.props
        return await getNotifications(page)
    }

    handleNotificationsTimeout = () => {
        this.notificationsTimeout = setTimeout(() => {
            this.fetchNumberOfNewNotifications().then(() => {
                this.setState(({ hotUpdate }) => ({ hotUpdate: !hotUpdate }))
            })
        }, 20000)
    }

    handleClick = () => {
        const { patchNumberOfNewNotifications, resetNotifications } = this.props
        const { anchorEl } = this.state
        if (anchorEl) {
            resetNotifications()
            this.setState({ anchorEl: null })
        } else {
            patchNumberOfNewNotifications()
            this.fetchNotifications(1).then(() => {
                this.setState({ anchorEl: document.getElementById("notification_popper") })
            })
        }
    }

    handleNotificationClick = (id, deal_id) => {
        const { history, patchNotificationStatus } = this.props
        this.handleClick()
        patchNotificationStatus(id)
        history.push(`/main/redirect/${deal_id}`)
    }

    onScroll = e => {
        const { notificationsList } = this.props
        if (e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight === 0 && notificationsList.next) {
            let query = notificationsList.next.split("?page=")[1]
            this.fetchNotifications(query).then(() => {
                // scrollbar.scrollTop(scrollbar.getScrollHeight() - scrolled)  //could not be useful
            })
        }
    }

    render() {
        const { numberOfNewNotifications, notificationsList } = this.props
        const { anchorEl } = this.state
        const open = Boolean(anchorEl)
        const unreadCount = numberOfNewNotifications > 9 ? "9+" : numberOfNewNotifications
        return (
            <div className="notifications_wrapper">
                <IconButton onClick={this.handleClick} id="notification_popper">
                    <div className="notification_icon">
                        {!!unreadCount && <div className="unread_count">{unreadCount}</div>}
                        <img src={NotificationsIcon} alt="notification" />
                    </div>
                </IconButton>
                <ClickAwayListener onClickAway={this.handleClick}>
                    <Popper open={open} anchorEl={anchorEl} placement="bottom-end">
                        {notificationsList.results.length > 0 ? (
                            <ScrollBar maxHeight="260px" onScroll={this.onScroll}>
                                <div className="notifications_list">
                                    {notificationsList.results.map(({ id, html_description, is_read, tender_id }) => (
                                        <div
                                            className={clsx("notification", !is_read && "unread")}
                                            onClick={() => this.handleNotificationClick(id, tender_id)}
                                            key={id}
                                            dangerouslySetInnerHTML={{ __html: html_description }}
                                        />
                                    ))}
                                </div>
                            </ScrollBar>
                        ) : (
                            <div className="no_notifications">Нет новых уведомлений</div>
                        )}
                    </Popper>
                </ClickAwayListener>
            </div>
        )
    }
}

const mapStateToProps = ({ notifications }) => {
    return {
        notificationsList: notifications.notificationsList,
        numberOfNewNotifications: notifications.numberOfNewNotifications
    }
}

const mapDispatchToProps = {
    getNotifications,
    getNumberOfNewNotifications,
    patchNumberOfNewNotifications,
    resetNotifications,
    patchNotificationStatus
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Notifications))
