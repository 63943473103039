import React, { Component, Fragment } from "react"
import { Field, reduxForm, SubmissionError } from "redux-form"
import { Link } from "react-router-dom"
import { connect } from "react-redux"

import { recoverPassword } from "../../../actions/authActions"
import RenderField from "../../HelperComponents/RenderField/RenderField"
import DefaultButton from "../../HelperComponents/Buttons/DefaultButton/DefaultButton"

class PasswordRecovery extends Component {
    state = {
        loading: false
    }

    componentDidMount() {
        const { history } = this.props
        if (localStorage.token) {
            history.push("/main")
        }
    }

    submitForm = data => {
        const { recoverPassword, history } = this.props
        this.setState({ loading: true })
        return recoverPassword(data).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                localStorage.setItem("email", JSON.stringify(data))
                history.push("/auth/password-recovery/success")
            } else {
                this.setState({ loading: false })
                throw new SubmissionError({
                    ...res.error.response.data,
                    _error: res.error.response.data.detail
                })
            }
        })
    }

    render() {
        const { handleSubmit, submitting, pristine, valid } = this.props
        const { loading } = this.state
        return (
            <Fragment>
                <form onSubmit={handleSubmit(this.submitForm)}>
                    <div className="auth_block_head">Восстановление пароля</div>
                    <div className="auth_block_descriptions">
                        Введите свой электронный адрес, который вы использовали для <br />
                        регистрации, чтобы восстановить ваш пароль
                    </div>
                    <Field name="email" type="text" component={RenderField} label="Электронный адрес" />
                    <div className="auth_btn_wrapper">
                        <DefaultButton
                            variant="contained"
                            classes="sign_up_button"
                            disabled={submitting || pristine || !valid}
                            loading={loading}
                            formAction
                        >
                            Войти
                        </DefaultButton>
                    </div>
                </form>
                <div className="back_to_login">
                    Вспомнили пароль? &nbsp&nbsp&nbsp
                    <Link to="/auth/login" className="text_hover">
                        Войти
                    </Link>
                </div>
            </Fragment>
        )
    }
}

const validate = values => {
    const errors = {}
    if (!values.email) {
        errors.email = "Обязательное поле"
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i.test(values.email)) {
        errors.email = "Недопустимый электронный адрес"
    }
    return errors
}

PasswordRecovery = reduxForm({
    form: "PasswordRecoveryForm",
    validate
})(PasswordRecovery)

const mapDispatchToProps = {
    recoverPassword
}

export default connect(null, mapDispatchToProps)(PasswordRecovery)
