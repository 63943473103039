import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import clsx from "clsx"
import LinearProgress from "@material-ui/core/LinearProgress"
import ProductsControls from "./ProductsControls"
import CategoriesControls from "./CategoriesControls"
import ProductsDialogs from "./ProductsDialogs"
import pencil from "../../assets/image/edit.svg"
import Pagination from "../HelperComponents/Pagination/Pagination"
import TooltipMessage from "../HelperComponents/TooltipMessage/TooltipMessage"

import {
    getProducts,
    getSuppliers,
    getCategories,
    changeProductActivity,
    excelImport
} from "../../actions/productsActions"

import { getProductStatus, formatNumber } from "../../helpers/functions"

// import UpdateIcon from "../../assets/image/update.svg"

import "./Products.scss"
import CategoriesDialogs from "./CategoriesDialogs"

class Products extends Component {
    constructor(props) {
        super(props)
        //getting filters from url
        let params = new URLSearchParams(decodeURIComponent(props.location.search.substring(1)))
        let chosen_products_statuses = params.get("chosen_products_statuses")
                ? params.get("chosen_products_statuses")
                : "",
            chosen_categories = [],
            chosen_suppliers = []

        if (params.get("chosen_categories")) {
            params
                .get("chosen_categories")
                .split(",")
                .forEach(el => chosen_categories.push(el))
        }
        if (params.get("chosen_suppliers")) {
            params
                .get("chosen_suppliers")
                .split(",")
                .forEach(el => chosen_suppliers.push(el))
        }
        this.state = {
            loading: true,
            reloading: true,
            activePage: 1,
            activeTab: "products",

            openBlockDialog: false,
            openUnblockDialog: false,
            openCategoriesDialog: false,
            currentProductId: "",
            currentCategoryId: "",
            currentCategoryName: "",
            category_marker: "",
            //selectors values
            chosen_products_statuses,
            chosen_categories,
            chosen_suppliers,
            productSearch: ""
        }
    }

    componentDidMount() {
        const request = async () => {
            await this.props.getCategories()
            await this.props.getSuppliers()
            await this.doRequest()
        }
        request()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.chosen_categories || prevState.chosen_products_statuses || prevState.chosen_suppliers) {
            if (
                prevState.chosen_categories !== this.state.chosen_categories ||
                prevState.chosen_products_statuses !== this.state.chosen_products_statuses ||
                prevState.chosen_suppliers !== this.state.chosen_suppliers
            ) {
                this.doRequest()
            }
        }
        if (prevState.productSearch !== this.state.productSearch) {
            this.doRequest()
        }
    }

    doRequest = async (page = 1) => {
        const { getProducts, getCategories, categoriesList, suppliersList } = this.props
        const { activeTab, chosen_products_statuses, chosen_categories, chosen_suppliers, productSearch } = this.state
        let res

        if (activeTab === "products") {
            this.setState({ reloading: true })

            // converting status names in ids
            let converted_statuses = []
            if (chosen_products_statuses.includes("zАктивен")) {
                converted_statuses.push(true)
            } else if (chosen_products_statuses.includes("zЗаблокирован")) {
                converted_statuses.push(false)
            }
            // converting category names in ids
            let converted_categories = []
            categoriesList.forEach(cat =>
                chosen_categories.forEach(el => (el.slice(1) === cat.name ? converted_categories.push(cat.id) : null))
            )

            // converting suppliers names in ids
            let converted_suppliers = []
            suppliersList.forEach(sup =>
                chosen_suppliers.forEach(el => (el.slice(1) === sup.name ? converted_suppliers.push(sup.id) : null))
            )
            res = await getProducts(converted_suppliers, converted_categories, converted_statuses, page, productSearch)
        } else {
            // converting status names in ids
            let converted_statuses = []
            if (chosen_products_statuses.includes("zАктивна")) {
                converted_statuses.push(true)
            } else if (chosen_products_statuses.includes("zЗаблокирована")) {
                converted_statuses.push(false)
            }
            res = await getCategories(converted_statuses)
        }

        if (res.payload.status === 200) {
            this.setState({
                loading: false,
                reloading: false,
                activePage: page,
                chosen_products_statuses,
                chosen_categories,
                chosen_suppliers
            })
        }
    }

    changeTab = activeTab => {
        const { history, getCategories } = this.props
        if (activeTab === "products") {
            getCategories()
        }
        history.push(`/main/products`)
        this.setState({ activeTab, chosen_products_statuses: "", activePage: 1 })
    }

    //dialogs handler
    toggleDialog = (type, id, category_marker, name) => {
        if (category_marker) {
            this.setState({ category_marker: category_marker })
        }
        if (this.state.activeTab === "categories") {
            this.setState({ currentCategoryId: id, currentCategoryName: name })
        } else {
            this.setState({ currentProductId: id })
        }

        type === "block" &&
            this.setState(({ openBlockDialog }) => ({
                openBlockDialog: !openBlockDialog
            }))
        type === "unblock" &&
            this.setState(({ openUnblockDialog }) => ({
                openUnblockDialog: !openUnblockDialog
            }))
        type === "categories" &&
            this.setState(({ openCategoriesDialog }) => ({
                openCategoriesDialog: !openCategoriesDialog
            }))
    }

    getStatusHandler = (status_display, category_is_blocked, id) => {
        const {
            options: {
                me: { role }
            } //admin || senior_manager || manager
        } = this.props
        if (this.state.activeTab === "products") {
            if (status_display) {
                return (
                    <button
                        disabled={role !== "admin" && role !== "senior_manager"}
                        className="blockable"
                        onClick={() => this.toggleDialog("block", id)}
                    >
                        Заблокировать
                    </button>
                )
            } else {
                if (!category_is_blocked) {
                    return (
                        <TooltipMessage
                            text="Невозможно разблокировать этот товар, т.к. он принадлежит заблокированной категории"
                            delay={200}
                            position="bottom"
                            classes=""
                        >
                            <button
                                disabled={role !== "admin" && role !== "senior_manager"}
                                className="not_unblockable"
                            >
                                Разблокировать
                            </button>
                        </TooltipMessage>
                    )
                } else {
                    return (
                        <button
                            disabled={role !== "admin" && role !== "senior_manager"}
                            className="unblockable"
                            onClick={() => this.toggleDialog("unblock", id)}
                        >
                            Разблокировать
                        </button>
                    )
                }
            }
        } else {
            if (status_display) {
                return (
                    <button
                        disabled={role !== "admin" && role !== "senior_manager"}
                        className="blockable"
                        onClick={() => this.toggleDialog("block", id)}
                    >
                        Заблокировать
                    </button>
                )
            } else {
                return (
                    <button
                        disabled={role !== "admin" && role !== "senior_manager"}
                        className="unblockable"
                        onClick={() => this.toggleDialog("unblock", id)}
                    >
                        Разблокировать
                    </button>
                )
            }
        }
    }

    uploadExcelFile = async e => {
        e.persist()
        const { excelImport } = this.props
        this.setState({ reloading: true })
        let file = e.target.files[0]
        const formData = new FormData()
        formData.append("file", file)
        const res = await excelImport(formData)
        if (res.error) {
            this.setState({ message: res.error.response.data.file[0] })
        } else if (res.payload.status === 201) {
            this.doRequest()
        }
        document.getElementById("excelFile").value = ""
        this.setState({ reloading: false })
    }

    changePage = page => {
        this.setState({ activePage: page.selected + 1 })
    }

    getContent = () => {
        const {
            productsList,
            categoriesList,
            suppliersList,
            changeProductActivity,
            excelImportError,
            excelImportTemplate,
            options: {
                me: { role }
            } //admin || senior_manager || manager
        } = this.props
        const {
            activeTab,
            activePage,

            openBlockDialog,
            openCategoriesDialog,
            openUnblockDialog,

            chosen_products_statuses,
            chosen_categories,
            chosen_suppliers,

            currentProductId,
            currentCategoryId,
            currentCategoryName,
            category_marker
        } = this.state
        let products = [...productsList.results]

        if (activeTab === "products") {
            return (
                <Fragment>
                    <ProductsControls
                        role={role}
                        chosen_products_statuses={chosen_products_statuses}
                        setProductsStatuses={chosen_products_statuses => this.setState({ chosen_products_statuses })}
                        chosen_categories={chosen_categories}
                        setCategories={chosen_categories => this.setState({ chosen_categories })}
                        categoriesList={categoriesList}
                        chosen_suppliers={chosen_suppliers}
                        setSuppliers={chosen_suppliers => this.setState({ chosen_suppliers })}
                        suppliersList={suppliersList}
                        uploadExcelFile={this.uploadExcelFile}
                        excelImportError={excelImportError}
                        excelImportTemplate={excelImportTemplate}
                        setProductSearch={productSearch => this.setState({ productSearch })}
                    />
                    <div className="table_container">
                        <div className="table_header no_select">
                            <div className="table_row">
                                <div className="row_item">
                                    <p>Наименование</p>
                                </div>
                                <div className="row_item">
                                    <p>Категория</p>
                                </div>
                                <div className="row_item">
                                    <p>Поставщик</p>
                                </div>
                                <div className="row_item">
                                    <p>Цена за единицу</p>
                                </div>
                                <div className="row_item">
                                    <p>Единица измерения</p>
                                </div>
                                <div className="row_item">
                                    <p>Статус</p>
                                </div>
                                <div className="row_item" />
                                <div className="row_item" />
                            </div>
                        </div>
                        <div className="table_body">
                            {products.length > 0 ? (
                                products.map(({ id, name, category, supplier, price, unit, is_active }) => (
                                    <div className="table_row" key={`product_${id}`}>
                                        <div className={clsx("row_item", !is_active && "blocked")}>
                                            <p className="overflowed_text" title={name}>
                                                {name}
                                            </p>
                                        </div>
                                        <div className={clsx("row_item", category && !category.is_active && "blocked")}>
                                            <p className="overflowed_text" title={category && category.name}>
                                                {category && category.name}
                                            </p>
                                        </div>
                                        <div className="row_item">
                                            <p title={supplier} className="overflowed_text">
                                                {supplier}
                                            </p>
                                        </div>
                                        <div className="row_item">{formatNumber((+price).toFixed(2))}</div>
                                        <div className="row_item">{unit}</div>
                                        <div className="row_item">{getProductStatus(is_active)}</div>
                                        <div className="row_item">
                                            {this.getStatusHandler(is_active, category ? category.is_active : true, id)}
                                        </div>
                                        <div className="row_item no_select usable_text text_hover">
                                            <Link to={`/main/product/${id}`}>
                                                <img src={pencil} alt="pencil" />
                                            </Link>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className="table_row">Отсутствуют продукты</div>
                            )}
                        </div>
                        <hr />
                    </div>
                    {products.length === 0 ? null : (
                        <Pagination
                            active={activePage - 1}
                            pageCount={productsList.count / 10}
                            onChange={e => {
                                this.doRequest(e.selected + 1)
                            }}
                        />
                    )}
                    <ProductsDialogs
                        currentProductId={currentProductId}
                        doRequest={this.doRequest}
                        toggleDialog={this.toggleDialog}
                        openBlockDialog={openBlockDialog}
                        openUnblockDialog={openUnblockDialog}
                        changeProductActivity={changeProductActivity}
                        activePage={activePage}
                    />
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <CategoriesControls
                        role={role}
                        chosen_products_statuses={chosen_products_statuses}
                        setProductsStatuses={chosen_products_statuses => this.setState({ chosen_products_statuses })}
                        toggleAddCategoryDialog={this.toggleDialog}
                    />
                    <div className="table_container ">
                        <div className="table_header no_select categories">
                            <div className="table_row">
                                <div className="row_item">
                                    <p>Наименование</p>
                                </div>
                                <div className="row_item">
                                    <p>Статус</p>
                                </div>
                                <div className="row_item" />
                                <div className="row_item" />
                            </div>
                        </div>
                        <div className="table_body categories">
                            {categoriesList.length > 0 ? (
                                (categoriesList.length > 10
                                    ? categoriesList.slice(activePage * 10 - 10, activePage * 10)
                                    : categoriesList
                                ).map(({ id, name, is_active }) => (
                                    <div className="table_row" key={`product_${id}`}>
                                        <div className={clsx("row_item", !is_active && "blocked")}>
                                            <p className="overflowed_text" title={name}>
                                                {name}
                                            </p>
                                        </div>
                                        <div className="row_item">{getProductStatus(is_active)}</div>
                                        <div className="row_item">
                                            {this.getStatusHandler(is_active, is_active, id)}
                                        </div>
                                        <div className="row_item no_select usable_text text_hover">
                                            <img
                                                onClick={() => this.toggleDialog("categories", id, "edit", name)}
                                                src={pencil}
                                                alt="pencil"
                                            />
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className="table_row">Отсутствуют продукты</div>
                            )}
                        </div>
                        <hr />
                    </div>
                    {Math.ceil(categoriesList.length / 10) > 1 && (
                        <Pagination
                            active={activePage - 1}
                            onChange={this.changePage}
                            pageCount={Math.ceil(categoriesList.length / 10)}
                        />
                    )}
                    <CategoriesDialogs
                        currentCategoryId={currentCategoryId}
                        doRequest={this.doRequest}
                        toggleDialog={this.toggleDialog}
                        openBlockDialog={openBlockDialog}
                        openUnblockDialog={openUnblockDialog}
                        openCategoriesDialog={openCategoriesDialog}
                        category_marker={category_marker}
                        categoriesList={categoriesList}
                        currentCategoryName={currentCategoryName}
                        initialValues={{ name: currentCategoryName }}
                        activePage={activePage}
                    />
                </Fragment>
            )
        }
    }

    render() {
        const { loading, reloading, activeTab } = this.state
        if (loading) return <LinearProgress />
        return (
            <Fragment>
                {reloading ? <LinearProgress /> : <hr className="loader_dummy" />}
                <div className="page_wrapper products_wrapper">
                    <h1>{activeTab === "products" ? "Товары" : "Категории"}</h1>
                    <div className="tabs_wrapper">
                        <div
                            className={clsx("tab text_hover", activeTab === "products" && "active_tab")}
                            onClick={() => this.changeTab("products")}
                        >
                            Товары
                        </div>
                        <div
                            className={clsx("tab text_hover", activeTab === "categories" && "active_tab")}
                            onClick={() => this.changeTab("categories")}
                        >
                            Категории
                        </div>
                    </div>
                    {this.getContent()}
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = ({ products, dashboard }) => {
    return {
        productsList: products.productsList,
        categoriesList: products.categoriesList,
        suppliersList: products.suppliersList,
        productActivityError: products.productActivityError,
        options: dashboard.options,
        excelImportError: products.excelImportError,
        excelImport: products.excelImport,
        excelImportTemplate: dashboard.options.catalog.template
    }
}

const mapDispatchToProps = {
    getProducts,
    getCategories,
    getSuppliers,
    changeProductActivity,
    excelImport
}

export default connect(mapStateToProps, mapDispatchToProps)(Products)
