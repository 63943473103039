import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import LinearProgress from "@material-ui/core/LinearProgress"
import moment from "moment"
import FormControl from "@material-ui/core/FormControl"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Radio from "@material-ui/core/Radio"
import clsx from "clsx"
import FormGroup from "@material-ui/core/FormGroup"
import Checkbox from "@material-ui/core/Checkbox"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import { Field, reduxForm, formValueSelector } from "redux-form"

import DefaultButton from "../HelperComponents/Buttons/DefaultButton/DefaultButton"
import DialogComponent from "../HelperComponents/DialogComponent/DialogComponent"
import PopperInfo from "../HelperComponents/PopperInfo/PopperInfo"
import Snack from "../HelperComponents/Snack/Snack"
import DateTimeField from "../HelperComponents/RenderDatePicker/RenderDateTimePicker"

import { formatNumber } from "../../helpers/functions"

import { getTender, patchTender, getManagers, reDownloadTender } from "../../actions/dashboardActions"
import { getCategories } from "../../actions/productsActions"

import "./Application.scss"

import BackIcon from "../../assets/image/back.svg"
import CheckIcon from "../../assets/image/check.svg"
import ArrowDownIcon from "../../assets/image/arrow_down.svg"
import FailedIcon from "../../assets/image/failed.svg"
import DocumentIcon from "../../assets/image/file.svg"
import Calculation from "../Calculation/Calculation"

class Application extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            reloading: true,
            selectedElements: [],
            radioValue: "first",
            openCalculateDialog: false,
            openDeleteDialog: false,
            openChangeTypeDialog: false,
            checkImportant: false,
            value: "",
            anchor: null,
            open: false,
            chosenManager: {},
            active_tab: 0,
            openTypeControl: false,
            chosenType: "",
            openErrorSnack: false
        }
    }

    async componentDidMount() {
        this.doRequest()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.doRequest()
        }
    }

    doRequest = async () => {
        const {
            getTender,
            match: {
                params: { id }
            }
        } = this.props
        this.setState({ reloading: true })
        const res = await getTender(id)
        if (res.payload.status === 200) {
            this.setState({ loading: false, reloading: false })
        }
    }

    toggleDialog = type => {
        type === "calculate" &&
            this.setState(({ openCalculateDialog }) => ({
                openCalculateDialog: !openCalculateDialog
            }))
        type === "delete" &&
            this.setState(({ openDeleteDialog }) => ({
                openDeleteDialog: !openDeleteDialog
            }))
        type === "change_type" &&
            this.setState(({ openChangeTypeDialog }) => ({
                openChangeTypeDialog: !openChangeTypeDialog
            }))
    }

    deleteTender = async () => {
        const {
            patchTender,
            history,
            match: {
                params: { id }
            }
        } = this.props
        this.setState({ reloading: true })
        const data = { status: "deleted" }
        await patchTender(id, data)
        this.setState({ reloading: false })
        this.toggleDialog("delete")
        history.push("/main/tenders")
    }

    calculateTender = async values => {
        const {
            patchTender,
            history,
            match: {
                params: { id }
            }
        } = this.props
        const { chosenManager, checkImportant, radioValue } = this.state
        this.setState({ reloading: true })

        let data = {
            is_important: checkImportant,
            custom_deadline: moment(values.deadline)
        }
        if (radioValue === "first") {
            data.status = "calculating"
            data.manager = chosenManager.id
        } else {
            data.status = "to_calculate"
            data.manager = null
        }
        await patchTender(id, data)
        this.toggleDialog("calculate")
        history.push("/main/tenders")
    }

    handleInputChange = e => this.setState({ value: e.target.value, chosenManager: {} })

    handleKeyUp = async e => {
        const { getManagers } = this.props
        let value = e.target.value
        if (value.length >= 1 && value.trim()) {
            const res = await getManagers(value)
            if (res.payload && res.payload.status === 200 && res.payload.data.length > 0) {
                this.setState({
                    anchor: document.getElementById("search_input"),
                    open: true
                })
            } else {
                this.clickAway()
            }
        } else {
            this.clickAway()
        }
    }

    selectElement = chosenManager => {
        this.setState({ value: chosenManager.fio, chosenManager })
        this.clickAway()
    }

    clickAway = () => this.setState({ open: false })

    handleTypeControl = () => this.setState(({ openTypeControl }) => ({ openTypeControl: !openTypeControl }))

    changeTab = active_tab => {
        this.setState({ active_tab })
    }

    changeType = async () => {
        const {
            patchTender,
            match: {
                params: { id }
            }
        } = this.props
        const { chosenType } = this.state
        let data = {
            type: chosenType
        }
        this.handleTypeControl()
        await patchTender(id, data)
        this.doRequest()
        this.toggleDialog("change_type")
    }

    reDownloadFiles = async () => {
        const {
            reDownloadTender,
            match: {
                params: { id }
            }
        } = this.props
        const res = await reDownloadTender(id)
        if (res.error) {
            this.setState({ openErrorSnack: true })
        } else {
            this.doRequest()
        }
    }

    closeSnack = () => this.setState({ openErrorSnack: false })

    getContent = () => {
        const {
            tender: {
                data: {
                    customer,
                    name,
                    start_price_contract,
                    datetime_dedline_request,
                    datetime_holding,
                    date_delivery_goods,
                    request_type,
                    category,
                    link,
                    request_provision_size,
                    e_place,
                    number,
                    reestr_number,
                },
                type,
                manager,
                responsible,
                archive_files,
                archive_status
            }
        } = this.props
        const { active_tab, openTypeControl } = this.state
        if (active_tab === 0) {
            return (
                <>
                    {number && number[0] !== "А" || reestr_number && reestr_number[0] !== "A" ? (
                        <Fragment>
                            <div className="appl_first_row">
                                <div>
                                    <span>Заказчик</span>
                                    <p>{customer}</p>
                                </div>
                                <div className="relative_div">
                                    <span>Название тендера</span>
                                    <p className="overflowed_text" title={name}>
                                        {name}
                                    </p>
                                </div>
                                <div>
                                    <span>Начальная цена контракта (лота)</span>
                                    <p>
                                        <b>{formatNumber(parseFloat(start_price_contract).toFixed(2))}</b>
                                    </p>
                                </div>
                            </div>
                            <hr />
                            <div className="appl_second_row">
                                <div>
                                    <span>Окончание подачи заявок</span>
                                    <p>
                                        {datetime_dedline_request === "-" || !moment(datetime_dedline_request).isValid()
                                            ? datetime_dedline_request
                                            : moment(datetime_dedline_request).format("DD/MM/YY HH:mm")}
                                    </p>
                                </div>
                                <div>
                                    <span>Время проведения</span>
                                    <p>

                                        {datetime_dedline_request === "-" || !moment(datetime_dedline_request).isValid()
                                            ? datetime_dedline_request
                                            : moment(datetime_dedline_request).format("DD/MM/YY HH:mm")}
                                    </p>
                                </div>
                                <div className="relative_div">
                                    <span>Сроки выполнения</span>
                                    <p className="overflowed_text" title={date_delivery_goods}>
                                        {date_delivery_goods || "-"}
                                    </p>
                                </div>
                            </div>
                            <hr />
                            <div className="appl_third_row">
                                <div>
                                    <span>Тип тендера</span>
                                    <p title={request_type}>{request_type || "-"}</p>
                                </div>
                                <div>
                                    <span>Категория</span>
                                    <p>{category || "-"}</p>
                                </div>
                                <div>
                                    <span>Сайт</span>
                                    <p>
                                        {link ? (
                                            <a
                                                className="text_hover"
                                                href={link.includes("http") ? link : `//${link}`}
                                                rel="noopener noreferrer"
                                                target="_blank"
                                            >
                                                {link}
                                            </a>
                                        ) : (
                                            "-"
                                        )}
                                    </p>
                                </div>
                            </div>
                            <hr />
                            <div className="appl_fourth_row">
                                <div>
                                    <span>Размер обеспечения заявки</span>
                                    <p>
                                        {request_provision_size
                                            ? formatNumber(
                                                  parseFloat(request_provision_size.toString().replace(",", "."))
                                              )
                                            : "-"}
                                    </p>
                                </div>
                                <div>
                                    <span>Размер обеспечения контракта</span>
                                    <p>-</p>
                                </div>
                                <div>
                                    <span>Электронная площадка</span>
                                    <p>
                                        {e_place ? (
                                            <a
                                                className="text_hover"
                                                href={e_place.includes("http") ? e_place : `//${e_place}`}
                                                rel="noopener noreferrer"
                                                target="_blank"
                                            >
                                                {e_place}
                                            </a>
                                        ) : (
                                            "-"
                                        )}
                                    </p>
                                </div>
                            </div>
                            <hr />
                            <div className="appl_fifth_row">
                                <div>
                                    <span>Тип</span>
                                    <p onClick={this.handleTypeControl} className="text_hover">
                                        {type || "Выберите тип"}
                                        <img src={ArrowDownIcon} alt="arrow_down" />
                                    </p>
                                    {openTypeControl && (
                                        <ClickAwayListener onClickAway={this.handleTypeControl}>
                                            <div className="types_popper">
                                                <div
                                                    className={clsx(type === "ЗК" && "current")}
                                                    onClick={() => {
                                                        this.toggleDialog("change_type")
                                                        this.setState({ chosenType: "ЗК" })
                                                    }}
                                                >
                                                    <span>ЗК</span>
                                                    {type === "ЗК" && <img src={CheckIcon} alt="!" />}
                                                </div>
                                                <div
                                                    className={clsx(type === "Ф2" && "current")}
                                                    onClick={() => {
                                                        this.toggleDialog("change_type")
                                                        this.setState({ chosenType: "Ф2" })
                                                    }}
                                                >
                                                    <span>Ф2</span>
                                                    {type === "Ф2" && <img src={CheckIcon} alt="!" />}
                                                </div>
                                                <div
                                                    className={clsx(type === "ЭА" && "current")}
                                                    onClick={() => {
                                                        this.toggleDialog("change_type")
                                                        this.setState({ chosenType: "ЭА" })
                                                    }}
                                                >
                                                    <span>ЭА</span>
                                                    {type === "ЭА" && <img src={CheckIcon} alt="!" />}
                                                </div>
                                                <div
                                                    className={clsx(type === "ОФ" && "current")}
                                                    onClick={() => {
                                                        this.toggleDialog("change_type")
                                                        this.setState({ chosenType: "ОФ" })
                                                    }}
                                                >
                                                    <span>ОФ</span>
                                                    {type === "ОФ" && <img src={CheckIcon} alt="!" />}
                                                </div>
                                            </div>
                                        </ClickAwayListener>
                                    )}
                                </div>
                            </div>
                            <hr />
                            <div className="dialog_buttons">
                                <DefaultButton
                                    variant="outlined"
                                    onClick={() => this.toggleDialog("delete")}
                                    classes="delete_btn"
                                >
                                    Удалить
                                </DefaultButton>
                                {type && (
                                    <DefaultButton
                                        variant="contained"
                                        onClick={() => this.toggleDialog("calculate")}
                                        classes="calculate_btn"
                                    >
                                        Рассчитать
                                    </DefaultButton>
                                )}
                            </div>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <div className="appl_first_row custom">
                                <div>
                                    <span>Заказчик</span>
                                    <p>{customer}</p>
                                </div>
                                <div className="relative_div">
                                    <span>Название тендера</span>
                                    <p className="overflowed_text" title={name}>
                                        {name}
                                    </p>
                                </div>
                            </div>
                            <hr />
                            <div className="appl_second_row custom">
                                <div>
                                    <span>Тип аукциона</span>
                                    <p>{type || "-"}</p>
                                </div>
                                <div>
                                    <span>Тип тендера</span>
                                    <p title={request_type}>{request_type || "-"}</p>
                                </div>
                                <div>
                                    <span>Привязанный</span>
                                    <p>{manager || "-"}</p>
                                </div>
                                <div>
                                    <span>Ответственный</span>
                                    <p>{responsible || "-"}</p>
                                </div>
                            </div>
                            <hr />
                            <div className="dialog_buttons">
                                <DefaultButton
                                    variant="outlined"
                                    onClick={() => this.toggleDialog("delete")}
                                    classes="delete_btn"
                                >
                                    Удалить
                                </DefaultButton>
                                {type && (
                                    <DefaultButton
                                        variant="contained"
                                        onClick={() => this.toggleDialog("calculate")}
                                        classes="calculate_btn"
                                    >
                                        Рассчитать
                                    </DefaultButton>
                                )}
                            </div>
                        </Fragment>
                    )}
                </>
            )
        } else if (active_tab === 1) {
            return (
                <Fragment>
                    {archive_status === "downloaded" ? (
                        <div className="documents_wrapper">
                            {archive_files.map(({ filename, url }) => (
                                <div className="document" key={filename}>
                                    <a href={url} download className="usable_text text_hover">
                                        <img src={DocumentIcon} alt="document" />
                                        {filename}
                                    </a>
                                </div>
                            ))}
                        </div>
                    ) : archive_status === "error" ? (
                        <div className="download_failed">
                            <img src={FailedIcon} alt="error" />
                            Попытка скачать документы не удалась
                            <div onClick={this.reDownloadFiles} className="text_hover usable_text">
                                Повторить попытку
                            </div>
                        </div>
                    ) : (
                        <div className="download_scheduled">В очереди на скачивание</div>
                    )}
                </Fragment>
            )
        } else {
            return (
                <Calculation
                    toggleDialog={this.toggleDialog}
                    setReloading={() => this.setState({ reloading: true })}
                    unsetReloading={() => this.setState({ reloading: false })}
                />
            )
        }
    }

    render() {
        const {
            loading,
            openDeleteDialog,
            openCalculateDialog,
            openChangeTypeDialog,
            radioValue,
            reloading,
            checkImportant,
            value,
            anchor,
            open,
            chosenManager,
            active_tab,
            openErrorSnack
        } = this.state
        if (loading) return <LinearProgress />
        const {
            tender: {
                data: { number, reestr_number }
            },
            managers,
            match: {
                params: { id }
            },
            handleSubmit,
            submitting,
            pristine,
            valid,
            deadline
        } = this.props
        return (
            <div className="page_wrapper application_wrapper">
                <Link to="/main/tenders" className="text_hover usable_text">
                    <img src={BackIcon} alt="<" />
                    Назад
                </Link>
                <h1>Детали заявки</h1>
                <div className="appl_number">
                    Заявка <span>№{number || reestr_number}</span>
                </div>
                <div className="tabs_wrapper">
                    <div
                        className={clsx("tab text_hover", active_tab === 0 && "active_tab")}
                        onClick={() => this.changeTab(0)}
                    >
                        Детали
                    </div>
                    {number && number[0] !== "А" && (
                        <div
                            className={clsx("tab text_hover", active_tab === 1 && "active_tab")}
                            onClick={() => this.changeTab(1)}
                        >
                            Документы
                        </div>
                    )}
                    {reestr_number && reestr_number[0] !== "А" && (
                        <div
                            className={clsx("tab text_hover", active_tab === 1 && "active_tab")}
                            onClick={() => this.changeTab(1)}
                        >
                            Документы
                        </div>
                    )}
                    {number && active_tab !== 2 && number[0] === "А" && (
                        <Link to={`/main/edit-custom-tender/${id}`} className="usable_text text_hover">
                            Редактировать
                        </Link>
                    )}
                    {reestr_number && active_tab !== 2 && reestr_number[0] === "А" && (
                        <Link to={`/main/edit-custom-tender/${id}`} className="usable_text text_hover">
                            Редактировать
                        </Link>
                    )}
                </div>
                {this.getContent()}
                <DialogComponent open={openCalculateDialog} onClose={() => this.toggleDialog("calculate")}>
                    <div className="dashboard_dialog no_select">
                        <h2>Рассчитать заявку</h2>
                        <br />
                        <form onSubmit={handleSubmit(this.calculateTender)}>
                            <FormControl component="fieldset">
                                <RadioGroup
                                    value={radioValue}
                                    onChange={e => this.setState({ radioValue: e.target.value })}
                                >
                                    <FormControlLabel value="first" control={<Radio />} label="Назначить менеджера" />
                                    <FormControlLabel value="second" control={<Radio />} label="Общий доступ" />
                                </RadioGroup>
                            </FormControl>
                            <div className={clsx("search_input_wrapper", radioValue === "second" && "disabled")}>
                                <input
                                    id="search_input"
                                    type="text"
                                    className="manager_input"
                                    placeholder="Введите имя менеджера"
                                    onKeyUp={this.handleKeyUp}
                                    onChange={this.handleInputChange}
                                    value={value}
                                    disabled={radioValue === "second"}
                                    autoComplete="off"
                                />
                                <PopperInfo
                                    open={open}
                                    anchorEl={anchor}
                                    clickAway={this.clickAway}
                                    position="bottom-start"
                                    classes="manager_popper"
                                    transition
                                >
                                    <ul>
                                        {managers.map(el => (
                                            <li
                                                className="text_hover"
                                                onClick={() => this.selectElement(el)}
                                                key={el.id}
                                            >
                                                {el.fio}
                                            </li>
                                        ))}
                                    </ul>
                                </PopperInfo>
                            </div>
                            <div className="deadline_and_important">
                                <Field
                                    name="deadline"
                                    component={DateTimeField}
                                    label="Дедлайн"
                                    disablePast={true}
                                    invalidDateMessage="Недопустимый дедлайн"
                                    minDateMessage="Недопустимый дедлайн"
                                    maxDateMessage="Недопустимый дедлайн"
                                    invalidLabel="Недопустимый дедлайн"
                                />
                                <FormControl component="fieldset">
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={checkImportant}
                                                    onChange={() => this.setState({ checkImportant: !checkImportant })}
                                                    color="primary"
                                                    inputProps={{ "aria-label": "secondary checkbox" }}
                                                />
                                            }
                                            label="Отметить заявку как важную"
                                        />
                                    </FormGroup>
                                </FormControl>
                            </div>
                            <div className="dialog_buttons">
                                <DefaultButton
                                    variant="outlined"
                                    onClick={() => this.toggleDialog("calculate")}
                                    classes="cancel_btn"
                                >
                                    Отмена
                                </DefaultButton>
                                <DefaultButton
                                    variant="contained"
                                    formAction
                                    classes="confirm_btn"
                                    disabled={
                                        (!chosenManager.fio && radioValue === "first") ||
                                        (deadline && !deadline._isValid) ||
                                        !valid ||
                                        submitting ||
                                        pristine
                                    }
                                    loading={reloading}
                                >
                                    Рассчитать
                                </DefaultButton>
                            </div>
                        </form>
                    </div>
                </DialogComponent>
                <DialogComponent open={openDeleteDialog} onClose={() => this.toggleDialog("delete")}>
                    <div className="dashboard_dialog no_select">
                        <h2>Удалить заявку</h2>
                        <div className="dialog_description">Вы уверены, что хотите удалить эту заявку?</div>
                        <div className="dialog_buttons">
                            <DefaultButton
                                variant="outlined"
                                onClick={() => this.toggleDialog("delete")}
                                classes="cancel_btn"
                            >
                                Отмена
                            </DefaultButton>
                            <DefaultButton
                                variant="contained"
                                onClick={this.deleteTender}
                                classes="confirm_btn"
                                loading={reloading}
                            >
                                Удалить
                            </DefaultButton>
                        </div>
                    </div>
                </DialogComponent>
                <DialogComponent open={openChangeTypeDialog} onClose={() => this.toggleDialog("change_type")}>
                    <div className="dashboard_dialog no_select">
                        <h2>Поменять тип сделки</h2>
                        <div className="dialog_description">Подтвердите, что Вы хотите поменять тип этой сделки</div>
                        <div className="dialog_buttons">
                            <DefaultButton
                                variant="outlined"
                                onClick={() => this.toggleDialog("change_type")}
                                classes="cancel_btn"
                            >
                                Отмена
                            </DefaultButton>
                            <DefaultButton
                                variant="contained"
                                onClick={this.changeType}
                                classes="confirm_btn"
                                loading={reloading}
                            >
                                Подтвердить
                            </DefaultButton>
                        </div>
                    </div>
                </DialogComponent>
                <Snack
                    open={openErrorSnack}
                    handleSnack={this.closeSnack}
                    message="Такое действие этому пользователю в этой заявке запрещено"
                    type="error"
                />
            </div>
        )
    }
}

const validate = values => {
    const errors = {}
    if (!values.deadline) {
        errors.deadline = "Недопустимый дедлайн"
    }
    return errors
}

Application = reduxForm({
    form: "ApplicationTenderForm",
    validate
})(Application)

const selector = formValueSelector("ApplicationTenderForm")

Application = connect(state => {
    const deadline = selector(state, "deadline")
    return {
        deadline
    }
})(Application)

const mapStateToProps = state => {
    return {
        tender: state.dashboard.tender,
        managers: state.dashboard.managers
    }
}
const mapDispatchToProps = {
    getTender,
    patchTender,
    getManagers,
    getCategories,
    reDownloadTender
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Application))
