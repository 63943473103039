import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import LinearProgress from "@material-ui/core/LinearProgress";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import clsx from "clsx";
import DefaultButton from "../HelperComponents/Buttons/DefaultButton/DefaultButton";
import DialogComponent from "../HelperComponents/DialogComponent/DialogComponent";
import { sortUsers } from "../../helpers/functions";
import editImg from "../../assets/image/edit.svg";
import Checkbox from "@material-ui/core/Checkbox";
import notFavouriteImg from "../../assets/image/not_favourite.svg";
import favouriteImg from "../../assets/image/favourite.svg";

import { getUsers, patchUser, deleteUser, getCustomers, patchCustomers } from "../../actions/dashboardActions";
import ReactTooltip from "react-tooltip";
import "./Users.scss";

import SortIcon from "../../assets/image/sort.svg";
import DeleteIcon from "../../assets/image/delete.svg";
import ExpandIcon from "../../assets/image/expand.svg";
import CheckIcon from "../../assets/image/check.svg";

class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            reloading: true,
            currentUser: null,
            selectedElements: [],
            openDeleteDialog: false,
            openChangeDialog: false,
            openRoleDialog: false,
            openCustomersDialog: false,
            sortBy: "",
            status: "",
            roleTo: "",
            searchValue: "",
            chosenCustomers: [],
            stateCustomers: [],
            chosenId: null
        };
    }

    componentDidMount() {
        this.doRequest();
    }

    doRequest = async () => {
        const { getUsers } = this.props;
        this.setState({ reloading: true });
        const res = await getUsers();
        if (res.payload.status === 200) {
            this.setState({ loading: false, reloading: false });
        }
    };

    handleSelected = id => {
        const { selectedElements } = this.state;
        if (selectedElements.includes(id)) {
            this.setState({
                selectedElements: selectedElements.filter(el => el !== id)
            });
        } else {
            this.setState({ selectedElements: [...selectedElements, id] });
        }
    };

    toggleDialog = type => {
        type === "delete" &&
            this.setState(({ openDeleteDialog }) => ({
                openDeleteDialog: !openDeleteDialog
            }));
        type === "change" &&
            this.setState(({ openChangeDialog }) => ({
                openChangeDialog: !openChangeDialog
            }));
        type === "role" &&
            this.setState(({ openRoleDialog }) => ({
                openRoleDialog: !openRoleDialog
            }));
        type === "customers" &&
            this.setState(({ openCustomersDialog }) => ({
                openCustomersDialog: !openCustomersDialog
            }));
    };

    changeRole = async () => {
        const { patchUser } = this.props;
        const { currentUser, roleTo } = this.state;
        this.handleSelected(currentUser);
        this.setState({ reloading: true });
        const data = {
            role: roleTo
        };
        await patchUser(currentUser, data);
        this.doRequest();
        this.toggleDialog("role");
    };

    deleteUser = async () => {
        const { deleteUser } = this.props;
        const { currentUser } = this.state;
        this.setState({ reloading: true });
        await deleteUser(currentUser);
        this.doRequest();
        this.toggleDialog("delete");
    };

    changeStatus = async () => {
        const { patchUser } = this.props;
        const { currentUser, status } = this.state;
        this.setState({ reloading: true });
        const data = {
            is_active: !status
        };
        await patchUser(currentUser, data);
        this.doRequest();
        this.toggleDialog("change");
    };

    render() {
        const { users, getCustomers, patchCustomers } = this.props;
        const {
            loading,
            selectedElements,
            openDeleteDialog,
            openChangeDialog,
            openRoleDialog,
            sortBy,
            reloading,
            status,
            openCustomersDialog,
            searchValue,
            stateCustomers,
            chosenCustomers,
            chosenId
        } = this.state;
        if (loading) return <LinearProgress />;
        const sortedUsers = sortUsers(users, sortBy);
        return (
            <Fragment>
                {reloading ? <LinearProgress /> : <hr className="loader_dummy" />}
                <div className="page_wrapper users_wrapper">
                    <h1>Пользователи</h1>
                    <div className="table_container">
                        <div className="table_header no_select">
                            <div className="table_row">
                                <div className="row_item">
                                    <p>Имя</p>
                                    <img
                                        className="text_hover"
                                        src={SortIcon}
                                        alt="Иконка сортировки"
                                        onClick={() => {
                                            this.setState({
                                                sortBy: sortBy === "nameUp" ? "nameDown" : "nameUp"
                                            });
                                        }}
                                    />
                                </div>
                                <div className="row_item">
                                    <p>Роль</p>
                                    <img
                                        className="text_hover"
                                        src={SortIcon}
                                        alt="Иконка сортировки"
                                        onClick={() => {
                                            this.setState({
                                                sortBy: sortBy === "roleUp" ? "roleDown" : "roleUp"
                                            });
                                        }}
                                    />
                                </div>
                                <div className="row_item">
                                    <p>Привязанный заказчик</p>
                                    <img
                                        className="text_hover"
                                        src={SortIcon}
                                        alt=""
                                        onClick={() => {
                                            this.setState({
                                                sortBy: sortBy === "statusUp" ? "statusDown" : "statusUp"
                                            });
                                        }}
                                    />
                                </div>
                                <div className="row_item">
                                    <p>Статус</p>
                                    <img
                                        className="text_hover"
                                        src={SortIcon}
                                        alt=""
                                        onClick={() => {
                                            this.setState({
                                                sortBy: sortBy === "statusUp" ? "statusDown" : "statusUp"
                                            });
                                        }}
                                    />
                                </div>
                                <div className="row_item" />
                                <div className="row_item" />
                            </div>
                        </div>
                        <div className="table_body">
                            {sortedUsers.map(({ id, fio, role, role_display, is_active, customers }) => (
                                <div className="table_row" key={id}>
                                    <div className="row_item">{fio || "-"}</div>
                                    <div className="row_item no_select">
                                        <span className="text_hover" onClick={() => this.handleSelected(id)}>
                                            {role_display || <span className="choose_role">Выберите роль</span>}
                                            <img
                                                src={ExpandIcon}
                                                alt=">"
                                                className={clsx(
                                                    selectedElements.includes(id) && "transform_rotate",
                                                    "text_hover"
                                                )}
                                            />
                                        </span>
                                        {selectedElements.includes(id) && (
                                            <ClickAwayListener onClickAway={() => this.handleSelected(id)}>
                                                <div className="roles_popper">
                                                    <div
                                                        className={clsx(role === "admin" && "current")}
                                                        onClick={() => {
                                                            this.setState({
                                                                currentUser: id,
                                                                roleTo: "admin"
                                                            });
                                                            this.toggleDialog("role");
                                                        }}
                                                    >
                                                        <span>Администратор</span>
                                                        {role === "admin" && <img src={CheckIcon} alt="!" />}
                                                    </div>
                                                    <div
                                                        className={clsx(role === "senior_manager" && "current")}
                                                        onClick={() => {
                                                            this.setState({
                                                                currentUser: id,
                                                                roleTo: "senior_manager"
                                                            });
                                                            this.toggleDialog("role");
                                                        }}
                                                    >
                                                        <span>Старший менеджер</span>
                                                        {role === "senior_manager" && <img src={CheckIcon} alt="!" />}
                                                    </div>
                                                    <div
                                                        className={clsx(role === "manager" && "current")}
                                                        onClick={() => {
                                                            this.setState({
                                                                currentUser: id,
                                                                roleTo: "manager"
                                                            });
                                                            this.toggleDialog("role");
                                                        }}
                                                    >
                                                        <span>Менеджер</span>
                                                        {role === "manager" && <img src={CheckIcon} alt="!" />}
                                                    </div>
                                                </div>
                                            </ClickAwayListener>
                                        )}
                                    </div>
                                    <div className="row_item no_select">
                                        <div className="customers_wrapper">
                                            <div className="customers_wrapper_content">
                                                {customers && customers.length === 0 && "-"}
                                                {customers && customers.length === 1 && (
                                                    <span>{customers[0].full_name}</span>
                                                )}
                                                {customers && customers.length > 1 && (
                                                    <>
                                                        <span>
                                                            {customers[0].full_name} и{" "}
                                                            <span
                                                                className="more-customers"
                                                                data-tip
                                                                data-for={`customers-${id}`}
                                                            >
                                                                ещё {customers.length - 1}
                                                            </span>
                                                        </span>
                                                        <ReactTooltip
                                                            id={`customers-${id}`}
                                                            place={"bottom"}
                                                            type={"light"}
                                                            effect={"solid"}
                                                            multiline={true}
                                                            className="extraClass"
                                                        >
                                                            {customers.slice(1).map(el => (
                                                                <div className="customer-tooltip">
                                                                    {el.full_name}
                                                                    {el.is_chosen && <img src={favouriteImg} alt="" />}
                                                                </div>
                                                            ))}
                                                        </ReactTooltip>
                                                    </>
                                                )}
                                                <img
                                                    src={editImg}
                                                    alt="Иконка редактирования"
                                                    onClick={() => {
                                                        this.setState({ chosenId: id });
                                                        getCustomers(id).then(res => {
                                                            if (
                                                                res.payload &&
                                                                res.payload.status &&
                                                                res.payload.status === 200
                                                            ) {
                                                                this.setState({
                                                                    stateCustomers: res.payload.data,
                                                                    chosenCustomers: res.payload.data.filter(
                                                                        el => el.manager === id
                                                                    )
                                                                });
                                                                this.toggleDialog("customers");
                                                            } else {
                                                            }
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row_item no_select">
                                        <span className={clsx(is_active ? "active" : "blocked")} />
                                        {is_active ? "Активен" : "Заблокирован"}
                                    </div>
                                    {role !== "admin" ? (
                                        <Fragment>
                                            <div className="row_item no_select">
                                                <p
                                                    className={clsx("text_hover", is_active ? "block" : "unblock")}
                                                    onClick={() => {
                                                        this.setState({
                                                            currentUser: id,
                                                            status: is_active
                                                        });
                                                        this.toggleDialog("change");
                                                    }}
                                                >
                                                    {is_active ? "Заблокировать" : "Разблокировать"}
                                                </p>
                                            </div>
                                            <div className="row_item no_select">
                                                <p
                                                    className="text_hover"
                                                    onClick={() => {
                                                        this.setState({ currentUser: id });
                                                        this.toggleDialog("delete");
                                                    }}
                                                >
                                                    <img src={DeleteIcon} alt="del" />
                                                </p>
                                            </div>
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <div className="row_item " />
                                            <div className="row_item " />
                                        </Fragment>
                                    )}
                                </div>
                            ))}
                        </div>
                        <hr />
                    </div>
                    <DialogComponent
                        open={openCustomersDialog}
                        onClose={() => {
                            this.toggleDialog("customers");
                            this.setState({ stateCustomers: [], chosenCustomers: [], searchValue: "" });
                        }}
                    >
                        <div className="dashboard_dialog no_select">
                            <h2>Привязанный заказчик</h2>
                            <p className="customer_search" id="search_popper">
                                <input
                                    type="text"
                                    value={searchValue}
                                    onChange={e => this.setState({ searchValue: e.target.value })}
                                    placeholder="Введите наименование заказчика"
                                />
                            </p>
                            <div
                                className="dialog-customer-wrap-reset"
                                onClick={() =>
                                    getCustomers(chosenId).then(res => {
                                        if (res.payload && res.payload.status && res.payload.status === 200) {
                                            this.setState({
                                                stateCustomers: res.payload.data,
                                                chosenCustomers: res.payload.data.filter(el => el.manager === chosenId),
                                                searchValue: ""
                                            });
                                        } else {
                                        }
                                    })
                                }
                            >
                                Сбросить выбранное
                            </div>
                            <div className="dialog-customer-wrap">
                                {stateCustomers &&
                                    stateCustomers
                                        .filter(el => el.full_name.toLowerCase().includes(searchValue.toLowerCase()))
                                        .map((el, idx) => (
                                            <div className="dialog-customer-wrap-el" key={idx}>
                                                <Checkbox
                                                    checked={
                                                        chosenCustomers.findIndex(i => i.id === el.id) !== -1 &&
                                                        chosenCustomers[chosenCustomers.findIndex(i => i.id === el.id)]
                                                            .manager === chosenId
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={() => {
                                                        const index = chosenCustomers.findIndex(i => i.id === el.id);
                                                        if (index === -1) {
                                                            this.setState(({ chosenCustomers }) => ({
                                                                chosenCustomers: [
                                                                    ...chosenCustomers,
                                                                    { ...el, manager: chosenId }
                                                                ]
                                                            }));
                                                        } else {
                                                            this.setState(({ chosenCustomers }) => ({
                                                                chosenCustomers: [
                                                                    ...chosenCustomers.slice(0, index),
                                                                    {
                                                                        ...el,
                                                                        manager:
                                                                            chosenCustomers &&
                                                                            chosenCustomers[index] &&
                                                                            chosenCustomers[index].manager
                                                                                ? null
                                                                                : chosenId
                                                                    },
                                                                    ...chosenCustomers.slice(index + 1)
                                                                ]
                                                            }));
                                                        }
                                                    }}
                                                    color="primary"
                                                    inputProps={{ "aria-label": "primary checkbox" }}
                                                />
                                                <p className="dialog-customer-wrap-name">{el.full_name}</p>
                                                <img
                                                    src={el.is_chosen ? favouriteImg : notFavouriteImg}
                                                    alt="Избранное"
                                                    onClick={() => {
                                                        const index = chosenCustomers.findIndex(i => i.id === el.id);
                                                        if (index === -1) {
                                                            this.setState(({ chosenCustomers, stateCustomers }) => ({
                                                                chosenCustomers: [
                                                                    ...chosenCustomers,
                                                                    { ...el, is_chosen: !stateCustomers[idx].is_chosen }
                                                                ],
                                                                stateCustomers: [
                                                                    ...stateCustomers.slice(0, idx),
                                                                    {
                                                                        ...stateCustomers[idx],
                                                                        is_chosen: !stateCustomers[idx].is_chosen
                                                                    },
                                                                    ...stateCustomers.slice(idx + 1)
                                                                ]
                                                            }));
                                                        } else {
                                                            this.setState(({ chosenCustomers, stateCustomers }) => ({
                                                                chosenCustomers: [
                                                                    ...chosenCustomers.slice(0, index),
                                                                    {
                                                                        ...el,
                                                                        is_chosen: !stateCustomers[idx].is_chosen
                                                                    },
                                                                    ...chosenCustomers.slice(index + 1)
                                                                ],
                                                                stateCustomers: [
                                                                    ...stateCustomers.slice(0, idx),
                                                                    {
                                                                        ...stateCustomers[idx],
                                                                        is_chosen: !stateCustomers[idx].is_chosen
                                                                    },
                                                                    ...stateCustomers.slice(idx + 1)
                                                                ]
                                                            }));
                                                        }
                                                    }}
                                                />
                                            </div>
                                        ))}
                            </div>

                            <div className="dialog_buttons">
                                <DefaultButton
                                    variant="outlined"
                                    onClick={() => {
                                        this.toggleDialog("customers");
                                        this.setState({ stateCustomers: [], chosenCustomers: [], searchValue: "" });
                                    }}
                                    classes="cancel_btn"
                                >
                                    Отмена
                                </DefaultButton>
                                <DefaultButton
                                    variant="contained"
                                    onClick={() => {
                                        patchCustomers({
                                            customers: chosenCustomers.map(el => ({
                                                id: el.id,
                                                is_chosen: el.is_chosen,
                                                manager: el.manager
                                            }))
                                        }).then(res => {
                                            if (res.payload && res.payload.status && res.payload.status === 200) {
                                                this.doRequest();
                                            } else {
                                            }
                                        });
                                        this.toggleDialog("customers");
                                        this.setState({ stateCustomers: [], chosenCustomers: [] });
                                    }}
                                    classes="confirm_btn"
                                    loading={reloading}
                                >
                                    Подтвердить
                                </DefaultButton>
                            </div>
                        </div>
                    </DialogComponent>
                    <DialogComponent open={openDeleteDialog} onClose={() => this.toggleDialog("delete")}>
                        <div className="dashboard_dialog no_select">
                            <h2>Удалить пользователя</h2>
                            <div className="dialog_description">Вы уверены, что хотите удалить этого пользователя?</div>
                            <div className="dialog_buttons">
                                <DefaultButton
                                    variant="outlined"
                                    onClick={() => this.toggleDialog("delete")}
                                    classes="cancel_btn"
                                >
                                    Отмена
                                </DefaultButton>
                                <DefaultButton
                                    variant="contained"
                                    onClick={this.deleteUser}
                                    classes="confirm_btn"
                                    loading={reloading}
                                >
                                    Удалить
                                </DefaultButton>
                            </div>
                        </div>
                    </DialogComponent>
                    <DialogComponent open={openRoleDialog} onClose={() => this.toggleDialog("role")}>
                        <div className="dashboard_dialog no_select">
                            <h2>Сменить роль пользователя</h2>
                            <div className="dialog_description">
                                Подтвердите, что Вы хотите сменить роль этого пользователя.
                            </div>
                            <div className="dialog_buttons">
                                <DefaultButton
                                    variant="outlined"
                                    onClick={() => this.toggleDialog("role")}
                                    classes="cancel_btn"
                                >
                                    Отмена
                                </DefaultButton>
                                <DefaultButton
                                    variant="contained"
                                    onClick={this.changeRole}
                                    classes="confirm_btn"
                                    loading={reloading}
                                >
                                    Подтвердить
                                </DefaultButton>
                            </div>
                        </div>
                    </DialogComponent>
                    <DialogComponent open={openChangeDialog} onClose={() => this.toggleDialog("change")}>
                        <div className="dashboard_dialog no_select">
                            <h2>{status ? "Заблокировать пользователя" : "Разблокировать пользователя"}</h2>
                            <div className="dialog_description">
                                {status
                                    ? "Вы уверены, что хотите заблокировать этого пользователя?"
                                    : "Подтвердите, что Вы хотите разблокировать этого пользователя."}
                            </div>
                            <div className="dialog_buttons">
                                <DefaultButton
                                    variant="outlined"
                                    onClick={() => this.toggleDialog("change")}
                                    classes="cancel_btn"
                                >
                                    Отмена
                                </DefaultButton>
                                <DefaultButton
                                    variant="contained"
                                    onClick={this.changeStatus}
                                    classes="confirm_btn"
                                    loading={reloading}
                                >
                                    {status ? "Заблокировать" : "Подтвердить"}
                                </DefaultButton>
                            </div>
                        </div>
                    </DialogComponent>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        users: state.dashboard.users,
        customersList: state.dashboard.customers
    };
};
const mapDispatchToProps = {
    getUsers,
    patchUser,
    deleteUser,
    getCustomers,
    patchCustomers
};
export default connect(mapStateToProps, mapDispatchToProps)(Users);
