import * as types from "../actions/constants";

const INITIAL_STATE = {
    numberOfNewNotifications: 0,
    notificationsList: {
        results: []
    }
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.GET_NUMBER_OF_NEW_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                numberOfNewNotifications: action.payload.data.new_count
            };
        case types.PATCH_NUMBER_OF_NEW_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                numberOfNewNotifications: 0
            };
        case types.GET_NOTIFICATIONS_SUCCESS:
            if (!state.notificationsList.results.length) return {...state, notificationsList: action.payload.data };
            if (action.payload.data.results.forEach(el => state.notificationsList.results.some(notif => notif.id === el.id))) {
                return {
                    ...state,
                    notificationsList: { ...action.payload.data, results: [ ...state.notificationsList.results, ...action.payload.data.results ] }
                };
            } else return {...state, notificationsList: action.payload.data };
        case types.RESET_NOTIFICATIONS:
            return {
                ...state,
                notificationsList: {
                    results: []
                }
            };
        default:
            return state;
    }
}
