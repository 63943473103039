import * as types from "./constants.jsx";

export function postLogin(data) {
    return {
        type: types.LOGIN,
        payload: {
            client: "default",
            request: {
                url: `/auth/login/`,
                method: "post",
                data
            }
        }
    };
}

export function register(data) {
    return {
        type: types.REGISTER,
        payload: {
            client: "default",
            request: {
                url: `/auth/register/`,
                method: "post",
                data
            }
        }
    };
}

export function recoverPassword(data) {
    return {
        type: types.RECOVER_PASSWORD,
        payload: {
            client: "default",
            request: {
                url: `/auth/recover-password/`,
                method: "post",
                data
            }
        }
    };
}

export function resetErrors() {
    return {
        type: types.RESET_ERRORS
    };
}
