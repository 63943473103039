import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import { API_BASE_URL } from "../../config"
import { withRouter } from "react-router-dom"
import DialogComponent from "../HelperComponents/DialogComponent/DialogComponent"
import DefaultButton from "../HelperComponents/Buttons/DefaultButton/DefaultButton"
import PopperInfo from "../HelperComponents/PopperInfo/PopperInfo"
import { Field, reduxForm } from "redux-form"
import moment from "moment"
import RenderField from "../HelperComponents/RenderField/RenderField"

import { getManagers, getCountries } from "../../actions/dashboardActions"

import {
    postCheck,
    postDelete,
    postDeleteAdmin,
    backToWorkAction,
    postToDelete,
    postFix,
    postApprove,
    postDecline,
    postPost,
    addPlacedBy,
    postWon,
    postLost,
    postSigned,
    postSignedWithDocs,
    postExecuting,
    postExecuted,
    postClosed,
    getBotDocuments,
    postPostBotInvolved,
    postBotDocument,
    deleteFile,
    getFilesGK
} from "../../actions/dealsActions"

import FormGroup from "@material-ui/core/FormGroup/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox/Checkbox"
import FormControl from "@material-ui/core/FormControl"
import SelectComponent from "../HelperComponents/SelectComponent/SelectComponent"
import DocumentIcon from "../../assets/image/file.svg"
import MultiSelectComponent from "../HelperComponents/MultiSelectComponent/MultiSelectComponent"
import DateTimeField from "../HelperComponents/RenderDatePicker/RenderDatePicker"

class DealsInProgressDialogs extends Component {
    constructor(props) {
        super(props)
        this.state = {
            reloading: false,
            approvalComment: "",
            min_auction_price: "",
            actual_price: "",
            responsible: "",
            chosenManager: {},
            fixComment: "",
            declineComment: "",
            anchor: null,
            open: false,
            contract_signed: true,
            goods_delivered: false,
            documents_signed: false,
            goods_paid: false,
            commercial_proposal: "",
            who_placed_the_deal: undefined,
            secondPlacingDialog: false,
            countries: [],
            chosen_countries: [],
            countries_search: "",
            selectIsOpen: false,
            otherDocuments: [{}],
            is_bot_involved: props.is_bot_involved,
            attention: false,
            contractDocuments: [null]
        }
    }

    componentDidMount() {
        const { getCountries } = this.props
        const tempCountries = []
        getCountries().then(res => {
            if (res.payload) {
                res.payload.data.forEach(({ id, name }) => tempCountries.push({ id, name: "z" + name }))
                this.setState({ countries: tempCountries })
            }
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.is_bot_involved !== this.props.is_bot_involved) {
            this.setState({ is_bot_involved: this.props.is_bot_involved })
        }
    }

    handleInputChange = e => this.setState({ responsible: e.target.value, chosenManager: {} })

    handleKeyUp = async e => {
        const { getManagers } = this.props
        let value = e.target.value
        if (value.length >= 1 && value.trim()) {
            const res = await getManagers(value)
            if (res.payload && res.payload.status === 200 && res.payload.data.length > 0) {
                this.setState({
                    anchor: document.getElementById("search_input"),
                    open: true
                })
            } else {
                this.clickAway()
            }
        } else {
            this.clickAway()
        }
    }

    selectElement = chosenManager => {
        this.setState({ responsible: chosenManager.fio, chosenManager })
        this.clickAway()
    }

    clickAway = () => this.setState({ open: false })

    deleteTender = async () => {
        const { postDelete, doRequest, currentTender, toggleDialog, history } = this.props
        this.setState({ reloading: true })
        let res = await postDelete(currentTender)
        if (res.payload.status === 200) {
            this.setState({ reloading: false })
            doRequest()
            toggleDialog("delete")
            history.push(`/main/deals-in-progress`)
        }
    }
    deleteAdminTender = async () => {
        const { postDeleteAdmin, doRequest, currentTender, toggleDialog, history } = this.props
        this.setState({ reloading: true })
        let res = await postDeleteAdmin({ tenders: [currentTender] })
        if (res.payload.status === 200) {
            this.setState({ reloading: false })
            doRequest()
            toggleDialog("delete_admin")
            history.push(`/main/deals-in-progress`)
        }
    }
    backToWork = async () => {
        const { backToWorkAction, doRequest, currentTender, toggleDialog } = this.props
        this.setState({ reloading: true })
        let res = await backToWorkAction({ tenders: [currentTender] })
        if (res.payload.status === 200) {
            this.setState({ reloading: false })
            doRequest()
            toggleDialog("back_to_work")
            // history.push(`/main/deals-in-progress`)
        }
    }

    sendToDeleteTender = async () => {
        const { postToDelete, doRequest, currentTender, toggleDialog } = this.props
        this.setState({ reloading: true })
        let res = await postToDelete({ tenders: [currentTender] })
        if (res.payload.status === 200) {
            this.setState({ reloading: false })
            doRequest()
            toggleDialog("to_delete")
            // history.push(`/main/deals-in-progress`)
        }
    }

    checkTender = async () => {
        const { postCheck, doRequest, currentTender, toggleDialog, history } = this.props
        const { attention } = this.state
        this.setState({ reloading: true })
        let res = await postCheck(currentTender, { attention })
        if (res.payload.status === 200) {
            this.setState({ reloading: false })
            doRequest()
            toggleDialog("to_check")
            history.push(`/main/deals-in-progress`)
        }
    }

    approveTender = async () => {
        const { postApprove, doRequest, currentTender, toggleDialog } = this.props
        const { approvalComment, min_auction_price, chosenManager } = this.state
        this.setState({ reloading: true })
        const data = {
            min_auction_price: min_auction_price,
            comment: approvalComment,
            responsible: chosenManager.id
        }
        await postApprove(currentTender, data)
        this.setState({ reloading: false, comment: "", chosenManager: {}, min_auction_price: "" })
        doRequest()
        toggleDialog("approve")
    }

    fixTender = async () => {
        const { postFix, doRequest, currentTender, toggleDialog } = this.props
        const { fixComment } = this.state
        this.setState({ reloading: true })
        const data = {
            comment: fixComment
        }
        await postFix(currentTender, data)
        this.setState({ reloading: false, fixComment: "" })
        doRequest()
        toggleDialog("to_fix")
    }

    declineTender = async () => {
        const { postDecline, doRequest, currentTender, toggleDialog } = this.props
        const { declineComment } = this.state
        this.setState({ reloading: true })
        const data = {
            comment: declineComment
        }
        await postDecline(currentTender, data)
        this.setState({ reloading: false, declineComment: "" })
        doRequest()
        toggleDialog("decline")
    }

    postTender = async () => {
        const {
            postPost,
            doRequest,
            addPlacedBy,
            currentTender,
            toggleDialog,
            deal_owners,
            waitingForApprove
        } = this.props
        const { who_placed_the_deal } = this.state
        this.setState({ reloading: true })
        const data = {
            placed_by: deal_owners.filter(el => who_placed_the_deal.includes(el.name))[0].id
        }
        waitingForApprove ? await addPlacedBy(currentTender, data) : await postPost(currentTender, data)
        this.setState({ reloading: false })
        doRequest()
        toggleDialog("post")
    }

    tenderWon = async () => {
        const { postWon, doRequest, currentTender, toggleDialog } = this.props
        this.setState({ reloading: true })
        await postWon(currentTender)
        this.setState({ reloading: false })
        doRequest()
        toggleDialog("won")
    }

    tenderLost = async () => {
        const { postLost, doRequest, currentTender, toggleDialog } = this.props
        this.setState({ reloading: true })
        await postLost(currentTender)
        this.setState({ reloading: false })
        doRequest()
        toggleDialog("lost")
    }

    signTender = async data => {
        const {
            postSignedWithDocs,
            doRequest,
            currentTender,
            toggleDialog,
            match: {
                params: { id }
            }
        } = this.props
        const { contractDocuments } = this.state
        const formData = new FormData()
        formData.append("contract_conclusion_date", moment(data.deadline).format())
        formData.append("contract_number", data.numberContract)
        formData.append("actual_price", data.actual_price)
        for (let i = 0; i < contractDocuments.length; i++) {
            contractDocuments[i] && formData.append("documents", contractDocuments[i])
        }
        this.setState({ reloading: true })
        await postSignedWithDocs(currentTender, formData)
        this.setState({ reloading: false })
        doRequest()
        getFilesGK(id)
        toggleDialog("sign")
    }

    tenderExecuting = async () => {
        const { postExecuting, doRequest, currentTender, toggleDialog } = this.props
        this.setState({ reloading: true })
        await postExecuting(currentTender)
        this.setState({ reloading: false })
        doRequest()
        toggleDialog("to_execute")
    }

    tenderExecuted = async () => {
        const { postExecuted, doRequest, currentTender, toggleDialog } = this.props
        const { goods_delivered, documents_signed, goods_paid } = this.state
        this.setState({ reloading: true })
        const data = {
            contract_signed: true,
            goods_delivered: goods_delivered,
            documents_signed: documents_signed,
            goods_paid: goods_paid
        }
        await postExecuted(currentTender, data)
        this.setState({ reloading: false })
        doRequest()
        toggleDialog("executed")
    }

    closeTender = async () => {
        const { postClosed, doRequest, currentTender, toggleDialog } = this.props
        this.setState({ reloading: true })
        await postClosed(currentTender)
        this.setState({ reloading: false })
        doRequest()
        toggleDialog("closed")
    }

    sendCommercialProposal = async () => {
        const { doRequest, currentTender, toggleDialog, deal_owners } = this.props
        const { commercial_proposal } = this.state
        this.setState({ reloading: true })
        let owner_id = deal_owners.filter(el => commercial_proposal.includes(el.name))[0].id
        let token = localStorage.getItem("token")
        window.open(
            `${API_BASE_URL}/admin/tenders/manual/${currentTender}/report/?report_owner=${owner_id}&token=${token}`,
            "_blank"
        )
        this.setState({ reloading: false })
        doRequest()
        toggleDialog("commercial_proposal")
    }

    continuePlacing = step => {
        const { toggleDialog, getBotDocuments, currentTender } = this.props
        const { otherDocuments } = this.state
        if (step === "first") {
            toggleDialog("post")
            toggleDialog("postFirst")
        } else {
            getBotDocuments(currentTender).then(res => {
                if (res && res.payload) {
                    this.setState({ otherDocuments: [otherDocuments, ...res.payload.data] })
                }
                toggleDialog("postFirst")
                this.setState({ secondPlacingDialog: true })
            })
        }
    }

    handleSelectCountries = e => {
        let chosen_countries = e.target.value.filter(el => el !== undefined)
        this.setState({ chosen_countries })
    }

    resetCountries = () => {
        this.setState({ chosen_countries: [], countries_search: "" })
    }

    uploadFirstFile = e => {
        e.persist()
        let file = e.target.files[0]
        this.setState({ productDocumentOne: file })
        document.getElementById("fileInputOne").value = ""
    }

    uploadSecondFile = e => {
        e.persist()
        let file = e.target.files[0]
        this.setState({ productDocumentTwo: file })
        document.getElementById("fileInputTwo").value = ""
    }

    uploadDocument = e => {
        e.persist()
        const { postBotDocument, currentTender } = this.props
        const { otherDocuments } = this.state
        let file = e.target.files[0]
        const formData = new FormData()
        formData.append("document", file)
        postBotDocument(currentTender, formData).then(res => {
            if (res && res.payload) {
                this.setState({ otherDocuments: [...otherDocuments, res.payload.data] })
            }
            document.getElementById("fileInput").value = ""
        })
    }

    postBotInvolvedTender = async () => {
        const { postPostBotInvolved, doRequest, currentTender, deal_owners, role } = this.props
        const { who_placed_the_deal, countries, chosen_countries, productDocumentOne, productDocumentTwo } = this.state
        this.setState({ reloading: true })
        const formData = new FormData()
        let countriesToPost = []
        countries.forEach(el => {
            const countryToPost = chosen_countries.some(item => item === el.name)
            countryToPost && countriesToPost.push(el.id)
        })
        role === "admin" &&
            formData.append("placed_by", deal_owners.filter(el => who_placed_the_deal.includes(el.name))[0].id)
        formData.append("countries", JSON.stringify(countriesToPost))
        productDocumentOne && formData.append("bot_document_one", productDocumentOne ? productDocumentOne : null)
        productDocumentTwo && formData.append("bot_document_two", productDocumentTwo ? productDocumentTwo : null)
        await postPostBotInvolved(currentTender, formData)
        this.setState({ reloading: false, secondPlacingDialog: false })
        doRequest()
    }

    render() {
        const {
            toggleDialog,
            openDeleteDialog,
            openToDeleteDialog,
            openDeleteAdmin,
            openBackDialog,
            openToCheckDialog,
            openApproveDialog,
            openToFixDialog,
            openDeclineDialog,
            openPostDialog,
            openWonDialog,
            openLostDialog,
            openSignedDialog,
            openExecuteDialog,
            openExecutedDialog,
            openClosedDialog,
            openCommercialProposal,
            managers,
            deal_owners,
            deleteFile,
            currentTender,
            getBotDocuments,
            firstPlacingDialog,
            waitingForApprove,
            handleSubmit,
            real_deal_owners
        } = this.props
        const {
            reloading,
            approvalComment,
            min_auction_price,
            responsible,
            fixComment,
            declineComment,
            anchor,
            open,
            chosenManager,
            contract_signed,
            goods_delivered,
            documents_signed,
            commercial_proposal,
            goods_paid,
            who_placed_the_deal,
            secondPlacingDialog,
            countries,
            chosen_countries,
            countries_search,
            productDocumentOne,
            productDocumentTwo,
            selectIsOpen,
            otherDocuments,
            is_bot_involved,
            attention,
            contractDocuments
        } = this.state

        let owners = [],
            realOwners = [],
            filteredCountries = []
        deal_owners.forEach(el => owners.push("z" + el.name))
        real_deal_owners.forEach(el => realOwners.push("z" + el.name))
        filteredCountries = countries.filter(el => el.name.toLowerCase().includes(countries_search.toLowerCase()))
        return (
            <Fragment>
                <DialogComponent open={!!openDeleteDialog} onClose={() => toggleDialog("delete")}>
                    <div className="dashboard_dialog no_select">
                        <h2>Удалить сделку</h2>
                        <div className="dialog_description">Вы уверены, что хотите удалить эту сделку?</div>
                        <div className="dialog_buttons">
                            <DefaultButton
                                variant="outlined"
                                onClick={() => toggleDialog("delete")}
                                classes="cancel_btn"
                            >
                                Отмена
                            </DefaultButton>
                            <DefaultButton
                                variant="contained"
                                onClick={this.deleteTender}
                                classes="confirm_btn"
                                loading={reloading}
                            >
                                Удалить
                            </DefaultButton>
                        </div>
                    </div>
                </DialogComponent>
                <DialogComponent open={!!openToDeleteDialog} onClose={() => toggleDialog("to_delete")}>
                    <div className="dashboard_dialog no_select">
                        <h2>Подать на удаление</h2>
                        <div className="dialog_description">Вы уверены, что хотите подать эту заявку на удаление?</div>
                        <div className="dialog_buttons">
                            <DefaultButton
                                variant="outlined"
                                onClick={() => toggleDialog("to_delete")}
                                classes="cancel_btn"
                            >
                                Отмена
                            </DefaultButton>
                            <DefaultButton
                                variant="contained"
                                onClick={this.sendToDeleteTender}
                                classes="confirm_btn"
                                loading={reloading}
                            >
                                Подтвердить
                            </DefaultButton>
                        </div>
                    </div>
                </DialogComponent>
                <DialogComponent open={!!openDeleteAdmin} onClose={() => toggleDialog("delete_admin")}>
                    <div className="dashboard_dialog no_select">
                        <h2>Удалить сделку</h2>
                        <div className="dialog_description">Вы уверены, что хотите удалить эту заявку?</div>
                        <div className="dialog_buttons">
                            <DefaultButton
                                variant="outlined"
                                onClick={() => toggleDialog("delete_admin")}
                                classes="cancel_btn"
                            >
                                Отмена
                            </DefaultButton>
                            <DefaultButton
                                variant="contained"
                                onClick={this.deleteAdminTender}
                                classes="confirm_btn"
                                loading={reloading}
                            >
                                Удалить
                            </DefaultButton>
                        </div>
                    </div>
                </DialogComponent>
                <DialogComponent open={!!openBackDialog} onClose={() => toggleDialog("back_to_work")}>
                    <div className="dashboard_dialog no_select">
                        <h2>Вернуть в работу</h2>
                        <div className="dialog_description">
                            Подтвердите, что Вы хотите вернуть менеджеру эту сделку в работу.
                        </div>
                        <div className="dialog_buttons">
                            <DefaultButton
                                variant="outlined"
                                onClick={() => toggleDialog("back_to_work")}
                                classes="cancel_btn"
                            >
                                Отмена
                            </DefaultButton>
                            <DefaultButton
                                variant="contained"
                                onClick={this.backToWork}
                                classes="confirm_btn"
                                loading={reloading}
                            >
                                Подтвердить
                            </DefaultButton>
                        </div>
                    </div>
                </DialogComponent>

                <DialogComponent open={!!openToCheckDialog} onClose={() => toggleDialog("to_check")}>
                    <div className="dashboard_dialog no_select">
                        <h2>Отправить на проверку</h2>
                        <div className="dialog_description">
                            Подтвердите, что Вы хотите отправить эту сделку на проверку.
                        </div>
                        <FormControl component="fieldset" className="dialog_important_fieldset">
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={attention}
                                            onChange={() =>
                                                this.setState(({ attention }) => ({ attention: !attention }))
                                            }
                                            color="primary"
                                            inputProps={{ "aria-label": "secondary checkbox" }}
                                        />
                                    }
                                    label="Пометить заявку меткой «Внимание!»"
                                />
                            </FormGroup>
                        </FormControl>
                        <div className="dialog_buttons">
                            <DefaultButton
                                variant="outlined"
                                onClick={() => toggleDialog("to_check")}
                                classes="cancel_btn"
                            >
                                Отмена
                            </DefaultButton>
                            <DefaultButton
                                variant="contained"
                                onClick={this.checkTender}
                                classes="confirm_btn"
                                loading={reloading}
                            >
                                Отправить
                            </DefaultButton>
                        </div>
                    </div>
                </DialogComponent>
                <DialogComponent open={!!openApproveDialog} onClose={() => toggleDialog("approve")}>
                    <div className="dashboard_dialog no_select">
                        <h2>Одобрить сделку</h2>
                        <textarea
                            value={approvalComment}
                            onChange={e => this.setState({ approvalComment: e.target.value })}
                            placeholder="Комментарий"
                        />
                        <div className="inputs_wrapper">
                            <input
                                type="number"
                                className="price_input"
                                placeholder="Мин. сумма по торгам"
                                onChange={e => this.setState({ min_auction_price: e.target.value })}
                                value={min_auction_price}
                            />
                            <div className="search_input_wrapper">
                                <input
                                    id="search_input"
                                    type="text"
                                    className="manager_input"
                                    placeholder="Введите имя ответственного"
                                    onKeyUp={this.handleKeyUp}
                                    onChange={this.handleInputChange}
                                    value={responsible}
                                    autoComplete="off"
                                />
                                <PopperInfo
                                    open={open}
                                    anchorEl={anchor}
                                    clickAway={this.clickAway}
                                    position="bottom-start"
                                    classes="manager_popper_60"
                                    transition
                                >
                                    <ul>
                                        {managers.map(el => (
                                            <li
                                                className="text_hover"
                                                onClick={() => this.selectElement(el)}
                                                key={el.id}
                                            >
                                                {el.fio}
                                            </li>
                                        ))}
                                    </ul>
                                </PopperInfo>
                            </div>
                        </div>
                        <div className="dialog_buttons">
                            <DefaultButton
                                variant="outlined"
                                onClick={() => toggleDialog("approve")}
                                classes="cancel_btn"
                            >
                                Отмена
                            </DefaultButton>
                            <DefaultButton
                                variant="contained"
                                onClick={this.approveTender}
                                classes="confirm_btn"
                                loading={reloading}
                                disabled={!approvalComment || !min_auction_price || !chosenManager.fio}
                            >
                                Одобрить
                            </DefaultButton>
                        </div>
                    </div>
                </DialogComponent>
                <DialogComponent open={!!openToFixDialog} onClose={() => toggleDialog("to_fix")}>
                    <div className="dashboard_dialog no_select">
                        <h2>Отправить на доработку</h2>
                        <textarea
                            value={fixComment}
                            onChange={e => this.setState({ fixComment: e.target.value })}
                            placeholder="Комментарий"
                        />
                        <div className="dialog_buttons">
                            <DefaultButton
                                variant="outlined"
                                onClick={() => toggleDialog("to_fix")}
                                classes="cancel_btn"
                            >
                                Отмена
                            </DefaultButton>
                            <DefaultButton
                                variant="contained"
                                onClick={this.fixTender}
                                classes="confirm_btn"
                                loading={reloading}
                                disabled={!fixComment}
                            >
                                Отправить
                            </DefaultButton>
                        </div>
                    </div>
                </DialogComponent>
                <DialogComponent open={!!openDeclineDialog} onClose={() => toggleDialog("decline")}>
                    <div className="dashboard_dialog no_select">
                        <h2>Отказаться от сделки</h2>
                        <textarea
                            value={declineComment}
                            onChange={e => this.setState({ declineComment: e.target.value })}
                            placeholder="Комментарий"
                        />
                        <div className="dialog_buttons">
                            <DefaultButton
                                variant="outlined"
                                onClick={() => toggleDialog("decline")}
                                classes="cancel_btn"
                            >
                                Отмена
                            </DefaultButton>
                            <DefaultButton
                                variant="contained"
                                onClick={this.declineTender}
                                classes="confirm_btn"
                                loading={reloading}
                                disabled={!declineComment}
                            >
                                Отказаться
                            </DefaultButton>
                        </div>
                    </div>
                </DialogComponent>
                <DialogComponent open={!!openPostDialog} onClose={() => toggleDialog("post")}>
                    <div className="dashboard_dialog no_select with_owner_selector">
                        <h2>Разместить сделку</h2>
                        <div className="dialog_description">
                            Выберите от кого нужно разместить сделку и подтвердите действие.
                        </div>
                        <SelectComponent
                            item={who_placed_the_deal}
                            items={realOwners}
                            handleChange={e => this.setState({ who_placed_the_deal: e.target.value })}
                            placeholder={"От кого"}
                        />
                        <div className="dialog_buttons">
                            <DefaultButton variant="outlined" onClick={() => toggleDialog("post")} classes="cancel_btn">
                                Отмена
                            </DefaultButton>
                            {waitingForApprove ? (
                                <DefaultButton
                                    variant="contained"
                                    onClick={this.postTender}
                                    classes="confirm_btn"
                                    loading={reloading}
                                    disabled={!who_placed_the_deal}
                                >
                                    Подтвердить
                                </DefaultButton>
                            ) : !is_bot_involved ? (
                                <DefaultButton
                                    variant="contained"
                                    onClick={this.postTender}
                                    classes="confirm_btn"
                                    loading={reloading}
                                    disabled={!who_placed_the_deal && this.state.role !== 'admin'}
                                >
                                    Разместить
                                </DefaultButton>
                            ) : (
                                <DefaultButton
                                    variant="contained"
                                    onClick={() => this.continuePlacing("first")}
                                    classes="confirm_btn"
                                    disabled={!who_placed_the_deal}
                                >
                                    Продолжить
                                </DefaultButton>
                            )}
                        </div>
                    </div>
                </DialogComponent>
                <DialogComponent open={!!firstPlacingDialog} onClose={() => toggleDialog("postFirst")}>
                    <div className="dashboard_dialog no_select with_owner_selector long_selector">
                        <h2>Первая часть заявки</h2>
                        <div className="dialog_description">Выберите страну происхождения товара</div>
                        <MultiSelectComponent
                            onOpen={() => this.setState({ selectIsOpen: true })}
                            onClose={() => this.setState({ selectIsOpen: false })}
                            open={selectIsOpen}
                            item={chosen_countries}
                            items={filteredCountries}
                            handleChange={this.handleSelectCountries}
                            placeholder={"Страна"}
                            handleSearch={e => this.setState({ countries_search: e.target.value })}
                            searchPlaceholder={"Введите название страны"}
                            searchValue={countries_search}
                            resetChosenUsers={this.resetCountries}
                            thereIsObjects
                        />
                        <div className="dialog_description">Конкретные показатели товара</div>
                        <div className="document_row">
                            <img src={DocumentIcon} alt="document" />
                            {productDocumentOne ? <p>{productDocumentOne["name"]}</p> : <p>Документ не выбран</p>}
                            <input
                                id="fileInputOne"
                                className="fileInput"
                                type="file"
                                onChange={this.uploadFirstFile}
                            />
                            <div
                                className="usable_text text_hover"
                                onClick={() => document.getElementById("fileInputOne").click()}
                            >
                                ВЫБРАТЬ ФАЙЛ
                            </div>
                            {productDocumentOne && (
                                <div
                                    className="delete_btn text_hover"
                                    onClick={() => {
                                        this.setState({ productDocumentOne: null })
                                        document.getElementById("fileInputOne").value = ""
                                    }}
                                >
                                    УДАЛИТЬ
                                </div>
                            )}
                        </div>
                        <div className="dialog_buttons">
                            <DefaultButton
                                variant="outlined"
                                onClick={() => toggleDialog("postFirst")}
                                classes="cancel_btn"
                            >
                                Отмена
                            </DefaultButton>
                            <DefaultButton
                                variant="contained"
                                onClick={() => this.continuePlacing("second")}
                                classes="confirm_btn"
                                disabled={!chosen_countries.length}
                            >
                                Продолжить
                            </DefaultButton>
                        </div>
                    </div>
                </DialogComponent>
                <DialogComponent
                    open={!!secondPlacingDialog}
                    onClose={() => this.setState({ secondPlacingDialog: false })}
                >
                    <div className="dashboard_dialog no_select with_owner_selector">
                        <h2>Вторая часть заявки</h2>
                        <div className="dialog_description">
                            Документ о показателях<span style={{ color: "#E44B4B" }}>*</span>
                        </div>
                        <div className="document_row">
                            <img src={DocumentIcon} alt="document" />
                            {productDocumentTwo ? <p>{productDocumentTwo["name"]}</p> : <p>Документ не выбран</p>}
                            <input
                                id="fileInputTwo"
                                className="fileInput"
                                type="file"
                                onChange={this.uploadSecondFile}
                            />
                            <div
                                className="usable_text text_hover"
                                onClick={() => document.getElementById("fileInputTwo").click()}
                            >
                                ВЫБРАТЬ ФАЙЛ
                            </div>
                            {productDocumentTwo && (
                                <div
                                    className="delete_btn text_hover"
                                    onClick={() => {
                                        this.setState({ productDocumentTwo: null })
                                        document.getElementById("fileInputTwo").value = ""
                                    }}
                                >
                                    УДАЛИТЬ
                                </div>
                            )}
                        </div>
                        <hr className="documents_separator" />
                        <div className="dialog_description">Иные документы</div>
                        <input id="fileInput" className="fileInput" type="file" onChange={this.uploadDocument} />
                        {otherDocuments.map(({ id, name }, key) => (
                            <div className="document_row" key={id || `no_document_${key}`}>
                                <img src={DocumentIcon} alt="document" />
                                {name ? <p>{name}</p> : <p>Документ не выбран</p>}
                                {!id && (
                                    <div
                                        className="usable_text text_hover"
                                        onClick={() => document.getElementById("fileInput").click()}
                                    >
                                        ВЫБРАТЬ ФАЙЛ
                                    </div>
                                )}
                                {id && (
                                    <div
                                        className="delete_btn text_hover"
                                        onClick={() => {
                                            deleteFile(currentTender, id).then(res => {
                                                if (res.payload && res.payload.status && res.payload.status === 204) {
                                                    getBotDocuments(currentTender).then(res => {
                                                        if (res && res.payload) {
                                                            this.setState({
                                                                otherDocuments: [otherDocuments, ...res.payload.data]
                                                            })
                                                        }
                                                    })
                                                } else {
                                                }
                                            })
                                        }}
                                    >
                                        УДАЛИТЬ
                                    </div>
                                )}
                            </div>
                        ))}
                        {/*<p*/}
                        {/*    className="text_hover usable_text add_additional_item"*/}
                        {/*    onClick={() => this.setState({ otherDocuments: [...otherDocuments, {}] })}*/}
                        {/*>*/}
                        {/*    + Добавить ещё*/}
                        {/*</p>*/}
                        <div className="dialog_buttons">
                            <DefaultButton
                                variant="outlined"
                                onClick={() => this.setState({ secondPlacingDialog: false })}
                                classes="cancel_btn"
                            >
                                Отмена
                            </DefaultButton>
                            <DefaultButton
                                variant="contained"
                                onClick={this.postBotInvolvedTender}
                                classes="confirm_btn"
                                disabled={!productDocumentTwo}
                            >
                                Разместить
                            </DefaultButton>
                        </div>
                    </div>
                </DialogComponent>
                <DialogComponent open={!!openWonDialog} onClose={() => toggleDialog("won")}>
                    <div className="dashboard_dialog no_select">
                        <h2>Сделка выиграна</h2>
                        <div className="dialog_description">Подтвердите, что сделка выиграна.</div>
                        <div className="dialog_buttons">
                            <DefaultButton variant="outlined" onClick={() => toggleDialog("won")} classes="cancel_btn">
                                Отмена
                            </DefaultButton>
                            <DefaultButton
                                variant="contained"
                                onClick={this.tenderWon}
                                classes="confirm_btn"
                                loading={reloading}
                            >
                                Подтвердить
                            </DefaultButton>
                        </div>
                    </div>
                </DialogComponent>
                <DialogComponent open={!!openLostDialog} onClose={() => toggleDialog("lost")}>
                    <div className="dashboard_dialog no_select">
                        <h2>Сделка проиграна</h2>
                        <div className="dialog_description">Подтвердите, что сделка проиграна.</div>
                        <div className="dialog_buttons">
                            <DefaultButton variant="outlined" onClick={() => toggleDialog("lost")} classes="cancel_btn">
                                Отмена
                            </DefaultButton>
                            <DefaultButton
                                variant="contained"
                                onClick={this.tenderLost}
                                classes="confirm_btn"
                                loading={reloading}
                            >
                                Подтвердить
                            </DefaultButton>
                        </div>
                    </div>
                </DialogComponent>
                <DialogComponent open={!!openSignedDialog} onClose={() => toggleDialog("sign")}>
                    <form className="dashboard_dialog no_select" onSubmit={handleSubmit(this.signTender)}>
                        <h2>Госконтракт заключён</h2>
                        <div className="dialog_description" style={{ marginBottom: "32px" }}>
                            Подтвердите, что госконтракт заключён для этой сделки.
                        </div>
                        <div className="dialog_description" style={{ marginBottom: "20px" }}>
                            Выберите дату и время заключения договора
                        </div>
                        <Field
                            name="deadline"
                            component={DateTimeField}
                            label="Дата"
                            className=" date-gk"
                            disablePast={false}
                            invalidDateMessage="Недопустимые дата и время"
                            minDateMessage="Недопустимые дата и время"
                            maxDateMessage="Недопустимые дата и время"
                            invalidLabel="Недопустимые дата и время"
                            onlyDate={true}
                        />
                        <div className="dialog_description" style={{ marginBottom: "20px" }}>
                            Введите номер контракта
                        </div>
                        <Field
                            type="text"
                            component={RenderField}
                            label="Номер конракта"
                            className="date-gk"
                            name="numberContract"
                        />
                        <div className="dialog_description" style={{ marginBottom: "20px" }}>
                            Фактическая цена
                        </div>
                        <Field
                            type="text"
                            component={RenderField}
                            label="Фактическая цена"
                            className="date-gk"
                            name="actual_price"
                        />
                        <div className="dialog_description" style={{ marginBottom: "23px" }}>
                            Контракт
                        </div>

                        {contractDocuments &&
                            contractDocuments.map((el, idx) => {
                                return (
                                    <div className="document_row" key={idx} style={{ marginBottom: "12px" }}>
                                        <img src={DocumentIcon} alt="document" />
                                        <p>{(el && el.name) || "Документ не выбран"}</p>
                                        <input
                                            id={`fileInput${idx}`}
                                            className="fileInput"
                                            type="file"
                                            multiple
                                            onChange={e => {
                                                const files = e.target.files
                                                this.setState(({ contractDocuments }) => ({
                                                    contractDocuments: [
                                                        ...contractDocuments,
                                                        ...files
                                                    ]
                                                }))
                                            }}
                                        />
                                        <div
                                            className="usable_text text_hover"
                                            onClick={() => document.getElementById(`fileInput${idx}`).click()}
                                        >
                                            ВЫБРАТЬ ФАЙЛ
                                        </div>
                                        {el && (
                                            <div
                                                className="delete_btn text_hover"
                                                onClick={() =>
                                                    this.setState(({ contractDocuments }) => ({
                                                        contractDocuments: [
                                                            ...contractDocuments.slice(0, idx),
                                                            ...contractDocuments.slice(idx + 1)
                                                        ]
                                                    }))
                                                }
                                            >
                                                УДАЛИТЬ
                                            </div>
                                        )}
                                    </div>
                                )
                            })}

                        <p
                            className="admin-documents-more"
                            onClick={() =>
                                this.setState(({ contractDocuments }) => ({
                                    contractDocuments: [...contractDocuments, null]
                                }))
                            }
                        >
                            + Добавить ещё
                        </p>
                        <div className="dialog_buttons">
                            <DefaultButton variant="outlined" onClick={() => toggleDialog("sign")} classes="cancel_btn">
                                Отмена
                            </DefaultButton>
                            <DefaultButton
                                variant="contained"
                                // onClick={this.signTender}
                                classes="confirm_btn"
                                loading={reloading}
                                formAction
                            >
                                Подтвердить
                            </DefaultButton>
                        </div>
                    </form>
                </DialogComponent>
                <DialogComponent open={!!openExecuteDialog} onClose={() => toggleDialog("to_execute")}>
                    <div className="dashboard_dialog no_select">
                        <h2>Исполнить сделку сразу</h2>
                        <div className="dialog_description">Подтвердите, что Вы хотите исполнить эту сделку сразу.</div>
                        <div className="dialog_buttons">
                            <DefaultButton
                                variant="outlined"
                                onClick={() => toggleDialog("to_execute")}
                                classes="cancel_btn"
                            >
                                Отмена
                            </DefaultButton>
                            <DefaultButton
                                variant="contained"
                                onClick={this.tenderExecuting}
                                classes="confirm_btn"
                                loading={reloading}
                            >
                                Подтвердить
                            </DefaultButton>
                        </div>
                    </div>
                </DialogComponent>
                <DialogComponent open={!!openExecutedDialog} onClose={() => toggleDialog("executed")}>
                    <div className="dashboard_dialog not_in_row no_select">
                        <h2>Закончить исполнение сделки</h2>
                        <br />
                        <FormControl component="fieldset">
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={contract_signed}
                                            disabled
                                            color="primary"
                                            inputProps={{ "aria-label": "secondary checkbox" }}
                                        />
                                    }
                                    label="Госконтракт заключён"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={goods_delivered}
                                            onChange={() => this.setState({ goods_delivered: !goods_delivered })}
                                            color="primary"
                                            inputProps={{ "aria-label": "secondary checkbox" }}
                                        />
                                    }
                                    label="Товары поставлены"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={documents_signed}
                                            onChange={() => this.setState({ documents_signed: !documents_signed })}
                                            color="primary"
                                            inputProps={{ "aria-label": "secondary checkbox" }}
                                        />
                                    }
                                    label="Закрывающие документы подписаны и загружены"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={goods_paid}
                                            onChange={() => this.setState({ goods_paid: !goods_paid })}
                                            color="primary"
                                            inputProps={{ "aria-label": "secondary checkbox" }}
                                        />
                                    }
                                    label="Товары оплачены"
                                />
                            </FormGroup>
                        </FormControl>
                        <div className="dialog_buttons">
                            <DefaultButton
                                variant="outlined"
                                onClick={() => toggleDialog("executed")}
                                classes="cancel_btn"
                            >
                                Отмена
                            </DefaultButton>
                            <DefaultButton
                                variant="contained"
                                onClick={this.tenderExecuted}
                                classes="confirm_btn"
                                loading={reloading}
                            >
                                Закончить
                            </DefaultButton>
                        </div>
                    </div>
                </DialogComponent>
                <DialogComponent open={!!openClosedDialog} onClose={() => toggleDialog("closed")}>
                    <div className="dashboard_dialog not_in_row no_select">
                        <h2>Закрыть контракт</h2>
                        <div className="dialog_description">
                            Подтвердите, что Вы хотите закрыть контракт для этой сделки.
                        </div>
                        <div className="dialog_buttons">
                            <DefaultButton
                                variant="outlined"
                                onClick={() => toggleDialog("closed")}
                                classes="cancel_btn"
                            >
                                Отмена
                            </DefaultButton>
                            <DefaultButton
                                variant="contained"
                                onClick={this.closeTender}
                                classes="confirm_btn"
                                loading={reloading}
                            >
                                Подтвердить
                            </DefaultButton>
                        </div>
                    </div>
                </DialogComponent>
                <DialogComponent open={!!openCommercialProposal} onClose={() => toggleDialog("commercial_proposal")}>
                    <div className="dashboard_dialog no_select with_owner_selector">
                        <h2>Сформировать КП</h2>
                        <div className="dialog_description">Выберите имя, от которого нужно сформировать КП</div>
                        <SelectComponent
                            item={commercial_proposal}
                            items={owners}
                            handleChange={e => this.setState({ commercial_proposal: e.target.value })}
                            placeholder={"Имя"}
                        />
                        <div className="dialog_buttons">
                            <DefaultButton
                                variant="outlined"
                                onClick={() => toggleDialog("commercial_proposal")}
                                classes="cancel_btn"
                            >
                                Отмена
                            </DefaultButton>
                            <DefaultButton
                                variant="contained"
                                classes="confirm_btn"
                                loading={reloading}
                                disabled={!commercial_proposal}
                                onClick={this.sendCommercialProposal}
                            >
                                Сформировать
                            </DefaultButton>
                        </div>
                    </div>
                </DialogComponent>
            </Fragment>
        )
    }
}

const validate = values => {
    const errors = {}
    if (!values.deadline) {
        errors.deadline = "Нужно выбрать дату и время заключения договора."
    }
    return errors
}

DealsInProgressDialogs = reduxForm({
    form: "DealsInProgressDialogsForm",
    validate
})(DealsInProgressDialogs)

const mapStateToProps = state => {
    return {
        managers: state.dashboard.managers,
        deal_owners: state.deals.deal_owners,
        role: state.dashboard.options.me.role,
        real_deal_owners: state.deals.real_deal_owners
    }
}

const mapDispatchToProps = {
    postCheck,
    postDelete,
    postDeleteAdmin,
    backToWorkAction,
    postToDelete,
    postFix,
    postApprove,
    postDecline,
    postPost,
    addPlacedBy,
    postWon,
    postLost,
    getManagers,
    postSigned,
    postSignedWithDocs,
    postExecuting,
    postExecuted,
    postClosed,
    getCountries,
    getBotDocuments,
    postPostBotInvolved,
    postBotDocument,
    deleteFile,
    getFilesGK
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DealsInProgressDialogs))
