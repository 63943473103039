import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import LinearProgress from "@material-ui/core/LinearProgress"
import moment from "moment"
import clsx from "clsx"
import ClickAwayListener from "@material-ui/core/ClickAwayListener/ClickAwayListener"

import DefaultButton from "../../HelperComponents/Buttons/DefaultButton/DefaultButton"
import DialogComponent from "../../HelperComponents/DialogComponent/DialogComponent"
import Snack from "../../HelperComponents/Snack/Snack"

import { formatNumber } from "../../../helpers/functions"

import { getDeal, patchTender, calculateDeal, reDownloadTender } from "../../../actions/dashboardActions"

import BackIcon from "../../../assets/image/back.svg"
import ArrowDownIcon from "../../../assets/image/arrow_down.svg"
import CheckIcon from "../../../assets/image/check.svg"
import DocumentIcon from "../../../assets/image/file.svg"
import FailedIcon from "../../../assets/image/failed.svg"

import "./Deal.scss"

class Deal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            reloading: true,
            selectedElements: [],
            openDeleteDialog: false,
            openReloadDialog: false,
            openToWorkDialog: false,
            openErrorDialog: false,
            openErrorMaxTendersDialog: false,
            openChangeTypeDialog: false,
            openErrorSnack: false,
            active_tab: 0,
            openTypeControl: false,
            chosenType: ""
        }
    }

    async componentDidMount() {
        this.doRequest()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.doRequest()
        }
    }

    doRequest = async () => {
        const {
            getDeal,
            match: {
                params: { id }
            }
        } = this.props
        this.setState({ reloading: true })
        const res = await getDeal(id)
        if (res.payload.status === 200) {
            this.setState({ loading: false, reloading: false })
        }
    }

    toggleDialog = type => {
        type === "delete" &&
            this.setState(({ openDeleteDialog }) => ({
                openDeleteDialog: !openDeleteDialog
            }))
        type === "reload" &&
            this.setState(({ openReloadDialog }) => ({
                openReloadDialog: !openReloadDialog
            }))
        type === "toWork" &&
            this.setState(({ openToWorkDialog }) => ({
                openToWorkDialog: !openToWorkDialog
            }))
        type === "error" &&
            this.setState(({ openErrorDialog }) => ({
                openErrorDialog: !openErrorDialog
            }))
        type === "errorMaxTenders" &&
            this.setState(({ openErrorMaxTendersDialog }) => ({
                openErrorMaxTendersDialog: !openErrorMaxTendersDialog
            }))
        type === "change_type" &&
            this.setState(({ openChangeTypeDialog }) => ({
                openChangeTypeDialog: !openChangeTypeDialog
            }))
    }

    deleteTender = async () => {
        const {
            patchTender,
            history,
            match: {
                params: { id }
            }
        } = this.props
        this.setState({ reloading: true })
        const data = { status: "deleted" }
        await patchTender(id, data)
        this.setState({ reloading: false })
        this.toggleDialog("delete")
        history.push("/main/deals")
    }

    reloadTender = async () => {
        const {
            patchTender,
            history,
            match: {
                params: { id }
            }
        } = this.props
        this.setState({ reloading: true })
        const data = { status: "new", manager: null }
        await patchTender(id, data)
        this.setState({ reloading: false })
        this.toggleDialog("reload")
        history.push("/main/deals")
    }

    takeToWork = async () => {
        const {
            calculateDeal,
            history,
            match: {
                params: { id }
            }
        } = this.props
        this.setState({ reloading: true })
        const res = await calculateDeal(id)
        this.setState({ reloading: false })
        if (res.payload && res.payload.status) {
            this.toggleDialog("toWork")
            history.push("/main/deals")
        } else {
            this.toggleDialog("toWork")
            if (res.error.response.data.code === "MANAGER_MAX_TENDERS") {
                this.toggleDialog("errorMaxTenders")
            } else {
                this.toggleDialog("error")
            }
        }
    }

    handleTypeControl = () => this.setState(({ openTypeControl }) => ({ openTypeControl: !openTypeControl }))

    changeTab = active_tab => {
        this.setState({ active_tab })
    }

    changeType = async () => {
        const {
            patchTender,
            match: {
                params: { id }
            }
        } = this.props
        const { chosenType } = this.state
        let data = {
            type: chosenType
        }
        this.handleTypeControl()
        await patchTender(id, data)
        this.doRequest()
        this.toggleDialog("change_type")
    }

    reDownloadFiles = async () => {
        const {
            reDownloadTender,
            match: {
                params: { id }
            }
        } = this.props
        const res = await reDownloadTender(id)
        if (res.error) {
            this.setState({ openErrorSnack: true })
        } else {
            this.doRequest()
        }
    }

    closeSnack = () => this.setState({ openErrorSnack: false })

    getContent = () => {
        const {
            role,
            deal: {
                data: {
                    customer,
                    name,
                    start_price_contract,
                    datetime_dedline_request,
                    datetime_holding,
                    date_delivery_goods,
                    request_type,
                    category,
                    link,
                    request_provision_size,
                    e_place
                },
                old_tender_deadline,
                custom_deadline,
                type,
                archive_files,
                archive_status
            }
        } = this.props
        const { active_tab, openTypeControl } = this.state
        if (active_tab === 0) {
            return (
                <Fragment>
                    <div className="appl_first_row">
                        <div>
                            <span>Заказчик</span>
                            <p>{customer}</p>
                        </div>
                        <div className="relative_div">
                            <span>Название тендера</span>
                            <p className="overflowed_text" title={name}>
                                {name}
                            </p>
                        </div>
                        <div>
                            <span>Начальная цена контракта (лота)</span>
                            <p>
                                <b>{formatNumber(parseFloat(start_price_contract).toFixed(2))}</b>
                            </p>
                        </div>
                    </div>
                    <hr />
                    <div className="appl_second_row">
                        <div>
                            <span>Окончание подачи заявок</span>
                            {!old_tender_deadline ? (
                                <p>
                                    {datetime_dedline_request === "-" || !moment(datetime_dedline_request).isValid()
                                        ? datetime_dedline_request
                                        : moment(datetime_dedline_request).format("DD/MM/YY HH:mm")}
                                </p>
                            ) : (
                                <p>
                                    <span className="previous_date">
                                        {old_tender_deadline === "-"
                                            ? old_tender_deadline
                                            : moment(old_tender_deadline).format("DD/MM/YY HH:mm")}
                                    </span>
                                    {datetime_dedline_request === "-" || !moment(datetime_dedline_request).isValid()
                                        ? datetime_dedline_request
                                        : moment(datetime_dedline_request).format("DD/MM/YY HH:mm")}
                                </p>
                            )}
                            {custom_deadline && (
                                <div className="deadline_date">{moment(custom_deadline).format("DD/MM/YY HH:mm")}</div>
                            )}
                        </div>
                        <div>
                            <span>Время проведения</span>
                            <p>
                                {datetime_holding === "-"
                                    ? datetime_holding
                                    : moment(datetime_holding).format("DD/MM/YY HH:mm")}
                            </p>
                        </div>
                        <div className="relative_div">
                            <span>Сроки выполнения</span>
                            <p className="overflowed_text" title={date_delivery_goods}>
                                {date_delivery_goods || "-"}
                            </p>
                        </div>
                    </div>
                    <hr />
                    <div className="appl_third_row">
                        <div>
                            <span>Тип тендера</span>
                            <p title={request_type}>{request_type || "-"}</p>
                        </div>
                        <div>
                            <span>Категория</span>
                            <p>{category || "-"}</p>
                        </div>
                        <div>
                            <span>Сайт</span>
                            <p>
                                {link ? (
                                    <a
                                        className="text_hover"
                                        href={link.includes("http") ? link : `//${link}`}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        {link}
                                    </a>
                                ) : (
                                    "-"
                                )}
                            </p>
                        </div>
                    </div>
                    <hr />
                    <div className="appl_fourth_row">
                        <div>
                            <span>Размер обеспечения заявки</span>
                            <p>
                                {request_provision_size
                                    ? formatNumber(parseFloat(request_provision_size.toString().replace(",", ".")))
                                    : "-"}
                            </p>
                        </div>
                        <div>
                            <span>Размер обеспечения контракта</span>
                            <p>-</p>
                        </div>
                        <div>
                            <span>Электронная площадка</span>
                            <p>
                                {e_place ? (
                                    <a
                                        className="text_hover"
                                        href={e_place.includes("http") ? e_place : `//${e_place}`}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        {e_place}
                                    </a>
                                ) : (
                                    "-"
                                )}
                            </p>
                        </div>
                    </div>
                    <hr />
                    <div className="appl_fifth_row">
                        <div>
                            <span>Тип</span>
                            <p onClick={this.handleTypeControl} className="text_hover">
                                {type || "Выберите тип"}
                                <img src={ArrowDownIcon} alt="arrow_down" />
                            </p>
                            {openTypeControl && (
                                <ClickAwayListener onClickAway={this.handleTypeControl}>
                                    <div className="types_popper">
                                        <div
                                            className={clsx(type === "ЗК" && "current")}
                                            onClick={() => {
                                                this.toggleDialog("change_type")
                                                this.setState({ chosenType: "ЗК" })
                                            }}
                                        >
                                            <span>ЗК</span>
                                            {type === "ЗК" && <img src={CheckIcon} alt="!" />}
                                        </div>
                                        <div
                                            className={clsx(type === "Ф2" && "current")}
                                            onClick={() => {
                                                this.toggleDialog("change_type")
                                                this.setState({ chosenType: "Ф2" })
                                            }}
                                        >
                                            <span>Ф2</span>
                                            {type === "Ф2" && <img src={CheckIcon} alt="!" />}
                                        </div>
                                        <div
                                            className={clsx(type === "ЭА" && "current")}
                                            onClick={() => {
                                                this.toggleDialog("change_type")
                                                this.setState({ chosenType: "ЭА" })
                                            }}
                                        >
                                            <span>ЭА</span>
                                            {type === "ЭА" && <img src={CheckIcon} alt="!" />}
                                        </div>
                                        <div
                                            className={clsx(type === "ОФ" && "current")}
                                            onClick={() => {
                                                this.toggleDialog("change_type")
                                                this.setState({ chosenType: "ОФ" })
                                            }}
                                        >
                                            <span>ОФ</span>
                                            {type === "ОФ" && <img src={CheckIcon} alt="!" />}
                                        </div>
                                    </div>
                                </ClickAwayListener>
                            )}
                        </div>
                    </div>
                    <hr />
                    <div className="dialog_buttons">
                        <div>
                            {role === "admin" && (
                                <DefaultButton
                                    variant="outlined"
                                    onClick={() => this.toggleDialog("delete")}
                                    classes="delete_btn"
                                >
                                    Удалить
                                </DefaultButton>
                            )}
                            <DefaultButton
                                variant="contained"
                                onClick={() => this.toggleDialog("toWork")}
                                classes="calculate_btn"
                            >
                                В работу
                            </DefaultButton>
                        </div>
                        {role === "admin" && (
                            <div className="remove_deal text_hover" onClick={() => this.toggleDialog("reload")}>
                                Вернуть во входящие
                            </div>
                        )}
                    </div>
                </Fragment>
            )
        } else if (active_tab === 1) {
            return (
                <Fragment>
                    {archive_status === "downloaded" ? (
                        <div className="documents_wrapper">
                            {archive_files.map(({ filename, url }) => (
                                <div className="document" key={filename}>
                                    <a href={url} download className="usable_text text_hover">
                                        <img src={DocumentIcon} alt="document" />
                                        {filename}
                                    </a>
                                </div>
                            ))}
                        </div>
                    ) : archive_status === "error" ? (
                        <div className="download_failed">
                            <img src={FailedIcon} alt="error" />
                            Попытка скачать документы не удалась
                            <div onClick={this.reDownloadFiles} className="text_hover usable_text">
                                Повторить попытку
                            </div>
                        </div>
                    ) : (
                        <div className="download_scheduled">В очереди на скачивание</div>
                    )}
                </Fragment>
            )
        } else {
            return <Fragment />
        }
    }

    render() {
        const {
            loading,
            openDeleteDialog,
            openReloadDialog,
            openToWorkDialog,
            openErrorDialog,
            openErrorMaxTendersDialog,
            openChangeTypeDialog,
            openErrorSnack,
            reloading,
            active_tab
        } = this.state
        if (loading) return <LinearProgress />
        const {
            deal: {
                data: { number, reestr_number }
            }
        } = this.props
        return (
            <div className="page_wrapper deal_wrapper">
                <Link to="/main/deals" className="text_hover usable_text">
                    <img src={BackIcon} alt="<" />
                    Назад
                </Link>
                <h1>Детали сделки</h1>
                <div className="appl_number">
                    Заявка <span>№{number || reestr_number}</span>
                </div>
                <div className="tabs_wrapper">
                    <div
                        className={clsx("tab text_hover", active_tab === 0 && "active_tab")}
                        onClick={() => this.changeTab(0)}
                    >
                        Детали
                    </div>
                    <div
                        className={clsx("tab text_hover", active_tab === 1 && "active_tab")}
                        onClick={() => this.changeTab(1)}
                    >
                        Документы
                    </div>
                    {/*<div*/}
                    {/*    className={clsx("tab text_hover", active_tab === 2 && "active_tab")}*/}
                    {/*    onClick={() => this.changeTab(2)}*/}
                    {/*>*/}
                    {/*    Расчёт*/}
                    {/*</div>*/}
                </div>
                {this.getContent()}
                <DialogComponent open={openDeleteDialog} onClose={() => this.toggleDialog("delete")}>
                    <div className="dashboard_dialog no_select">
                        <h2>Удалить сделку</h2>
                        <div className="dialog_description">Вы уверены, что хотите удалить эту сделку?</div>
                        <div className="dialog_buttons">
                            <DefaultButton
                                variant="outlined"
                                onClick={() => this.toggleDialog("delete")}
                                classes="cancel_btn"
                            >
                                Отмена
                            </DefaultButton>
                            <DefaultButton
                                variant="contained"
                                onClick={this.deleteTender}
                                classes="confirm_btn"
                                loading={reloading}
                            >
                                Удалить
                            </DefaultButton>
                        </div>
                    </div>
                </DialogComponent>
                <DialogComponent open={openReloadDialog} onClose={() => this.toggleDialog("reload")}>
                    <div className="dashboard_dialog no_select">
                        <h2>Вернуть во входящие</h2>
                        <div className="dialog_description">
                            Вы уверены, что хотите вернуть эту сделку в список входящих заявок?
                        </div>
                        <div className="dialog_buttons">
                            <DefaultButton
                                variant="outlined"
                                onClick={() => this.toggleDialog("reload")}
                                classes="cancel_btn"
                            >
                                Отмена
                            </DefaultButton>
                            <DefaultButton
                                variant="contained"
                                onClick={this.reloadTender}
                                classes="confirm_btn"
                                loading={reloading}
                            >
                                Вернуть
                            </DefaultButton>
                        </div>
                    </div>
                </DialogComponent>
                <DialogComponent open={openToWorkDialog} onClose={() => this.toggleDialog("toWork")}>
                    <div className="dashboard_dialog no_select">
                        <h2>Взять в работу</h2>
                        <div className="dialog_description">Подтвердите, что Вы хотите взять эту сделку в работу.</div>
                        <div className="dialog_buttons">
                            <DefaultButton
                                variant="outlined"
                                onClick={() => this.toggleDialog("toWork")}
                                classes="cancel_btn"
                            >
                                Отмена
                            </DefaultButton>
                            <DefaultButton
                                variant="contained"
                                onClick={this.takeToWork}
                                classes="confirm_btn"
                                loading={reloading}
                            >
                                Подтвердить
                            </DefaultButton>
                        </div>
                    </div>
                </DialogComponent>
                <DialogComponent open={openErrorDialog} onClose={() => this.toggleDialog("error")}>
                    <div className="dashboard_dialog no_select">
                        <h2>Сделка уже занята</h2>
                        <div className="dialog_description">
                            Извините, но сделка уже закреплена за другим менеджером. Пожалуйста, выберите другую сделку.
                        </div>
                    </div>
                </DialogComponent>
                <DialogComponent open={openErrorMaxTendersDialog} onClose={() => this.toggleDialog("errorMaxTenders")}>
                    <div className="dashboard_dialog no_select">
                        <h2>Превышен лимит</h2>
                        <div className="dialog_description">
                            За Вами уже закреплено 3 сделки. Это максимальное количество одновременно активных сделок.
                            Пожалуйста, завершите одну из активных сделок, чтобы взять в работу новую.
                        </div>
                    </div>
                </DialogComponent>
                <DialogComponent open={openChangeTypeDialog} onClose={() => this.toggleDialog("change_type")}>
                    <div className="dashboard_dialog no_select">
                        <h2>Поменять тип сделки</h2>
                        <div className="dialog_description">Подтвердите, что Вы хотите поменять тип этой сделки</div>
                        <div className="dialog_buttons">
                            <DefaultButton
                                variant="outlined"
                                onClick={() => this.toggleDialog("change_type")}
                                classes="cancel_btn"
                            >
                                Отмена
                            </DefaultButton>
                            <DefaultButton
                                variant="contained"
                                onClick={this.changeType}
                                classes="confirm_btn"
                                loading={reloading}
                            >
                                Подтвердить
                            </DefaultButton>
                        </div>
                    </div>
                </DialogComponent>
                <Snack
                    open={openErrorSnack}
                    handleSnack={this.closeSnack}
                    message="Такое действие этому пользователю в этой заявке запрещено"
                    type="error"
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        deal: state.dashboard.deal,
        role: state.dashboard.options.me.role
    }
}
const mapDispatchToProps = {
    getDeal,
    patchTender,
    calculateDeal,
    reDownloadTender
}
export default connect(mapStateToProps, mapDispatchToProps)(Deal)
