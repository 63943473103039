import React from "react";
import clsx from "clsx";

export function getOption(label) {
    return (
        <div className={`status ${label}`}>
            <div>
                {label !== "All networks" && <span />}
                {label}
            </div>
        </div>
    );
}

export function splitByCommas(data) {
    return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function formatNumber(data) {
    return data
        .toString()
        .replace(".", ",")
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function sortValues(arr, sort_by) {
    switch (sort_by) {
        case "numberUp":
            return arr.sort((a, b) => parseFloat(b.data.number) - parseFloat(a.data.number));
        case "numberDown":
            return arr.sort((a, b) => parseFloat(a.data.number) - parseFloat(b.data.number));
        case "dateUp":
            return arr.sort((a, b) => {
                return (
                    (!b.data.datetime_dedline_request || b.data.datetime_dedline_request === "-"
                        ? 0
                        : new Date(b.data.datetime_dedline_request)) -
                    (!a.data.datetime_dedline_request || a.data.datetime_dedline_request === "-"
                        ? 0
                        : new Date(a.data.datetime_dedline_request))
                );
            });
        case "dateDown":
            return arr.sort((a, b) => {
                return (
                    (!a.data.datetime_dedline_request || a.data.datetime_dedline_request === "-"
                        ? 0
                        : new Date(a.data.datetime_dedline_request)) -
                    (!b.data.datetime_dedline_request || b.data.datetime_dedline_request === "-"
                        ? 0
                        : new Date(b.data.datetime_dedline_request))
                );
            });
        case "timeUp":
            return arr.sort((a, b) => {
                return (
                    (b.data.datetime_holding === "-" ? 0 : new Date(b.data.datetime_holding)) -
                    (a.data.datetime_holding === "-" ? 0 : new Date(a.data.datetime_holding))
                );
            });
        case "timeDown":
            return arr.sort((a, b) => {
                return (
                    (a.data.datetime_holding === "-" ? 0 : new Date(a.data.datetime_holding)) -
                    (b.data.datetime_holding === "-" ? 0 : new Date(b.data.datetime_holding))
                );
            });
        case "priceUp":
            return arr.sort(
                (a, b) => parseFloat(b.data.start_price_contract) - parseFloat(a.data.start_price_contract)
            );
        case "priceDown":
            return arr.sort(
                (a, b) => parseFloat(a.data.start_price_contract) - parseFloat(b.data.start_price_contract)
            );
        // case "customerUp" :
        //     return arr.sort((a, b) => a.data.customer.localeCompare(b.data.customer));
        // case "customerDown" :
        //     return arr.sort((a, b) => b.data.customer.localeCompare(a.data.customer));
        // case "nameUp" :
        //     return arr.sort((a, b) => a.data.name.localeCompare(b.data.name));
        // case "nameDown" :
        //     return arr.sort((a, b) => b.data.name.localeCompare(a.data.name));
        default:
            return arr;
    }
}

export function sortUsers(arr, sort_by) {
    const roles = ["admin", "senior_manager", "manager", "tender_specialist", "accountant"];
    switch (sort_by) {
        case "roleUp":
            return arr.sort((a, b) => roles.indexOf(a.role) - roles.indexOf(b.role));
        case "roleDown":
            return arr.sort((a, b) => roles.indexOf(b.role) - roles.indexOf(a.role));
        case "nameUp":
            return arr.sort((a, b) => a.fio.localeCompare(b.fio));
        case "nameDown":
            return arr.sort((a, b) => b.fio.localeCompare(a.fio));
        case "statusUp":
            return arr.sort((a, b) => (a.is_active === b.is_active ? 0 : a.is_active ? 1 : -1));
        case "statusDown":
            return arr.sort((a, b) => (a.is_active === b.is_active ? 0 : b.is_active ? 1 : -1));
        default:
            return arr;
    }
}

export const deals_statuses = [
    { value: "new", label: "Новый" },
    { value: "deleted", label: "Удален" },
    { value: "to_calculate", label: "В работу" },
    { value: "calculating", label: "Расчёт" },
    { value: "verifying", label: "На проверке" },
    { value: "approved", label: "Одобрена" },
    { value: "declined", label: "Отказано" },
    { value: "placed", label: "Размещена" },
    { value: "contract_conclusion", label: "Заключение ГК" },
    { value: "lost", label: "Проиграна" },
    { value: "executing", label: "Исполнение" },
    { value: "executed", label: "Исполнена" },
    { value: "contract_closed", label: "Контракт закрыт" }
];

export const deals_statuses_list = [
    // '_Новый', '_Удален', '_В работу',
    "zРасчёт",
    "zНа проверке",
    "zОдобрена",
    "zОтказано",
    "zРазмещена",
    "zОжидает размещения",
    "zРазмещение",
    "zОшибка размещения",
    "zЗаключение ГК",
    "zПроиграна",
    "zИсполнение",
    "zИсполнена",
    "zКонтракт закрыт",
    "zНа удаление"
];

export const translateDealsStatuses = statuses => {
    return statuses.map(status => {
        switch (status) {
            case "zРасчёт":
                return "calculating";
            case "zНа проверке":
                return "verifying";
            case "zОдобрена":
                return "approved";
            case "zОтказано":
                return "declined";
            case "zРазмещена":
                return "placed";
            case "zОжидает размещения":
                return "waiting_for_placing";
            case "zРазмещение":
                return "placing";
            case "zОшибка размещения":
                return "placing_error";
            case "zЗаключение ГК":
                return "contract_conclusion";
            case "zПроиграна":
                return "lost";
            case "zИсполнение":
                return "executing";
            case "zИсполнена":
                return "executed";
            case "zКонтракт закрыт":
                return "contract_closed";
            case "zНа удаление":
                return "to_delete";
            default:
                return "";
        }
    });
};

export const execution_statuses = ["zВсе", "zИсполнение", "zИсполнена"];

export const translateExecutionStatuses = status => {
    switch (status) {
        case "zИсполнение":
            return "executing";
        case "zИсполнена":
            return "executed";
        default:
            return "";
    }
};

export const importance_List = ["zВсе", "zДа", "zНет"];

export const translateImportance = importance => {
    switch (importance) {
        case "zДа":
            return true;
        case "zНет":
            return false;
        default:
            return "";
    }
};

export const auction_types = ["zЗК", "zФ2", "zЭА", "zОФ"];

export const products_statuses_list = ["zВсе", "zАктивен", "zЗаблокирован"];
export const categories_statuses_list = ["zВсе", "zАктивна", "zЗаблокирована"];

export const products_categories_list = ["zКатегория1", "zКатегория2", "zКатегория3", "zКатегория4", "zКатегория5"];

export const suppliers_list = [
    "zАрхипова Полина Евгеньевна",
    "zНазаров Леон Богданович",
    "zПавлик Филипп Валериевич",
    "zПанов Юрий Артёмович",
    "zПетренко Илларион Алексеeвич",
    "zФамилия Имя Отчество",
    "zName Surname"
];

export function getProductStatus(status_display) {
    return (
        <div className="product_status_wrapper">
            <span className={clsx("product_status", status_display ? "active" : "blocked")} />
            {status_display ? "Активен" : "Заблокирован"}
        </div>
    );
}
