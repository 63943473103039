import React from "react";
import clsx from "clsx";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";

import CheckIcon from "../../../assets/image/check.svg";

import "./SelectComponent.scss";

const SelectComponent = ({ items, item, handleChange, placeholder, toggleCategories, err }) => {
    return (
        <div className="wrapper_multiselect">
            <span className={clsx("select_placeholder", item && "actually_label")}>{placeholder}</span>
            <Select
                style={item ? null : err && { border: "1px solid red" }}
                labelId="multiple-checkbox-label"
                id="select-checkbox"
                value={item}
                onChange={handleChange}
                input={<Input />}
                renderValue={selected => selected.slice(1)}
                MenuProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                    },
                    getContentAnchorEl: null
                }}
            >
                {items.map(name => (
                    <MenuItem key={name} value={name}>
                        <ListItemText primary={name} />
                        {name === item && <img src={CheckIcon} alt="><" />}
                    </MenuItem>
                ))}
                {toggleCategories && (
                    <div>
                        <span
                            className="add_button"
                            onClick={e => {
                                e.stopPropagation();
                                toggleCategories("categories");
                            }}
                        >
                            + Добавить
                        </span>
                    </div>
                )}
            </Select>
            {err && !item && <span className="error_text">{err}</span>}
        </div>
    );
};

export default SelectComponent;
