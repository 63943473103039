import React, { Component, Fragment } from "react"
import { Field, reduxForm } from "redux-form"
import { Link } from "react-router-dom"
import { connect } from "react-redux"

import ErrorIcon from "@material-ui/icons/Error"
import { postLogin, resetErrors } from "../../../actions/authActions"
import RenderField from "../../HelperComponents/RenderField/RenderField"
import DefaultButton from "../../HelperComponents/Buttons/DefaultButton/DefaultButton"
import TooltipMessage from "../../HelperComponents/TooltipMessage/TooltipMessage"
import DialogComponent from "../../HelperComponents/DialogComponent/DialogComponent"

class Login extends Component {
    state = {
        loading: false,
        open: false
    }

    componentDidMount() {
        const { history } = this.props
        if (localStorage.token) {
            history.push("/main")
        }
    }

    componentWillUnmount() {
        const { resetErrors } = this.props
        resetErrors()
    }

    submitForm = data => {
        const { postLogin, history, resetErrors } = this.props
        this.setState({ loading: true })
        resetErrors()
        postLogin(data).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                localStorage.token = res.payload.data.token

                history.push("/main")
            } else {
                this.setState({ loading: false })
                if (res.error.response.data.code === "INACTIVE") {
                    this.setState({ open: true })
                }
            }
        })
    }

    render() {
        const { handleSubmit, submitting, pristine, valid, error_auth } = this.props
        const { loading, open } = this.state
        return (
            <Fragment>
                <form onSubmit={handleSubmit(this.submitForm)}>
                    <div className="auth_block_head">Войдите в аккаунт</div>
                    <div className="auth_block_descriptions">Заполните поля ниже, чтобы войти в аккаунт</div>
                    <Field name="email" type="text" component={RenderField} label="Электронный адрес" />
                    <Field name="password" type="password" component={RenderField} label="Пароль" />
                    <div className="auth_btn_wrapper">
                        <DefaultButton
                            variant="contained"
                            classes="sign_up_button"
                            disabled={submitting || pristine || !valid}
                            loading={loading}
                            formAction
                        >
                            Войти
                        </DefaultButton>
                        {error_auth ? (
                            <TooltipMessage text={error_auth} delay={200} error position="right" classes="">
                                <ErrorIcon />
                            </TooltipMessage>
                        ) : (
                            ""
                        )}
                    </div>
                </form>
                <Link to="/auth/password-recovery" className="forget_pass_text usable_text text_hover">
                    Забыли пароль?
                </Link>
                <div className="no_account">
                    Ещё нет аккаунта? &nbsp&nbsp&nbsp
                    <Link to="/auth/register" className="text_hover">
                        Зарегистрироваться
                    </Link>
                </div>
                <DialogComponent open={open} onClose={() => this.setState({ open: false })}>
                    <div className="dashboard_dialog no_select">
                        <h2>Доступ запрещён</h2>
                        <div className="dialog_description">
                            Извините, но Вы не можете сейчас работать с системой, так как администратор заблокировал
                            Вашу учётную запись. Пожалуйста, свяжитесь с ним, чтобы решить этот вопрос.
                        </div>
                    </div>
                </DialogComponent>
            </Fragment>
        )
    }
}

const validate = values => {
    const errors = {}
    if (!values.email) {
        errors.email = "Обязательное поле"
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i.test(values.email)) {
        errors.email = "Недопустимый электронный адрес"
    }
    if (!values.password) {
        errors.password = "Обязательное поле"
    } else if (values.password.length < 8) {
        errors.password = "Пароль должен содержать 8 или больше символов"
    }
    return errors
}

Login = reduxForm({
    form: "LoginForm",
    validate
})(Login)

const mapStateToProps = state => {
    return {
        error_auth: state.auth.error_auth
    }
}
const mapDispatchToProps = {
    postLogin,
    resetErrors
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
