import React, { Component, Fragment } from "react";
import "./Shipment.scss";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { reset } from "redux-form";
import {
    getShipment,
    createShipment,
    changeShipment,
    getSingleShipment,
    deleteShipment,
    deleteShipmentReport,
    createShipmentReport,
    addReportData,
    changeReportData,
    deleteReportData,
    sendMail,
    changeAccountShipment
} from "../../../actions/shipmentActions";
import { getDeal } from "../../../actions/dashboardActions";
import DialogComponent from "../../HelperComponents/DialogComponent/DialogComponent";
import DefaultButton from "../../HelperComponents/Buttons/DefaultButton/DefaultButton";
import { Field, reduxForm } from "redux-form";
import RenderField from "../../HelperComponents/RenderField/RenderField";
import DateField from "../../HelperComponents/RenderDatePicker/RenderDatePicker";
import moment from "moment";
import EditImg from "../../../assets/image/edit.svg";
import DeleteImg from "../../../assets/image/delete.svg";
import ExpandImg from "../../../assets/image/expand.svg";
import { Collapse } from "react-collapse";
import FileImg from "../../../assets/image/file.svg";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import SendShip from "../../../assets/image/send_email.svg";
import NotShip from "../../../assets/image/failed.svg";
import CompleteShip from "../../../assets/image/complete.svg";
import DisabledSendShip from "../../../assets/image/disabled_email.svg";
import Snack from "../../HelperComponents/Snack/Snack";

class Shipment extends Component {
    state = {
        addShipmentDialog: false,
        editShipmentDialog: false,
        loading: false,
        selectedElements: [],
        chosenShip: null,
        deleteShipmentDialog: false,
        deleteReportDialog: false,
        chosenReport: null,
        positionsDialog: false,
        chosenElements: [],
        deletedElements: [],
        sendMailDialog: false,
        accountValues: [],
        openSingleSent: false,
        chosenId: null,
        minimalResults: false,
        successSnack: null,
        errorSnack: null
    };

    componentDidMount() {
        const {
            getShipment,
            match: {
                params: { id }
            }
        } = this.props;
        getShipment(id).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                const elements = res.payload.data;

                this.setState({
                    accountValues: [
                        ...elements
                            .map(el => el.accounts)
                            .flat()
                            .map(el => ({
                                id: el.id,
                                price: el.total_price
                            }))
                    ]
                });
            }
        });
    }

    addShipment = data => {
        const {
            createShipment,
            getShipment,
            match: {
                params: { id }
            },
            reset
        } = this.props;
        createShipment(id, {
            name: data.name,
            address: data.adress,
            delivery_date: moment(data.deadline).format("YYYY-MM-DD")
        }).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 201) {
                this.setState({ addShipmentDialog: false });
                getShipment(id);
                reset();
            }
        });
    };

    editShipment = data => {
        const {
            changeShipment,
            getShipment,
            match: {
                params: { id }
            },
            reset,
            singleShipment
        } = this.props;
        const { chosenShip } = this.state;
        const obj = {};
        if (singleShipment.name_edit !== data.name_edit) obj.name = data.name_edit;
        if (singleShipment.adress_edit !== data.adress_edit) obj.address = data.adress_edit;
        if (singleShipment.deadline_edit !== data.deadline_edit)
            obj.delivery_date = moment(data.deadline_edit).format("YYYY-MM-DD");

        changeShipment(id, chosenShip, obj).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                this.setState({ editShipmentDialog: false });
                getShipment(id);
                reset();
            }
            if (res.error && res.error.response && res.error.response.data && res.error.response.data.email_sent) {
                this.setState({ errorSnack: res.error.response.data.email_sent[0], editShipmentDialog: false });
            }
        });
    };

    handleSelected = id => {
        const { selectedElements } = this.state;
        if (selectedElements.includes(id)) {
            this.setState({
                selectedElements: selectedElements.filter(el => el !== id)
            });
        } else {
            this.setState({ selectedElements: [...selectedElements, id] });
        }
    };

    returnRole = (status_display, role, email_sent = false) => {
        if (role === "admin" || role === "manager" || role === "senior_manager") {
            return true;
        } else {
            if (
                (status_display === "Новый" ||
                    status_display === "В работу" ||
                    status_display === "Расчёт") &&
                !email_sent
            ) {
                return true;
            }
            return false;
        }
    };

    closeSuccessSnack = () => this.setState({ successSnack: null });
    closeErrorSnack = () => this.setState({ errorSnack: null });

    render() {
        const {
            data,
            handleSubmit,
            getSingleShipment,
            deleteShipment,
            match: {
                params: { id }
            },
            getShipment,
            deleteShipmentReport,
            createShipmentReport,
            deal: {
                calculation: { sell, sell_total_amount, additional },
                status_display
            },
            addReportData,
            changeReportData,
            deleteReportData,
            role,
            sendMail,
            getDeal,
            changeAccountShipment
        } = this.props;
        const {
            addShipmentDialog,
            loading,
            selectedElements,
            editShipmentDialog,
            deleteShipmentDialog,
            chosenShip,
            deleteReportDialog,
            chosenReport,
            positionsDialog,
            chosenElements,
            deletedElements,
            sendMailDialog,
            accountValues,
            openSingleSent,
            chosenId,
            minimalResults,
            successSnack,
            errorSnack
        } = this.state;

        const additionalTotal = (additional || [])
            .map(el => el.total_price)
            .reduce((acc, el) => {
                if (el) acc += el;
                return acc;
            }, 0);

        const prices = data
            .map(el => el.accounts)
            .flat()
            .map(el => el.total_price);

        const allTotalPrices = prices.reduce((acc, el) => {
            if (el) acc += el;
            return acc;
        }, 0);

        const allSells = data
            .map(el => el.accounts)
            .flat()
            .map(el => el.account_items)
            .flat()
            .reduce((acc, el) => {
                return (acc += +el.sell_price * +el.amount);
            }, 0);

        const allTotalPricesWithAdditional = allTotalPrices + additionalTotal;
        const percent = Math.round(((allSells - allTotalPricesWithAdditional) / allSells) * 100);

        return (
            <>
                <div className="shipment-content-wrapper">
                    <div className="shipment-wrapper">
                        <div className="shipment-head">
                            <p>Отгрузка</p>
                            {this.returnRole(status_display, role) && (
                                <div>
                                    <button onClick={() => this.setState({ addShipmentDialog: true })}>
                                        Добавить отгрузку
                                    </button>
                                </div>
                            )}
                        </div>
                        {data && data.length > 0 && (
                            <div className="table_container">
                                <div className="table_header no_select">
                                    <div className="table_row">
                                        <div className="row_item">Наименование</div>
                                        <div className="row_item">Адрес поставки</div>
                                        <div className="row_item">Дата поставки</div>
                                        <div className="row_item">Статус и управление</div>
                                    </div>
                                </div>
                                <div className="table_body">
                                    {data.map(({ id: shipId, address, name, delivery_date, accounts, email_sent }) => (
                                        <Fragment key={shipId}>
                                            <div className="table_row">
                                                <div className="row_item">
                                                    <div
                                                        className="text_hover"
                                                        onClick={() => this.handleSelected(shipId)}
                                                    >
                                                        <img
                                                            src={ExpandImg}
                                                            alt="expand"
                                                            className={
                                                                !selectedElements.includes(shipId) ? "expand-img" : ""
                                                            }
                                                        />
                                                    </div>
                                                    {name}
                                                </div>
                                                <div className="row_item">
                                                    {address ? address : "-"}
                                                </div>
                                                <div className="row_item">
                                                    {moment(delivery_date).format("DD/MM/YYYY")}
                                                </div>
                                                <div className="row_item">
                                                    {
                                                        this.returnRole(status_display, role, email_sent) && (
                                                            <>
                                                                {
                                                                    email_sent
                                                                        ? <img
                                                                            src={CompleteShip}
                                                                            alt="Отгружено"
                                                                        />
                                                                        : <img
                                                                            src={NotShip}
                                                                            alt="Не отгружено"
                                                                        />
                                                                }
                                                                {
                                                                    email_sent
                                                                        ? role === 'admin'
                                                                            ? <img
                                                                                src={SendShip}
                                                                                alt="Send Ship"
                                                                                onClick={() => {
                                                                                    this.setState({
                                                                                        openSingleSent: true,
                                                                                        chosenId: shipId
                                                                                    });
                                                                                }}
                                                                            />
                                                                            : <img
                                                                                className="disabled-icon"
                                                                                alt="Неактивная иконка"
                                                                                src={DisabledSendShip}
                                                                            />
                                                                        : <img
                                                                            src={SendShip}
                                                                            alt="Send Ship"
                                                                            onClick={() => {
                                                                                this.setState({
                                                                                    openSingleSent: true,
                                                                                    chosenId: shipId
                                                                                });
                                                                            }}
                                                                        />
                                                                }
                                                                {
                                                                    role === 'admin'
                                                                        ? <>
                                                                            <img
                                                                                src={EditImg}
                                                                                alt="Редактирование"
                                                                                onClick={() => {
                                                                                    getSingleShipment({
                                                                                        name_edit: name,
                                                                                        deadline_edit: delivery_date,
                                                                                        adress_edit: address,
                                                                                    });
                                                                                    this.setState({
                                                                                        editShipmentDialog: true,
                                                                                        chosenShip: shipId
                                                                                    });
                                                                                }}
                                                                            />
                                                                            <img
                                                                                src={DeleteImg}
                                                                                alt="Удаление"
                                                                                onClick={() =>
                                                                                    this.setState({
                                                                                        deleteShipmentDialog: true,
                                                                                        chosenShip: shipId
                                                                                    })
                                                                                }
                                                                            />
                                                                        </>
                                                                        : null
                                                                }
                                                            </>
                                                        )}
                                                </div>
                                            </div>
                                            <Collapse isOpened={selectedElements.includes(shipId)}>
                                                <div className="collapsed_block">
                                                    {accounts &&
                                                        accounts.length > 0 &&
                                                        accounts.map(
                                                            ({
                                                                 doc_name,
                                                                 doc_account,
                                                                 id: docId,
                                                                 account_items,
                                                                 total_price
                                                             }) => (
                                                                <div className="doc-wrapper" key={docId}>
                                                                    <a href={doc_account} download className="doc-name">
                                                                        <img src={FileImg} alt="Файл" />
                                                                        <p>{doc_name}</p>
                                                                    </a>
                                                                    <div className="input-price-wrapper">
                                                                        <p>Сумма счёта</p>
                                                                        <input
                                                                            value={
                                                                                (accountValues &&
                                                                                    accountValues.find(
                                                                                        el => el.id === docId
                                                                                    ) &&
                                                                                    accountValues.find(
                                                                                        el => el.id === docId
                                                                                    ).price) || ''
                                                                            }
                                                                            onChange={e => {
                                                                                const {
                                                                                    target: { value }
                                                                                } = e;
                                                                                const idx = accountValues.findIndex(
                                                                                    el => el.id === docId
                                                                                );
                                                                                this.setState(
                                                                                    ({ accountValues: vals }) => ({
                                                                                        accountValues: [
                                                                                            ...vals.slice(0, idx),
                                                                                            {
                                                                                                id: docId,
                                                                                                price: value
                                                                                            },
                                                                                            ...vals.slice(idx + 1)
                                                                                        ]
                                                                                    })
                                                                                );
                                                                            }}
                                                                            onBlur={e => {
                                                                                changeAccountShipment(
                                                                                    id,
                                                                                    shipId,
                                                                                    docId,
                                                                                    {
                                                                                        total_price: +e.target.value
                                                                                    }
                                                                                ).then(res => {
                                                                                    if (
                                                                                        res.payload &&
                                                                                        res.payload.status &&
                                                                                        res.payload.status === 200
                                                                                    ) {
                                                                                        getShipment(id);
                                                                                    }
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <p
                                                                        onClick={() => {
                                                                            getDeal(id).then(res => {
                                                                                this.setState({
                                                                                    positionsDialog: true,
                                                                                    chosenShip: shipId,
                                                                                    chosenReport: docId,
                                                                                    chosenElements:
                                                                                        account_items &&
                                                                                        account_items.map(elem => {
                                                                                            return {
                                                                                                id: elem.sell_item,
                                                                                                amount: elem.amount,
                                                                                                productId: elem.id
                                                                                            };
                                                                                        }),
                                                                                    deletedElements: []
                                                                                });
                                                                                !this.returnRole(
                                                                                    status_display,
                                                                                    role,
                                                                                    email_sent
                                                                                ) &&
                                                                                this.setState({
                                                                                    minimalResults: true
                                                                                });
                                                                            });
                                                                        }}
                                                                    >
                                                                        Задать позиции
                                                                    </p>
                                                                    {this.returnRole(
                                                                        status_display,
                                                                        role,
                                                                        email_sent
                                                                    ) && (
                                                                        <img
                                                                            src={DeleteImg}
                                                                            alt="Удаление"                                                                            onClick={() =>
                                                                            this.setState({
                                                                                deleteReportDialog: true,
                                                                                chosenShip: shipId,
                                                                                chosenReport: docId
                                                                            })
                                                                        }
                                                                        />
                                                                    )}
                                                                </div>
                                                            )
                                                        )}
                                                    <div className="doc-wrapper more-accs">
                                                        {this.returnRole(status_display, role, email_sent) && (
                                                            <label htmlFor={`fileInput-${shipId}`}>+ Добавить счёт</label>
                                                        )}
                                                        <input
                                                            type="file"
                                                            id={`fileInput-${shipId}`}
                                                            style={{ display: "none" }}
                                                            onChange={e => {
                                                                const formData = new FormData();
                                                                formData.append("doc_account", e.target.files[0]);
                                                                createShipmentReport(id, shipId, formData).then(res => {
                                                                    if (
                                                                        res.payload &&
                                                                        res.payload.status &&
                                                                        res.payload.status === 201
                                                                    ) {
                                                                        getShipment(id);
                                                                    }
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </Collapse>
                                        </Fragment>
                                    ))}
                                    <div className="table_row"></div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="economic-wrapper">
                        <div className="economic-head">
                            <p>Экономика контракта</p>
                        </div>
                        {data && data.length > 0 && (
                            <div className="table_container">
                                <div className="table_header no_select">
                                    <div className="table_row">
                                        <div className="row_item"></div>
                                        <div className="row_item">Закупка</div>
                                        <div className="row_item">Продажа</div>
                                        <div className="row_item">Профит</div>
                                    </div>
                                </div>
                                <div className="table_body">
                                    {data.map(({ id: shipId, address, name, delivery_date, accounts, email_sent }) => (
                                        <Fragment key={shipId}>
                                            <div className="table_row">
                                                <div className="row_item">
                                                    <div
                                                        className="text_hover"
                                                        onClick={() => this.handleSelected(shipId)}
                                                    >
                                                        <img
                                                            src={ExpandImg}
                                                            alt="Expand"
                                                            className={
                                                                !selectedElements.includes(shipId) ? "expand-img" : ""
                                                            }
                                                        />
                                                    </div>
                                                    {name}
                                                </div>
                                            </div>
                                            <Collapse isOpened={selectedElements.includes(shipId)}>
                                                <div className="collapsed_block">
                                                    {accounts &&
                                                        accounts.length > 0 &&
                                                        accounts.map(
                                                            ({
                                                                 doc_name,
                                                                 doc_account,
                                                                 id: docId,
                                                                 account_items,
                                                                 total_price
                                                             }) => {
                                                                const sell = account_items.reduce((acc, el) => {
                                                                    return (acc += +el.sell_price * +el.amount);
                                                                }, 0);

                                                                return (
                                                                    <div className="doc-wrapper" key={docId}>
                                                                        <div className="table_row economic_table_row">
                                                                            <div className="row_item"></div>
                                                                            <div className="row_item">
                                                                                {total_price}
                                                                            </div>
                                                                            <div className="row_item">
                                                                                {sell && sell.toFixed(2)}
                                                                            </div>
                                                                            <div className="row_item">
                                                                                {sell && total_price && (sell - total_price).toFixed(2)}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                    <div className="doc-wrapper more-accs"></div>
                                                </div>
                                            </Collapse>
                                        </Fragment>
                                    ))}
                                    <div className="table_row bold-text">
                                        <div className="row_item">Доп. расходы</div>
                                        <div className="row_item red-text">{additionalTotal}</div>
                                        <div className="row_item"></div>
                                        <div className="row_item"></div>
                                    </div>

                                    <div className="table_row bold-text">
                                        <div className="row_item">ИТОГО</div>
                                        <div className="row_item">{allTotalPricesWithAdditional}</div>
                                        <div className="row_item">{allSells.toFixed(2)}</div>
                                        <div className="row_item">
                                            {(allSells - allTotalPricesWithAdditional).toFixed(2)}
                                            <span style={percent > 0 ? { color: "#2CC197" } : { color: "#E44B4B" }}>
                                                {" "}
                                                ({percent}%)
                                            </span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <DialogComponent open={sendMailDialog} onClose={() => this.setState({ sendMailDialog: false })}>
                    <div className="dashboard_dialog no_select">
                        <h2>Отправить список отгрузок</h2>
                        <div className="dialog_description">
                            Подтвердите, что Вы хотите отправить список отгрузок и счетов на почту бухглатера.
                        </div>
                        <div className="dialog_buttons shipment-btns">
                            <DefaultButton
                                variant="outlined"
                                onClick={() => this.setState({ sendMailDialog: false })}
                                classes="cancel_btn"
                            >
                                Отмена
                            </DefaultButton>
                            <DefaultButton
                                variant="contained"
                                classes="confirm_btn"
                                loading={loading}
                                onClick={() => {
                                    sendMail(id, { deliveries: data && data.map(el => el.id) }).then(res => {
                                        if (res.payload && res.payload.status && res.payload.status === 200) {
                                            this.setState({ addShipmentDialog: false, successSnack: "Письмо было успешно отправлено" });
                                            getShipment(id);
                                        }
                                        if (res.error && res.error.response && res.error.response.data) {
                                            this.setState({ errorSnack: res.error.response.data[0] });
                                        }
                                    });
                                    this.setState({ sendMailDialog: false });
                                }}
                            >
                                Подтвердить
                            </DefaultButton>
                        </div>
                    </div>
                </DialogComponent>

                <DialogComponent open={openSingleSent} onClose={() => this.setState({ openSingleSent: false })}>
                    <div className="dashboard_dialog no_select">
                        <h2>Отправить отгрузку</h2>
                        <div className="dialog_description">
                            Подтвердите, что Вы хотите отправить эту отгрузку и её счета на почту бухглатера.
                        </div>
                        <div className="dialog_buttons shipment-btns">
                            <DefaultButton
                                variant="outlined"
                                onClick={() => this.setState({ openSingleSent: false })}
                                classes="cancel_btn"
                            >
                                Отмена
                            </DefaultButton>
                            <DefaultButton
                                variant="contained"
                                classes="confirm_btn"
                                loading={loading}
                                onClick={() => {
                                    sendMail(id, { all_sells: allSells, deliveries: [chosenId] }).then(res => {
                                        if (res.payload && res.payload.status && res.payload.status === 200) {
                                            this.setState({ openSingleSent: false, chosenId: null, successSnack: "Письмо было успешно отправлено" });
                                            getShipment(id);
                                        }
                                        if (res.error && res.error.response && res.error.response.data) {
                                            this.setState({ openSingleSent: false, chosenId: null, errorSnack: res.error.response.data[0] });
                                        }
                                    });
                                }}
                            >
                                Подтвердить
                            </DefaultButton>
                        </div>
                    </div>
                </DialogComponent>

                <DialogComponent open={addShipmentDialog} onClose={() => this.setState({ addShipmentDialog: false })}>
                    <form className="dashboard_dialog no_select" onSubmit={handleSubmit(this.addShipment)}>
                        <h2>Добавить отгрузку</h2>
                        <div className="fields-row">
                            <Field
                                type="text"
                                component={RenderField}
                                label="Наименование"
                                className="date-gk"
                                name="name"
                            />
                            <Field
                                name="deadline"
                                component={DateField}
                                label="Дата поставки"
                                className=" date-gk"
                                disablePast={true}
                                invalidDateMessage="Недопустимые дата и время"
                                minDateMessage="Недопустимые дата и время"
                                maxDateMessage="Недопустимые дата и время"
                                invalidLabel="Недопустимые дата и время"
                                onlyDate={true}
                            />
                        </div>
                        <Field
                            type="text"
                            component={RenderField}
                            label="Адрес поставки"
                            className="date-gk"
                            name="adress"
                        />
                        <div className="dialog_buttons shipment-btns">
                            <DefaultButton
                                variant="outlined"
                                onClick={() => this.setState({ addShipmentDialog: false })}
                                classes="cancel_btn"
                            >
                                Отмена
                            </DefaultButton>
                            <DefaultButton variant="contained" classes="confirm_btn" loading={loading} formAction>
                                Добавить
                            </DefaultButton>
                        </div>
                    </form>
                </DialogComponent>
                <DialogComponent open={editShipmentDialog} onClose={() => this.setState({ editShipmentDialog: false })}>
                    <form className="dashboard_dialog no_select" onSubmit={handleSubmit(this.editShipment)}>
                        <h2>Сохранить отгрузку</h2>
                        <div className="fields-row">
                            <Field
                                type="text"
                                component={RenderField}
                                label="Наименование"
                                className="date-gk"
                                name="name_edit"
                            />
                            <Field
                                name="deadline_edit"
                                component={DateField}
                                label="Дата поставки"
                                className=" date-gk"
                                // disablePast={true}
                                invalidDateMessage="Недопустимые дата и время"
                                minDateMessage="Недопустимые дата и время"
                                maxDateMessage="Недопустимые дата и время"
                                invalidLabel="Недопустимые дата и время"
                                onlyDate={true}
                            />
                        </div>
                        <Field
                            type="text"
                            component={RenderField}
                            label="Адрес поставки"
                            className="date-gk"
                            name="adress_edit"
                        />
                        <div className="dialog_buttons shipment-btns">
                            <DefaultButton
                                variant="outlined"
                                onClick={() => this.setState({ editShipmentDialog: false })}
                                classes="cancel_btn"
                            >
                                Отмена
                            </DefaultButton>
                            <DefaultButton variant="contained" classes="confirm_btn" loading={loading} formAction>
                                Сохранить
                            </DefaultButton>
                        </div>
                    </form>
                </DialogComponent>
                <DialogComponent
                    open={deleteShipmentDialog}
                    onClose={() => this.setState({ deleteShipmentDialog: false })}
                >
                    <div className="dashboard_dialog no_select">
                        <h2>Удалить отгрузку</h2>
                        <div className="dialog_description">Вы уверены, что хотите удалить эту отгрузку?</div>
                        <div className="dialog_buttons shipment-btns">
                            <DefaultButton
                                variant="outlined"
                                onClick={() => this.setState({ deleteShipmentDialog: false })}
                                classes="cancel_btn"
                            >
                                Отмена
                            </DefaultButton>
                            <DefaultButton
                                variant="contained"
                                classes="confirm_btn"
                                loading={loading}
                                onClick={() => {
                                    deleteShipment(id, chosenShip).then(res => {
                                        if (res.payload && res.payload.status && res.payload.status === 204) {
                                            this.setState({ deleteShipmentDialog: false });
                                            getShipment(id);
                                        }
                                        if (res.error && res.error.response && res.error.response.data && res.error.response.data.email_sent) {
                                            this.setState({ errorSnack: res.error.response.data.email_sent[0] });
                                        }
                                    });
                                }}
                            >
                                Удалить
                            </DefaultButton>
                        </div>
                    </div>
                </DialogComponent>
                <DialogComponent open={deleteReportDialog} onClose={() => this.setState({ deleteReportDialog: false })}>
                    <div className="dashboard_dialog no_select">
                        <h2>Удалить счёт</h2>
                        <div className="dialog_description">Вы уверены, что хотите удалить этот счёт?</div>
                        <div className="dialog_buttons shipment-btns">
                            <DefaultButton
                                variant="outlined"
                                onClick={() => this.setState({ deleteReportDialog: false })}
                                classes="cancel_btn"
                            >
                                Отмена
                            </DefaultButton>
                            <DefaultButton
                                variant="contained"
                                classes="confirm_btn"
                                loading={loading}
                                onClick={() => {
                                    deleteShipmentReport(id, chosenShip, chosenReport).then(res => {
                                        if (res.payload && res.payload.status && res.payload.status === 204) {
                                            this.setState({ deleteReportDialog: false });
                                            getShipment(id);
                                        }
                                    });
                                }}
                            >
                                Удалить
                            </DefaultButton>
                        </div>
                    </div>
                </DialogComponent>
                <DialogComponent open={positionsDialog} onClose={() => this.setState({ positionsDialog: false })}>
                    <div className="dashboard_dialog no_select">
                        <h2>Задать позиции</h2>
                        <div className="dialog_description">
                            Выберите позиции для этого счёта и введите нужное количество
                        </div>
                        <div className="positions">
                            {sell &&
                                (sell.length > 0 ? (
                                    sell.map(({ id: elId, name, price, unit }) => {
                                        const idx = chosenElements.findIndex(el => el.id === elId);
                                        return (
                                            <div key={elId}>
                                                <FormControl component="fieldset">
                                                    <FormGroup>
                                                        {!minimalResults ? (
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={chosenElements.some(
                                                                            el => el.id === elId
                                                                        )}
                                                                        onChange={() => {
                                                                            if (idx >= 0) {
                                                                                this.setState(
                                                                                    ({
                                                                                         chosenElements,
                                                                                         deletedElements
                                                                                     }) => ({
                                                                                        chosenElements: [
                                                                                            ...chosenElements.slice(
                                                                                                0,
                                                                                                idx
                                                                                            ),
                                                                                            ...chosenElements.slice(
                                                                                                idx + 1
                                                                                            )
                                                                                        ],
                                                                                        deletedElements: [
                                                                                            ...deletedElements,
                                                                                            chosenElements[idx]
                                                                                        ]
                                                                                    })
                                                                                );
                                                                            } else {
                                                                                if (
                                                                                    deletedElements.some(
                                                                                        el => el.id === elId
                                                                                    )
                                                                                ) {
                                                                                    const deletedId = deletedElements.findIndex(
                                                                                        el => el.id === elId
                                                                                    );
                                                                                    this.setState(
                                                                                        ({
                                                                                             chosenElements,
                                                                                             deletedElements
                                                                                         }) => ({
                                                                                            chosenElements: [
                                                                                                ...chosenElements,
                                                                                                { id: elId, amount: 0 }
                                                                                            ],
                                                                                            deletedElements: [
                                                                                                ...deletedElements.slice(
                                                                                                    0,
                                                                                                    deletedId
                                                                                                ),
                                                                                                ...deletedElements.slice(
                                                                                                    deletedId + 1
                                                                                                )
                                                                                            ]
                                                                                        })
                                                                                    );
                                                                                } else {
                                                                                    this.setState(
                                                                                        ({ chosenElements }) => ({
                                                                                            chosenElements: [
                                                                                                ...chosenElements,
                                                                                                { id: elId, amount: 0 }
                                                                                            ]
                                                                                        })
                                                                                    );
                                                                                }
                                                                            }
                                                                        }}
                                                                        color="primary"
                                                                        inputProps={{
                                                                            "aria-label": "secondary checkbox"
                                                                        }}
                                                                    />
                                                                }
                                                                label={name}
                                                            />
                                                        ) : (
                                                            <p className="minimal-name">{name}</p>
                                                        )}
                                                    </FormGroup>
                                                </FormControl>
                                                <p className="price-unit-name">Цена за {unit}</p>
                                                <input className="price-unit" value={price} disabled />
                                                <input
                                                    type="number"
                                                    disabled={
                                                        !chosenElements.some(el => el.id === elId) || minimalResults
                                                    }
                                                    label="0"
                                                    value={idx >= 0 ? chosenElements[idx].amount : 0}
                                                    onChange={e => {
                                                        const idx = chosenElements.findIndex(el => el.id === elId);
                                                        const { value } = e.target;
                                                        this.setState(({ chosenElements }) => ({
                                                            chosenElements: [
                                                                ...chosenElements.slice(0, idx),
                                                                { ...chosenElements[idx], amount: value },
                                                                ...chosenElements.slice(idx + 1)
                                                            ]
                                                        }));
                                                    }}
                                                    style={minimalResults ? { opacity: "1" } : {}}
                                                />
                                                <span>{unit}</span>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div>Необходимо заполнить поля с продажами</div>
                                ))}
                        </div>
                        <div className="total-price-modal" style={minimalResults ? { paddingLeft: "0" } : {}}>
                            Продажа: {sell_total_amount}
                        </div>
                        {!minimalResults && (
                            <div className="dialog_buttons shipment-btns">
                                <DefaultButton
                                    variant="outlined"
                                    onClick={() => this.setState({ positionsDialog: false })}
                                    classes="cancel_btn"
                                >
                                    Отмена
                                </DefaultButton>
                                <DefaultButton
                                    variant="contained"
                                    classes="confirm_btn"
                                    loading={loading}
                                    onClick={() => {
                                        chosenElements &&
                                        chosenElements.filter(el => !el.productId) &&
                                        chosenElements.filter(el => !el.productId).length > 0 &&
                                        addReportData(
                                            id,
                                            chosenShip,
                                            chosenReport,
                                            chosenElements &&
                                            chosenElements
                                                .filter(el => !el.productId)
                                                .map(el => ({ sell_item: el.id, amount: el.amount }))
                                        ).then(res => {
                                            if (res.payload && res.payload.status && res.payload.status === 201) {
                                                this.setState({ positionsDialog: false });
                                                getShipment(id);
                                            }
                                        });

                                        chosenElements &&
                                        chosenElements.filter(el => el.productId) &&
                                        chosenElements.filter(el => el.productId).length > 0 &&
                                        changeReportData(
                                            id,
                                            chosenShip,
                                            chosenReport,
                                            chosenElements &&
                                            chosenElements
                                                .filter(el => el.productId)
                                                .map(el => ({
                                                    sell_item: el.id,
                                                    amount: el.amount,
                                                    id: el.productId
                                                }))
                                        ).then(res => {
                                            if (res.payload && res.payload.status && res.payload.status === 200) {
                                                this.setState({ positionsDialog: false });
                                                getShipment(id);
                                            }
                                        });

                                        deletedElements &&
                                        deletedElements.filter(el => el.productId) &&
                                        deletedElements.filter(el => el.productId).length > 0 &&
                                        deleteReportData(
                                            id,
                                            chosenShip,
                                            chosenReport,
                                            deletedElements &&
                                            deletedElements.filter(el => el.productId).map(el => el.productId)
                                        ).then(res => {
                                            if (res.payload && res.payload.status && res.payload.status === 204) {
                                                this.setState({ positionsDialog: false });
                                                getShipment(id);
                                            }
                                        });
                                    }}
                                >
                                    Сохранить
                                </DefaultButton>
                            </div>
                        )}
                    </div>
                </DialogComponent>

                <Snack
                    open={successSnack}
                    handleSnack={this.closeSuccessSnack}
                    message={successSnack}
                />

                <Snack
                    open={errorSnack}
                    handleSnack={this.closeErrorSnack}
                    message={errorSnack}
                    type="error"
                />
            </>
        );
    }
}

const validate = values => {
    const errors = {};
    if (!values.deadline) {
        errors.deadline = "Нужно выбрать дату.";
    }
    if (!values.name) {
        errors.name = "Нужно выбрать наименование.";
    }
    if (!values.adress) {
        errors.adress = "Нужно выбрать адрес.";
    }
    if (!values.deadline_edit) {
        errors.deadline_edit = "Нужно выбрать дату.";
    }
    if (!values.name_edit) {
        errors.name_edit = "Нужно выбрать наименование.";
    }
    if (!values.adress_edit) {
        errors.adress_edit = "Нужно выбрать адрес.";
    }
    return errors;
};

Shipment = reduxForm({
    form: "ShipmentForm",
    validate,
    enableReinitialize: true
})(Shipment);

const mapStateToProps = ({ shipment, dashboard }) => {
    return {
        role: dashboard.options.me.role,
        data: shipment.shipmentData,
        singleShipment: shipment.singleShipment,
        deal: dashboard.deal,
        initialValues: {
            ...shipment.singleShipment
        }
    };
};

const mapDispatchToProps = {
    getShipment,
    createShipment,
    changeShipment,
    getSingleShipment,
    deleteShipment,
    deleteShipmentReport,
    createShipmentReport,
    addReportData,
    changeReportData,
    reset,
    deleteReportData,
    sendMail,
    getDeal,
    changeAccountShipment
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Shipment));
