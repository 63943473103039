import * as types from "../actions/constants"

const INITIAL_STATE = {
    parsedData: { idling: true, sell: [] },
    calculation_error: "",
    catalogItems: [],
    suppliers: [],
    preliminaryPurchase: null,
    preliminaryPurchases: null,
}

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.GET_DEAL_SUCCESS:
            let temp_calculation = { idling: true }

            if (action.payload && action.payload.data && action.payload.data.calculation && action.payload.data.calculation.import_file) {
                temp_calculation = action.payload.data.calculation;
            }
            return { ...state, parsedData: temp_calculation }
        case types.POST_EXCEL_SUCCESS:
            return {
                ...state,
                parsedData: action.payload.data,
                calculation_error: "",
            };
        case types.POST_BUY_ITEM_SUCCESS:
            return {
                ...state,
                parsedData: {
                    ...action.payload.data,
                    idling: state.parsedData.idling,
                },
            }
        case types.ADD_SELL_CALCULATION_SUCCESS:
            return {
                ...state,
                parsedData: { ...action.payload.data, idling: state.parsedData.idling ? true : false }
            }
        case types.PATCH_SELL_ITEM_SUCCESS:
            return {
                ...state,
                parsedData: { ...action.payload.data, idling: state.parsedData.idling ? true : false }
            }
        case types.PATCH_BUY_ITEM_SUCCESS:
            return {
                ...state,
                parsedData: { ...action.payload.data, idling: state.parsedData.idling ? true : false }
            }
        case types.DELETE_BUY_ITEM_SUCCESS:
            return {
                ...state,
                parsedData: { ...action.payload.data, idling: state.parsedData.idling ? true : false }
            }
        case types.POST_ADDITIONAL_ITEM_SUCCESS:
            return {
                ...state,
                parsedData: { ...action.payload.data, idling: state.parsedData.idling ? true : false }
            }
        case types.PATCH_ADDITIONAL_ITEM_SUCCESS:
            return {
                ...state,
                parsedData: { ...action.payload.data, idling: state.parsedData.idling ? true : false }
            }
        case types.DELETE_ADDITIONAL_ITEM_SUCCESS:
            return {
                ...state,
                parsedData: { ...action.payload.data, idling: state.parsedData.idling ? true : false }
            }
        case types.SEARCH_CATALOG_SUCCESS:
            return {
                ...state,
                catalogItems: action.payload.data
            }
        case types.SEARCH_SUPPLIERS_SUCCESS:
            return {
                ...state,
                suppliers: action.payload.data
            }
        case types.GET_PURCHASE_SUCCESS:
            return {
                ...state,
                preliminaryPurchase: action.payload.data
            }
        case types.GET_PURCHASES_SUCCESS:
            return {
                ...state,
                preliminaryPurchases: action.payload.data
            }

        case types.GET_PURCHASE_ERROR:
            return {
                ...state,
                calculation_error: action.error.response
            }
        case types.GET_PURCHASES_ERROR:
            return {
                ...state,
                calculation_error: action.error.response
            }
        case types.POST_EXCEL_FAIL:
            return {
                ...state,
                calculation_error: action.error.response ? action.error.response.data.import_file[0] : action.error.data
            }

        case types.POST_BUY_ITEM_FAIL:
            return {
                ...state,
                calculation_error: action.error.response.data.catalog_item
            }

        case types.POST_CATALOG_ITEM_FAIL:
            let temp_error = ""
            if (action.error.response.data.name) {
                temp_error = action.error.response.data.name
            } else if (action.error.response.data.price) {
                temp_error = action.error.response.data.price
            } else if (action.error.response.data.supplier_name) {
                temp_error = action.error.response.data.supplier_name
            } else if (action.error.response.data.unit) {
                temp_error = action.error.response.data.unit
            }
            return {
                ...state,
                calculation_error: temp_error
            }

        case types.POST_SUPPLIER_FAIL:
            return {
                ...state,
                calculation_error: action.error.response.data.name
            }

        case types.POST_ADDITIONAL_ITEM_FAIL:
            let temp_additional_error = ""
            if (action.error.response.data.name) {
                temp_additional_error = action.error.response.data.name
            } else if (action.error.response.data.total_price) {
                temp_additional_error = action.error.response.data.total_price
            } else if (action.error.response.data.comment) {
                temp_additional_error = action.error.response.data.comment
            }
            return {
                ...state,
                calculation_error: temp_additional_error
            }

        case types.PATCH_BUY_ITEM_FAIL:
            let temp_patch_error = ""
            if (action.error.response.data.comment) {
                temp_patch_error = action.error.response.data.comment
            } else if (action.error.response.data.unit) {
                temp_patch_error = action.error.response.data.unit
            } else if (action.error.response.data.amount) {
                temp_patch_error = action.error.response.data.amount
            }
            return {
                ...state,
                calculation_error: temp_patch_error
            }

        case types.RESET_FILE_ERROR:
            return {
                ...state,
                calculation_error: ""
            }
        default:
            return state
    }
}
