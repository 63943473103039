import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { postNewCategory } from "../../../actions/categoriesActions"
import { addCharacteristic } from "../../../actions/productsActions"
import DialogComponent from "../../HelperComponents/DialogComponent/DialogComponent"
import DefaultButton from "../../HelperComponents/Buttons/DefaultButton/DefaultButton"
import { Field, reduxForm, SubmissionError } from "redux-form"
import RenderField from "../../HelperComponents/RenderField/RenderField"

class ProductUpdateDialogs extends Component {
    constructor(props) {
        super(props)
        this.state = {
            reloading: false,
            item_chars: []
        }
    }

    componentDidMount() {
        const { chars_array, chosen_characteristics } = this.props
        const { item_chars } = this.state
        let arr = [...item_chars]
        if (this.props.match.params.id) {
            chars_array.forEach(char =>
                chosen_characteristics.forEach(el =>
                    el.slice(1).includes(char.char.name)
                        ? arr.push({
                              char: char.char.id,
                              char_name: char.char.name,
                              char_unit: char.char.unit,
                              value: char.value
                          })
                        : null
                )
            )
        }
        this.setState({ item_chars: [...arr] })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.chosen_characteristics !== this.props.chosen_characteristics) {
            if (this.props.chosen_characteristics.length < 1) {
                this.setState({ item_chars: [] })
            }
        }
        if (prevState.item_chars !== this.state.item_chars) {
            this.setState({ charsChanged: false })
        }
        if (
            prevProps.openCategoriesDialog !== this.props.openCategoriesDialog ||
            prevProps.openAddCharDialog !== this.props.openAddCharDialog
        ) {
            this.props.reset("ProductUpdateDialogs")
        }
    }

    submitNewCategory = async data => {
        const { toggleDialog, doRequest, postNewCategory } = this.props
        this.setState({ reloading: true })
        return postNewCategory(data).then(res => {
            this.setState({ reloading: false })
            if (res.payload) {
                toggleDialog("categories")
                doRequest()
            } else {
                throw new SubmissionError({
                    ...res.error.response.data,
                    _error: res.error.response.data.detail
                })
            }
        })
    }

    submitNewCharacteristics = async data => {
        const { toggleDialog, doRequest, addCharacteristic } = this.props
        this.setState({ reloading: true })
        await addCharacteristic(data)
        this.setState({ reloading: false })
        toggleDialog("addCharacteristics")
        doRequest()
    }

    handleChar = (e, char) => {
        // const pattern = /^\d*(\.?)\d*$/g
        // const allowedCodes = [8, 9, 27, 35, 36, 37, 38, 39, 46, 110, 188]

        // if (!(pattern.test(e.target.value) || allowedCodes.some(code => code === e.keyCode))) {
        //     return e.target.value.slice(0, -1)
        // }
        const { item_chars } = this.state
        let arr = [...item_chars]
        if (arr.length === 0) {
            arr.push({ value: e.target.value, char: char.id, char_name: char.name, char_unit: char.unit })
        } else {
            if (arr.some(el => el.char === char.id)) {
                for (let i = 0; i < arr.length; i++) {
                    if (arr[i].char === char.id) {
                        if (e.target.value.length !== 0) {
                            arr[i].value = e.target.value
                        } else {
                            // delete arr[i]
                            arr[i].value = ""
                        }
                    }
                }
            } else {
                arr.push({ value: e.target.value, char: char.id, char_name: char.name, char_unit: char.unit })
            }
        }
        this.setState({ item_chars: [...arr] })
    }

    pickChars = async converted_characteristics => {
        const { setCharacteristics, charsIsOpen, toggleDialog, reset, characteristic_data } = this.props
        const { item_chars } = this.state
        this.setState({ reloading: true })

        let chars = []

        let newArr = item_chars.filter(el => converted_characteristics.some(elem => el.char_name === elem.name))
        newArr.forEach(char => chars.push(`z${char.char_name} ${char.value}${char.char_unit}`))

        await (setCharacteristics(chars), charsIsOpen(false), characteristic_data(newArr))
        this.setState({ reloading: false, item_chars: newArr })
        reset()
        toggleDialog("characteristics")
    }

    render() {
        const {
            handleSubmit,
            toggleDialog,
            openCategoriesDialog,
            openCharacteristicsDialog,
            openAddCharDialog,
            submitting,
            pristine,
            valid,
            chosen_characteristics,
            characteristicsList
        } = this.props
        const { reloading, item_chars } = this.state
        let converted_characteristics = []
        characteristicsList.forEach(char =>
            chosen_characteristics.forEach(el =>
                el.slice(1).includes(char.name) ? converted_characteristics.push(char) : null
            )
        )

        return (
            <Fragment>
                <DialogComponent open={openCategoriesDialog} onClose={() => toggleDialog("categories")}>
                    <div className="dashboard_dialog no_select">
                        <h2>Добавить категорию</h2>
                        <form onSubmit={handleSubmit(this.submitNewCategory)} className="dialog_description">
                            <Field name="name" type="text" label="Наименование" component={RenderField} />
                            <div className="dialog_buttons">
                                <DefaultButton
                                    variant="outlined"
                                    onClick={() => toggleDialog("categories")}
                                    classes="cancel_btn"
                                >
                                    Отмена
                                </DefaultButton>
                                <DefaultButton
                                    variant="contained"
                                    classes="confirm_btn"
                                    loading={reloading}
                                    disabled={submitting || pristine || !valid}
                                    formAction
                                >
                                    Добавить
                                </DefaultButton>
                            </div>
                        </form>
                    </div>
                </DialogComponent>
                <DialogComponent open={openAddCharDialog} onClose={() => toggleDialog("addCharacteristics")}>
                    <div className="dashboard_dialog no_select">
                        <h2>Добавить характеристику</h2>
                        <form onSubmit={handleSubmit(this.submitNewCharacteristics)} className="dialog_description">
                            <Field name="name" type="text" label="Наименование" component={RenderField} />
                            <Field name="unit" type="text" label="Ед. измерения" component={RenderField} />
                            <div className="dialog_buttons">
                                <DefaultButton
                                    variant="outlined"
                                    onClick={() => toggleDialog("addCharacteristics")}
                                    classes="cancel_btn"
                                >
                                    Отмена
                                </DefaultButton>
                                <DefaultButton
                                    variant="contained"
                                    classes="confirm_btn"
                                    loading={reloading}
                                    disabled={submitting || pristine || !valid}
                                    formAction
                                >
                                    Добавить
                                </DefaultButton>
                            </div>
                        </form>
                    </div>
                </DialogComponent>
                <DialogComponent open={openCharacteristicsDialog} onClose={() => toggleDialog("characteristics")}>
                    <div className="dashboard_dialog no_select">
                        <h2 style={{ marginBottom: "40px" }}>
                            Добавить характеристик{converted_characteristics.length === 1 ? "у" : "и"}
                        </h2>
                        {converted_characteristics.map(char => (
                            <div key={char.id} className="dialog_description chars">
                                {char.name}{" "}
                                <input
                                    id={char.id}
                                    value={
                                        item_chars.filter(item => item.char_name === char.name)[0]
                                            ? item_chars.filter(item => item.char_name === char.name)[0].value
                                            : ""
                                    }
                                    onChange={e => this.handleChar(e, char)}
                                    name={`${char.name}`}
                                    type="text"
                                    className="char_input"
                                    autoComplete="off"
                                />{" "}
                                <span>{char.unit}</span>
                            </div>
                        ))}
                        <div className="dialog_buttons">
                            <DefaultButton
                                variant="outlined"
                                onClick={() => toggleDialog("characteristics")}
                                classes="cancel_btn"
                            >
                                Отмена
                            </DefaultButton>
                            <DefaultButton
                                variant="contained"
                                onClick={() => {
                                    this.pickChars(converted_characteristics)
                                    toggleDialog("close")
                                }}
                                classes="confirm_btn"
                                loading={reloading}
                                disabled={
                                    item_chars.length < 1 ||
                                    item_chars.length !== converted_characteristics.length ||
                                    item_chars.some(el => el.value === "")
                                }
                            >
                                Подтвердить
                            </DefaultButton>
                        </div>
                    </div>
                </DialogComponent>
            </Fragment>
        )
    }
}

const validate = values => {
    const errors = {}

    return errors
}

ProductUpdateDialogs = reduxForm({
    form: "ProductUpdateDialogs",
    validate
})(ProductUpdateDialogs)

const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = {
    postNewCategory,
    addCharacteristic
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductUpdateDialogs))
