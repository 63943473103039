import * as types from "./constants.jsx";

export function getShipment(id) {
    return {
        type: types.GET_SHIPMENT,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/${id}/calculation/delivery/`,
                method: "GET"
            }
        }
    };
}

export function createShipment(id, data) {
    return {
        type: types.CREATE_SHIPMENT,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/${id}/calculation/delivery/`,
                method: "POST",
                data
            }
        }
    };
}

export function deleteShipment(id, shipId) {
    return {
        type: types.DELETE_SHIPMENT,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/${id}/calculation/delivery/${shipId}/`,
                method: "DELETE"
            }
        }
    };
}

export function changeShipment(id, shipId, data) {
    return {
        type: types.CHANGE_SHIPMENT,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/${id}/calculation/delivery/${shipId}/`,
                method: "PATCH",
                data
            }
        }
    };
}

export function getSingleShipment(data) {
    return {
        type: types.GET_SINGLE_SHIPMENT,
        payload: {
            data
        }
    };
}

export function getShipmentReports(id, shipId) {
    return {
        type: types.GET_SHIPMENT_REPORTS,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/${id}/calculation/delivery/${shipId}/accounts/`,
                method: "GET"
            }
        }
    };
}

export function createShipmentReport(id, shipId, data) {
    return {
        type: types.CREATE_SHIPMENT_REPORT,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/${id}/calculation/delivery/${shipId}/accounts/`,
                method: "POST",
                data
            }
        }
    };
}

export function sendMail(id, data) {
    return {
        type: types.SEND_MAIL,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/${id}/calculation/delivery/send-mail/`,
                method: "POST",
                data
            }
        }
    };
}

export function deleteShipmentReport(id, shipId, reportId) {
    return {
        type: types.DELETE_SHIPMENT_REPORT,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/${id}/calculation/delivery/${shipId}/accounts/${reportId}/`,
                method: "DELETE"
            }
        }
    };
}

export function changeShipmentReport(id, shipId, data) {
    return {
        type: types.CHANGE_SHIPMENT_REPORT,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/${id}/calculation/delivery/${shipId}/accounts/`,
                method: "PATCH",
                data
            }
        }
    };
}

export function getReportData(id, shipId, reportId) {
    return {
        type: types.GET_REPORT_DATA,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/${id}/calculation/delivery/${shipId}/accounts/${reportId}/`,
                method: "GET"
            }
        }
    };
}

export function changeAccountShipment(id, shipId, reportId, data) {
    return {
        type: types.CHANGE_ACCOUNT_SHIPMENT,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/${id}/calculation/delivery/${shipId}/accounts/${reportId}/`,
                method: "PATCH",
                data
            }
        }
    };
}

export function addReportData(id, shipId, reportId, data) {
    return {
        type: types.ADD_REPORT_DATA,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/${id}/calculation/delivery/${shipId}/accounts/${reportId}/add-items/`,
                method: "POST",
                data
            }
        }
    };
}

export function changeReportData(id, shipId, reportId, data) {
    return {
        type: types.CHANGE_REPORT_DATA,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/${id}/calculation/delivery/${shipId}/accounts/${reportId}/edit-items/`,
                method: "PATCH",
                data
            }
        }
    };
}

export function deleteReportData(id, shipId, reportId, data) {
    return {
        type: types.DELETE_REPORT_DATA,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/${id}/calculation/delivery/${shipId}/accounts/${reportId}/edit-items/`,
                method: "DELETE",
                data
            }
        }
    };
}
