import React from "react";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ruLocale from "date-fns/locale/ru";

import "./RenderDatePicker.scss";
import moment from "moment";

const DateTimeField = props => {
    const {
        meta: { submitting, error, touched },
        input: { onBlur, value, ...inputProps },
        ...others
    } = props;

    const onChange = date => {
        moment(date) ? inputProps.onChange(moment(date)) : inputProps.onChange(null);
    };
    return (
        <div className="datepicker">
            <MuiPickersUtilsProvider locale={ruLocale} utils={DateFnsUtils}>
                <KeyboardDatePicker
                    {...inputProps}
                    {...others}
                    ampm={false}
                    variant="dialog"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    okLabel="ОК"
                    cancelLabel="ОТМЕНА"
                    value={value ? new Date(value) : null}
                    disabled={submitting}
                    onBlur={() => onBlur(value ? moment(value) : null)}
                    onChange={onChange}
                    KeyboardButtonProps={{
                        "aria-label": "change date"
                    }}
                />
            </MuiPickersUtilsProvider>
            {touched && !!error && <span className="error_text">{error}</span>}
        </div>
    );
};

export default DateTimeField;
