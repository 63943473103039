import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import { Collapse } from "react-collapse"
import { Link } from "react-router-dom"
import clsx from "clsx"
import LinearProgress from "@material-ui/core/LinearProgress"
import Checkbox from "@material-ui/core/Checkbox"
import moment from "moment"
import ClickAwayListener from "@material-ui/core/ClickAwayListener/ClickAwayListener"

import DefaultButton from "../HelperComponents/Buttons/DefaultButton/DefaultButton"
import DialogComponent from "../HelperComponents/DialogComponent/DialogComponent"
import PopperInfo from "../HelperComponents/PopperInfo/PopperInfo"
import DealsInProgressControls from "./DealsInProgressControls"
import DealsInProgressDialogs from "./DealsInProgressDialogs"
// import Snack from "../HelperComponents/Snack/Snack"
import Pagination from "../HelperComponents/Pagination/Pagination"
import ManagerSelector from "../HelperComponents/ManagerSelector/ManagerSelector"
import {
    formatNumber,
    translateDealsStatuses,
    translateExecutionStatuses,
    translateImportance
} from "../../helpers/functions"

import {
    getActiveDeals,
    getUsers,
    getManagers,
    addSelectedActiveDeal,
    removeSelectedActiveDeal,
    clearSelectedActiveDeals,
    bulkUpdateTenders,
    setActiveDealsPage,
    setActiveDealsOrder
} from "../../actions/dashboardActions"

import { postCalculation, getOwners, getAllOwners } from "../../actions/dealsActions"

import UpdateIcon from "../../assets/image/update.svg"
import ImportantIcon from "../../assets/image/important.svg"
import SortIcon from "../../assets/image/sort.svg"
import ExpandIcon from "../../assets/image/expand.svg"
import MoreControlsIcon from "../../assets/image/more_icon.svg"
import CompleteIcon from "../../assets/image/complete.svg"
import FailedIcon from "../../assets/image/failed.svg"
import UncheckIcon from "../../assets/image/uncheck_all.svg"
import AttentionIcon from "../../assets/image/attention.svg"

import "./DealsInProgress.scss"
import GenerateXLSDialog from "../GenerateXLSDialog/GenerateXLSDialog"

import { getPurchases } from "../../actions/calculationActions"

class DealsInProgress extends Component {
    constructor(props) {
        super(props)

        //getting filters from url
        let params = new URLSearchParams(decodeURIComponent(props.location.search.substring(1)))
        let chosen_deals_statuses = [],
            chosen_execution_status = params.get("chosen_execution_status")
                ? params.get("chosen_execution_status")
                : "",
            chosen_importance = params.get("chosen_importance") ? params.get("chosen_importance") : "",
            chosen_connected_managers = [],
            chosen_responsible_managers = []
        if (params.get("chosen_deals_statuses")) {
            params
                .get("chosen_deals_statuses")
                .split(",")
                .forEach(el => chosen_deals_statuses.push(el))
            // chosen_deals_statuses.push(decodeURIComponent(params.get("chosen_deals_statuses")).split(','))
        }
        if (params.get("chosen_connected_managers")) {
            params
                .get("chosen_connected_managers")
                .split(",")
                .forEach(el => chosen_connected_managers.push(el))
        }
        if (params.get("chosen_responsible_managers")) {
            params
                .get("chosen_responsible_managers")
                .split(",")
                .forEach(el => chosen_responsible_managers.push(el))
        }
        this.state = {
            loading: true,
            reloading: true,
            selectedElements: [],
            openControls: [],
            currentTender: null,
            responsibleSelector: null,
            managerSelector: null,
            openDeleteDialog: false,
            openBulkDeleteDialog: false,
            openToCheckDialog: false,
            openApproveDialog: false,
            openToFixDialog: false,
            openDeclineDialog: false,
            openPostDialog: false,
            openWonDialog: false,
            openLostDialog: false,
            openSignedDialog: false,
            openExecuteDialog: false,
            openExecutedDialog: false,
            openClosedDialog: false,
            openResponsibleDialog: false,
            openConnectDialog: false,
            inputValue: "",
            responsible: "",
            chosenManager: {},
            anchor: null,
            popperOpen: false,
            is_bot_involved: false,
            //selectors values
            chosen_deals_statuses,
            chosen_execution_status,
            chosen_importance,
            chosen_connected_managers,
            chosen_responsible_managers,
            selectedAll: false,
            preliminaryPurchases: []
        }
    }

    componentDidMount() {
        const { getUsers, getOwners, getAllOwners } = this.props
        getUsers().then(() => {
            getAllOwners()
            getOwners().then(() => {
                this.doRequest().then(() => {
                    this.setState({ loading: false })
                })
            })
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.order !== this.props.order) this.doRequest()
        if (prevProps.page !== this.props.page) this.doRequest()
        if (prevState.inputValue !== this.state.inputValue) {
            this.props.setActiveDealsPage(1)
            setTimeout(() => {
                this.doRequest()
            }, 0)
        }
        if (
            JSON.stringify(prevState.chosen_deals_statuses) !== JSON.stringify(this.state.chosen_deals_statuses) ||
            JSON.stringify(prevState.chosen_execution_status) !== JSON.stringify(this.state.chosen_execution_status) ||
            JSON.stringify(prevState.chosen_connected_managers) !==
                JSON.stringify(this.state.chosen_connected_managers) ||
            JSON.stringify(prevState.chosen_responsible_managers) !==
                JSON.stringify(this.state.chosen_responsible_managers) ||
            JSON.stringify(prevState.chosen_importance) !== JSON.stringify(this.state.chosen_importance)
        ) {
            this.props.setActiveDealsPage(1)
            setTimeout(() => {
                this.doRequest()
            }, 0)
        }
    }

    doRequest = async () => {
        const { getActiveDeals, page, order, users } = this.props
        const {
            inputValue,
            chosen_deals_statuses,
            chosen_execution_status,
            chosen_connected_managers,
            chosen_responsible_managers,
            chosen_importance
        } = this.state
        this.setState({ reloading: true })
        await getActiveDeals(
            page,
            order,
            inputValue,
            translateDealsStatuses(chosen_deals_statuses),
            translateExecutionStatuses(chosen_execution_status),
            chosen_connected_managers.map(manager_name => users.find(user => `z${user.fio}` === manager_name).id),
            chosen_responsible_managers.map(manager_name => users.find(user => `z${user.fio}` === manager_name).id),
            translateImportance(chosen_importance)
        )

        // Вызов данных по предрасчетам
        await this.props.getPurchases()
        await this.setState({preliminaryPurchases: this.props.preliminaryPurchases.length > 0 ? this.props.preliminaryPurchases : null})

        this.setState({ reloading: false })
    }

    // comment
    commentFind = (id) => {
    const preliminaryPurchases = this.state.preliminaryPurchases || [];
    const findItem = preliminaryPurchases.find((item) => item.tender_id === id);

    if (findItem && findItem.preliminary_sale_comment) {
        return findItem.preliminary_sale_comment;
    }

    return null;
};

    //expanded selecting
    handleSelected = id => {
        const { selectedElements } = this.state
        if (selectedElements.includes(id)) {
            this.setState({
                selectedElements: selectedElements.filter(el => el !== id)
            })
        } else {
            this.setState({ selectedElements: [...selectedElements, id] })
        }
    }

    //dialogs handler
    toggleDialog = type => {
        type === "delete" &&
            this.setState(({ openDeleteDialog }) => ({
                openDeleteDialog: !openDeleteDialog
            }))
        type === "to_check" &&
            this.setState(({ openToCheckDialog }) => ({
                openToCheckDialog: !openToCheckDialog
            }))
        type === "approve" &&
            this.setState(({ openApproveDialog }) => ({
                openApproveDialog: !openApproveDialog
            }))
        type === "to_fix" &&
            this.setState(({ openToFixDialog }) => ({
                openToFixDialog: !openToFixDialog
            }))
        type === "decline" &&
            this.setState(({ openDeclineDialog }) => ({
                openDeclineDialog: !openDeclineDialog
            }))
        type === "post" &&
            this.setState(({ openPostDialog }) => ({
                openPostDialog: !openPostDialog
            }))
        type === "won" &&
            this.setState(({ openWonDialog }) => ({
                openWonDialog: !openWonDialog
            }))
        type === "lost" &&
            this.setState(({ openLostDialog }) => ({
                openLostDialog: !openLostDialog
            }))
        type === "sign" &&
            this.setState(({ openSignedDialog }) => ({
                openSignedDialog: !openSignedDialog
            }))
        type === "to_execute" &&
            this.setState(({ openExecuteDialog }) => ({
                openExecuteDialog: !openExecuteDialog
            }))
        type === "executed" &&
            this.setState(({ openExecutedDialog }) => ({
                openExecutedDialog: !openExecutedDialog
            }))
        type === "closed" &&
            this.setState(({ openClosedDialog }) => ({
                openClosedDialog: !openClosedDialog
            }))
        type === "bulk-delete" &&
            this.setState(({ openBulkDeleteDialog }) => ({
                openBulkDeleteDialog: !openBulkDeleteDialog
            }))
        type === "set-responsible" &&
            this.setState(({ openResponsibleDialog }) => ({
                openResponsibleDialog: !openResponsibleDialog
            }))
        type === "set-connected" &&
            this.setState(({ openConnectDialog }) => ({
                openConnectDialog: !openConnectDialog
            }))
    }

    deleteTenders = async () => {
        const { bulkUpdateTenders, clearSelectedActiveDeals } = this.props
        this.setState({ reloading: true })
        const data = { ids: this.props.selected, data: { status: "deleted" } }
        await bulkUpdateTenders(data)
        this.doRequest()
        clearSelectedActiveDeals()
        this.toggleDialog("bulk-delete")
    }

    setResponsible = async () => {
        const { bulkUpdateTenders, clearSelectedActiveDeals } = this.props
        const { chosenManager } = this.state
        this.setState({ reloading: true })
        const data = {
            ids: this.props.selected,
            data: {
                responsible: chosenManager.id
            }
        }
        await bulkUpdateTenders(data)
        this.doRequest()
        this.setState({ chosenManager: {} })
        clearSelectedActiveDeals()
        this.toggleDialog("set-responsible")
    }

    setConnected = async () => {
        const { bulkUpdateTenders, clearSelectedActiveDeals } = this.props
        const { chosenManager } = this.state
        this.setState({ reloading: true })
        const data = {
            ids: this.props.selected,
            data: {
                manager: chosenManager.id
            }
        }
        await bulkUpdateTenders(data)
        this.doRequest()
        this.setState({ chosenManager: {} })
        clearSelectedActiveDeals()
        this.toggleDialog("set-connected")
    }

    //deals actions
    handleControlsOpening = id => {
        const { openControls } = this.state
        if (openControls.includes(id)) {
            this.setState({
                openControls: openControls.filter(el => el !== id)
            })
        } else {
            this.setState({ openControls: [...openControls, id] })
        }
    }

    getActions = (status, id, execution_status, is_bot_involved) => {
        const {
            options: {
                me: { role }
            }
        } = this.props

        if (status === "Расчёт") {
            if (role === "admin") {
                return (
                    <div className="deals_actions_popper" onClick={() => this.handleControlsOpening(id)}>
                        <div className="text_hover" onClick={() => this.toggleDialog("to_check")}>
                            На проверку
                        </div>
                        <div className="text_hover red_text" onClick={() => this.toggleDialog("delete")}>
                            Удалить
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className="deals_actions_popper" onClick={() => this.handleControlsOpening(id)}>
                        <div className="text_hover" onClick={() => this.toggleDialog("to_check")}>
                            На проверку
                        </div>
                    </div>
                )
            }
        } else if (status === "На проверке") {
            if (role === "admin") {
                return (
                    <div className="deals_actions_popper" onClick={() => this.handleControlsOpening(id)}>
                        <div className="text_hover" onClick={() => this.toggleDialog("approve")}>
                            Одобрить
                        </div>
                        <div className="text_hover" onClick={() => this.toggleDialog("to_fix")}>
                            На доработку
                        </div>
                        <div className="text_hover" onClick={() => this.toggleDialog("decline")}>
                            Отказать
                        </div>
                        <div className="text_hover red_text" onClick={() => this.toggleDialog("delete")}>
                            Удалить
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className="deals_actions_popper" onClick={() => this.handleControlsOpening(id)}>
                        Нет доступных Вам действий
                    </div>
                )
            }
        } else if (status === "Отказано") {
            if (role === "admin") {
                return (
                    <div className="deals_actions_popper" onClick={() => this.handleControlsOpening(id)}>
                        <div className="text_hover red_text" onClick={() => this.toggleDialog("delete")}>
                            Удалить
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className="deals_actions_popper" onClick={() => this.handleControlsOpening(id)}>
                        Нет доступных Вам действий
                    </div>
                )
            }
        } else if (status === "Одобрена") {
            if (role === "admin") {
                return (
                    <div className="deals_actions_popper" onClick={() => this.handleControlsOpening(id)}>
                        <div
                            className="text_hover red_text"
                            onClick={() => {
                                this.setState({ is_bot_involved })
                                this.toggleDialog("post")
                            }}
                        >
                            Разместить
                        </div>
                        <div className="text_hover red_text" onClick={() => this.toggleDialog("delete")}>
                            Удалить
                        </div>
                    </div>
                )
            } else if (role === "senior_manager") {
                return (
                    <div className="deals_actions_popper" onClick={() => this.handleControlsOpening(id)}>
                        <div
                            className="text_hover red_text"
                            onClick={() => {
                                this.setState({ is_bot_involved })
                                this.toggleDialog("post")
                            }}
                        >
                            Разместить
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className="deals_actions_popper" onClick={() => this.handleControlsOpening(id)}>
                        Нет доступных Вам действий
                    </div>
                )
            }
        } else if (status === "Размещена") {
            if (role === "admin") {
                return (
                    <div className="deals_actions_popper" onClick={() => this.handleControlsOpening(id)}>
                        <div className="text_hover" onClick={() => this.toggleDialog("won")}>
                            Выиграна
                        </div>
                        <div className="text_hover" onClick={() => this.toggleDialog("lost")}>
                            Проиграна
                        </div>
                        <div className="text_hover red_text" onClick={() => this.toggleDialog("delete")}>
                            Удалить
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className="deals_actions_popper" onClick={() => this.handleControlsOpening(id)}>
                        Нет доступных Вам действий
                    </div>
                )
            }
        } else if (status === "Ожидает размещения" || status === "Размещение" || status === "Ошибка размещения") {
            if (role === "admin") {
                return (
                    <div className="deals_actions_popper" onClick={() => this.handleControlsOpening(id)}>
                        <div className="text_hover red_text" onClick={() => this.toggleDialog("delete")}>
                            Удалить
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className="deals_actions_popper" onClick={() => this.handleControlsOpening(id)}>
                        Нет доступных Вам действий
                    </div>
                )
            }
        } else if (status === "Проиграна") {
            if (role === "admin") {
                return (
                    <div className="deals_actions_popper" onClick={() => this.handleControlsOpening(id)}>
                        <div className="text_hover red_text" onClick={() => this.toggleDialog("delete")}>
                            Удалить
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className="deals_actions_popper" onClick={() => this.handleControlsOpening(id)}>
                        Нет доступных Вам действий
                    </div>
                )
            }
        } else if (status === "Заключение ГК") {
            if (!execution_status) {
                if (role === "admin") {
                    return (
                        <div className="deals_actions_popper" onClick={() => this.handleControlsOpening(id)}>
                            <div className="text_hover" onClick={() => this.toggleDialog("sign")}>
                                ГК заключен
                            </div>
                            <div className="text_hover" onClick={() => this.toggleDialog("to_execute")}>
                                Исполнить сразу
                            </div>
                            <div className="text_hover red_text" onClick={() => this.toggleDialog("delete")}>
                                Удалить
                            </div>
                        </div>
                    )
                } else if (role === "senior_manager") {
                    return (
                        <div className="deals_actions_popper" onClick={() => this.handleControlsOpening(id)}>
                            <div className="text_hover" onClick={() => this.toggleDialog("sign")}>
                                ГК заключен
                            </div>
                        </div>
                    )
                } else {
                    return (
                        <div className="deals_actions_popper" onClick={() => this.handleControlsOpening(id)}>
                            Нет доступных Вам действий
                        </div>
                    )
                }
            } else {
                if (role === "admin") {
                    return (
                        <div className="deals_actions_popper" onClick={() => this.handleControlsOpening(id)}>
                            <div className="text_hover" onClick={() => this.toggleDialog("executed")}>
                                Закончить
                            </div>
                            <div className="text_hover red_text" onClick={() => this.toggleDialog("delete")}>
                                Удалить
                            </div>
                        </div>
                    )
                } else {
                    return (
                        <div className="deals_actions_popper" onClick={() => this.handleControlsOpening(id)}>
                            <div className="text_hover" onClick={() => this.toggleDialog("executed")}>
                                Закончить
                            </div>
                        </div>
                    )
                }
            }
        } else if (status === "Исполнение") {
            if (role === "admin") {
                return (
                    <div className="deals_actions_popper" onClick={() => this.handleControlsOpening(id)}>
                        <div className="text_hover" onClick={() => this.toggleDialog("executed")}>
                            Закончить
                        </div>
                        <div className="text_hover red_text" onClick={() => this.toggleDialog("delete")}>
                            Удалить
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className="deals_actions_popper" onClick={() => this.handleControlsOpening(id)}>
                        <div className="text_hover" onClick={() => this.toggleDialog("executed")}>
                            Закончить
                        </div>
                    </div>
                )
            }
        } else if (status === "Исполнена") {
            if (role === "admin") {
                return (
                    <div className="deals_actions_popper" onClick={() => this.handleControlsOpening(id)}>
                        <div className="text_hover" onClick={() => this.toggleDialog("closed")}>
                            Закрыть контракт
                        </div>
                        <div className="text_hover red_text" onClick={() => this.toggleDialog("delete")}>
                            Удалить
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className="deals_actions_popper" onClick={() => this.handleControlsOpening(id)}>
                        Нет доступных Вам действий
                    </div>
                )
            }
        } else if (status === "Контракт закрыт") {
            if (role === "admin") {
                return (
                    <div className="deals_actions_popper" onClick={() => this.handleControlsOpening(id)}>
                        <div className="text_hover red_text" onClick={() => this.toggleDialog("delete")}>
                            Удалить
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className="deals_actions_popper" onClick={() => this.handleControlsOpening(id)}>
                        Нет доступных Вам действий
                    </div>
                )
            }
        } else {
            return (
                <div className="deals_actions_popper" onClick={() => this.handleControlsOpening(id)}>
                    Нет доступных Вам действий
                </div>
            )
        }
    }

    handleManagerSelector = id => {
        const { managerSelector } = this.state
        if (managerSelector === id) {
            this.setState({ managerSelector: null })
        } else {
            this.setState({ managerSelector: id })
        }
    }

    handleResponsibleSelector = id => {
        const { responsibleSelector } = this.state
        if (responsibleSelector === id) {
            this.setState({ responsibleSelector: null })
        } else {
            this.setState({ responsibleSelector: id })
        }
    }
    //deals actions

    handleInputChange = e => this.setState({ responsible: e.target.value, chosenManager: {} })

    handleKeyUp = async e => {
        const { getManagers } = this.props
        let value = e.target.value
        if (value.length >= 1 && value.trim()) {
            const res = await getManagers(value)
            if (res.payload && res.payload.status === 200 && res.payload.data.length > 0) {
                this.setState({
                    anchor: document.getElementById("search_input"),
                    popperOpen: true
                })
            } else {
                this.clickAway()
            }
        } else {
            this.clickAway()
        }
    }

    selectElement = chosenManager => {
        this.setState({ responsible: chosenManager.fio, chosenManager })
        this.clickAway()
    }

    clickAway = () => this.setState({ popperOpen: false })

    setSort = fieldName => {
        const { order, setActiveDealsOrder } = this.props
        switch (order) {
            case fieldName:
                setActiveDealsOrder("-" + fieldName)
                break
            case "-" + fieldName:
                setActiveDealsOrder("")
                break
            default:
                setActiveDealsOrder(fieldName)
                break
        }
    }

    render() {
        const {
            setActiveDealsPage,
            active_deals,
            usersList,
            managers,
            history,
            deal_owners,
            page,
            count,
            options: {
                me: { role }
            } //admin || senior_manager || manager
        } = this.props
        const {
            loading,
            selectedElements,
            reloading,
            openControls,
            currentTender,
            responsibleSelector,
            managerSelector,
            openDeleteDialog,
            openToCheckDialog,
            openApproveDialog,
            openToFixDialog,
            openDeclineDialog,
            openPostDialog,
            openWonDialog,
            openLostDialog,
            openSignedDialog,
            openExecuteDialog,
            openExecutedDialog,
            openClosedDialog,
            openBulkDeleteDialog,
            openResponsibleDialog,
            openConnectDialog,
            responsible,
            chosenManager,
            anchor,
            popperOpen,
            chosen_deals_statuses,
            chosen_execution_status,
            chosen_importance,
            chosen_connected_managers,
            chosen_responsible_managers,
            inputValue,
            selectedAll,
            preliminaryPurchases,
        } = this.state

        if (loading) return <LinearProgress />
        const sortedDeals = active_deals
        // filtering by selectors values
        // if (chosen_deals_statuses.length > 0) {
        //     sortedDeals = sortedDeals.filter(el =>
        //         chosen_deals_statuses.some(item => item.slice(1) === el.status_display)
        //     )
        // }
        // if (chosen_execution_status && chosen_execution_status.slice(1) !== "Все") {
        //     sortedDeals = sortedDeals.filter(el =>
        //         chosen_execution_status.slice(1) === "-"
        //             ? !el.execution_status_display
        //             : el.execution_status_display === chosen_execution_status.slice(1)
        //     )
        // }
        // if (chosen_importance && chosen_importance.slice(1) !== "Все") {
        //     sortedDeals = sortedDeals.filter(el =>
        //         chosen_importance.slice(1) === "Да" ? !!el.is_important : !el.is_important
        //     )
        // }
        // if (chosen_connected_managers.length > 0) {
        //     sortedDeals = sortedDeals.filter(el =>
        //         chosen_connected_managers.some(item => item.slice(1) === el.manager_display)
        //     )
        // }
        // if (chosen_responsible_managers.length > 0) {
        //     sortedDeals = sortedDeals.filter(el =>
        //         chosen_responsible_managers.some(item => item.slice(1) === el.responsible_display)
        //     )
        // }
        //filtering by selectors values
        return (
            <Fragment>
                {reloading ? <LinearProgress /> : <hr className="loader_dummy" />}
                <div className="page_wrapper deals_in_progress_wrapper">
                    <div className="dashboard_head">
                        <h1>Сделки в работе</h1>
                        <div>{role === "admin" && <GenerateXLSDialog />}</div>
                    </div>
                    <DealsInProgressControls
                        role={role}
                        usersList={usersList}
                        chosen_deals_statuses={chosen_deals_statuses}
                        setDealsStatuses={chosen_deals_statuses => this.setState({ chosen_deals_statuses })}
                        chosen_execution_status={chosen_execution_status}
                        setExecutionStatuses={chosen_execution_status => this.setState({ chosen_execution_status })}
                        chosen_importance={chosen_importance}
                        setImportanceStatuses={chosen_importance => this.setState({ chosen_importance })}
                        chosen_connected_managers={chosen_connected_managers}
                        setConnectedManagersStatuses={chosen_connected_managers =>
                            this.setState({ chosen_connected_managers })
                        }
                        chosen_responsible_managers={chosen_responsible_managers}
                        setResponsibleManagersStatuses={chosen_responsible_managers =>
                            this.setState({ chosen_responsible_managers })
                        }
                    />
                    <div className="table_container">
                        <div className="table_header no_select">
                            <div className="table_row">
                                <div className="row_item">
                                    <Checkbox
                                        checked={selectedAll}
                                        onChange={() => {
                                            if (selectedAll) {
                                                sortedDeals &&
                                                    sortedDeals.forEach(el =>
                                                        this.props.removeSelectedActiveDeal(el.id)
                                                    )
                                                this.setState({ selectedAll: false })
                                            } else {
                                                sortedDeals &&
                                                    sortedDeals.forEach(el => this.props.addSelectedActiveDeal(el.id))
                                                this.setState({ selectedAll: true })
                                            }
                                        }}
                                        color="primary"
                                        inputProps={{ "aria-label": "primary checkbox" }}
                                    />
                                </div>
                                <div
                                    className="row_item text_hover"
                                    onClick={() => {
                                        this.setSort("number")
                                    }}
                                >
                                    <p>Номер</p>
                                    <img src={SortIcon} alt="" />
                                </div>
                                <div className="row_item">
                                    <p className="filter_by_name">
                                        <input
                                            type="text"
                                            value={inputValue}
                                            onChange={e => this.setState({ inputValue: e.target.value })}
                                            placeholder="Заказчик"
                                        />
                                    </p>
                                </div>
                                <div className="row_item">
                                    <p>Название тендера</p>
                                </div>
                                <div
                                    className="row_item text_hover"
                                    onClick={() => {
                                        this.setSort("datetime_dedline_request")
                                    }}
                                >
                                    <p>Окончание подачи заявок</p>
                                    <img src={SortIcon} alt="" />
                                </div>
                                <div className="row_item">
                                    <p>Себестоимость</p>
                                </div>
                                <div className="row_item text_hover">
                                    <p>Цена за ед.</p>
                                    <img src={SortIcon} alt="" />
                                </div>
                                <div
                                    className="row_item text_hover"
                                    onClick={() => {
                                        this.setSort("start_price_contract")
                                    }}
                                >
                                    <p>Начальная цена контракта (лота)</p>
                                    <img src={SortIcon} alt="" />
                                </div>
                                <div className="row_item">
                                    <p>Статус</p>
                                </div>
                                <div className="row_item" />
                                <div className="row_item" />
                            </div>
                        </div>
                        <div className="table_body">
                            {sortedDeals.length > 0 ? (
                                sortedDeals.map(
                                    ({
                                        id,
                                        has_columns_changed,
                                        is_important,
                                        archive_status,
                                        status_display,
                                        type,
                                        execution_status_display,
                                        manager,
                                        manager_display,
                                        responsible,
                                        responsible_display,
                                        buy_total_amount,
                                        sell_total_amount,
                                        custom_deadline,
                                        old_tender_deadline,
                                        placed_by,
                                        bot_number,
                                        is_bot_involved,
                                        attention,
                                        data: {
                                            category,
                                            customer,
                                            customer_name,
                                            date_delivery_goods,
                                            datetime_holding,
                                            datetime_dedline_request,
                                            e_place,
                                            link,
                                            name,
                                            number,
                                            reestr_number,
                                            request_provision_size,
                                            request_type,
                                            start_price_contract
                                        }
                                    }) => (
                                        <Fragment key={id}>
                                            <div
                                                className={clsx(
                                                    "table_row",
                                                    selectedElements.includes(id) && "margin_for_row"
                                                )}
                                                onClick={() => this.setState({ currentTender: id })}
                                            >
                                                <div className="row_item">
                                                    <Checkbox
                                                        checked={this.props.selected.includes(id)}
                                                        onChange={() => {
                                                            this.setState({ selectedAll: false })
                                                            if (this.props.selected.includes(id)) {
                                                                this.props.removeSelectedActiveDeal(id)
                                                            } else {
                                                                this.props.addSelectedActiveDeal(id)
                                                            }
                                                        }}
                                                        color="primary"
                                                        inputProps={{ "aria-label": "primary checkbox" }}
                                                    />
                                                </div>
                                                <div className="row_item usable_text">
                                                    <Link
                                                        to={{
                                                            pathname: `/main/deal-in-progress/${id}`,
                                                            state: { prevQueries: history.location.search }
                                                        }}
                                                    >
                                                        {number ? number : reestr_number}
                                                        {archive_status === "downloaded" ? (
                                                            <img src={CompleteIcon} alt="complete" />
                                                        ) : archive_status === "error" ? (
                                                            <img src={FailedIcon} alt="failed" />
                                                        ) : (
                                                            ""
                                                        )}
                                                    </Link>
                                                    <div>
                                                        {is_important && (
                                                            <Fragment>
                                                                <p className="">
                                                                    <img src={ImportantIcon} alt="" />
                                                                    Важная
                                                                </p>
                                                            </Fragment>
                                                        )}
                                                        {is_important && has_columns_changed && (
                                                            <span className="separator">|</span>
                                                        )}
                                                        {has_columns_changed && (
                                                            <p className="">
                                                                <img src={UpdateIcon} alt="" />
                                                                Обновлено
                                                            </p>
                                                        )}
                                                        {(is_important && has_columns_changed && attention) ||
                                                            (is_important && attention && !has_columns_changed && (
                                                                <span className="separator">|</span>
                                                            ))}
                                                        {attention && (
                                                            <p className="" style={{ color: "#E4944B" }}>
                                                                <img src={AttentionIcon} alt="" />
                                                                Внимание
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="row_item">
                                                    <span className="overflowed_text" title={customer || customer_name}>
                                                        {customer || customer_name}
                                                    </span>
                                                </div>
                                                <div className="row_item">
                                                    <span className="overflowed_text" title={name}>
                                                        {name}
                                                    </span>
                                                </div>
                                                <div
                                                    className="row_item"
                                                    style={{
                                                        display: "flex",
                                                        flexFlow: "column",
                                                        alignItems: "flex-start"
                                                    }}
                                                >
                                                    <div>
                                                        {!datetime_dedline_request || datetime_dedline_request === "-"
                                                            ? "-"
                                                            : moment(datetime_dedline_request).format("DD/MM/YY HH:mm")}
                                                        {!!old_tender_deadline && (
                                                            <img
                                                                src={ImportantIcon}
                                                                alt="important"
                                                                style={{ marginLeft: "4px" }}
                                                            />
                                                        )}
                                                    </div>
                                                    {custom_deadline && (
                                                        <div className="deadline_date">
                                                            {moment(custom_deadline).format("DD/MM/YY HH:mm")}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="row_item cost_price">
                                                    <p
                                                        className={`${
                                                            sell_total_amount &&
                                                            preliminaryPurchases.find(item => item.tender_id === id) &&
                                                            ((sell_total_amount - preliminaryPurchases.find(item => item.tender_id === id).preliminary_purchase_price) /
                                                                sell_total_amount) *
                                                            100 >
                                                            0
                                                                ? "green"
                                                                : "red"
                                                        }`}
                                                    >
                                                        {
                                                            preliminaryPurchases.find(item => item.tender_id === id)
                                                                ? formatNumber(parseFloat(preliminaryPurchases.find(item => item.tender_id === id).preliminary_purchase_price).toFixed(2))
                                                                : "-"
                                                        }
                                                    </p>
                                                    {/*<p*/}
                                                    {/*    className={`${*/}
                                                    {/*        sell_total_amount &&*/}
                                                    {/*        buy_total_amount &&*/}
                                                    {/*        ((sell_total_amount - buy_total_amount) /*/}
                                                    {/*            sell_total_amount) **/}
                                                    {/*        100 >*/}
                                                    {/*        0*/}
                                                    {/*            ? "green"*/}
                                                    {/*            : "red"*/}
                                                    {/*    }`}*/}
                                                    {/*>*/}
                                                        {/*{sell_total_amount && buy_total_amount*/}
                                                        {/*    ? `${formatNumber(*/}
                                                        {/*          parseFloat(*/}
                                                        {/*              ((sell_total_amount - buy_total_amount) /*/}
                                                        {/*                  sell_total_amount) **/}
                                                        {/*                  100*/}
                                                        {/*          ).toFixed(2)*/}
                                                        {/*      )}%`*/}
                                                        {/*    : ""}*/}
                                                    {/*</p>*/}
                                                </div>
                                                <div className="row_item">
                                                    {sell_total_amount
                                                        ? formatNumber(parseFloat(sell_total_amount).toFixed(2))
                                                        : "-"}
                                                </div>
                                                <div className="row_item">
                                                    {start_price_contract
                                                        ? formatNumber(parseFloat(start_price_contract).toFixed(2))
                                                        : "-"}
                                                </div>
                                                <div
                                                    className="row_item"
                                                    style={{
                                                        display: "flex",
                                                        flexFlow: "column",
                                                        alignItems: "flex-start"
                                                    }}
                                                >
                                                    <div
                                                        style={
                                                            !placed_by && status_display === "Ожидает размещения"
                                                                ? { color: "#E4944B" }
                                                                : {}
                                                        }
                                                    >
                                                        {status_display}
                                                    </div>
                                                    {(status_display === "Размещена" ||
                                                        status_display === "Ожидает размещения" ||
                                                        status_display === "Размещение" ||
                                                        status_display === "Ошибка размещения") && (
                                                        <p
                                                            className="red_text_with_overflow"
                                                            title={
                                                                placed_by
                                                                    ? deal_owners.find(el => el.id === placed_by) &&
                                                                      deal_owners.find(el => el.id === placed_by).name
                                                                    : ""
                                                            }
                                                        >
                                                            {placed_by
                                                                ? deal_owners.find(el => el.id === placed_by) &&
                                                                  deal_owners.find(el => el.id === placed_by).name
                                                                : ""}
                                                        </p>
                                                    )}
                                                    {status_display === "Размещена" && (
                                                        <p className="red_text_with_overflow" title={bot_number}>
                                                            {bot_number}
                                                        </p>
                                                    )}
                                                </div>
                                                <div className="row_item no_select">
                                                    <p
                                                        className="text_hover"
                                                        onClick={() => this.handleControlsOpening(id)}
                                                    >
                                                        <img src={MoreControlsIcon} alt="replay" />
                                                    </p>
                                                    {openControls.includes(id) && (
                                                        <ClickAwayListener
                                                            onClickAway={() => this.handleControlsOpening(id)}
                                                        >
                                                            {this.getActions(
                                                                status_display,
                                                                id,
                                                                execution_status_display,
                                                                is_bot_involved
                                                            )}
                                                        </ClickAwayListener>
                                                    )}
                                                </div>
                                                <div
                                                    className="row_item no_select"
                                                    onClick={() => this.handleSelected(id)}
                                                >
                                                    <p className="text_hover">
                                                        <img
                                                            src={ExpandIcon}
                                                            alt=">"
                                                            className={clsx(
                                                                selectedElements.includes(id) && "transform_rotate"
                                                            )}
                                                        />
                                                    </p>
                                                </div>
                                            </div>
                                            <Collapse isOpened={selectedElements.includes(id)}>
                                                <div className="collapsed_block">
                                                    <div>
                                                        <div>
                                                            <span>Тип тендера</span>
                                                            <p title={request_type}>{request_type}</p>
                                                        </div>
                                                        <div>
                                                            <span>Категория</span>
                                                            <p>{category}</p>
                                                        </div>
                                                        <div>
                                                            <span>Сайт</span>
                                                            <p>
                                                                {link ? (
                                                                    <a
                                                                        className="text_hover"
                                                                        href={
                                                                            link.includes("http") ? link : `//${link}`
                                                                        }
                                                                        rel="noopener noreferrer"
                                                                        target="_blank"
                                                                    >
                                                                        {link}
                                                                    </a>
                                                                ) : (
                                                                    "-"
                                                                )}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div>
                                                            <span>Размер обеспечения заявки</span>
                                                            <p>
                                                                {request_provision_size
                                                                    ? formatNumber(
                                                                          parseFloat(
                                                                              request_provision_size
                                                                                  .toString()
                                                                                  .replace(",", ".")
                                                                          )
                                                                      )
                                                                    : "-"}
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <span>Размер обеспечения контракта</span>
                                                            <p>-</p>
                                                        </div>
                                                        <div>
                                                            <span>Электронная площадка</span>
                                                            <p>
                                                                {e_place ? (
                                                                    <a
                                                                        className="text_hover"
                                                                        href={
                                                                            e_place.includes("http")
                                                                                ? e_place
                                                                                : `//${e_place}`
                                                                        }
                                                                        rel="noopener noreferrer"
                                                                        target="_blank"
                                                                    >
                                                                        {e_place}
                                                                    </a>
                                                                ) : (
                                                                    "-"
                                                                )}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div>
                                                            <span>Сроки выполнения</span>
                                                            <p>{date_delivery_goods || "-"}</p>
                                                        </div>
                                                        <div>
                                                            <span>Время проведения</span>
                                                            <p>
                                                                {datetime_holding === "-"
                                                                    ? datetime_holding
                                                                    : moment(datetime_holding).format("DD/MM/YY HH:mm")}
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <span>Комментарий</span>
                                                            <p>
                                                                {
                                                                    this.commentFind(id)
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="relative_div no_overflow">
                                                            <span>Привязанный</span>
                                                            {role === "admin" ? (
                                                                <Fragment>
                                                                    <p
                                                                        onClick={() => this.handleManagerSelector(id)}
                                                                        className="text_hover"
                                                                    >
                                                                        {manager_display || "Не назначен"}
                                                                        <img
                                                                            src={ExpandIcon}
                                                                            alt=">"
                                                                            className={clsx(
                                                                                "text_hover",
                                                                                managerSelector === id &&
                                                                                    "transform_rotate"
                                                                            )}
                                                                        />
                                                                    </p>
                                                                    {managerSelector === id && (
                                                                        <ClickAwayListener
                                                                            onClickAway={() =>
                                                                                this.handleManagerSelector(id)
                                                                            }
                                                                        >
                                                                            <ManagerSelector
                                                                                currentTender={currentTender}
                                                                                responsible={false}
                                                                                manager={manager}
                                                                                doRequest={this.doRequest}
                                                                                handleManagerSelector={
                                                                                    this.handleManagerSelector
                                                                                }
                                                                            />
                                                                        </ClickAwayListener>
                                                                    )}
                                                                </Fragment>
                                                            ) : (
                                                                <p>{manager_display || "Не назначен"}</p>
                                                            )}
                                                        </div>
                                                        <div className="relative_div no_overflow">
                                                            <span>Ответственный</span>
                                                            {role === "admin" ? (
                                                                <Fragment>
                                                                    <p
                                                                        onClick={() =>
                                                                            this.handleResponsibleSelector(id)
                                                                        }
                                                                        className="text_hover"
                                                                    >
                                                                        {responsible_display || "Не назначен"}
                                                                        <img
                                                                            src={ExpandIcon}
                                                                            alt=">"
                                                                            className={clsx(
                                                                                "text_hover",
                                                                                responsibleSelector === id &&
                                                                                    "transform_rotate"
                                                                            )}
                                                                        />
                                                                    </p>
                                                                    {responsibleSelector === id && (
                                                                        <ClickAwayListener
                                                                            onClickAway={() =>
                                                                                this.handleResponsibleSelector(id)
                                                                            }
                                                                        >
                                                                            <ManagerSelector
                                                                                currentTender={currentTender}
                                                                                responsible={true}
                                                                                manager={responsible}
                                                                                doRequest={this.doRequest}
                                                                                handleManagerSelector={
                                                                                    this.handleResponsibleSelector
                                                                                }
                                                                            />
                                                                        </ClickAwayListener>
                                                                    )}
                                                                </Fragment>
                                                            ) : (
                                                                <p>{responsible_display || "Не назначен"}</p>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Collapse>
                                        </Fragment>
                                    )
                                )
                            ) : (
                                <div className="table_row">Отсутствуют сделки в работе</div>
                            )}
                        </div>
                        <hr />
                    </div>

                    {count > 50 && (
                        <Pagination
                            active={page - 1}
                            pageCount={count / 50}
                            onChange={e => {
                                setActiveDealsPage(e.selected + 1)
                                window.scrollTo(0, 100)
                            }}
                        />
                    )}

                    <DealsInProgressDialogs
                        currentTender={currentTender}
                        is_bot_involved={this.state.is_bot_involved}
                        doRequest={this.doRequest}
                        toggleDialog={this.toggleDialog}
                        openDeleteDialog={openDeleteDialog}
                        openToCheckDialog={openToCheckDialog}
                        openApproveDialog={openApproveDialog}
                        openToFixDialog={openToFixDialog}
                        openDeclineDialog={openDeclineDialog}
                        openPostDialog={openPostDialog}
                        openWonDialog={openWonDialog}
                        openLostDialog={openLostDialog}
                        openSignedDialog={openSignedDialog}
                        openExecuteDialog={openExecuteDialog}
                        openExecutedDialog={openExecutedDialog}
                        openClosedDialog={openClosedDialog}
                    />
                    <DialogComponent open={openBulkDeleteDialog} onClose={() => this.toggleDialog("bulk-delete")}>
                        <div className="dashboard_dialog no_select">
                            <h2>Удалить выбранные заявки</h2>
                            <div className="dialog_description">Вы уверены, что хотите удалить эти заявки?</div>
                            <div className="dialog_buttons">
                                <DefaultButton
                                    variant="outlined"
                                    onClick={() => this.toggleDialog("bulk-delete")}
                                    classes="cancel_btn"
                                >
                                    Отмена
                                </DefaultButton>
                                <DefaultButton
                                    variant="contained"
                                    onClick={this.deleteTenders}
                                    classes="confirm_btn"
                                    loading={reloading}
                                >
                                    Удалить
                                </DefaultButton>
                            </div>
                        </div>
                    </DialogComponent>
                    <DialogComponent open={openResponsibleDialog} onClose={() => this.toggleDialog("set-responsible")}>
                        <div className="dashboard_dialog no_select">
                            <h2>Назначить ответственного</h2>

                            <div className="search_input_wrapper">
                                <input
                                    id="search_input"
                                    type="text"
                                    className="manager_input"
                                    placeholder="Введите имя ответственного"
                                    onKeyUp={this.handleKeyUp}
                                    onChange={this.handleInputChange}
                                    value={responsible}
                                    autoComplete="off"
                                />
                                <PopperInfo
                                    open={popperOpen}
                                    anchorEl={anchor}
                                    clickAway={this.clickAway}
                                    position="bottom-start"
                                    classes="manager_popper_60"
                                    transition
                                >
                                    <ul>
                                        {managers.map(el => (
                                            <li
                                                className="text_hover"
                                                onClick={() => this.selectElement(el)}
                                                key={el.id}
                                            >
                                                {el.fio}
                                            </li>
                                        ))}
                                    </ul>
                                </PopperInfo>
                            </div>

                            <div className="dialog_buttons">
                                <DefaultButton
                                    variant="outlined"
                                    onClick={() => this.toggleDialog("set-responsible")}
                                    classes="cancel_btn"
                                >
                                    Отмена
                                </DefaultButton>
                                <DefaultButton
                                    variant="contained"
                                    onClick={this.setResponsible}
                                    classes="confirm_btn"
                                    loading={reloading}
                                    disabled={!chosenManager.fio}
                                >
                                    Назначить
                                </DefaultButton>
                            </div>
                        </div>
                    </DialogComponent>
                    <DialogComponent open={openConnectDialog} onClose={() => this.toggleDialog("set-connected")}>
                        <div className="dashboard_dialog no_select">
                            <h2>Назначить привязанного</h2>

                            <div className="search_input_wrapper">
                                <input
                                    id="search_input"
                                    type="text"
                                    className="manager_input"
                                    placeholder="Введите имя привязанного"
                                    onKeyUp={this.handleKeyUp}
                                    onChange={this.handleInputChange}
                                    value={responsible}
                                    autoComplete="off"
                                />
                                <PopperInfo
                                    open={popperOpen}
                                    anchorEl={anchor}
                                    clickAway={this.clickAway}
                                    position="bottom-start"
                                    classes="manager_popper_60"
                                    transition
                                >
                                    <ul>
                                        {managers.map(el => (
                                            <li
                                                className="text_hover"
                                                onClick={() => this.selectElement(el)}
                                                key={el.id}
                                            >
                                                {el.fio}
                                            </li>
                                        ))}
                                    </ul>
                                </PopperInfo>
                            </div>

                            <div className="dialog_buttons">
                                <DefaultButton
                                    variant="outlined"
                                    onClick={() => this.toggleDialog("set-connected")}
                                    classes="cancel_btn"
                                >
                                    Отмена
                                </DefaultButton>
                                <DefaultButton
                                    variant="contained"
                                    onClick={this.setConnected}
                                    classes="confirm_btn"
                                    loading={reloading}
                                    disabled={!chosenManager.fio}
                                >
                                    Назначить
                                </DefaultButton>
                            </div>
                        </div>
                    </DialogComponent>
                </div>
                {this.props.selected.length > 0 && (
                    <div className="bulk-panel">
                        <div className="bulk-uncheck" onClick={this.props.clearSelectedActiveDeals}>
                            <img src={UncheckIcon} alt="uncheck" />
                            <span className="bulk-uncheck-text">
                                <strong>{this.props.selected.length}</strong> выбрано
                            </span>
                        </div>
                        <div className="bulk-control">
                            <button className="button-primary" onClick={() => this.toggleDialog("set-connected")}>
                                НАЗНАЧИТЬ ПРИВЯЗАННОГО
                            </button>

                            <button className="button-primary" onClick={() => this.toggleDialog("set-responsible")}>
                                НАЗНАЧИТЬ ОТВЕТСТВЕННОГО
                            </button>
                            {role === "admin" && (
                                <button className="button-delete" onClick={() => this.toggleDialog("bulk-delete")}>
                                    УДАЛИТЬ
                                </button>
                            )}
                        </div>
                        <div></div>
                    </div>
                )}
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        active_deals: state.dashboard.active_deals,
        options: state.dashboard.options,
        users: state.dashboard.users,
        usersList: state.dashboard.usersList,
        selected: state.dashboard.selectedActiveDeals,
        managers: state.dashboard.managers,
        deal_owners: state.deals.deal_owners,
        page: state.dashboard.activeDealsPage,
        order: state.dashboard.activeDealsOrder,
        count: state.dashboard.activeDealsCount,
        preliminaryPurchases: state.calculation.preliminaryPurchases,
    }
}

const mapDispatchToProps = {
    getUsers,
    getActiveDeals,
    getManagers,
    postCalculation,
    addSelectedActiveDeal,
    removeSelectedActiveDeal,
    clearSelectedActiveDeals,
    bulkUpdateTenders,
    getOwners,
    getAllOwners,
    setActiveDealsPage,
    setActiveDealsOrder,
    getPurchases
}

export default connect(mapStateToProps, mapDispatchToProps)(DealsInProgress)
