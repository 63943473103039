import * as types from "./constants.jsx"

export function getTenderTypes() {
    return {
        type: types.GET_TENDER_TYPES,
        payload: {
            client: "default",
            request: {
                url: `/options/tender-types/`,
                method: "GET"
            }
        }
    }
}

export function postCustomApplication(data) {
    return {
        type: types.POST_CUSTOM_APPLICATION,
        payload: {
            client: "default",
            request: {
                url: `/admin/tenders/manual/create/`,
                method: "POST",
                data
            }
        }
    }
}

export function patchCustomApplication(data, id) {
    return {
        type: types.PATCH_CUSTOM_APPLICATION,
        payload: {
            client: "default",
            request: {
                url: `/admin/tenders/manual/${id}/`,
                method: "PATCH",
                data
            }
        }
    }
}
