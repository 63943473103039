import * as types from "../actions/constants";

const INITIAL_STATE = {
    error_auth: ""
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.LOGIN_FAIL:
            if (action.error && action.error.response && action.error.response.data) {
                return {
                    ...state,
                    error_auth: action.error.response.data.code ? "" : action.error.response.data.error
                };
            } else {
                return {
                    ...state,
                    error_auth: 'Неизвестная ошибка'
                };
            }

        case types.RESET_ERRORS:
            return { ...state, error_auth: "" };

        default:
            return state;
    }
}


