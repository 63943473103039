import React from "react";
import clsx from "clsx";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";

import "./MultiSelectComponent.scss";
import DefaultButton from "../Buttons/DefaultButton/DefaultButton";

const MultiSelectComponent = ({
    items,
    item,
    handleChange,
    placeholder,
    handleSearch,
    resetChosenUsers,
    searchPlaceholder = false,
    maxItems = 2,
    toggleCharacteristics,
    open,
    onOpen,
    onClose,
    searchValue,
    thereIsObjects = false
}) => {
    return (
        <div className="wrapper_multiselect">
            <span className={clsx("select_placeholder", item.length > 0 && "actually_label")}>{placeholder}</span>
            <Select
                onOpen={onOpen}
                onClose={onClose}
                open={open}
                labelId="multiple-checkbox-label"
                id="multiple-checkbox"
                multiple
                value={item}
                onChange={handleChange}
                input={<Input />}
                renderValue={selected => {
                    const items = [];
                    selected.forEach(el => items.push(el.slice(1)));
                    // if (items.length <= maxItems) {
                    return items.join(", ");
                    // } else if (maxItems === 2) {
                    //     return(`${items[0]}, ${items[1]} +  ${items.length - 1} more`)
                    // } else {
                    //     return(`${items[0]} +  ${items.length - 1} more`)
                    // }
                }}
                MenuProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                    },
                    getContentAnchorEl: null
                }}
            >
                <div className="custom_mu">
                    {searchPlaceholder && (
                        <div>
                            <input
                                value={searchValue}
                                type="text"
                                onChange={handleSearch}
                                placeholder={searchPlaceholder}
                            />
                        </div>
                    )}
                    <button
                        className="btn_clear"
                        onClick={e => {
                            e.stopPropagation();
                            resetChosenUsers();
                        }}
                    >
                        zСбросить выбранное
                    </button>
                </div>
                {toggleCharacteristics && (
                    <div className="char_btn">
                        {/*<hr />*/}
                        <DefaultButton
                            disabled={item.length < 1}
                            variant="contained"
                            onClick={() => {
                                toggleCharacteristics("characteristics");
                            }}
                            classes="characteristic_btn"
                        >
                            Продолжить
                        </DefaultButton>
                    </div>
                )}
                {!thereIsObjects
                    ? items.map(name => (
                          <MenuItem key={name} value={name}>
                              <Checkbox checked={item.some(elem => elem.toString().includes(name.toString()))} />
                              <ListItemText primary={name} />
                          </MenuItem>
                      ))
                    : items.map(({ id, name }) => (
                          <MenuItem key={id} value={name}>
                              <Checkbox checked={item.some(element => element === name)} />
                              <ListItemText primary={name} />
                          </MenuItem>
                      ))}
                {toggleCharacteristics && (
                    <div>
                        <span
                            className="add_button"
                            onClick={e => {
                                e.stopPropagation();
                                toggleCharacteristics("addCharacteristics");
                            }}
                        >
                            + Добавить
                        </span>
                    </div>
                )}
            </Select>
        </div>
    );
};

export default MultiSelectComponent;
