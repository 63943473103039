import React, { Component } from "react"
import { withRouter } from "react-router-dom"

import MultiSelectComponent from "../HelperComponents/MultiSelectComponent/MultiSelectComponent"
import { products_statuses_list } from "../../helpers/functions"

import ImportIcon from "../../assets/image/file-template.svg"

import "./Products.scss"
import SelectComponent from "../HelperComponents/SelectComponent/SelectComponent"

class ProductsControls extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchSuppliers: "",
            searchCategories: "",
            inputValue: ""
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.inputValue !== this.state.inputValue) {
            this.props.setProductSearch(this.state.inputValue)
        }
    }

    //adding filters to url
    handleLocation = () => {
        const { history, chosen_products_statuses, chosen_categories, chosen_suppliers } = this.props
        let queries = []
        if (chosen_products_statuses) {
            if (chosen_products_statuses.length > 0) {
                queries.push(encodeURIComponent(`chosen_products_statuses=${chosen_products_statuses}`))
            } else {
                queries.push(encodeURIComponent(""))
            }
        }
        if (chosen_categories) {
            if (chosen_categories.length > 0) {
                queries.push(encodeURIComponent(`chosen_categories=${chosen_categories}`))
            } else {
                queries.push(encodeURIComponent(""))
            }
        }
        if (chosen_suppliers) {
            if (chosen_suppliers.length > 0) {
                queries.push(encodeURIComponent(`chosen_suppliers=${chosen_suppliers}`))
            } else {
                queries.push(encodeURIComponent(""))
            }
        }
        queries.filter(el => el !== "").length > 0
            ? history.push(`/main/products${queries.length && queries.length !== 0 ? "?" : ""}${queries.join("&")}`)
            : history.push(`/main/products`)
    }

    //products statuses
    handleSelectChangePS = async e => {
        const { setProductsStatuses } = this.props
        await setProductsStatuses(e.target.value)
        this.handleLocation()
    }
    //products statuses

    //products categories
    handleSelectChangePC = async e => {
        const { setCategories } = this.props
        let chosen_categories = e.target.value.filter(el => el !== undefined)
        await setCategories(chosen_categories)
        this.handleLocation()
    }
    handleSearchPC = e => {
        this.setState({ searchCategories: e.target.value })
    }
    resetPC = async () => {
        const { setCategories } = this.props
        this.setState({ searchCategories: "" })
        await setCategories([])
        this.handleLocation()
    }
    //products categories

    //suppliers
    handleSelectChangeCS = async e => {
        const { setSuppliers } = this.props
        let chosen_suppliers = e.target.value.filter(el => el !== undefined)
        await setSuppliers(chosen_suppliers)
        this.handleLocation()
    }

    handleSearchCS = e => {
        this.setState({ searchSuppliers: e.target.value })
    }

    resetCS = async () => {
        const { setSuppliers } = this.props
        this.setState({ searchSuppliers: "" })
        await setSuppliers([])
        this.handleLocation()
    }
    //suppliers

    render() {
        const {
            chosen_products_statuses,
            chosen_categories,
            chosen_suppliers,
            categoriesList,
            suppliersList,
            history,
            uploadExcelFile,
            excelImportError,
            excelImportTemplate
        } = this.props
        const { searchSuppliers, searchCategories, inputValue } = this.state

        let suppliers_names = []
        suppliersList.forEach(el => suppliers_names.push("z" + el.name))
        const suppliers = suppliers_names.filter(el => el.toLowerCase().includes(searchSuppliers.toLowerCase()))
        let categories_names = []
        categoriesList.forEach(el => categories_names.push("z" + el.name))
        const categories = categories_names.filter(el => el.toLowerCase().includes(searchCategories.toLowerCase()))

        return (
            <div className="controls_panel no_select">
                <div>
                    <div className="search_by_name">
                        <input
                            type="text"
                            value={inputValue}
                            onChange={e => this.setState({ inputValue: e.target.value })}
                            placeholder="Наименование"
                        />
                    </div>
                    <MultiSelectComponent
                        item={chosen_categories}
                        items={categories}
                        handleChange={this.handleSelectChangePC}
                        placeholder={"Категория"}
                        handleSearch={this.handleSearchPC}
                        searchPlaceholder={"Введите название категории"}
                        searchValue={searchCategories}
                        resetChosenUsers={this.resetPC}
                    />
                    <MultiSelectComponent
                        item={chosen_suppliers}
                        items={suppliers}
                        handleChange={this.handleSelectChangeCS}
                        placeholder={"Поставщик"}
                        handleSearch={this.handleSearchCS}
                        searchPlaceholder={"Введите имя поставщика"}
                        searchValue={searchSuppliers}
                        resetChosenUsers={this.resetCS}
                    />
                    <SelectComponent
                        item={chosen_products_statuses}
                        items={products_statuses_list}
                        handleChange={this.handleSelectChangePS}
                        placeholder={"Статус"}
                    />
                    <input id="excelFile" className="fileInput" type="file" onChange={e => uploadExcelFile(e)} />
                    <div className="import_area">
                        <div
                            onClick={() => document.getElementById("excelFile").click()}
                            className="import_wrapper text_hover"
                        >
                            <div>
                                <img src={ImportIcon} alt="import" />
                                Импорт
                            </div>
                        </div>
                        <span>{excelImportError}</span>
                    </div>
                    <div className="import_area">
                        <a className="import_wrapper text_hover" href={excelImportTemplate} download>
                            <img src={ImportIcon} alt="import" />
                            <div>Скачать шаблон</div>
                        </a>
                    </div>
                </div>
                <div className="usable_text text_hover" onClick={() => history.push(`/main/new-product`)}>
                    + Добавить
                </div>
            </div>
        )
    }
}

export default withRouter(ProductsControls)
