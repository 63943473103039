import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import LinearProgress from "@material-ui/core/LinearProgress"
import { Field, reduxForm, SubmissionError } from "redux-form"
import {
    getProducts,
    getSuppliers,
    getCategories,
    changeProductActivity,
    getCharacteristics,
    patchProduct,
    addProduct,
    getCertainProduct
} from "../../../actions/productsActions"
import RenderField from "../../HelperComponents/RenderField/RenderField"
import ProductUpdateDialogs from "./ProductUpdateDialogs"
import BackIcon from "../../../assets/image/back.svg"
import MultiSelectComponent from "../../HelperComponents/MultiSelectComponent/MultiSelectComponent"
import DefaultButton from "../../HelperComponents/Buttons/DefaultButton/DefaultButton"
import SelectComponent from "../../HelperComponents/SelectComponent/SelectComponent"
import "./ProductUpdate.scss"

class ProductUpdate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            reloading: true,
            searchSuppliers: "",
            searchCharacteristics: "",
            charsIsOpen: false,
            openCharacteristicsDialog: false,
            openCategoriesDialog: false,
            openAddCharDialog: false,
            //selectors values
            chosen_categories: "",
            chosen_suppliers: "",
            item_chars: "",
            chosen_characteristics: [],

            characteristic_data: [],
            response_error: ""
        }
    }

    componentDidMount() {
        this.doRequest()
    }

    doRequest = async () => {
        const {
            getCategories,
            getSuppliers,
            getCharacteristics,
            getCertainProduct,
            match: {
                params: { id }
            }
        } = this.props

        this.setState({ reloading: true })
        await getCharacteristics()
        await getSuppliers()

        let response = await getCertainProduct(id)
        if (id && response.payload.status === 200) {
            let data = response.payload.data
            let arr = []
            data.item_chars &&
                data.item_chars.forEach(characteristic =>
                    arr.push("z" + characteristic.char.name + " " + characteristic.value + characteristic.char.unit)
                )
            this.setState({
                item_chars: data.item_chars,
                chosen_categories: data.category ? "z" + data.category.name : "",
                chosen_suppliers: data.supplier ? "z" + data.supplier.name : "",
                chosen_characteristics: arr
            })
        }
        const res = await getCategories()
        if (res.payload.status === 200) this.setState({ loading: false, reloading: false })
    }

    //dialogs handler
    toggleDialog = type => {
        type === "categories" &&
            this.setState(({ openCategoriesDialog }) => ({
                openCategoriesDialog: !openCategoriesDialog
            }))
        type === "characteristics" &&
            this.setState(({ openCharacteristicsDialog }) => ({
                openCharacteristicsDialog: !openCharacteristicsDialog
            }))
        type === "addCharacteristics" &&
            this.setState(({ openAddCharDialog }) => ({
                openAddCharDialog: !openAddCharDialog
            }))
        type === "open" &&
            this.setState({
                charsIsOpen: true
            })
        type === "close" &&
            this.setState({
                charsIsOpen: false
            })
    }
    //dialogs handler

    //products categories
    handleSelectChangeCS = e => {
        // let chosen_categories = e.target.value.filter(el => el !== undefined)
        this.setState({ chosen_categories: e.target.value })
    }
    //products categories

    //suppliers
    handleSelectChangeSP = e => {
        // let chosen_suppliers = e.target.value.filter(el => el !== undefined)
        this.setState({ chosen_suppliers: e.target.value })
    }
    //suppliers

    //characteristic
    unique = arr => {
        let result = []
        for (let i = 0; i < arr.length; i++) {
            if (!result.some(el => el.includes(arr[i]))) {
                result.push(arr[i])
            } else {
                let indexes = []
                arr.forEach((el, idx) => (el.toString().includes(arr[i].toString()) ? indexes.push(idx) : null))
                indexes.forEach(idx => result.splice(idx, 1))
            }
        }

        return result
    }

    handleSelectChangeCR = e => {
        let chosen_characteristics = e.target.value.filter(el => el !== undefined)
        this.setState({ chosen_characteristics: this.unique(chosen_characteristics) })
    }
    handleSearchCR = e => {
        this.setState({ searchCharacteristics: e.target.value })
    }
    resetCR = () => {
        this.setState({ chosen_characteristics: [], searchCharacteristics: "" })
    }
    //characteristic

    submitForm = async data => {
        const { chosen_characteristics, chosen_suppliers, chosen_categories, characteristic_data } = this.state
        const {
            categoriesList,
            addProduct,
            suppliersList,
            patchProduct,
            history,
            match: {
                params: { id }
            },
            match
        } = this.props
        // converting category names in ids
        let converted_categories
        categoriesList.forEach(cat =>
            chosen_categories.slice(1) === cat.name ? (converted_categories = cat.id) : null
        )
        //converting supplier names in ids
        let converted_suppliers
        suppliersList.forEach(sup => (chosen_suppliers.slice(1) === sup.name ? (converted_suppliers = sup.id) : null))
        //converting characteristics
        let converted_characteristics = []
        characteristic_data.forEach(char =>
            chosen_characteristics.forEach(chsn =>
                chsn.slice(1).includes(char.char_name)
                    ? converted_characteristics.push({ value: char.value, char: char.char })
                    : null
            )
        )
        let tempData = { ...data }
        delete tempData["item_chars"]
        let values = {
            ...tempData,
            category: converted_categories,
            supplier: converted_suppliers
        }
        converted_characteristics.length && (values.item_chars = converted_characteristics)
        this.setState({ reloading: true })
        if (match.url.includes("new-product")) {
            return await addProduct(values)
                .then(res => {
                    if (res.payload && res.payload.status === 201) {
                        this.setState({ reloading: false })
                        history.push(`/main/products`)
                    } else {
                        this.setState({ reloading: false, response_error: { ...res.error.response.data } })
                        throw res.error.response.data
                    }
                })
                .catch(e => {
                    this.setState({ reloading: false })
                    throw new SubmissionError(e)
                })
        } else {
            return await patchProduct(id, values)
                .then(res => {
                    if (res.payload && res.payload.status === 200) {
                        this.setState({ reloading: false })
                        history.push(`/main/products`)
                    } else {
                        this.setState({ reloading: false, response_error: { ...res.error.response.data } })
                        throw res.error.response.data
                    }
                })
                .catch(e => {
                    this.setState({ reloading: false })
                    throw new SubmissionError(e)
                })
        }
    }

    render() {
        const {
            characteristicsList,
            suppliersList,
            categoriesList,
            handleSubmit,
            submitting,
            pristine,
            valid,
            history,
            match,
            reset
        } = this.props

        const {
            loading,
            reloading,
            chosen_categories,
            chosen_suppliers,
            chosen_characteristics,
            searchCharacteristics,
            openCharacteristicsDialog,
            openCategoriesDialog,
            openAddCharDialog,
            response_error,
            item_chars,
            charsIsOpen
        } = this.state
        if (loading) return <LinearProgress />

        let categories = []
        categoriesList.forEach(el => (el.is_active ? categories.push("z" + el.name) : null))
        let suppliers = []
        suppliersList.forEach(el => (el.is_active ? suppliers.push("z" + el.name) : null))
        let characteristic_names = []
        characteristicsList.forEach(el => characteristic_names.push("z" + el.name))
        const characteristics = characteristic_names.filter(el =>
            el.toLowerCase().includes(searchCharacteristics.toLowerCase())
        )

        return (
            <Fragment>
                {reloading ? <LinearProgress /> : <hr className="loader_dummy" />}
                <div className="page_wrapper products_wrapper">
                    <div className="hr" />
                    <Link to="/main/products" onClick={() => reset()} className="text_hover usable_text">
                        <img src={BackIcon} alt="<" /> Назад
                    </Link>
                    {match.url.includes("new-product") ? <h1>Добавить товар</h1> : <h1>Редактировать товар</h1>}
                    <form onSubmit={handleSubmit(this.submitForm)} className="update_block">
                        <div className="update_row">
                            <Field type="text" name="name" label="Наименование" component={RenderField} />
                            <SelectComponent
                                item={chosen_categories}
                                items={categories}
                                handleChange={this.handleSelectChangeCS}
                                placeholder={"Категория"}
                                toggleCategories={this.toggleDialog}
                                err={response_error.category}
                            />
                        </div>
                        <div className="update_row">
                            <SelectComponent
                                item={chosen_suppliers}
                                items={suppliers}
                                handleChange={this.handleSelectChangeSP}
                                placeholder={"Поставщик"}
                                err={response_error.supplier}
                            />
                            <Field type="text" name="price" label="Цена за единицу" component={RenderField} />
                            <Field type="text" name="unit" label="Единица измерения" component={RenderField} />
                        </div>
                        <div className="update_row alone">
                            <MultiSelectComponent
                                onOpen={() => this.toggleDialog("open")}
                                onClose={() => this.toggleDialog("close")}
                                isChars={true}
                                open={charsIsOpen}
                                item={chosen_characteristics}
                                items={characteristics}
                                handleChange={this.handleSelectChangeCR}
                                placeholder={"Характеристики"}
                                handleSearch={this.handleSearchCR}
                                searchPlaceholder={"Введите название характеристики"}
                                searchValue={searchCharacteristics}
                                resetChosenUsers={this.resetCR}
                                toggleCharacteristics={this.toggleDialog}
                            />
                        </div>
                        <div className="update_row">
                            <DefaultButton
                                variant="outlined"
                                onClick={() => {
                                    reset()
                                    history.push("/main/products")
                                }}
                                classes="cancel_btn"
                            >
                                Отмена
                            </DefaultButton>

                            <DefaultButton
                                variant="contained"
                                disabled={match.url.includes("new-product") ? submitting || pristine || !valid : false}
                                loading={loading}
                                formAction
                            >
                                {match.url.includes("new-product") ? <>Добавить</> : <>Сохранить</>}
                            </DefaultButton>
                        </div>
                    </form>
                </div>
                <ProductUpdateDialogs
                    doRequest={this.doRequest}
                    toggleDialog={this.toggleDialog}
                    openCharacteristicsDialog={openCharacteristicsDialog}
                    openCategoriesDialog={openCategoriesDialog}
                    openAddCharDialog={openAddCharDialog}
                    chosen_characteristics={chosen_characteristics}
                    characteristicsList={characteristicsList}
                    setCharacteristics={chosen_characteristics => this.setState({ chosen_characteristics })}
                    charsIsOpen={charsIsOpen => this.setState({ charsIsOpen })}
                    characteristic_data={characteristic_data => this.setState({ characteristic_data })}
                    chars_array={item_chars}
                />
            </Fragment>
        )
    }
}

const validate = values => {
    const errors = {}
    if (!values.name) {
        errors.name = "Обязательное поле"
    }
    if (!values.price) {
        errors.price = "Обязательное поле"
    } else if (!/^\d*(\.?)(\d{1,2})$/g.test(values.price)) {
        errors.price = "Введено неверно"
    }
    return errors
}

ProductUpdate = reduxForm({
    form: "ProductUpdate",
    validate,
    enableReinitialize: true
})(ProductUpdate)

const mapStateToProps = ({ products }) => {
    return {
        productsList: products.productsList,
        categoriesList: products.categoriesList,
        suppliersList: products.suppliersList,
        characteristicsList: products.characteristicsList,
        initialValues: products.certain_product,
        productActivityError: products.productActivityError,
        productError: products.productError
    }
}

const mapDispatchToProps = {
    getProducts,
    getCategories,
    getSuppliers,
    changeProductActivity,
    getCharacteristics,
    patchProduct,
    getCertainProduct,
    addProduct
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductUpdate)
