import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import LinearProgress from "@material-ui/core/LinearProgress"
import { Field, formValueSelector, reduxForm } from "redux-form"
import moment from "moment"

import { getTenderTypes, postCustomApplication, patchCustomApplication } from "../../../actions/customTendersActions"
import RenderField from "../../HelperComponents/RenderField/RenderField"
import BackIcon from "../../../assets/image/back.svg"
import DefaultButton from "../../HelperComponents/Buttons/DefaultButton/DefaultButton"
import { auction_types } from "../../../helpers/functions"
import SelectComponent from "../../HelperComponents/SelectComponent/SelectComponent"
import SelectWithSearchComponent from "../../HelperComponents/SelectWithSearchComponent/SelectWithSearchComponent"
import { getManagers, getUsers, getTender } from "../../../actions/dashboardActions"
import "./EditCustomApplication.scss"
import Snack from "../../HelperComponents/Snack/Snack"
import DateTimeField from "../../HelperComponents/RenderDatePicker/RenderDateTimePicker"
import DocumentIcon from "../../../assets/image/file.svg"

class EditCustomApplication extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            reloading: true,
            //selectors values
            chosen_auction_type: "",
            chosen_tender_type: "",
            chosen_manager: "",
            search_manager: "",
            manager_matches: [],
            chosen_responsible: "",
            search_responsible: "",
            responsible_matches: [],
            custom_document_reUpload: undefined,
            openErrorSnack: false,
            errorMessage: ""
        }
    }

    componentDidMount() {
        if (this.props.redirectToDeals) {
            this.props.history.push("/main")
        } else {
            this.doRequest()
        }
    }

    doRequest = async () => {
        const { getTenderTypes, getUsers, tender_type, auction_type, manager, responsible } = this.props
        this.setState({ reloading: true })
        await getTenderTypes()
        let res = await getUsers()
        if (res.payload.status === 200) {
            let manager_fio
            manager
                ? res.payload.data.forEach(el => (manager === el.id ? (manager_fio = "z" + el.fio) : null))
                : (manager_fio = "")
            let responsible_fio
            responsible
                ? res.payload.data.forEach(el => (responsible === el.id ? (responsible_fio = "z" + el.fio) : null))
                : (responsible_fio = "")

            this.setState({
                chosen_auction_type: auction_type ? "z" + auction_type : "",
                chosen_tender_type: tender_type ? "z" + tender_type : "",
                chosen_manager: manager_fio,
                chosen_responsible: responsible_fio
            })
        }
        this.setState({ loading: false, reloading: false })
    }

    handleSelectChangeAT = async e => {
        this.setState({ chosen_auction_type: e.target.value })
    }

    handleSelectChangeTT = async e => {
        this.setState({ chosen_tender_type: e.target.value })
    }

    //привязанный
    handleSelectChangeCM = async e => {
        this.setState({ chosen_manager: e.target.value === undefined ? "" : e.target.value })
    }

    handleSearchCM = async e => {
        const { getManagers } = this.props
        let value = e.target.value
        if (value.length >= 1) {
            let res = await getManagers(value)
            if (res.payload && res.payload.status === 200 && res.payload.data.length > 0) {
                let arr = []
                res.payload.data.forEach(el => arr.push("z" + el.fio))
                this.setState({ manager_matches: arr })
            }
        } else {
            this.resetCM()
        }
        this.setState({ search_manager: value })
    }

    resetCM = async () => {
        this.setState({ chosen_manager: "", search_manager: "", manager_matches: [] })
    }
    //привязанный

    //ответственный
    handleSelectChangeRM = async e => {
        this.setState({ chosen_responsible: e.target.value === undefined ? "" : e.target.value })
    }

    handleSearchRM = async e => {
        const { getManagers } = this.props
        let value = e.target.value
        if (value.length >= 1) {
            let res = await getManagers(value)
            if (res.payload && res.payload.status === 200 && res.payload.data.length > 0) {
                let arr = []
                res.payload.data.forEach(el => arr.push("z" + el.fio))
                this.setState({ responsible_matches: arr })
            }
        } else {
            this.resetRM()
        }
        this.setState({ search_responsible: value })
    }

    resetRM = async () => {
        this.setState({ chosen_responsible: "", search_responsible: "", responsible_matches: [] })
    }
    //ответственный

    uploadFile = e => {
        e.persist()
        let file = e.target.files[0]
        this.setState({ custom_document_reUpload: file })
        document.getElementById("fileInput").value = ""
    }

    submitForm = async data => {
        const {
            chosen_tender_type,
            chosen_auction_type,
            chosen_manager,
            chosen_responsible,
            custom_document_reUpload
        } = this.state
        const {
            patchCustomApplication,
            history,
            match: {
                params: { id }
            },
            reset,
            users
        } = this.props
        this.setState({ reloading: true })
        let manager_id
        users.forEach(el =>
            chosen_manager.slice(1) === el.fio && chosen_manager !== "" ? (manager_id = el.id) : null
        )
        let responsible_id
        users.forEach(el =>
            chosen_responsible.slice(1) === el.fio && chosen_responsible !== "" ? (responsible_id = el.id) : null
        )

        const formData = new FormData()
        formData.append("customer_name", data.customer_name)
        formData.append("tender_name", data.tender_name)
        formData.append("custom_deadline", new Date(data.deadline).toISOString())
        chosen_tender_type && formData.append("tender_type", chosen_tender_type.slice(1))
        chosen_auction_type && formData.append("type", chosen_auction_type.slice(1))
        manager_id && formData.append("manager", manager_id)
        responsible_id && formData.append("responsible", responsible_id)
        custom_document_reUpload && formData.append("custom_document", custom_document_reUpload)

        const res = await patchCustomApplication(formData, id)
        if (res.payload) {
            this.setState({ reloading: false })
            reset()
            if (chosen_auction_type) {
                history.push(`/main/deal-in-progress/${id}`)
            } else {
                history.push(`/main/tender/${id}`)
            }
        } else {
            const errorMessage =
                res.error.response.data.customer_name ||
                res.error.response.data.tender_name ||
                res.error.response.data.deadline ||
                res.error.response.data.detail ||
                res.error.response.data.manager ||
                res.error.response.data.responsible ||
                res.error.response.data.custom_document ||
                "Что-то пошло не так"
            this.setState({ openErrorSnack: true, errorMessage })
        }
    }

    render() {
        const {
            handleSubmit,
            submitting,
            valid,
            history,
            match: {
                params: { id }
            },
            tenderTypes,
            deadline,
            custom_document
        } = this.props

        const {
            loading,
            reloading,
            chosen_tender_type,
            chosen_auction_type,
            chosen_manager,
            search_manager,
            manager_matches,
            chosen_responsible,
            search_responsible,
            responsible_matches,
            custom_document_reUpload,
            openErrorSnack,
            errorMessage
        } = this.state
        if (loading) return <LinearProgress />

        let tender_types = []
        tenderTypes.forEach(el => tender_types.push("z" + el))
        console.log(deadline)
        return (
            <Fragment>
                {reloading ? <LinearProgress /> : <hr className="loader_dummy" />}
                <div className="page_wrapper edit_custom_application_wrapper">
                    <div className="hr" />
                    <Link
                        to={
                            chosen_auction_type
                                ? id
                                    ? `/main/deal-in-progress/${id}`
                                    : `/main/deals-in-progress`
                                : id
                                ? `/main/tender/${id}`
                                : `/main/tenders`
                        }
                        className="text_hover usable_text"
                    >
                        <img src={BackIcon} alt="<" /> Назад
                    </Link>
                    {!id ? <h1>Добавить заявку</h1> : <h1>Редактировать заявку</h1>}
                    <form onSubmit={handleSubmit(this.submitForm)} className="update_block">
                        <div className="update_row alone">
                            <Field
                                name="customer_name"
                                type="text"
                                label="Заказчик"
                                component={RenderField}
                                rows={4}
                                multiline={true}
                            />
                            <Field
                                name="tender_name"
                                type="text"
                                label="Название тендера"
                                component={RenderField}
                                rows={4}
                                multiline={true}
                            />
                        </div>
                        <div className="update_row space_btwn">
                            <div>
                                <Field
                                    name="deadline"
                                    component={DateTimeField}
                                    label="Дедлайн"
                                    disablePast={true}
                                    invalidDateMessage="Недопустимый дедлайн"
                                    minDateMessage="Недопустимый дедлайн"
                                    maxDateMessage="Недопустимый дедлайн"
                                    invalidLabel="Недопустимый дедлайн"
                                />
                                <SelectComponent
                                    item={chosen_auction_type}
                                    items={auction_types}
                                    handleChange={this.handleSelectChangeAT}
                                    placeholder={"Тип аукциона"}
                                />
                                <SelectComponent
                                    item={chosen_tender_type}
                                    items={tender_types}
                                    handleChange={this.handleSelectChangeTT}
                                    placeholder={"Тип тендера"}
                                />
                            </div>
                            <div>
                                <SelectWithSearchComponent
                                    disabled={chosen_auction_type.length === 0}
                                    items={manager_matches}
                                    item={chosen_manager}
                                    handleChange={this.handleSelectChangeCM}
                                    placeholder={"Привязанный"}
                                    handleSearch={this.handleSearchCM}
                                    searchPlaceholder={"Введите имя"}
                                    searchValue={search_manager}
                                    resetChosenUsers={this.resetCM}
                                />
                                <SelectWithSearchComponent
                                    disabled={chosen_auction_type.length === 0}
                                    items={responsible_matches}
                                    item={chosen_responsible}
                                    handleChange={this.handleSelectChangeRM}
                                    placeholder={"Ответственный"}
                                    handleSearch={this.handleSearchRM}
                                    searchPlaceholder={"Введите имя"}
                                    searchValue={search_responsible}
                                    resetChosenUsers={this.resetRM}
                                />
                            </div>
                        </div>
                        <div className="document_row">
                            <img src={DocumentIcon} alt="document" />
                            {custom_document_reUpload ? (
                                <p>{custom_document_reUpload["name"]}</p>
                            ) : custom_document ? (
                                <a href={custom_document} rel="noopener noreferrer" target="_blank">
                                    {decodeURIComponent(custom_document.split("/")[7])}
                                </a>
                            ) : (
                                <p>Документ не выбран</p>
                            )}
                            <input id="fileInput" className="fileInput" type="file" onChange={this.uploadFile} />
                            <div
                                className="usable_text text_hover"
                                onClick={() => document.getElementById("fileInput").click()}
                            >
                                ВЫБРАТЬ ФАЙЛ
                            </div>
                        </div>
                        <div className="btn_wrapper">
                            <DefaultButton
                                variant="outlined"
                                onClick={() => {
                                    history.push(`/main/tender/${id}`)
                                }}
                                classes="cancel_btn"
                            >
                                Отмена
                            </DefaultButton>

                            <DefaultButton
                                variant="contained"
                                disabled={submitting || !valid || (deadline && !deadline._isValid)}
                                loading={loading}
                                formAction
                            >
                                {!id ? <>Добавить</> : <>Сохранить</>}
                            </DefaultButton>
                        </div>
                    </form>
                </div>
                <Snack
                    open={openErrorSnack}
                    handleSnack={() => this.setState({ openErrorSnack: false })}
                    message={errorMessage}
                    type="error"
                />
            </Fragment>
        )
    }
}

const validate = values => {
    const errors = {}
    if (!values.customer_name) {
        errors.customer_name = "Обязательное поле"
    }
    if (!values.tender_name) {
        errors.tender_name = "Обязательное поле"
    }
    if (!values.deadline) {
        errors.deadline = "Недопустимый дедлайн"
    }
    return errors
}

const selector = formValueSelector("EditCustomApplication")

EditCustomApplication = connect(state => {
    const deadline = selector(state, "deadline")
    return {
        deadline
    }
})(EditCustomApplication)

EditCustomApplication = reduxForm({
    form: "EditCustomApplication",
    enableReinitialize: true,
    validate
})(EditCustomApplication)

const mapStateToProps = ({ customTenders, dashboard }) => {
    if (!dashboard.tender.data)
        return {
            redirectToDeals: true
        }
    return {
        tenderTypes: customTenders.tenderTypes,
        managers: dashboard.managers,
        users: dashboard.users,
        tender_type: dashboard.tender.data.request_type,
        auction_type: dashboard.tender.type,
        responsible: dashboard.tender.responsible,
        manager: dashboard.tender.manager,
        custom_document: dashboard.tender.custom_document,
        initialValues: {
            customer_name: dashboard.tender.data.customer,
            tender_name: dashboard.tender.data.name,
            deadline: dashboard.tender.custom_deadline ? moment(dashboard.tender.custom_deadline) : ""
        }
    }
}

const mapDispatchToProps = {
    getTenderTypes,
    getManagers,
    postCustomApplication,
    patchCustomApplication,
    getUsers,
    getTender
}

export default connect(mapStateToProps, mapDispatchToProps)(EditCustomApplication)
