import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { categories_statuses_list } from "../../helpers/functions"
import "./Products.scss"
import SelectComponent from "../HelperComponents/SelectComponent/SelectComponent"

class CategoriesControls extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    //adding filters to url
    handleLocation = () => {
        const { history, chosen_products_statuses } = this.props
        let queries = []
        if (chosen_products_statuses) {
            if (chosen_products_statuses.length > 0) {
                queries.push(encodeURIComponent(`chosen_products_statuses=${chosen_products_statuses}`))
            } else {
                queries.push(encodeURIComponent(""))
            }
        }
        queries.filter(el => el !== "").length > 0
            ? history.push(`/main/products${queries.length && queries.length !== 0 ? "?" : ""}${queries.join("&")}`)
            : history.push(`/main/products`)
    }

    //products statuses
    handleSelectChangePS = async e => {
        const { setProductsStatuses } = this.props
        await setProductsStatuses(e.target.value)
        this.handleLocation()
    }
    //products statuses

    render() {
        const { chosen_products_statuses, toggleAddCategoryDialog } = this.props

        return (
            <div className="controls_panel no_select">
                <div>
                    <SelectComponent
                        item={chosen_products_statuses}
                        items={categories_statuses_list}
                        handleChange={this.handleSelectChangePS}
                        placeholder={"Статус"}
                    />
                </div>
                <div
                    onClick={() => toggleAddCategoryDialog("categories", "", "new")}
                    className="usable_text text_hover"
                >
                    + Добавить
                </div>
            </div>
        )
    }
}

export default withRouter(CategoriesControls)
