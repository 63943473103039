import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import clsx from "clsx"
import LinearProgress from "@material-ui/core/LinearProgress"
import SuppliersControls from "./SuppliersControls"
import SuppliersDialogs from "./SuppliersDialogs"
import pencil from "../../assets/image/edit.svg"
import { getSuppliers } from "../../actions/suppliersActions"
import { getProductStatus } from "../../helpers/functions"
import "./Suppliers.scss"
import { Link } from "react-router-dom"

class Suppliers extends Component {
    constructor(props) {
        super(props)
        //getting filters from url
        let params = new URLSearchParams(decodeURIComponent(props.location.search.substring(1)))
        let chosen_suppliers_statuses = params.get("chosen_suppliers_statuses")
            ? params.get("chosen_suppliers_statuses")
            : ""

        this.state = {
            loading: true,
            reloading: true,
            activePage: 1,

            openBlockDialog: false,
            openUnblockDialog: false,
            openCategoriesDialog: false,
            currentSupplierId: "",
            supplierMarker: "",
            //selectors values
            chosen_suppliers_statuses
        }
    }

    componentDidMount() {
        this.doRequest()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.chosen_suppliers_statuses !== this.state.chosen_suppliers_statuses) {
            this.doRequest()
        }
    }

    doRequest = async (page = 1) => {
        const { getSuppliers } = this.props
        const { chosen_suppliers_statuses } = this.state
        let res
        this.setState({ reloading: true })

        // converting status names in boolean
        let converted_statuses = []
        if (chosen_suppliers_statuses.includes("zАктивен")) {
            converted_statuses.push(true)
        } else if (chosen_suppliers_statuses.includes("zЗаблокирован")) {
            converted_statuses.push(false)
        }

        res = await getSuppliers(converted_statuses)

        if (res.payload.status === 200) {
            this.setState({ loading: false, reloading: false, chosen_suppliers_statuses })
        }
    }

    //dialogs handler
    toggleDialog = (type, id) => {
        this.setState({ currentSupplierId: id })

        type === "block" &&
            this.setState(({ openBlockDialog }) => ({
                openBlockDialog: !openBlockDialog
            }))
        type === "unblock" &&
            this.setState(({ openUnblockDialog }) => ({
                openUnblockDialog: !openUnblockDialog
            }))
    }

    getStatusHandler = (status_display, id) => {
        const {
            options: {
                me: { role }
            } //admin || senior_manager || manager
        } = this.props
        if (status_display) {
            return (
                <button
                    disabled={role !== "admin" && role !== "senior_manager"}
                    className="blockable"
                    onClick={() => this.toggleDialog("block", id)}
                    style={{ cursor: `${role === "manager" ? "default" : "pointer"}` }}
                >
                    Заблокировать
                </button>
            )
        } else {
            return (
                <button
                    disabled={role !== "admin" && role !== "senior_manager"}
                    className="unblockable"
                    onClick={() => this.toggleDialog("unblock", id)}
                    style={{ cursor: `${role === "manager" ? "default" : "pointer"}` }}
                >
                    Разблокировать
                </button>
            )
        }
    }

    getContent = () => {
        const {
            suppliers,
            options: {
                me: { role }
            } //admin || senior_manager || manager
        } = this.props
        const { openBlockDialog, openUnblockDialog, chosen_suppliers_statuses, currentSupplierId } = this.state
        // let products = [...productsList.results]

        return (
            <Fragment>
                <SuppliersControls
                    role={role}
                    chosen_suppliers_statuses={chosen_suppliers_statuses}
                    setSuppliersStatuses={chosen_suppliers_statuses => this.setState({ chosen_suppliers_statuses })}
                    toggleDialog={this.toggleDialog}
                />
                <div className="table_container ">
                    <div className="table_header no_select">
                        <div className="table_row">
                            <div className="row_item">
                                <p>Наименование</p>
                            </div>
                            <div className="row_item">
                                <p>Контактная информация</p>
                            </div>
                            <div className="row_item">
                                <p>Информация</p>
                            </div>
                            <div className="row_item">
                                <p>Статус</p>
                            </div>
                            <div className="row_item" />
                            <div className="row_item" />
                        </div>
                    </div>
                    <div className="table_body">
                        {suppliers.length > 0 ? (
                            suppliers.map(({ id, name, contact_info, info, is_active }) => (
                                <div className="table_row" key={`product_${id}`}>
                                    <div className={clsx("row_item", !is_active && "blocked")}>
                                        <p>{name}</p>
                                    </div>
                                    <div className="row_item overflowed_text" title={contact_info}>
                                        {contact_info}
                                    </div>
                                    <div className="row_item overflowed_text" title={info}>
                                        {info}
                                    </div>
                                    <div className="row_item">{getProductStatus(is_active)}</div>
                                    <div className="row_item">{this.getStatusHandler(is_active, id)}</div>
                                    {role !== "manager" && (
                                        <div className="row_item no_select usable_text text_hover">
                                            <Link to={`/main/supplier/${id}`}>
                                                <img src={pencil} alt="pencil" />
                                            </Link>{" "}
                                        </div>
                                    )}
                                </div>
                            ))
                        ) : (
                            <div className="table_row">Отсутствуют поставщики</div>
                        )}
                    </div>
                    <hr />
                </div>
                {/*<Pagination*/}
                {/*    active={activePage - 1}*/}
                {/*    pageCount={productsList.count / 10}*/}
                {/*    onChange={e => {*/}
                {/*        this.doRequest(e.selected + 1)*/}
                {/*    }}*/}
                {/*/>*/}
                <SuppliersDialogs
                    currentSupplierId={currentSupplierId}
                    doRequest={this.doRequest}
                    toggleDialog={this.toggleDialog}
                    openBlockDialog={openBlockDialog}
                    openUnblockDialog={openUnblockDialog}
                    suppliers={suppliers}
                />
            </Fragment>
        )
    }

    render() {
        const { loading, reloading } = this.state
        if (loading) return <LinearProgress />
        return (
            <Fragment>
                {reloading ? <LinearProgress /> : <hr className="loader_dummy" />}
                <div className="page_wrapper suppliers_wrapper">
                    <h1>Поставщики</h1>
                    {this.getContent()}
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = ({ suppliers, dashboard }) => {
    return {
        suppliers: suppliers.suppliers,
        options: dashboard.options
    }
}

const mapDispatchToProps = {
    getSuppliers
}

export default connect(mapStateToProps, mapDispatchToProps)(Suppliers)
