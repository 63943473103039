import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import LinearProgress from "@material-ui/core/LinearProgress"
import { Field, reduxForm } from "redux-form"
import { patchSupplier, getCurrentSupplier, addSupplier } from "../../../actions/suppliersActions"
import RenderField from "../../HelperComponents/RenderField/RenderField"
import BackIcon from "../../../assets/image/back.svg"
import DefaultButton from "../../HelperComponents/Buttons/DefaultButton/DefaultButton"
import "./SupplierUpdate.scss"

class SupplierUpdate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            reloading: true,
            //selectors values
            chosen_supplier: ""
        }
    }

    componentDidMount() {
        this.doRequest()
    }

    doRequest = async () => {
        const {
            getCurrentSupplier,
            match: {
                params: { id }
            }
        } = this.props
        this.setState({ reloading: true })
        await getCurrentSupplier(id)
        this.setState({ loading: false, reloading: false })
    }

    submitForm = async data => {
        const {
            addSupplier,
            patchSupplier,
            history,
            match: {
                params: { id }
            },
            match,
            reset
        } = this.props
        let res

        this.setState({ reloading: true })
        if (match.url.includes("new-supplier")) {
            res = await addSupplier(data)
            if (res.payload.status === 201) {
                this.setState({ reloading: false })
                reset()
                history.push(`/main/suppliers`)
            }
        } else {
            res = await patchSupplier(id, data)
            if (res.payload.status === 200) {
                this.setState({ reloading: false })
                reset()
                history.push(`/main/suppliers`)
            }
        }
    }

    render() {
        const { handleSubmit, submitting, pristine, valid, history, match } = this.props

        const { loading, reloading } = this.state
        if (loading) return <LinearProgress />

        return (
            <Fragment>
                {reloading ? <LinearProgress /> : <hr className="loader_dummy" />}
                <div className="page_wrapper suppliers_update_wrapper">
                    <div className="hr" />
                    <Link to="/main/suppliers" className="text_hover usable_text">
                        <img src={BackIcon} alt="<" /> Назад
                    </Link>
                    {match.url.includes("new-supplier") ? (
                        <h1>Добавить поставщика</h1>
                    ) : (
                        <h1>Редактировать поставщика</h1>
                    )}
                    <form onSubmit={handleSubmit(this.submitForm)} className="update_block">
                        <div className="update_row">
                            <Field name="name" type="text" label="Наименование" component={RenderField} />
                        </div>
                        <div className="update_row alone">
                            <Field
                                name="contact_info"
                                type="text"
                                label="Контактная информация"
                                component={RenderField}
                                rows={4}
                                multiline={true}
                            />
                        </div>
                        <div className="update_row alone">
                            <Field
                                name="info"
                                type="text"
                                label="Информация"
                                component={RenderField}
                                rows={4}
                                multiline={true}
                            />
                        </div>
                        <div className="update_row">
                            <DefaultButton
                                variant="outlined"
                                onClick={() => {
                                    history.push("/main/suppliers")
                                }}
                                classes="cancel_btn"
                            >
                                Отмена
                            </DefaultButton>

                            <DefaultButton
                                variant="contained"
                                disabled={submitting || pristine || !valid}
                                loading={loading}
                                formAction
                            >
                                {match.url.includes("new-supplier") ? <>Добавить</> : <>Сохранить</>}
                            </DefaultButton>
                        </div>
                    </form>
                </div>
            </Fragment>
        )
    }
}

const validate = values => {
    const errors = {}
    if (!values.name) {
        errors.name = "Обязательное поле"
    }
    if (!values.contact_info) {
        errors.contact_info = "Обязательное поле"
    }

    return errors
}

SupplierUpdate = reduxForm({
    form: "SupplierUpdate",
    enableReinitialize: true,
    validate
})(SupplierUpdate)

const mapStateToProps = ({ suppliers }) => {
    return {
        currentSupplier: suppliers.currentSupplier,
        supplierError: suppliers.supplierError,
        initialValues: suppliers.currentSupplier
    }
}

const mapDispatchToProps = {
    getCurrentSupplier,
    patchSupplier,
    addSupplier
}

export default connect(mapStateToProps, mapDispatchToProps)(SupplierUpdate)
