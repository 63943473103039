import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { changeSupplierActivity } from "../../actions/suppliersActions";
import DialogComponent from "../HelperComponents/DialogComponent/DialogComponent";
import DefaultButton from "../HelperComponents/Buttons/DefaultButton/DefaultButton";

class SuppliersDialogs extends Component {
    state = {
        reloading: false,
        item_chars: []
    };

    blockCategory = async () => {
        const { toggleDialog, doRequest, changeSupplierActivity, currentSupplierId } = this.props;
        this.setState({ reloading: true });
        await changeSupplierActivity(currentSupplierId);
        this.setState({ reloading: false });
        doRequest();
        toggleDialog("block");
    };

    unblockCategory = async () => {
        const { toggleDialog, changeSupplierActivity, currentSupplierId, doRequest } = this.props;
        this.setState({ reloading: true });
        await changeSupplierActivity(currentSupplierId);
        this.setState({ reloading: false });
        doRequest();
        toggleDialog("unblock");
    };

    render() {
        const { toggleDialog, openBlockDialog, openUnblockDialog } = this.props;
        const { reloading } = this.state;

        return (
            <Fragment>
                <DialogComponent open={openBlockDialog} onClose={() => toggleDialog("block")}>
                    <div className="dashboard_dialog no_select">
                        <h2>Заблокировать поставщика</h2>
                        <div className="dialog_description">Вы уверены, что хотите заблокировать этого поставщика?</div>
                        <div className="dialog_buttons">
                            <DefaultButton
                                variant="outlined"
                                onClick={() => toggleDialog("block")}
                                classes="cancel_btn"
                            >
                                Отмена
                            </DefaultButton>
                            <DefaultButton
                                variant="contained"
                                onClick={this.blockCategory}
                                classes="confirm_btn"
                                loading={reloading}
                            >
                                Заблокировать
                            </DefaultButton>
                        </div>
                    </div>
                </DialogComponent>
                <DialogComponent open={openUnblockDialog} onClose={() => toggleDialog("unblock")}>
                    <div className="dashboard_dialog no_select">
                        <h2>Разблокировать поставщика</h2>
                        <div className="dialog_description">
                            Подтвердите, что Вы хотите разблокировать этого поставщика.
                        </div>
                        <div className="dialog_buttons">
                            <DefaultButton
                                variant="outlined"
                                onClick={() => toggleDialog("unblock")}
                                classes="cancel_btn"
                            >
                                Отмена
                            </DefaultButton>
                            <DefaultButton
                                variant="contained"
                                onClick={this.unblockCategory}
                                classes="confirm_btn"
                                loading={reloading}
                            >
                                Подтвердить
                            </DefaultButton>
                        </div>
                    </div>
                </DialogComponent>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = {
    changeSupplierActivity
};

export default connect(mapStateToProps, mapDispatchToProps)(SuppliersDialogs);
