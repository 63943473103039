import React from "react";
import { css } from "@emotion/core";
import RotateLoader from "react-spinners/RotateLoader";

import { getDeal } from "../../actions/dashboardActions";
import { connect } from "react-redux";

const override = css`
    display: block;
    margin: 200px auto;
`;

class RedirectPage extends React.Component {
    componentDidMount() {
        const {
            getDeal,
            history,
            match: {
                params: { id }
            }
        } = this.props;
        getDeal(id).then(res => {
            if (res.payload) {
                const { status } = res.payload.data;
                switch (status) {
                    case "new":
                        history.push(`/main/tender/${id}`);
                        break;
                    case "to_calculate":
                        history.push(`/main/deal/${id}`);
                        break;
                    case "deleted":
                        history.push(`/main/not-found`);
                        break;
                    default:
                        history.push(`/main/deal-in-progress/${id}`);
                        break;
                }
            } else {
                history.push(`/main/not-found`);
            }
        });
    }
    render() {
        return (
            <div className="loader_block">
                <RotateLoader css={override} sizeUnit={"px"} color={"#F0F5FF"} loading={true} />
            </div>
        );
    }
}

const mapDispatchToProps = {
    getDeal
};
export default connect(null, mapDispatchToProps)(RedirectPage);
