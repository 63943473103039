//////////////////////////////auth actions//////////////////////////////

export const LOGIN = "LOGIN"
export const LOGIN_FAIL = "LOGIN_FAIL"

export const REGISTER = "REGISTER"
export const REGISTER_FAIL = "REGISTER_FAIL"

export const RECOVER_PASSWORD = "RECOVER_PASSWORD"

export const RESET_ERRORS = "RESET_ERRORS"

//////////////////////////////Dashboard/////////////////////////////////

export const GET_OPTIONS = "GET_OPTIONS"
export const GET_OPTIONS_SUCCESS = "GET_OPTIONS_SUCCESS"
export const GET_OPTIONS_FAIL = "GET_OPTIONS_FAIL"

export const GET_TENDERS = "GET_TENDERS"
export const GET_TENDERS_SUCCESS = "GET_TENDERS_SUCCESS"

export const GET_TENDERS_BY_SEARCH = "GET_TENDERS_BY_SEARCH"
export const GET_TENDERS_BY_SEARCH_SUCCESS = "GET_TENDERS_BY_SEARCH_SUCCESS"

export const GET_TENDER = "GET_TENDER"
export const GET_TENDER_SUCCESS = "GET_TENDER_SUCCESS"

export const PATCH_TENDER = "PATCH_TENDER"

export const RE_DOWNLOAD_TENDER = "RE_DOWNLOAD_TENDER"

export const GET_MANAGERS = "GET_MANAGERS"
export const GET_MANAGERS_SUCCESS = "GET_MANAGERS_SUCCESS"

export const GET_DEALS = "GET_DEALS"
export const GET_DEALS_SUCCESS = "GET_DEALS_SUCCESS"

export const GET_ACTIVE_DEALS = "GET_ACTIVE_DEALS"
export const GET_ACTIVE_DEALS_SUCCESS = "GET_ACTIVE_DEALS_SUCCESS"

export const GET_DEAL = "GET_DEAL"
export const GET_DEAL_SUCCESS = "GET_DEAL_SUCCESS"

export const GET_CUSTOMERS = "GET_CUSTOMERS"
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS"

export const PATCH_CUSTOMERS = "PATCH_CUSTOMERS"

//////////////////////////////Users/////////////////////////////////

export const CALCULATE_DEAL = "CALCULATE_DEAL"

export const GET_USERS = "GET_USERS"
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS"

export const PATCH_USER = "PATCH_USER"

export const DELETE_USER = "DELETE_USER"

export const GET_COUNTRIES = "GET_COUNTRIES"
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS"

//////////////////////////////Deals actions/////////////////////////////////

export const POST_CALCULATION = "POST_CALCULATION"
export const POST_CHECK = "POST_CHECK"
export const POST_FIX = "POST_FIX"
export const POST_APPROVE = "POST_APPROVE"
export const POST_DELETE = "POST_DELETE"
export const POST_DECLINE = "POST_DECLINE"
export const POST_POST = "POST_POST"
export const ADD_PLACED_BY = "ADD_PLACED_BY"
export const GET_BOT_DOCUMENT = "GET_BOT_DOCUMENT"
export const POST_BOT_DOCUMENT = "POST_BOT_DOCUMENT"
export const POST_POST_BOT_INVOLVED = "POST_POST_BOT_INVOLVED"
export const POST_SIGNED = "POST_SIGNED"
export const POST_WON = "POST_WON"
export const POST_LOST = "POST_LOST"
export const POST_EXECUTING = "POST_EXECUTING"
export const POST_EXECUTED = "POST_EXECUTED"
export const POST_CLOSED = "POST_CLOSED"
export const POST_RESPONSIBLE = "POST_RESPONSIBLE"
export const POST_MANAGER = "POST_MANAGER"
export const GET_OWNERS = "GET_OWNERS"
export const GET_OWNERS_SUCCESS = "GET_OWNERS_SUCCESS"
export const GET_ALL_OWNERS = "GET_ALL_OWNERS"
export const GET_ALL_OWNERS_SUCCESS = "GET_ALL_OWNERS_SUCCESS"
export const GET_CP = "GET_CP"
export const GET_EXCEL = "GET_EXCEL"
export const DELETE_FILE = "DELETE_FILE"
export const DOWNLOAD_CONTRACT_FILES = "DOWNLOAD_CONTRACT_FILES"
export const GET_CONTRACT_FILES = "GET_CONTRACT_FILES"
export const GET_CONTRACT_FILES_SUCCESS = "GET_CONTRACT_FILES_SUCCESS"

export const ADD_COMMENT = "ADD_COMMENT"
export const CHANGE_DOCUMENT = "CHANGE_DOCUMENT"

export const GET_CALCULATION_DOCUMENTS = "GET_CALCULATION_DOCUMENTS"
export const GET_CALCULATION_DOCUMENTS_SUCCESS = "GET_CALCULATION_DOCUMENTS_SUCCESS"

export const CREATE_CALCULATION_DOCUMENT = "CREATE_CALCULATION_DOCUMENT"
export const UPDATE_CALCULATION_DOCUMENT = "UPDATE_CALCULATION_DOCUMENT"
export const DELETE_CALCULATION_DOCUMENT = "DELETE_CALCULATION_DOCUMENT"

export const GET_FILES_GK = "GET_FILES_GK"
export const GET_FILES_GK_SUCCESS = "GET_FILES_GK_SUCCESS"

//////////////////////////////Calculations actions/////////////////////////////////

export const POST_EXCEL = "POST_EXCEL"
export const POST_EXCEL_SUCCESS = "POST_EXCEL_SUCCESS"
export const POST_EXCEL_FAIL = "POST_EXCEL_FAIL"

export const SEARCH_CATALOG = "SEARCH_CATALOG"
export const SEARCH_CATALOG_SUCCESS = "SEARCH_CATALOG_SUCCESS"

export const SEARCH_SUPPLIERS = "SEARCH_SUPPLIERS"
export const SEARCH_SUPPLIERS_SUCCESS = "SEARCH_SUPPLIERS_SUCCESS"

export const POST_SUPPLIER = "POST_SUPPLIER"
export const POST_SUPPLIER_FAIL = "POST_SUPPLIER_FAIL"

export const POST_CATALOG_ITEM = "POST_CATALOG_ITEM"
export const POST_CATALOG_ITEM_FAIL = "POST_CATALOG_ITEM_FAIL"

export const POST_BUY_ITEM = "POST_BUY_ITEM"
export const POST_BUY_ITEM_SUCCESS = "POST_BUY_ITEM_SUCCESS"
export const POST_BUY_ITEM_FAIL = "POST_BUY_ITEM_FAIL"

export const PATCH_BUY_ITEM = "PATCH_BUY_ITEM"
export const PATCH_BUY_ITEM_SUCCESS = "PATCH_BUY_ITEM_SUCCESS"
export const PATCH_BUY_ITEM_FAIL = "PATCH_BUY_ITEM_FAIL"

export const PATCH_SELL_ITEM = "PATCH_SELL_ITEM"
export const PATCH_SELL_ITEM_SUCCESS = "PATCH_SELL_ITEM_SUCCESS"
export const PATCH_SELL_ITEM_FAIL = "PATCH_SELL_ITEM_FAIL"

export const PATCH_SELL_ITEM_PRICE = "PATCH_SELL_ITEM_PRICE"

export const DELETE_BUY_ITEM = "DELETE_BUY_ITEM"
export const DELETE_BUY_ITEM_SUCCESS = "DELETE_BUY_ITEM_SUCCESS"

export const POST_ADDITIONAL_ITEM = "POST_ADDITIONAL_ITEM"
export const POST_ADDITIONAL_ITEM_SUCCESS = "POST_ADDITIONAL_ITEM_SUCCESS"
export const POST_ADDITIONAL_ITEM_FAIL = "POST_ADDITIONAL_ITEM_FAIL"

export const PATCH_ADDITIONAL_ITEM = "PATCH_ADDITIONAL_ITEM"
export const PATCH_ADDITIONAL_ITEM_SUCCESS = "PATCH_ADDITIONAL_ITEM_SUCCESS"

export const DELETE_ADDITIONAL_ITEM = "DELETE_ADDITIONAL_ITEM"
export const DELETE_ADDITIONAL_ITEM_SUCCESS = "DELETE_ADDITIONAL_ITEM_SUCCESS"

export const RESET_FILE_ERROR = "RESET_FILE_ERROR"

export const ADD_SELL_CALCULATION = "ADD_SELL_CALCULATION"
export const ADD_SELL_CALCULATION_SUCCESS = "ADD_SELL_CALCULATION_SUCCESS"

export const UPDATE_SELL_CALCULATION = "UPDATE_SELL_CALCULATION"

export const DELETE_SELL_CALCULATION = "DELETE_SELL_CALCULATION"

// export const PATCH_SELL_ITEM      = 'PATCH_SELL_ITEM'
// export const PATCH_SELL_ITEM_SUCCESS      = 'PATCH_SELL_ITEM_SUCCESS'
//                                                                                for future generations
// export const DELETE_SELL_ITEM      = 'DELETE_SELL_ITEM'
// export const DELETE_SELL_ITEM_SUCCESS      = 'DELETE_SELL_ITEM_SUCCESS'

export const SET_TENDERS_PAGE = "SET_TENDERS_PAGE"
export const SET_TENDERS_ORDER = "SET_TENDERS_ORDER"

export const ADD_SELECTED_TENDER = "ADD_SELECTED_TENDER"
export const REMOVE_SELECTED_TENDER = "REMOVE_SELECTED_TENDER"
export const CLEAR_SELECTED_TENDERS = "CLEAR_SELECTED_TENDERS"
export const BULK_UPDATE_TENDERS = "BULK_UPDATE_TENDERS"
export const BULK_UPDATE_TENDERS_SUCCESS = "BULK_UPDATE_TENDERS_SUCCESS"

export const SET_DEALS_PAGE = "SET_DEALS_PAGE"
export const SET_DEALS_ORDER = "SET_DEALS_ORDER"

export const SET_ACTIVE_DEALS_PAGE = "SET_ACTIVE_DEALS_PAGE"
export const SET_ACTIVE_DEALS_ORDER = "SET_ACTIVE_DEALS_ORDER"

export const ADD_SELECTED_DEAL = "ADD_SELECTED_DEAL"
export const REMOVE_SELECTED_DEAL = "REMOVE_SELECTED_DEAL"
export const CLEAR_SELECTED_DEALS = "CLEAR_SELECTED_DEALS"

export const ADD_SELECTED_ACTIVE_DEAL = "ADD_SELECTED_ACTIVE_DEAL"
export const REMOVE_SELECTED_ACTIVE_DEAL = "REMOVE_SELECTED_ACTIVE_DEAL"
export const CLEAR_SELECTED_ACTIVE_DEALS = "CLEAR_SELECTED_ACTIVE_DEALS"

export const SEND_ADDITIONALS = "SEND_ADDITIONALS"

export const GET_PURCHASE = "GET_PURCHASE"
export const GET_PURCHASE_SUCCESS = "GET_PURCHASE_SUCCESS"
export const GET_PURCHASE_ERROR = "GET_PURCHASE_ERROR"

export const CHANGE_PURCHASE = "CHANGE_PURCHASE"

export const GET_PURCHASES = "GET_PURCHASES"
export const GET_PURCHASES_SUCCESS = "GET_PURCHASES_SUCCESS"
export const GET_PURCHASES_ERROR = "GET_PURCHASES_ERROR"

////////////////////PRODUCTS//////////////////////

export const GET_PRODUCTS = "GET_PRODUCTS"
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS"

export const GET_CERTAIN_PRODUCT = "GET_CERTAIN_PRODUCT"
export const GET_CERTAIN_PRODUCT_SUCCESS = "GET_CERTAIN_PRODUCT_SUCCESS"
export const GET_CERTAIN_PRODUCT_FAIL = "GET_CERTAIN_PRODUCT_FAIL"

export const GET_CATEGORIES = "GET_CATEGORIES"
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS"

export const GET_SUPPLIERS = "GET_SUPPLIERS"
export const GET_SUPPLIERS_SUCCESS = "GET_SUPPLIERS_SUCCESS"

export const GET_CHARACTERISTICS = "GET_CHARACTERISTICS"
export const GET_CHARACTERISTICS_SUCCESS = "GET_CHARACTERISTICS_SUCCESS"

export const POST_NEW_CHARACTERISTIC = "POST_NEW_CHARACTERISTIC"
export const POST_NEW_CHARACTERISTIC_SUCCESS = "POST_NEW_CHARACTERISTIC_SUCCESS"
export const POST_NEW_CHARACTERISTIC_FAIL = "POST_NEW_CHARACTERISTIC_FAIL"

export const POST_CHANGE_PRODUCT_ACTIVITY = "POST_CHANGE_PRODUCT_ACTIVITY"
export const POST_CHANGE_PRODUCT_ACTIVITY_SUCCESS = "POST_CHANGE_PRODUCT_ACTIVITY_SUCCESS"
export const POST_CHANGE_PRODUCT_ACTIVITY_FAIL = "POST_CHANGE_PRODUCT_ACTIVITY_FAIL"

export const POST_ADD_PRODUCT = "POST_ADD_PRODUCT"
export const POST_ADD_PRODUCT_SUCCESS = "POST_ADD_PRODUCT_SUCCESS"
export const POST_ADD_PRODUCT_FAIL = "POST_ADD_PRODUCT_FAIL"

export const PATCH_PRODUCT = "PATCH_PRODUCT"
export const PATCH_PRODUCT_SUCCESS = "PATCH_PRODUCT_SUCCESS"
export const PATCH_PRODUCT_FAIL = "PATCH_PRODUCT_FAIL"

export const POST_EXCEL_ITEM = "POST_EXCEL_ITEM"
export const POST_EXCEL_ITEM_SUCCESS = "POST_EXCEL_ITEM_SUCCESS"
export const POST_EXCEL_ITEM_FAIL = "POST_EXCEL_ITEM_FAIL"

////////////////////CATEGORIES//////////////////////

export const POST_NEW_CATEGORY = "POST_NEW_CATEGORY"
export const POST_NEW_CATEGORY_SUCCESS = "POST_NEW_CATEGORY_SUCCESS"
export const POST_NEW_CATEGORY_FAIL = "POST_NEW_CATEGORY_FAIL"

export const POST_CHANGE_CATEGORY_ACTIVITY = "POST_CHANGE_CATEGORY_ACTIVITY"
export const POST_CHANGE_CATEGORY_ACTIVITY_SUCCESS = "POST_CHANGE_CATEGORY_ACTIVITY_SUCCESS"
export const POST_CHANGE_CATEGORY_ACTIVITY_FAIL = "POST_CHANGE_CATEGORY_ACTIVITY_FAIL"

export const PATCH_CATEGORY = "PATCH_CATEGORY"
export const PATCH_CATEGORY_SUCCESS = "PATCH_CATEGORY_SUCCESS"
export const PATCH_CATEGORY_FAIL = "PATCH_CATEGORY_FAIL"

////////////////////SUPPLIERS//////////////////////

export const GET_SUPPLIERS_LIST = "GET_SUPPLIERS_LIST"
export const GET_SUPPLIERS_LIST_SUCCESS = "GET_SUPPLIERS_LIST_SUCCESS"

export const GET_CURRENT_SUPPLIER = "GET_CURRENT_SUPPLIER"
export const GET_CURRENT_SUPPLIER_SUCCESS = "GET_CURRENT_SUPPLIER_SUCCESS"
export const GET_CURRENT_SUPPLIER_FAIL = "GET_CURRENT_SUPPLIER_FAIL"

export const POST_CHANGE_SUPPLIERS_ACTIVITY = "POST_CHANGE_SUPPLIERS_ACTIVITY"
export const POST_CHANGE_SUPPLIERS_ACTIVITY_SUCCESS = "POST_CHANGE_SUPPLIERS_ACTIVITY_SUCCESS"
export const POST_CHANGE_SUPPLIERS_ACTIVITY_FAIL = "POST_CHANGE_SUPPLIERS_ACTIVITY_FAIL"

export const PATCH_SUPPLIER = "PATCH_SUPPLIER"
export const PATCH_SUPPLIER_SUCCESS = "PATCH_SUPPLIER_SUCCESS"
export const PATCH_SUPPLIER_FAIL = "PATCH_SUPPLIER_FAIL"

////////////////////CUSTOM TENDER//////////////////////

export const GET_TENDER_TYPES = "GET_TENDER_TYPES"
export const GET_TENDER_TYPES_SUCCESS = "GET_TENDER_TYPES_SUCCESS"

export const POST_CUSTOM_APPLICATION = "POST_CUSTOM_APPLICATION"
export const POST_CUSTOM_APPLICATION_SUCCESS = "POST_CUSTOM_APPLICATION_SUCCESS"
export const POST_CUSTOM_APPLICATION_FAIL = "POST_CUSTOM_APPLICATION_FAIL"

export const PATCH_CUSTOM_APPLICATION = "PATCH_CUSTOM_APPLICATION"
export const PATCH_CUSTOM_APPLICATION_SUCCESS = "PATCH_CUSTOM_APPLICATION_SUCCESS"
export const PATCH_CUSTOM_APPLICATION_FAIL = "PATCH_CUSTOM_APPLICATION_FAIL"

////////////////////NOTIFICATIONS/////////////////////

export const GET_NUMBER_OF_NEW_NOTIFICATIONS = "GET_NUMBER_OF_NEW_NOTIFICATIONS"
export const GET_NUMBER_OF_NEW_NOTIFICATIONS_SUCCESS = "GET_NUMBER_OF_NEW_NOTIFICATIONS_SUCCESS"

export const PATCH_NUMBER_OF_NEW_NOTIFICATIONS = "PATCH_NUMBER_OF_NEW_NOTIFICATIONS"
export const PATCH_NUMBER_OF_NEW_NOTIFICATIONS_SUCCESS = "PATCH_NUMBER_OF_NEW_NOTIFICATIONS_SUCCESS"

export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS"
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS"

export const PATCH_NOTIFICATION_STATUS = "PATCH_NOTIFICATION_STATUS"

export const RESET_NOTIFICATIONS = "RESET_NOTIFICATIONS"

////////////////////////////////////SHIPMENT//////////////////////////
export const GET_SINGLE_SHIPMENT = "GET_SINGLE_SHIPMENT"
export const GET_SHIPMENT = "GET_SHIPMENT"
export const GET_SHIPMENT_SUCCESS = "GET_SHIPMENT_SUCCESS"
export const CREATE_SHIPMENT = "CREATE_SHIPMENT"
export const DELETE_SHIPMENT = "DELETE_SHIPMENT"
export const CHANGE_SHIPMENT = "CHANGE_SHIPMENT"
export const GET_SHIPMENT_REPORTS = "GET_SHIPMENT_REPORTS"
export const GET_SHIPMENT_REPORTS_SUCCESS = "GET_SHIPMENT_REPORTS_SUCCESS"
export const CREATE_SHIPMENT_REPORT = "CREATE_SHIPMENT_REPORT"
export const DELETE_SHIPMENT_REPORT = "DELETE_SHIPMENT_REPORT"
export const CHANGE_SHIPMENT_REPORT = "CHANGE_SHIPMENT_REPORT"
export const GET_REPORT_DATA = "GET_REPORT_DATA"
export const GET_REPORT_DATA_SUCCESS = "GET_REPORT_DATA_SUCCESS"
export const ADD_REPORT_DATA = "ADD_REPORT_DATA"
export const CHANGE_REPORT_DATA = "CHANGE_REPORT_DATA"
export const DELETE_REPORT_DATA = "DELETE_REPORT_DATA"
export const SEND_MAIL = "SEND_MAIL"
export const CHANGE_ACCOUNT_SHIPMENT = "CHANGE_ACCOUNT_SHIPMENT"
