import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import {
    postExcel,
    resetError,
    searchCatalog,
    searchSuppliers,
    postSupplier,
    postCatalogItem,
    postBuyItem,
    patchBuyItem,
    deleteBuyItem,
    postAdditionalItem,
    patchAdditionalItem,
    deleteAdditionalItem,
    addSellCalculation,
    updateSellCalculation,
    deleteSellCalculation,
    sendAdditionals,
    getPurchase,
    changePurchase,
} from "../../actions/calculationActions"
import { getDeal } from "../../actions/dashboardActions"
import { getCategories, getCharacteristics } from "../../actions/productsActions"

import DefaultButton from "../HelperComponents/Buttons/DefaultButton/DefaultButton"
import Snack from "../HelperComponents/Snack/Snack"
import PopperInfo from "../HelperComponents/PopperInfo/PopperInfo"
import DialogComponent from "../HelperComponents/DialogComponent/DialogComponent"
import SelectComponent from "../HelperComponents/SelectComponent/SelectComponent"
import MultiSelectComponent from "../HelperComponents/MultiSelectComponent/MultiSelectComponent"

import RefreshIcon from "../../assets/image/refresh-line.svg"
import DeleteIcon from "../../assets/image/delete.svg"
import TemplateIcon from "../../assets/image/file-template.svg"
import SendShip from "../../assets/image/send_email.svg"
import DisabledSendShip from "../../assets/image/disabled_email.svg"
import AnchorDown from "../../assets/image/arrow_down.svg"

import "./Calculation.scss"
import ProductUpdateDialogs from "../Products/ProductUpdate/ProductUpdateDialogs"
import { formatNumber } from "../../helpers/functions"
import Shipment from "./Shipment/Shipment"
import CompleteShip from "../../assets/image/complete.svg";
import NotShip from "../../assets/image/failed.svg";

const initialAddBuyItemInfo = {
    name: "",
    price: "",
    supplier_name: "",
    unit: ""
}

class Calculation extends Component {
    state = {
        buyingName: "",
        anchorBuyingName: null,
        anchorUnitInput: null,
        anchorSupplierInput: null,
        buy_id: undefined,
        loading: false,
        additionalRow: false,
        addBuyItemInfo: initialAddBuyItemInfo,
        supplier_input: "",
        chosen_characteristics: [],
        searchCharacteristics: "",
        item_chars: [],

        openBuyingNamePopper: false,
        openUnitPopper: false,
        openSupplierPopper: false,
        openNewBuyItemDialog: false,
        openAddSupplierDialog: false,
        openCharacteristicsDialog: false,
        openAddCharDialog: false,
        openCategoriesDialog: false,
        charsIsOpen: false,

        chosen_category: "",
        sellName: "",
        sellPrice: "",
        singleSellId: null,
        sellComment: "",
        singleSell: {},
        additionalSell: [],
        total: "",
        profitSingle: "",
        openSendModal: false,
        errorSnack: null,
        successSnack: null,
        disableSendButton: true,

        openSingleSent: false,
        chosenId: null,

        idFieldAdditional: null,

        preliminarySalePrice: null,
        preliminaryPurchasePrice: null,
        profitPreliminary: null,

        showListSales: false,
        showListAdditional: false,

        preliminaryPurchase: null,

        inputSale: "",
        inputBuy: "",
    }

    componentDidMount() {
        const { tender } = this.props
        this.doRequest()
        if (tender && tender.calculation && tender.calculation.sell && tender.calculation.sell[0]) {
            const item = tender.calculation.sell[0]
            this.setState({
                singleSellId: item.id,
                sellPrice: item.price,
                sellName: item.name,
                sellComment: item.comment,
                singleSell: item,
                additionalSell: tender.calculation.additional,
                total: tender.calculation.buy_total_amount,
                profitSingle: tender.calculation.profit
            })
        }
    }

    componentDidUpdate(prevProps) {
        const { tender, getDeal, match: {params: {id}} } = this.props

        if (this.props.parsedData.sell && this.props.parsedData.sell !== prevProps.parsedData.sell) {
            this.setState({
                singleSell: this.props.parsedData.sell[0]
            })
        }

        if (this.props.parsedData.additional && this.props.parsedData.additional !== prevProps.parsedData.additional) {
            this.setState({
                additionalSell: this.props.parsedData.additional
            })

            this.unsentMailPresence(this.props.parsedData.additional)
        }

        if (this.props.parsedData.buy_total_amount !== prevProps.parsedData.buy_total_amount) {
            this.setState({
                total: this.props.parsedData.buy_total_amount
            })
            getDeal(id)
        }

        if (this.props.parsedData.profit !== prevProps.parsedData.profit) {
            this.setState({
                profitSingle: this.props.parsedData.profit
            })
        }
        if (prevProps.tender !== tender) {
            if (tender && tender.calculation && tender.calculation.sell && tender.calculation.sell[0]) {
                const item = tender.calculation.sell[0]
                this.setState({
                    singleSellId: item.id,
                    sellPrice: item.price,
                    sellName: item.name,
                    sellComment: item.comment,
                    singleSell: item,
                    additionalSell: tender.calculation.additional,
                    total: tender.calculation.buy_total_amount,
                    profitSingle: tender.calculation.profit
                })
            }
        }
    }

    doRequest = async () => {
        const { getCharacteristics, getCategories } = this.props
        getCharacteristics()
        getCategories()

        // Вызов данных по предрасчетам
        await this.props.getPurchase(this.props.deal.id)
        await this.setState({preliminaryPurchase: this.props.preliminaryPurchase.length > 0 ? this.props.preliminaryPurchase : null})
    }

    toggleDialogs = type => {
        type === "add_buy_item" &&
            this.setState(({ openNewBuyItemDialog }) => ({
                openNewBuyItemDialog: !openNewBuyItemDialog
            }))
        type === "add_supplier" &&
            this.setState(({ openAddSupplierDialog }) => ({
                openAddSupplierDialog: !openAddSupplierDialog
            }))
        type === "open" &&
            this.setState({
                charsIsOpen: true
            })
        type === "close" &&
            this.setState({
                charsIsOpen: false
            })
        type === "characteristics" &&
            this.setState(({ openCharacteristicsDialog }) => ({
                openCharacteristicsDialog: !openCharacteristicsDialog
            }))
        type === "addCharacteristics" &&
            this.setState(({ openAddCharDialog }) => ({
                openAddCharDialog: !openAddCharDialog
            }))
        type === "categories" &&
            this.setState(({ openCategoriesDialog }) => ({
                openCategoriesDialog: !openCategoriesDialog
            }))
    }

    changeBuyItemInfo = (value, field) => {
        const { addBuyItemInfo } = this.state
        const { categoriesList } = this.props
        let tempBuyItemInfo = { ...addBuyItemInfo }
        if (field === "name") {
            tempBuyItemInfo.name = value
        } else if (field === "price") {
            tempBuyItemInfo.price = value
        } else if (field === "supplier_name") {
            tempBuyItemInfo.supplier_name = value
        } else if (field === "unit") {
            tempBuyItemInfo.unit = value
        } else if (field === "categories") {
            let converted_categories
            categoriesList.forEach(cat => (value.slice(1) === cat.name ? (converted_categories = cat.id) : null))
            tempBuyItemInfo.category = converted_categories
            this.setState({ chosen_category: value })
        }
        this.setState({ addBuyItemInfo: tempBuyItemInfo })
    }

    getSellingTitle = () => {
        const {
            options: { calculation },
            role,
            deal: { status_display }
        } = this.props


        return (
            <div className="upload_excel_block no_select">

                {this.returnRole(status_display, role) && (
                    <>
                        <div className="templates_block">
                            <a href={calculation.import_template_wide} download className="usable_text text_hover">
                                <img src={TemplateIcon} alt="download template" />
                                Скачать шаблон
                            </a>
                        </div>
                        <input id="fileInput" className="fileInput" type="file" onChange={e => this.parseExcel(e)} />
                        <div
                            className="download_btn usable_text text_hover"
                            onClick={() => document.getElementById("fileInput").click()}
                        >
                            Загрузить таблицу
                        </div>
                    </>
                )}
            </div>
        )
    }

    parseExcel = async e => {
        e.persist()
        const {
            postExcel,
            match: {
                params: { id }
            },
            setReloading,
            unsetReloading
        } = this.props
        setReloading()
        let file = e.target.files[0]
        const formData = new FormData()
        formData.append("import_file", file)
        const res = await postExcel(id, formData)
        if (res.error) {
            this.setState({ message: res.error.response.data.import_file[0] })
        }
        document.getElementById("fileInput").value = ""
        unsetReloading()
    }

    closeSnack = () => {
        const { resetError } = this.props
        resetError()
    }

    handleKeyUp = async (e, id) => {
        const { searchCatalog } = this.props
        let value = e.target.value
        if (value.length >= 1 && value.trim()) {
            const res = await searchCatalog(value)
            if (res.payload && res.payload.status === 200) {
                this.setState({
                    buy_id: id,
                    anchorBuyingName: document.getElementById(`buying_name_input_${id}`),
                    openBuyingNamePopper: true
                })
            } else {
                this.clickAway()
            }
        } else {
            this.clickAway()
        }
    }

    handleUnitKeyUp = e => {
        const {
            options: {
                calculation: { units }
            }
        } = this.props
        let value = e.target.value
        if (value.length >= 1 && value.trim() && units.filter(el => el.toLowerCase().includes(e.target.value)).length) {
            this.setState({
                anchorUnitInput: document.getElementById(`unit_input`),
                openUnitPopper: true
            })
        } else {
            this.clickAway()
        }
    }

    handleSupplierKeyUp = async e => {
        const { searchSuppliers } = this.props
        let value = e.target.value
        if (value.length >= 1 && value.trim()) {
            const res = await searchSuppliers(value)
            if (res.payload && res.payload.status === 200) {
                this.setState({
                    anchorSupplierInput: document.getElementById(`supplier_input`),
                    openSupplierPopper: true
                })
            } else {
                this.clickAway()
            }
        } else {
            this.clickAway()
        }
    }

    selectCatalogItem = async id => {
        const {
            // parsedData: { tender },
            // tender,
            postBuyItem,
            match: {
                params: { id: ourId }
            }
        } = this.props
        const { buy_id } = this.state
        this.clickAway()
        const data = {
            catalog_item: id
        }
        await postBuyItem(ourId, buy_id, data)
    }

    selectSupplier = async name => {
        this.changeBuyItemInfo(name, "supplier_name")
        this.clickAway()
    }

    selectUnit = async unit => {
        this.changeBuyItemInfo(unit, "unit")
        this.clickAway()
    }

    removeBuyItem = async (buy_id, sell_id) => {
        const {
            parsedData: { tender },
            deleteBuyItem,
            match: {
                params: { id }
            }
        } = this.props
        await deleteBuyItem(tender || id, sell_id, buy_id)
    }

    changeBuyItemAmount = async (amount, buy_id, sell_id) => {
        const {
            parsedData: { tender },
            patchBuyItem,
            match: {
                params: { id: tenderID }
            }
        } = this.props
        const data = {
            amount: amount
        }

        await patchBuyItem(tender || tenderID, sell_id, buy_id, data)
    }

    changeBuyItemComment = async (comment, buy_id, sell_id) => {
        const {
            parsedData: { tender },
            patchBuyItem,
            match: {
                params: { id: tenderID }
            }
        } = this.props
        const data = {
            comment: comment
        }
        await patchBuyItem(tender || tenderID, sell_id, buy_id, data)
    }

    // Измнение стоимомти

    replaceCopyText = async (value, name_field) => {
        switch (name_field) {
            case "input_Sale":
                this.setState({inputSale: value.replaceAll(' ', '').replaceAll(',', '.')})
                break
            case "input_Buy":
                this.setState({inputBuy: value.replaceAll(' ', '').replaceAll(',', '.')})
                break
            default:
                break
        }
    }
    changePurchase = async (tender_id, value, name_field) => {
        // Запуск функции вычисления профита
        const profitCalculation = () => {
            // Формула профита
            if (this.state.preliminarySalePrice && this.state.preliminaryPurchasePrice) {
                this.setState({profitPreliminary: `${(100 - this.state.preliminaryPurchasePrice * 100 / this.state.preliminarySalePrice).toFixed(2)}%`}, () => {
                    this.props.changePurchase(tender_id, {"name_field": "profit", "value": this.state.profitPreliminary})
                })
            }
        }

        switch (name_field) {
            case "preliminary_sale_price":
                value = value.replaceAll(' ', '').replaceAll(',', '.')
                if (value.length > 0) {
                    this.setState({ preliminarySalePrice: Number(value) }, () => {
                      profitCalculation()
                      this.props.changePurchase(tender_id, {"name_field": "preliminary_sale_price", "value": this.state.preliminarySalePrice ? this.state.preliminarySalePrice : 0})
                    })
                }
                break
            case "preliminary_purchase_price":
                value = value.replaceAll(' ', '').replaceAll(',', '.')
                if (value.length > 0) {
                    this.setState({ preliminaryPurchasePrice: Number(value) }, () => {
                      profitCalculation()
                      this.props.changePurchase(tender_id, {"name_field": "preliminary_purchase_price", "value": this.state.preliminaryPurchasePrice ? this.state.preliminaryPurchasePrice : 0 })
                    })
                }
                break
            case "preliminary_sale_comment":
                if (value.length > 0) {
                    this.props.changePurchase(tender_id, {"name_field": "preliminary_sale_comment", "value": value})
                }
                break
            case "preliminary_purchase_comment":
                if (value.length > 0) {
                    this.props.changePurchase(tender_id, {"name_field": "preliminary_purchase_comment", "value": value})
                }
                break
            default:
                break
        }
    }

    addAdditionalItem = async name => {
        const {
            parsedData: { tender },
            postAdditionalItem,
            match: {
                params: { id: tenderID }
            }
        } = this.props
        const data = {
            name: name,
            total_price: 0,
            comment: ""
        }
        const res = await postAdditionalItem(tender || tenderID, data)
        if (res.payload && res.payload.status === 200) {
            this.setState({ additionalRow: false })
        } else if (res.error && res.error.response.data)  {
            this.setState({errorSnack: res.error.response.data.detail || res.error.response.data.error})
        }
    }

    changeAdditionalItem = async (field, value, id) => {
        const {
            parsedData: { tender },
            patchAdditionalItem,
            match: {
                params: { id: tenderID }
            }
        } = this.props
        let data = {}
        if (field === "name") {
            data.name = value
            data.send_mail = false
        } else if (field === "total_price") {
            data.total_price = value
            data.send_mail = false
        } else {
            data.comment = value
            data.send_mail = false
        }
        const res = await patchAdditionalItem(tender || tenderID, id, data)

        if (res.error && res.error.response.data) {
            this.setState({errorSnack: res.error.response.data.detail || res.error.response.data.error})
        }
    }

    removeAdditionalItem = async id => {
        const {
            parsedData: { tender },
            deleteAdditionalItem,
            match: {
                params: { id: tenderID }
            }
        } = this.props
        const res = await deleteAdditionalItem(tender || tenderID, id)
        if (res.error && res.error.response.data) {
            this.setState({errorSnack: res.error.response.data.detail || res.error.response.data.error})
        } else {
            this.setState({disableSendButton: false})
        }
    }

    clickAway = () =>
        this.setState({
            openBuyingNamePopper: false,
            anchorBuyingName: null,
            openUnitPopper: false,
            anchorUnitInput: null,
            openSupplierPopper: false,
            anchorSupplierInput: null
        })

    addNewBuyItem = async () => {
        const { postCatalogItem } = this.props
        const { addBuyItemInfo, characteristic_data, chosen_characteristics } = this.state
        this.setState({ loading: true })
        let converted_characteristics = []
        characteristic_data &&
            characteristic_data.forEach(char =>
                chosen_characteristics.forEach(chsn =>
                    chsn.slice(1).includes(char.char_name)
                        ? converted_characteristics.push({ value: char.value, char: char.char })
                        : null
                )
            )
        const data = {
            name: addBuyItemInfo.name,
            price: addBuyItemInfo.price,
            supplier_name: addBuyItemInfo.supplier_name,
            unit: addBuyItemInfo.unit,
            category: addBuyItemInfo.category,
            item_chars: converted_characteristics
        }
        const res = await postCatalogItem(data)
        if (res.payload && res.payload.status) {
            this.selectCatalogItem(res.payload.data.id)
            this.setState({ loading: false, addBuyItemInfo: initialAddBuyItemInfo, chosen_category: "" })
            this.toggleDialogs("add_buy_item")
        }
    }

    addSupplier = async () => {
        const { postSupplier } = this.props
        const { supplier_input } = this.state
        const data = {
            name: supplier_input
        }
        const res = await postSupplier(data)
        if (res.payload && res.payload.status) {
            this.changeBuyItemInfo(res.payload.data.name, "supplier_name")
            this.toggleDialogs("add_supplier")
        }
    }

    renderEmptyBuyingRow = (buy_id, defaultName) => {
        const {
            catalogItems,
            role,
            deal: { status_display }
        } = this.props
        const { openBuyingNamePopper, anchorBuyingName } = this.state
        return (
            <div className="new_buying_input_wrapper">
                <input
                    id={`buying_name_input_${buy_id}`}
                    type="text"
                    placeholder="Наименование"
                    onKeyUp={e => this.handleKeyUp(e, buy_id)}
                    autoComplete="off"
                    disabled={!this.returnRole(status_display, role)}
                />
                {anchorBuyingName && anchorBuyingName.id === `buying_name_input_${buy_id}` && (
                    <PopperInfo
                        open={openBuyingNamePopper}
                        anchorEl={anchorBuyingName}
                        clickAway={this.clickAway}
                        position="bottom-start"
                        classes="catalog_items_popper"
                        transition
                    >
                        <ul>
                            {catalogItems.map(({ id, name, price, unit, supplier }) => (
                                <li
                                    className="text_hover"
                                    onClick={() => this.selectCatalogItem(id, buy_id)}
                                    key={`catalog_item_${id}`}
                                >
                                    <p className="overflowed_p">
                                        {name} ({supplier.name}; {price} за {unit})
                                    </p>
                                </li>
                            ))}
                            {this.returnRole(status_display, role) && (
                                <li
                                    className="text_hover usable_text"
                                    onClick={() => {
                                        this.changeBuyItemInfo(defaultName, "name")
                                        this.toggleDialogs("add_buy_item")
                                        this.setState({ buy_id })
                                        this.clickAway()
                                    }}
                                >
                                    + Добавить
                                </li>
                            )}
                        </ul>
                    </PopperInfo>
                )}
            </div>
        )
    }

    //characteristic
    unique = arr => {
        let result = []
        for (let i = 0; i < arr.length; i++) {
            if (!result.some(el => el.includes(arr[i]))) {
                result.push(arr[i])
            } else {
                let indexes = []
                arr.forEach((el, idx) => (el.toString().includes(arr[i].toString()) ? indexes.push(idx) : null))
                indexes.forEach(idx => result.splice(idx, 1))
            }
        }

        return result
    }

    handleSelectChangeCR = e => {
        let chosen_characteristics = e.target.value.filter(el => el !== undefined)
        this.setState({ chosen_characteristics: this.unique(chosen_characteristics) })
    }
    handleSearchCR = e => {
        this.setState({ searchCharacteristics: e.target.value })
    }
    resetCR = () => {
        this.setState({ chosen_characteristics: [], searchCharacteristics: "" })
    }
    //characteristic

    returnRole = (status_display, role) => {
        if (role === "admin" || role === "manager" || role === "senior_manager") {
            return true
        } else {
            if (
                status_display === "Новый" ||
                status_display === "В работу" ||
                status_display === "Расчёт" ||
                status_display === "На проверке"
            ) {
                return true
            }
            return false
        }
    }


    disableAdditional = (status_display, role, email_sent) => {
        if (role !== "admin" && email_sent) {
            return true
        } else {
            if (status_display === "Новый" || status_display === "В работу") {
                return true
            }
            return false
        }
    }

    unsentMailPresence = (array) => {
        const filter = array.filter(item => !item.send_mail)
        if (filter.length) {
            this.setState({disableSendButton: false})
        } else {
            this.setState({disableSendButton: true})
        }
    }

    closeSuccessSnack = () => this.setState({ successSnack: null })
    closeErrorSnack = () => this.setState({ errorSnack: null })

    render() {
        const {
            parsedData: { idling, additional, buy_total_amount, profit, sell, sell_total_amount },
            calculation_error,
            toggleDialog,
            deal: { status_display },
            options: {
                calculation: { units }
            },
            suppliers,
            categoriesList,
            characteristicsList,
            addSellCalculation,
            updateSellCalculation,
            match: {
                params: { id }
            },
            role,
        } = this.props
        const {
            openNewBuyItemDialog,
            loading,
            additionalRow,
            addBuyItemInfo,
            openAddSupplierDialog,
            openCharacteristicsDialog,
            openCategoriesDialog,
            openAddCharDialog,
            supplier_input,
            openUnitPopper,
            openSupplierPopper,
            charsIsOpen,
            anchorSupplierInput,
            anchorUnitInput,
            chosen_characteristics,
            searchCharacteristics,
            chosen_category,
            item_chars,
            sellName,
            sellPrice,
            sellComment,
            singleSellId,
            singleSell,
            additionalSell,
            total,
            profitSingle,
            openSendModal,
            errorSnack,
            successSnack,
            disableSendButton,
            preliminaryPurchase
        } = this.state
        const categories = categoriesList.filter(el => el.is_active).map(el => "z" + el.name)
        let characteristic_names = []
        characteristicsList.forEach(el => characteristic_names.push("z" + el.name))
        const characteristics = characteristic_names.filter(el =>
            el.toLowerCase().includes(searchCharacteristics.toLowerCase())
        )
        let converted_characteristics = []
        characteristicsList.forEach(char =>
            chosen_characteristics.forEach(el =>
                el.slice(1).includes(char.name) ? converted_characteristics.push(char) : null
            )
        )

        // Methods anchor
        // Якорь вверх
        const anchorUp = () => {
            document.querySelector('.selling_block').scrollIntoView()
        }

        // Якорь вниз
        const anchorBottom = (e) => {
            document.querySelector('.selling_block').scrollIntoView({ block: "end" })
        }

        return (
            <div className="calculation_wrapper">
                { preliminaryPurchase
                    ? <div className="preliminary_calculation_wrapper">
                        <div className="container_titles">
                            <div className="column_title">Продажа</div>
                            <div className="column_title">Закупка</div>
                        </div>
                        <div className="container_preliminary_calculation">
                            <div className="pre-sale_block">
                            <div className="table_container">
                                <div className="table_header no_select">
                                    <div className="table_row">
                                        <div className="row_item">
                                            <p>Стоимость</p>
                                        </div>
                                        <div className="row_item">
                                            <p>Коммент</p>
                                        </div>
                                        <div className="row_item">
                                            <p>Профит</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="table_body">
                                    <input
                                        type="text"
                                        defaultValue={preliminaryPurchase[0].preliminary_sale_price || this.state.preliminarySalePrice || 0}
                                        disabled={!this.returnRole(status_display, role) && role !== 'admin' && status_display !== 'На проверке'}
                                        placeholder="Стоимость"
                                        onChange={e => this.replaceCopyText(e.target.value, 'input_Sale')}
                                        onBlur={e => this.changePurchase(id, e.target.value, "preliminary_sale_price")}
                                    />
                                    <input
                                        type="text"
                                        disabled={!this.returnRole(status_display, role) && role !== 'admin' && status_display !== 'На проверке'}
                                        defaultValue={preliminaryPurchase[0].preliminary_sale_comment || ''}
                                        placeholder="-Коммент"
                                        onBlur={e => this.changePurchase(id, e.target.value, "preliminary_sale_comment")}
                                    />
                                    <p>
                                        {this.state.profitPreliminary || preliminaryPurchase[0].profit}
                                    </p>
                                </div>
                            </div>
                        </div>
                            <div className="pre-purchase_block">
                            <div className="table_container">
                                <div className="table_header no_select">
                                    <div className="table_row">
                                        <div className="row_item">
                                            <p>Стоимость</p>
                                        </div>
                                        <div className="row_item">
                                            <p>Коммент</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="table_body">
                                    <input
                                        type="text"
                                        defaultValue={preliminaryPurchase[0].preliminary_purchase_price || this.state.preliminaryPurchasePrice || 0}
                                        disabled={!this.returnRole(status_display, role) && role !== 'admin' && status_display !== 'На проверке'}
                                        placeholder="Стоимость"
                                        onChange={e => this.replaceCopyText(e.target.value, 'input_Buy')}
                                        onBlur={e => this.changePurchase(id, e.target.value, "preliminary_purchase_price")}
                                    />
                                    <input
                                        type="text"
                                        defaultValue={preliminaryPurchase[0].preliminary_purchase_comment}
                                        disabled={!this.returnRole(status_display, role) && role !== 'admin' && status_display !== 'На проверке'}
                                        placeholder="-Коммент"
                                        onBlur={e => this.changePurchase(id, e.target.value, "preliminary_purchase_comment")}
                                    />
                                </div>
                            </div>
                        </div>
                        </div>
                        <div className="container_get_selling">
                            {this.getSellingTitle()}
                        </div>
                      </div>
                    : <div className="preliminary_calculation_wrapper">

                        <div className="container_titles">
                            <div className="column_title">Продажа</div>
                            <div className="column_title">Закупка</div>
                        </div>
                        <div className="container_preliminary_calculation">
                            <div className="pre-sale_block">
                            <div className="table_container">
                                <div className="table_header no_select">
                                    <div className="table_row">
                                        <div className="row_item">
                                            <p>Стоимость</p>
                                        </div>
                                        <div className="row_item">
                                            <p>Коммент</p>
                                        </div>
                                        <div className="row_item">
                                            <p>Профит</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="table_body">
                                    <input
                                        type="text"
                                        disabled={!this.returnRole(status_display, role)}
                                        placeholder="Стоимость"
                                        name="inputSale"
                                        onChange={(e) => this.replaceCopyText(e.target.value, "input_Sale")}
                                        onBlur={e => this.changePurchase(id, e.target.value, "preliminary_sale_price")}
                                    />
                                    <input
                                        type="text"
                                        disabled={!this.returnRole(status_display, role)}
                                        placeholder="-Коммент"
                                        onBlur={e => this.changePurchase(id, e.target.value, "preliminary_sale_comment")}
                                    />
                                    <p>
                                        {this.state.profitPreliminary ? this.state.profitPreliminary : "0"}
                                    </p>
                                </div>
                            </div>
                        </div>
                            <div className="pre-purchase_block">
                            <div className="table_container">
                                <div className="table_header no_select">
                                    <div className="table_row">
                                        <div className="row_item">
                                            <p>Стоимость</p>
                                        </div>
                                        <div className="row_item">
                                            <p>Коммент</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="table_body">
                                    <input
                                        type="text"
                                        disabled={!this.returnRole(status_display, role)}
                                        placeholder="Стоимость"
                                        name="inputBuy"
                                        onChange={(e) => this.replaceCopyText(e.target.value, "input_Buy")}
                                        onBlur={e => this.changePurchase(id, e.target.value, "preliminary_purchase_price")}
                                    />
                                    <input
                                        type="text"
                                        disabled={!this.returnRole(status_display, role)}
                                        placeholder="-Коммент"
                                        onBlur={e => this.changePurchase(id, e.target.value, "preliminary_purchase_comment")}
                                    />
                                </div>
                            </div>
                        </div>
                        </div>
                        <div className="container_get_selling">
                            {this.getSellingTitle()}
                        </div>
                      </div>
                }

                {idling ? (
                    <>
                        <div className="btns-container">
                            {
                                this.state.showListSales
                                    ? <button
                                        onClick={() => this.setState({showListSales: false})}
                                        className="btn-hide-list-sales">Скрыть список</button>
                                    : <button
                                        onClick={() => this.setState({showListSales: true})}
                                        className="btn-show-list-sales">Показать список Продажа / Закупка</button>
                            }
                        </div>
                        <div className={this.state.showListSales ? "selling_buying_wrapper" : "d-none"}>
                            <div className="selling_block">
                                <div className="table_container">
                                    <div className="table_header no_select">
                                        <div className="table_row">
                                            <div className="row_item">
                                                <p>№</p>
                                            </div>
                                            <div className="row_item">
                                                <p>Наименование</p>
                                            </div>
                                            <div className="row_item">
                                                <p>Кол-во</p>
                                            </div>
                                            <div className="row_item">
                                                <p>Ед. изм.</p>
                                            </div>
                                            <div className="row_item">
                                                <p>Цена за ед.</p>
                                            </div>
                                            <div className="row_item">
                                                <p>Общая сумма</p>
                                            </div>
                                            <div className="row_item">
                                                <p>Комментарий</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table_body">
                                        <div className="table_row">
                                            <div className="row_item">1</div>
                                            <div className="row_item">
                                                <input
                                                    className="sell_input"
                                                    value={sellName}
                                                    type="text"
                                                    onChange={e => this.setState({ sellName: e.target.value })}
                                                    autoComplete="off"
                                                    disabled={!this.returnRole(status_display, role)}
                                                    placeholder="Наименование"
                                                    onBlur={e => {
                                                        singleSellId
                                                            ? updateSellCalculation(id, singleSellId, {
                                                                  name: e.target.value,
                                                                  price: sellPrice || 0,
                                                                  amount: 1,
                                                                  unit: "шт.",
                                                                  total_price: sellPrice || 0,
                                                                  comment: sellComment
                                                              })
                                                            : addSellCalculation(id, {
                                                                  name: e.target.value,
                                                                  price: sellPrice || 0,
                                                                  amount: 1,
                                                                  unit: "шт.",
                                                                  total_price: sellPrice || 0,
                                                                  comment: sellComment
                                                              }).then(res => {
                                                                  if (
                                                                      res.payload &&
                                                                      res.payload.status &&
                                                                      res.payload.status === 200
                                                                  ) {
                                                                      this.setState({
                                                                          singleSellId: res.payload.data.sell[0].id
                                                                      })
                                                                  } else {
                                                                  }
                                                              })
                                                    }}
                                                />
                                            </div>
                                            <div className="row_item">1</div>
                                            <div className="row_item">шт.</div>
                                            <div className="row_item">
                                                <input
                                                    className="sell_input"
                                                    value={sellPrice}
                                                    type="number"
                                                    disabled={!this.returnRole(status_display, role)}
                                                    onChange={e => this.setState({ sellPrice: e.target.value })}
                                                    onBlur={e => {
                                                        singleSellId &&
                                                            updateSellCalculation(id, singleSellId, {
                                                                name: sellName,
                                                                price: +e.target.value,
                                                                amount: 1,
                                                                unit: "шт.",
                                                                total_price: +e.target.value || 0,
                                                                comment: sellComment
                                                            })
                                                    }}
                                                    autoComplete="off"
                                                    placeholder="Цена"
                                                />
                                            </div>
                                            <div className="row_item">{sellPrice}</div>
                                            <div className="row_item">
                                                <input
                                                    className="sell_input"
                                                    value={sellComment}
                                                    disabled={!this.returnRole(status_display, role)}
                                                    type="text"
                                                    onChange={e => this.setState({ sellComment: e.target.value })}
                                                    onBlur={e => {
                                                        singleSellId &&
                                                            updateSellCalculation(id, singleSellId, {
                                                                name: sellName,
                                                                price: sellPrice || 0,
                                                                amount: 1,
                                                                unit: "шт.",
                                                                total_price: sellPrice || 0,
                                                                comment: e.target.value
                                                            })
                                                    }}
                                                    autoComplete="off"
                                                    placeholder="Комментарий"
                                                />
                                            </div>
                                        </div>
                                        <div className="table_row">
                                            <div className="row_item" />
                                            <div className="row_item bold_text">ИТОГО</div>
                                            <div className="row_item" />
                                            <div className="row_item" />
                                            <div className="row_item" />
                                            <div className="row_item bold_text">{sellPrice}</div>
                                            <div className="row_item" />
                                        </div>
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="buying_block">
                                <div className="column_title"></div>
                                <div className="table_container">
                                    <div className="table_header no_select">
                                        <div className="table_row">
                                            <div className="row_item">
                                                <p>Наименование</p>
                                            </div>
                                            <div className="row_item">
                                                <p>Поставщик</p>
                                            </div>
                                            <div className="row_item">
                                                <p>Кол-во</p>
                                            </div>
                                            <div className="row_item">
                                                <p>Ед. изм.</p>
                                            </div>
                                            <div className="row_item">
                                                <p>Цена за ед.</p>
                                            </div>
                                            <div className="row_item">
                                                <p>Общая сумма</p>
                                            </div>
                                            <div className="row_item">
                                                <p>Комментарий</p>
                                            </div>
                                        </div>
                                    </div>

                                    {singleSell && (
                                        <div
                                            className="table_body"
                                            style={singleSellId ? {} : { pointerEvents: "none", opacity: "0.5" }}
                                        >
                                            <div className="table_row">
                                                {singleSell &&
                                                singleSell.buy &&
                                                singleSell.buy.length > 0 &&
                                                singleSell.buy[0] ? (
                                                    <Fragment>
                                                        <div className="row_item medium_with_opacity_name no_select relative_div">
                                                            <p
                                                                className="overflowed_line"
                                                                title={singleSell.buy[0].name || "-"}
                                                            >
                                                                {singleSell.buy[0].name || "-"}
                                                            </p>
                                                        </div>
                                                        <div className="row_item relative_div">
                                                            <p
                                                                className="overflowed_line"
                                                                title={singleSell.buy[0].supplier.name}
                                                            >
                                                                {singleSell.buy[0].supplier.name}
                                                            </p>
                                                        </div>
                                                        <div className="row_item">
                                                            <input
                                                                type="number"
                                                                defaultValue={singleSell.buy[0].amount}
                                                                disabled={!this.returnRole(status_display, role)}
                                                                onBlur={e =>
                                                                    this.changeBuyItemAmount(
                                                                        e.target.value,
                                                                        singleSell.buy[0].id,
                                                                        singleSell.buy[0].sell_item
                                                                    )
                                                                }
                                                                autoComplete="off"
                                                            />
                                                        </div>
                                                        <div className="row_item">
                                                            <p>{singleSell.buy[0].unit}</p>
                                                        </div>
                                                        <div className="row_item">
                                                            <p>{formatNumber(singleSell.buy[0].price.toFixed(2))}</p>
                                                        </div>
                                                        <div className="row_item">
                                                            <p>
                                                                {formatNumber(singleSell.buy[0].total_price.toFixed(2))}
                                                            </p>
                                                        </div>
                                                        <div className="row_item relative_div">
                                                            <p
                                                                className="overflowed_line"
                                                                title={singleSell.buy[0].comment || "-"}
                                                            >
                                                                <input
                                                                    type="text"
                                                                    disabled={!this.returnRole(status_display, role)}
                                                                    defaultValue={singleSell.buy[0].comment || "-"}
                                                                    onBlur={e =>
                                                                        this.changeBuyItemComment(
                                                                            e.target.value,
                                                                            singleSell.buy[0].id,
                                                                            singleSell.buy[0].sell_item
                                                                        )
                                                                    }
                                                                    autoComplete="off"
                                                                />
                                                            </p>
                                                            <img
                                                                src={RefreshIcon}
                                                                alt=""
                                                                className="text_hover"
                                                                onClick={() =>
                                                                    this.removeBuyItem(
                                                                        singleSell.buy[0].id,
                                                                        singleSell.buy[0].sell_item
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </Fragment>
                                                ) : (
                                                    <div>{this.renderEmptyBuyingRow(singleSellId, "")}</div>
                                                )}
                                            </div>
                                            <div className="table_row flexible">
                                                <div className="column_title">Дополнительные расходы</div>
                                                {additionalSell && additionalSell.length > 0 && (
                                                    <button
                                                        disabled={disableSendButton}
                                                        onClick={() => this.setState({ openSendModal: true })}
                                                        className="send-btn"
                                                    >
                                                        Отправить
                                                    </button>
                                                )}
                                            </div>
                                            <div className="table_header no_select">
                                                <div className="table_row">
                                                    <div className="row_item">
                                                        <p>Наименование</p>
                                                    </div>
                                                    <div className="row_item" />
                                                    <div className="row_item" />
                                                    <div className="row_item" />
                                                    <div className="row_item" />
                                                    <div className="row_item">
                                                        <p>Общая сумма</p>
                                                    </div>
                                                    <div className="row_item">
                                                        <p>Комментарий</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {additionalSell &&
                                                additionalSell.map(({ id, comment, name, total_price, send_mail }) => (
                                                    <div className="table_row" key={`additional_item_${id}`}>
                                                        <div className="row_item">
                                                            <p className="additional_input_wrapper">
                                                                <input
                                                                    type="text"
                                                                    placeholder="Наименование"
                                                                    defaultValue={name}
                                                                    // disabled={!this.returnRole(status_display, role)}
                                                                    disabled={!this.disableAdditional(role, send_mail)}
                                                                    onBlur={e =>
                                                                        this.changeAdditionalItem(
                                                                            "name",
                                                                            e.target.value,
                                                                            id
                                                                        )
                                                                    }
                                                                    autoComplete="off"
                                                                />
                                                            </p>
                                                        </div>
                                                        <div className="row_item" />
                                                        <div className="row_item" />
                                                        <div className="row_item" />
                                                        <div className="row_item" />
                                                        <div className="row_item">
                                                            <p>
                                                                <input
                                                                    type="text"
                                                                    defaultValue={total_price || 0}
                                                                    // disabled={!this.returnRole(status_display, role)}
                                                                    disabled={this.disableAdditional(role, send_mail)}
                                                                    onBlur={e =>
                                                                        this.changeAdditionalItem(
                                                                            "total_price",
                                                                            e.target.value,
                                                                            id
                                                                        )
                                                                    }
                                                                    autoComplete="off"
                                                                />
                                                            </p>
                                                        </div>
                                                        <div className="row_item relative_div">
                                                            <p className="overflowed_line" title={comment || "-"}>
                                                                <input
                                                                    type="text"
                                                                    defaultValue={comment || "-"}
                                                                    // disabled={!this.returnRole(status_display, role)}
                                                                    disabled={this.disableAdditional(role, send_mail)}
                                                                    onBlur={e =>
                                                                        this.changeAdditionalItem(
                                                                            "comment",
                                                                            e.target.value,
                                                                            id
                                                                        )
                                                                    }
                                                                    autoComplete="off"
                                                                />
                                                            </p>
                                                            <button
                                                                className="del_btn text_hover"
                                                                type="button"
                                                                disabled={this.disableAdditional(role, send_mail)}
                                                                aria-label="Удалить дополнительный расход"
                                                                onClick={() => this.removeAdditionalItem(id)}
                                                            >
                                                                <img src={DeleteIcon} alt="Delete icon" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                ))}
                                            <div className="table_row">
                                                <div className="solo_item">
                                                    {additionalRow ? (
                                                        <p className="additional_input_wrapper">
                                                            <input
                                                                type="text"
                                                                id="additional_item_input"
                                                                placeholder="Наименование"
                                                                onBlur={e => this.addAdditionalItem(e.target.value)}
                                                                onKeyPress={e =>
                                                                    e.key === "Enter" &&
                                                                    this.addAdditionalItem(e.target.value)
                                                                }
                                                                autoComplete="off"
                                                                disabled={!this.returnRole(status_display, role)}
                                                            />
                                                        </p>
                                                    ) : (
                                                        this.returnRole(status_display, role) && (
                                                            <p
                                                                className="text_hover usable_text add_additional_item"
                                                                onClick={() => this.setState({ additionalRow: true })}
                                                            >
                                                                + Добавить
                                                            </p>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                            <div className="table_row">
                                                <div className="row_item bold_text">ИТОГО</div>
                                                <div className="row_item" />
                                                <div className="row_item" />
                                                <div className="row_item" />
                                                <div className="row_item" />
                                                <div className="row_item bold_text">
                                                    {total && formatNumber(total.toFixed(2))}
                                                </div>
                                                <div className="row_item" />
                                            </div>
                                            <div className="table_row last_row">
                                                <div className="row_item bold_text">ИТОГО ПРОФИТ</div>
                                                <div className="row_item" />
                                                <div className="row_item" />
                                                <div className="row_item" />
                                                <div className="row_item" />
                                                <div className="row_item bold_text">
                                                    {profitSingle ? (
                                                        <div>
                                                            {formatNumber(profitSingle.toFixed(2))}
                                                            {profitSingle && total ? (
                                                                <span
                                                                    className={`${
                                                                        profitSingle / total &&
                                                                        (profitSingle / total) * 100 > 0
                                                                            ? "green_percent"
                                                                            : "red_percent"
                                                                    }`}
                                                                >
                                                                    {" "}
                                                                    (
                                                                    {formatNumber(
                                                                        ((profitSingle / sellPrice) * 100).toFixed(1)
                                                                    )}
                                                                    %)
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                    ) : (
                                                        0
                                                    )}
                                                </div>
                                                <div className="row_item" />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <hr className="no_margin" />
                        <div className={this.state.showListSales ? "" : "d-none"}>
                            <Shipment />
                        </div>
                        {status_display === "Расчёт" && (
                            <div className="dialog_buttons">
                                <DefaultButton
                                    variant="contained"
                                    onClick={() => toggleDialog("to_check")}
                                    classes="calculate_btn"
                                >
                                    На проверку
                                </DefaultButton>
                            </div>
                        )}
                    </>
                ) : (
                    <Fragment>
                        <div className="btns-container">
                            {
                                this.state.showListSales
                                    ? <button
                                        onClick={() => this.setState({showListSales: false})}
                                        className="btn-hide-list-sales ">Скрыть список</button>
                                    : <button
                                        onClick={() => this.setState({showListSales: true})}
                                        className="btn-show-list-sales">Показать список Продажа / Закупка</button>
                            }
                        </div>

                        <div className={this.state.showListSales ? "selling_buying_wrapper" : "d-none"}>
                            <div className="anchor_table">
                                <span className="anchor" onClick={anchorUp}>
                                    <img src={AnchorDown} alt="Якорь"/>
                                </span>
                                <span className="anchor" onClick={anchorBottom}>
                                    <img src={AnchorDown} alt="Якорь"/>
                                </span>
                            </div>
                            <div className="selling_block">
                                {this.getSellingTitle()}
                                <div className="table_container">
                                    <div className="table_header no_select">
                                        <div className="table_row">
                                            <div className="row_item">
                                                <p>№</p>
                                            </div>
                                            <div className="row_item">
                                                <p>Наименование</p>
                                            </div>
                                            <div className="row_item">
                                                <p>Кол-во</p>
                                            </div>
                                            <div className="row_item">
                                                <p>Ед. изм.</p>
                                            </div>
                                            <div className="row_item">
                                                <p>Цена за ед.</p>
                                            </div>
                                            <div className="row_item">
                                                <p>Общая сумма</p>
                                            </div>
                                            <div className="row_item">
                                                <p>Комментарий</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table_body">
                                        {sell.map(({ id, amount, comment, name, price, total_price, unit }, key) => (
                                            <div className="table_row" key={`selling_${id}`}>
                                                <div className="row_item">{key + 1}</div>
                                                <div className="row_item">
                                                    <p className="overflowed_line medium_text" title={name}>
                                                        {name}
                                                    </p>
                                                </div>
                                                <div className="row_item">{amount}</div>
                                                <div className="row_item">{unit || "-"}</div>
                                                <div className="row_item">{formatNumber(price.toFixed(2))}</div>
                                                <div className="row_item">{formatNumber(total_price.toFixed(2))}</div>
                                                <div className="row_item">
                                                    <p className="overflowed_line" title={comment}>
                                                        {comment || "-"}
                                                    </p>
                                                </div>
                                            </div>
                                        ))}
                                        <div className="table_row">
                                            <div className="row_item" />
                                            <div className="row_item bold_text">ИТОГО</div>
                                            <div className="row_item" />
                                            <div className="row_item" />
                                            <div className="row_item" />
                                            <div className="row_item bold_text">
                                                {formatNumber(sell_total_amount.toFixed(2))}
                                            </div>
                                            <div className="row_item" />
                                        </div>
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            <div className="buying_block">
                                <div className="column_title"></div>
                                <div className="table_container">
                                    <div className="table_header no_select">
                                        <div className="table_row">
                                            <div className="row_item">
                                                <p>Наименование</p>
                                            </div>
                                            <div className="row_item">
                                                <p>Поставщик</p>
                                            </div>
                                            <div className="row_item">
                                                <p>Кол-во</p>
                                            </div>
                                            <div className="row_item">
                                                <p>Ед. изм.</p>
                                            </div>
                                            <div className="row_item">
                                                <p>Цена за ед.</p>
                                            </div>
                                            <div className="row_item">
                                                <p>Общая сумма</p>
                                            </div>
                                            <div className="row_item">
                                                <p>Комментарий</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table_body">
                                        {/*<div className="table_row">*/}
                                        {/*        <Fragment>*/}
                                        {/*            <div className="row_item relative_div">*/}
                                        {/*                <p className="overflowed_line f-size-24">*/}
                                        {/*                    Закуп*/}
                                        {/*                </p>*/}
                                        {/*            </div>*/}
                                        {/*            <div className="row_item relative_div">*/}
                                        {/*                <input*/}
                                        {/*                    type="number"*/}
                                        {/*                    // defaultValue={buy[0].amount}*/}
                                        {/*                    disabled={!this.returnRole(status_display, role)}*/}
                                        {/*                    onBlur={e =>*/}
                                        {/*                        // this.changeBuyItemAmount(*/}
                                        {/*                        //     e.target.value,*/}
                                        {/*                        //     buy[0].id,*/}
                                        {/*                        //     buy[0].sell_item*/}
                                        {/*                        // )*/}
                                        {/*                        this.changePurchase(id, id, e.target.value)*/}
                                        {/*                    }*/}
                                        {/*                    autoComplete="off"*/}
                                        {/*                />*/}
                                        {/*            </div>*/}
                                        {/*        </Fragment>*/}
                                        {/*    </div>*/}


                                        {sell.map(({ id, buy, name }) => (
                                            <div className="table_row" key={`buying_${id}`}>
                                                {buy[0] ? (
                                                    <Fragment>
                                                        <div className="row_item medium_with_opacity_name no_select relative_div">
                                                            <p className="overflowed_line" title={buy[0].name || "-"}>
                                                                {buy[0].name || "-"}
                                                            </p>
                                                        </div>
                                                        <div className="row_item relative_div">
                                                            <p className="overflowed_line" title={buy[0].supplier.name}>
                                                                {buy[0].supplier.name}
                                                            </p>
                                                        </div>
                                                        <div className="row_item">
                                                            <input
                                                                type="number"
                                                                defaultValue={buy[0].amount}
                                                                disabled={!this.returnRole(status_display, role)}
                                                                onBlur={e =>
                                                                    this.changeBuyItemAmount(
                                                                        e.target.value,
                                                                        buy[0].id,
                                                                        buy[0].sell_item
                                                                    )
                                                                }
                                                                autoComplete="off"
                                                            />
                                                        </div>
                                                        <div className="row_item">
                                                            <p>{buy[0].unit}</p>
                                                        </div>
                                                        <div className="row_item">
                                                            <p>{formatNumber(buy[0].price.toFixed(2))}</p>
                                                        </div>
                                                        <div className="row_item">
                                                            <p>{formatNumber(buy[0].total_price.toFixed(2))}</p>
                                                        </div>
                                                        <div className="row_item relative_div">
                                                            <p
                                                                className="overflowed_line"
                                                                title={buy[0].comment || "-"}
                                                            >
                                                                <input
                                                                    type="text"
                                                                    defaultValue={buy[0].comment || "-"}
                                                                    disabled={!this.returnRole(status_display, role)}
                                                                    onBlur={e =>
                                                                        this.changeBuyItemComment(
                                                                            e.target.value,
                                                                            buy[0].id,
                                                                            buy[0].sell_item
                                                                        )
                                                                    }
                                                                    autoComplete="off"
                                                                />
                                                            </p>
                                                            <img
                                                                src={RefreshIcon}
                                                                alt=""
                                                                className="text_hover"
                                                                onClick={() =>
                                                                    this.removeBuyItem(buy[0].id, buy[0].sell_item)
                                                                }
                                                            />
                                                        </div>
                                                    </Fragment>
                                                ) : (
                                                    <div>{this.renderEmptyBuyingRow(id, name)}</div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="btns-container">
                                {
                                    this.state.showListAdditional
                                        ? <button
                                            onClick={() => this.setState({showListAdditional: false})}
                                            className="btn-hide-list-sales ">Скрыть список Доп. расходов</button>
                                        : <button
                                            onClick={() => this.setState({showListAdditional: true})}
                                            className="btn-show-list-sales">Показать список Доп. расходов</button>
                                }
                            </div>

                        <div className={this.state.showListAdditional ? "selling_buying_wrapper additional__expenses" : "d-none"}>


                            <div className="buying_block">
                                <div className="table_container">
                                    <div className="table_row flexible">
                                        <div className="column_title">Дополнительные расходы</div>
                                    </div>
                                    <div className="table_header no_select">
                                        <div className="table_row">
                                            <div className="row_item">
                                                <p>Наименование</p>
                                            </div>
                                            <div className="row_item" />
                                            <div className="row_item" />
                                            <div className="row_item" />
                                            <div className="row_item" />
                                            <div className="row_item">
                                                <p>Общая сумма</p>
                                            </div>
                                            <div className="row_item">
                                                <p>Комментарий</p>
                                            </div>
                                            <div className="row_item">
                                                <p>Статус и управление</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="table_body">
                                        {additional.map(({ id, comment, name, total_price, send_mail }) => (
                                            <div className="table_row" key={`additional_item_${id}`}>
                                                            <div className="row_item">
                                                                <p className="additional_input_wrapper">
                                                                    <input
                                                                        type="text"
                                                                        placeholder="Наименование"
                                                                        disabled={this.disableAdditional(role, send_mail)}
                                                                        defaultValue={name}
                                                                        onBlur={e =>
                                                                            this.changeAdditionalItem("name", e.target.value, id)
                                                                        }
                                                                        autoComplete="off"
                                                                    />
                                                                </p>
                                                            </div>
                                                            <div className="row_item" />
                                                            <div className="row_item" />
                                                            <div className="row_item" />
                                                            <div className="row_item" />
                                                            <div className="row_item">
                                                                <p>
                                                                    <input
                                                                        type="text"
                                                                        defaultValue={total_price || 0}
                                                                        disabled={!this.returnRole(status_display, role)}
                                                                        // disabled={this.disableAdditional(role, send_mail)}
                                                                        // disabled={this.state.disableSendButton}
                                                                        onBlur={e =>
                                                                            this.changeAdditionalItem(
                                                                                "total_price",
                                                                                e.target.value,
                                                                                id
                                                                            )
                                                                        }
                                                                        autoComplete="off"
                                                                    />
                                                                </p>
                                                            </div>
                                                            <div className="row_item relative_div">
                                                                <p className="overflowed_line" title={comment || "-"}>
                                                                    <input
                                                                        type="text"
                                                                        defaultValue={comment || "-"}
                                                                        onBlur={e =>
                                                                            this.changeAdditionalItem("comment", e.target.value, id)
                                                                        }
                                                                        autoComplete="off"
                                                                        disabled={this.disableAdditional(role, send_mail)}
                                                                    />
                                                                </p>

                                                            </div>
                                                            <div className="row_item">
                                                                <div className="message_status_img_wrapper">
                                                                    {
                                                                        send_mail
                                                                        ? <img
                                                                            src={CompleteShip}
                                                                            alt="Отгружено"
                                                                          />
                                                                        : <img
                                                                            src={NotShip}
                                                                            alt="Не отгружено"
                                                                          />
                                                                    }
                                                                </div>
                                                                {
                                                                    !this.disableAdditional(status_display, role, send_mail)
                                                                        ? <button className="mail_btn text_hover">
                                                                            <img
                                                                                src={SendShip}
                                                                                alt="Отправить сообщение"
                                                                                onClick={() => this.setState({ openSendModal: true, idFieldAdditional: id})}
                                                                            />
                                                                          </button>
                                                                        : <button className="mail_btn disabled-icon"
                                                                                  disabled={disableSendButton}>
                                                                            <img
                                                                                src={DisabledSendShip}
                                                                                alt="Поиск недоступен"
                                                                            />
                                                                          </button>
                                                                }

                                                                <button
                                                                    className="del_btn text_hover"
                                                                    type="button"
                                                                    disabled={this.disableAdditional(status_display, role, send_mail)}
                                                                    aria-label="Удалить дополнительный расход"
                                                                    onClick={() => this.removeAdditionalItem(id)}
                                                                >
                                                                    <img src={DeleteIcon} alt="Delete icon" />
                                                                </button>
                                                            </div>
                                                        </div>
                                        ))}

                                        <div className="table_row">
                                                        <div className="solo_item">
                                                            {additionalRow ? (
                                                                <p className="additional_input_wrapper">
                                                                    <input
                                                                        type="text"
                                                                        id="additional_item_input"
                                                                        placeholder="Наименование"
                                                                        disabled={!this.returnRole(status_display, role)}
                                                                        onBlur={e => this.addAdditionalItem(e.target.value)}
                                                                        onKeyPress={e =>
                                                                            e.key === "Enter" &&
                                                                            this.addAdditionalItem(e.target.value)
                                                                        }
                                                                        autoComplete="off"
                                                                    />
                                                                </p>
                                                            ) : (
                                                                this.returnRole(status_display, role) && (
                                                                    <p
                                                                        className="text_hover usable_text add_additional_item"
                                                                        onClick={() => this.setState({ additionalRow: true })}
                                                                    >
                                                                        + Добавить
                                                                    </p>
                                                                )
                                                            )}
                                                        </div>
                                                    </div>

                                        <div className="table_row">
                                                        <div className="row_item bold_text">ИТОГО</div>
                                                        <div className="row_item" />
                                                        <div className="row_item" />
                                                        <div className="row_item" />
                                                        <div className="row_item" />
                                                        <div className="row_item bold_text">
                                                            {formatNumber(buy_total_amount.toFixed(2))}
                                                        </div>
                                                        <div className="row_item" />
                                                    </div>

                                        <div className="table_row last_row">
                                                        <div className="row_item bold_text">ИТОГО ДОП. РАСХОДЫ</div>
                                                        <div className="row_item" />
                                                        <div className="row_item" />
                                                        <div className="row_item" />
                                                        <div className="row_item" />
                                                        <div className="row_item bold_text">
                                                            {profit ? (
                                                                <div>
                                                                    {formatNumber(profit.toFixed(2))}
                                                                    <span
                                                                        className={`${
                                                                            profit / sell_total_amount &&
                                                                            (profit / sell_total_amount) * 100 > 0
                                                                                ? "green_percent"
                                                                                : "red_percent"
                                                                        }`}
                                                                    >
                                                                        {" "}
                                                                        (
                                                                        {formatNumber(
                                                                            ((profit / sell_total_amount) * 100).toFixed(1)
                                                                        )}
                                                                        %)
                                                                    </span>
                                                                </div>
                                                            ) : (
                                                                0
                                                            )}
                                                        </div>
                                                        <div className="row_item" />
                                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr className="no_margin" />
                        <div className={this.state.showListSales ? "" : "d-none"}>
                            <Shipment />
                        </div>

                        {status_display === "Расчёт" && (
                            <div className="dialog_buttons">
                                <DefaultButton
                                    variant="contained"
                                    onClick={() => toggleDialog("to_check")}
                                    classes="calculate_btn"
                                >
                                    На проверку
                                </DefaultButton>
                            </div>
                        )}
                    </Fragment>
                )}
                <Snack
                    open={!!calculation_error}
                    handleSnack={this.closeSnack}
                    message={calculation_error}
                    type="error"
                />
                <DialogComponent
                    open={openNewBuyItemDialog}
                    onClose={() => {
                        this.toggleDialogs("add_buy_item")
                        this.setState({ addBuyItemInfo: initialAddBuyItemInfo })
                    }}
                >
                    <div className="dashboard_dialog no_select">
                        <h2>Добавить товар</h2>
                        <div className="buy_item_dialog_inputs_vol2">
                            <input
                                type="text"
                                placeholder="Наименование"
                                disabled={!this.returnRole(status_display, role)}
                                value={addBuyItemInfo.name}
                                onChange={e => this.changeBuyItemInfo(e.target.value, "name")}
                            />
                            <SelectComponent
                                item={chosen_category}
                                items={categories}
                                handleChange={e => this.changeBuyItemInfo(e.target.value, "categories")}
                                placeholder={"Категория"}
                                toggleCategories={this.toggleDialogs}
                            />
                        </div>
                        <div className="buy_item_dialog_inputs">
                            <input
                                type="text"
                                id="supplier_input"
                                value={addBuyItemInfo.supplier_name}
                                disabled={!this.returnRole(status_display, role)}
                                placeholder="Поставщик"
                                onKeyUp={e => this.handleSupplierKeyUp(e)}
                                onChange={e => this.changeBuyItemInfo(e.target.value, "supplier_name")}
                            />
                            <PopperInfo
                                open={openSupplierPopper}
                                anchorEl={anchorSupplierInput}
                                clickAway={this.clickAway}
                                position="bottom-start"
                                classes="catalog_items_popper suppliers_popper"
                                transition
                            >
                                <ul>
                                    {suppliers.map(({ name, id }) => (
                                        <li
                                            className="text_hover"
                                            onClick={() => this.selectSupplier(name)}
                                            key={`supplier_${id}`}
                                        >
                                            {name}
                                        </li>
                                    ))}
                                    {this.returnRole(status_display, role) && (
                                        <li
                                            className="text_hover usable_text"
                                            onClick={() => {
                                                this.toggleDialogs("add_supplier")
                                                this.clickAway()
                                            }}
                                        >
                                            + Добавить
                                        </li>
                                    )}
                                </ul>
                            </PopperInfo>
                            <input
                                type="number"
                                value={addBuyItemInfo.price}
                                disabled={!this.returnRole(status_display, role)}
                                placeholder="Цена за ед."
                                onChange={e => this.changeBuyItemInfo(e.target.value, "price")}
                            />
                            <input
                                type="text"
                                id="unit_input"
                                value={addBuyItemInfo.unit}
                                disabled={!this.returnRole(status_display, role)}
                                placeholder="Ед. изм."
                                onKeyUp={e => this.handleUnitKeyUp(e)}
                                onChange={e => this.changeBuyItemInfo(e.target.value, "unit")}
                            />
                            <PopperInfo
                                open={openUnitPopper}
                                anchorEl={anchorUnitInput}
                                clickAway={this.clickAway}
                                position="bottom-start"
                                classes="catalog_items_popper units_popper"
                                transition
                            >
                                <ul>
                                    {units
                                        .filter(el => el.toLowerCase().includes(addBuyItemInfo.unit.toLowerCase()))
                                        .map(el => (
                                            <li
                                                className="text_hover"
                                                onClick={() => this.selectUnit(el)}
                                                key={`unit_${el}`}
                                            >
                                                {el}
                                            </li>
                                        ))}
                                </ul>
                            </PopperInfo>
                        </div>
                        <div className="calculation_char_multiselect">
                            <MultiSelectComponent
                                onOpen={() => this.toggleDialogs("open")}
                                onClose={() => this.toggleDialogs("close")}
                                isChars={true}
                                open={charsIsOpen}
                                item={chosen_characteristics}
                                items={characteristics}
                                handleChange={this.handleSelectChangeCR}
                                placeholder={"Характеристики"}
                                handleSearch={this.handleSearchCR}
                                searchPlaceholder={"Введите название характеристики"}
                                searchValue={searchCharacteristics}
                                resetChosenUsers={this.resetCR}
                                toggleCharacteristics={this.toggleDialogs}
                            />
                        </div>

                        <div className="dialog_buttons">
                            <DefaultButton
                                variant="outlined"
                                onClick={() => {
                                    this.toggleDialogs("add_buy_item")
                                    this.setState({ addBuyItemInfo: initialAddBuyItemInfo })
                                }}
                                classes="cancel_btn"
                            >
                                Отмена
                            </DefaultButton>
                            <DefaultButton
                                variant="contained"
                                onClick={this.addNewBuyItem}
                                classes="confirm_btn"
                                loading={loading}
                                disabled={!addBuyItemInfo.name || !addBuyItemInfo.price}
                            >
                                Добавить
                            </DefaultButton>
                        </div>
                    </div>
                </DialogComponent>
                <DialogComponent open={openAddSupplierDialog} onClose={() => this.toggleDialogs("add_supplier")}>
                    <div className="dashboard_dialog no_select">
                        <h2>Добавить поставщика</h2>
                        <input
                            type="text"
                            className="buy_item_name_input"
                            placeholder="Наименование"
                            disabled={!this.returnRole(status_display, role)}
                            value={supplier_input}
                            onChange={e => this.setState({ supplier_input: e.target.value })}
                        />
                        <div className="dialog_buttons">
                            <DefaultButton
                                variant="outlined"
                                onClick={() => this.toggleDialogs("add_supplier")}
                                classes="cancel_btn"
                            >
                                Отмена
                            </DefaultButton>
                            <DefaultButton
                                variant="contained"
                                onClick={this.addSupplier}
                                classes="confirm_btn"
                                loading={loading}
                                disabled={!supplier_input}
                            >
                                Добавить
                            </DefaultButton>
                        </div>
                    </div>
                </DialogComponent>
                <DialogComponent open={openSendModal} onClose={() => this.setState({ openSendModal: false })}>
                    <div className="dashboard_dialog no_select">
                        <h2>Отправить данные</h2>
                        <div className="dialog_description">
                            Подтвердите, что Вы хотите отправить данные о дополнительных расходах на почту
                            администратора.
                        </div>
                        <div className="dialog_buttons">
                            <DefaultButton
                                variant="outlined"
                                onClick={() => this.setState({ openSendModal: false })}
                                classes="cancel_btn"
                            >
                                Отмена
                            </DefaultButton>
                            <DefaultButton
                                variant="contained"
                                onClick={() =>
                                    this.props.sendAdditionals(id, this.state.idFieldAdditional).then(res => {

                                        if (res.payload && res.payload.status && res.payload.status === 200) {
                                            this.setState({
                                                openSendModal: false,
                                                successSnack: "Письмо было успешно отправлено",
                                                disableSendButton: true
                                            })
                                            this.props.getDeal(id)
                                        } else if (res.error.response.data) {
                                            this.setState({
                                                openSendModal: false,
                                                errorSnack: res.error.response.data.detail || res.error.response.data.error,
                                            })
                                        }
                                    })
                                }
                                classes="confirm_btn"
                                loading={loading}
                            >
                                Подтвердить
                            </DefaultButton>
                        </div>
                    </div>
                </DialogComponent>
                <ProductUpdateDialogs
                    doRequest={this.doRequest}
                    toggleDialog={this.toggleDialogs}
                    openCategoriesDialog={openCategoriesDialog}
                    openCharacteristicsDialog={openCharacteristicsDialog}
                    openAddCharDialog={openAddCharDialog}
                    chosen_characteristics={chosen_characteristics}
                    characteristicsList={characteristicsList}
                    setCharacteristics={chosen_characteristics => this.setState({ chosen_characteristics })}
                    charsIsOpen={charsIsOpen => this.setState({ charsIsOpen })}
                    characteristic_data={characteristic_data => this.setState({ characteristic_data })}
                    chars_array={item_chars}
                />

                <Snack open={successSnack} handleSnack={this.closeSuccessSnack} message={successSnack} />

                <Snack open={errorSnack} handleSnack={this.closeErrorSnack} message={errorSnack} type="error" />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        role: state.dashboard.options.me.role,
        options: state.dashboard.options,
        deal: state.dashboard.deal,
        parsedData: state.calculation.parsedData,
        calculation_error: state.calculation.calculation_error,
        catalogItems: state.calculation.catalogItems,
        suppliers: state.calculation.suppliers,
        preliminaryPurchase: state.calculation.preliminaryPurchase,
        categoriesList: state.products.categoriesList,
        characteristicsList: state.products.characteristicsList,
        tender: state.dashboard.tender,
    }
}

const mapDispatchToProps = {
    postExcel,
    resetError,
    searchCatalog,
    searchSuppliers,
    postSupplier,
    postCatalogItem,
    postBuyItem,
    patchBuyItem,
    deleteBuyItem,
    postAdditionalItem,
    patchAdditionalItem,
    deleteAdditionalItem,
    getCategories,
    getCharacteristics,
    addSellCalculation,
    updateSellCalculation,
    deleteSellCalculation,
    sendAdditionals,
    getDeal,
    getPurchase,
    changePurchase,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Calculation))
