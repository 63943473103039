import React, { Fragment } from "react"
import { Switch, Route, Redirect } from "react-router-dom"

import Login from "../../components/Auth/Login/Login"
import Register from "../../components/Auth/Register/Register"
import PasswordRecovery from "../../components/Auth/PasswordRecovery/PasswordRecovery"
import PasswordRecoverySuccess from "../../components/Auth/PasswordRecoverySuccess/PasswordRecoverySuccess"

import "./AuthContainer.scss"

import Logotype from "../../assets/image/logotype.svg"

const AuthContainer = props => {
    const { match } = props
    if (!!localStorage.token) return <Redirect to="/main" />
    return (
        <Fragment>
            <main className="auth_container no_select">
                <div className="auth-box">
                    <div className="auth_bg">
                        <img src={Logotype} alt="zakupki" />
                    </div>
                    <div className="auth_content">
                        <Switch>
                            <Route path={`${match.url}/login`} exact component={Login} />
                            <Route path={`${match.url}/register`} exact component={Register} />
                            <Route path={`${match.url}/password-recovery`} exact component={PasswordRecovery} />
                            <Route
                                path={`${match.url}/password-recovery/success`}
                                exact
                                component={PasswordRecoverySuccess}
                            />
                            <Route render={() => <p>Not found</p>} />
                        </Switch>
                    </div>
                </div>
            </main>
        </Fragment>
    )
}

export default AuthContainer
