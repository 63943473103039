import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import { Collapse } from "react-collapse"
import { Link } from "react-router-dom"
import clsx from "clsx"
import LinearProgress from "@material-ui/core/LinearProgress"
import Checkbox from "@material-ui/core/Checkbox"
import moment from "moment"

import DefaultButton from "../HelperComponents/Buttons/DefaultButton/DefaultButton"
import DialogComponent from "../HelperComponents/DialogComponent/DialogComponent"
import Pagination from "../HelperComponents/Pagination/Pagination"
import { formatNumber } from "../../helpers/functions"
import GenerateXLSDialog from "../GenerateXLSDialog/GenerateXLSDialog"

import {
    getDeals,
    patchTender,
    calculateDeal,
    setDealsPage,
    setDealsOrder,
    addSelectedDeal,
    removeSelectedDeal,
    clearSelectedDeals,
    bulkUpdateTenders
} from "../../actions/dashboardActions"

import "./Deals.scss"

import UpdateIcon from "../../assets/image/update.svg"
import ImportantIcon from "../../assets/image/important.svg"
import SortIcon from "../../assets/image/sort.svg"
import DeleteIcon from "../../assets/image/delete.svg"
import ExpandIcon from "../../assets/image/expand.svg"
import ReplayIcon from "../../assets/image/replay.svg"
import CompleteIcon from "../../assets/image/complete.svg"
import FailedIcon from "../../assets/image/failed.svg"
import UncheckIcon from "../../assets/image/uncheck_all.svg"

class Deals extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            reloading: true,
            selectedElements: [],
            openDeleteDialog: false,
            openBulkDeleteDialog: false,
            openReloadDialog: false,
            openBulkReturnDialog: false,
            openToWorkDialog: false,
            openBulkToWorkDialog: false,
            openErrorDialog: false,
            openErrorMaxTendersDialog: false,
            inputValue: ""
        }
    }

    componentDidMount() {
        this.doRequest()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.order !== this.props.order) this.doRequest()
        if (prevProps.page !== this.props.page) this.doRequest()
        if (prevState.inputValue !== this.state.inputValue) {
            this.props.setDealsPage(1)
            setTimeout(() => {
                this.doRequest()
            }, 0)
        }
    }

    doRequest = async () => {
        const { getDeals, page, order } = this.props
        const { inputValue } = this.state
        this.setState({ reloading: true })
        const res = await getDeals(page, order, inputValue)
        if (res.payload.status === 200) {
            this.setState({ loading: false, reloading: false })
        }
    }

    handleSelected = id => {
        const { selectedElements } = this.state
        if (selectedElements.includes(id)) {
            this.setState({
                selectedElements: selectedElements.filter(el => el !== id)
            })
        } else {
            this.setState({ selectedElements: [...selectedElements, id] })
        }
    }

    toggleDialog = type => {
        type === "delete" &&
            this.setState(({ openDeleteDialog }) => ({
                openDeleteDialog: !openDeleteDialog
            }))
        type === "bulk-delete" &&
            this.setState(({ openBulkDeleteDialog }) => ({
                openBulkDeleteDialog: !openBulkDeleteDialog
            }))
        type === "reload" &&
            this.setState(({ openReloadDialog }) => ({
                openReloadDialog: !openReloadDialog
            }))
        type === "bulk-return" &&
            this.setState(({ openBulkReturnDialog }) => ({
                openBulkReturnDialog: !openBulkReturnDialog
            }))
        type === "toWork" &&
            this.setState(({ openToWorkDialog }) => ({
                openToWorkDialog: !openToWorkDialog
            }))
        type === "bulk-toWork" &&
            this.setState(({ openBulkToWorkDialog }) => ({
                openBulkToWorkDialog: !openBulkToWorkDialog
            }))
        type === "error" &&
            this.setState(({ openErrorDialog }) => ({
                openErrorDialog: !openErrorDialog
            }))
        type === "errorMaxTenders" &&
            this.setState(({ openErrorMaxTendersDialog }) => ({
                openErrorMaxTendersDialog: !openErrorMaxTendersDialog
            }))
    }

    deleteTender = async () => {
        const { patchTender } = this.props
        const { currentTender } = this.state
        this.setState({ reloading: true })
        const data = { status: "deleted" }
        await patchTender(currentTender, data)
        this.doRequest()
        this.toggleDialog("delete")
    }

    deleteTenders = async () => {
        const { bulkUpdateTenders, clearSelectedDeals } = this.props
        this.setState({ reloading: true })
        const data = { ids: this.props.selected, data: { status: "deleted" } }
        await bulkUpdateTenders(data)
        this.doRequest()
        clearSelectedDeals()
        this.toggleDialog("bulk-delete")
    }

    reloadTender = async () => {
        const { patchTender } = this.props
        const { currentTender } = this.state
        this.setState({ reloading: true })
        const data = { status: "new", manager: null }
        await patchTender(currentTender, data)
        this.doRequest()
        this.toggleDialog("reload")
    }

    bulkReturnTenders = async () => {
        const { bulkUpdateTenders, clearSelectedDeals } = this.props
        this.setState({ reloading: true })
        const data = { ids: this.props.selected, data: { status: "new", manager: null } }
        await bulkUpdateTenders(data)
        this.doRequest()
        clearSelectedDeals()
        this.toggleDialog("bulk-return")
    }

    takeToWork = async () => {
        const { calculateDeal } = this.props
        const { currentTender } = this.state
        this.setState({ reloading: true })
        const res = await calculateDeal(currentTender)
        this.setState({ reloading: false })
        if (res.payload && res.payload.status) {
            this.doRequest()
            this.toggleDialog("toWork")
        } else {
            this.toggleDialog("toWork")
            if (res.error.response.data.code === "MANAGER_MAX_TENDERS") {
                this.toggleDialog("errorMaxTenders")
            } else {
                this.toggleDialog("error")
            }
        }
    }

    bulkTakeToWork = async () => {
        const { bulkUpdateTenders, clearSelectedDeals } = this.props
        this.setState({ reloading: true })
        const res = await bulkUpdateTenders({ ids: this.props.selected, data: { set_calculation: true } })
        this.setState({ reloading: false })
        if (res.payload && res.payload.status) {
            this.doRequest()
            clearSelectedDeals()
            this.toggleDialog("bulk-toWork")
        } else {
            this.toggleDialog("bulk-toWork")
            if (res.error.response.data.code === "MANAGER_MAX_TENDERS") {
                this.toggleDialog("errorMaxTenders")
            } else {
                this.toggleDialog("error")
            }
        }
    }

    setSort = fieldName => {
        const { order, setDealsOrder } = this.props
        switch (order) {
            case fieldName:
                setDealsOrder("-" + fieldName)
                break
            case "-" + fieldName:
                setDealsOrder("")
                break
            default:
                setDealsOrder(fieldName)
                break
        }
    }

    render() {
        const {
            deals,
            options: {
                me: { role }
            },
            setDealsPage,
            page,
            count
        } = this.props

        const {
            loading,
            selectedElements,
            openDeleteDialog,
            openReloadDialog,
            openToWorkDialog,
            openErrorDialog,
            openErrorMaxTendersDialog,
            openBulkDeleteDialog,
            openBulkReturnDialog,
            openBulkToWorkDialog,
            reloading,
            inputValue
        } = this.state

        if (loading) return <LinearProgress />

        return (
            <Fragment>
                {reloading ? <LinearProgress /> : <hr className="loader_dummy" />}
                <div className="page_wrapper deals_wrapper">
                    <div className="dashboard_head">
                        <h1>Сделки на расчёт</h1>
                        <div>
                            <Link to="/main/add-custom-tender" className="usable_text text_hover">
                                + Добавить
                            </Link>
                            {role === "admin" && <GenerateXLSDialog />}
                        </div>
                    </div>
                    <div className={clsx("table_container", role !== "admin" && "no_admin")}>
                        <div className="table_header no_select">
                            <div className="table_row">
                                <div className="row_item"></div>
                                <div
                                    className="row_item text_hover"
                                    onClick={() => {
                                        this.setSort("number")
                                    }}
                                >
                                    <p>Номер</p>
                                    <img src={SortIcon} alt="" />
                                </div>
                                <div className="row_item">
                                    <p className="filter_by_name">
                                        <input
                                            type="text"
                                            value={inputValue}
                                            onChange={e => this.setState({ inputValue: e.target.value })}
                                            placeholder="Заказчик"
                                        />
                                    </p>
                                    {/*<img className="text_hover" src={SortIcon} alt="" onClick={() => {*/}
                                    {/*    this.setState({ sortBy: sortBy === 'customerUp' ? 'customerDown' : 'customerUp' })*/}
                                    {/*}}/>*/}
                                </div>
                                <div className="row_item">
                                    <p>Название тендера</p>
                                    {/*<img className="text_hover" src={SortIcon} alt="" onClick={() => {*/}
                                    {/*    this.setState({ sortBy: sortBy === 'nameUp' ? 'nameDown' : 'nameUp' })*/}
                                    {/*}}/>*/}
                                </div>
                                <div
                                    className="row_item text_hover"
                                    onClick={() => {
                                        this.setSort("datetime_dedline_request")
                                    }}
                                >
                                    <p>Окончание подачи заявок</p>
                                    <img src={SortIcon} alt="" />
                                </div>
                                <div
                                    className="row_item text_hover"
                                    onClick={() => {
                                        this.setSort("handle_date")
                                    }}
                                >
                                    <p>Время проведения</p>
                                    <img src={SortIcon} alt="" />
                                </div>
                                <div className="row_item">
                                    <p>Сроки выполнения</p>
                                </div>
                                <div
                                    className="row_item text_hover"
                                    onClick={() => {
                                        this.setSort("start_price_contract")
                                    }}
                                >
                                    <p>Начальная цена контракта (лота)</p>
                                    <img src={SortIcon} alt="" />
                                </div>
                                <div className="row_item">Тип</div>
                                <div className="row_item" />
                                <div className="row_item" />
                                {role === "admin" && <div className="row_item" />}
                            </div>
                        </div>
                        <div className="table_body">
                            {deals.length > 0 ? (
                                deals.map(
                                    ({
                                        id,
                                        has_columns_changed,
                                        is_important,
                                        type,
                                        archive_status,
                                        custom_deadline,
                                        old_tender_deadline,
                                        data: {
                                            category,
                                            customer,
                                            customer_name,
                                            date_delivery_goods,
                                            datetime_dedline_request,
                                            datetime_holding,
                                            e_place,
                                            link,
                                            name,
                                            number,
                                            reestr_number,
                                            request_provision_size,
                                            request_type,
                                            start_price_contract
                                        }
                                    }) => (
                                        <Fragment key={id}>
                                            <div
                                                className={clsx(
                                                    "table_row",
                                                    selectedElements.includes(id) && "margin_for_row"
                                                )}
                                            >
                                                <div className="row_item">
                                                    <Checkbox
                                                        checked={this.props.selected.includes(id)}
                                                        onChange={() => {
                                                            if (this.props.selected.includes(id)) {
                                                                this.props.removeSelectedDeal(id)
                                                            } else {
                                                                this.props.addSelectedDeal(id)
                                                            }
                                                        }}
                                                        color="primary"
                                                        inputProps={{ "aria-label": "primary checkbox" }}
                                                    />
                                                </div>
                                                <div className="row_item usable_text">
                                                    <Link to={`/main/deal/${id}`}>
                                                        {number ? number : reestr_number}
                                                        {archive_status === "downloaded" ? (
                                                            <img src={CompleteIcon} alt="complete" />
                                                        ) : archive_status === "error" ? (
                                                            <img src={FailedIcon} alt="failed" />
                                                        ) : (
                                                            ""
                                                        )}
                                                    </Link>
                                                    <div>
                                                        {is_important && (
                                                            <Fragment>
                                                                <p className="">
                                                                    <img src={ImportantIcon} alt="" />
                                                                    Важная
                                                                </p>
                                                            </Fragment>
                                                        )}
                                                        {is_important && has_columns_changed && (
                                                            <span className="separator">|</span>
                                                        )}
                                                        {has_columns_changed && (
                                                            <p className="">
                                                                <img src={UpdateIcon} alt="" />
                                                                Обновлено
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="row_item">
                                                    <span className="overflowed_text" title={customer || customer_name}>
                                                        {customer || customer_name}
                                                    </span>
                                                </div>
                                                <div className="row_item">
                                                    <span className="overflowed_text" title={name}>
                                                        {name}
                                                    </span>
                                                </div>
                                                <div
                                                    className="row_item"
                                                    style={{
                                                        display: "flex",
                                                        flexFlow: "column",
                                                        alignItems: "flex-start"
                                                    }}
                                                >
                                                    <div>
                                                        {datetime_dedline_request === "-"
                                                            ? datetime_dedline_request
                                                            : moment(datetime_dedline_request).format("DD/MM/YY HH:mm")}
                                                        {!!old_tender_deadline && (
                                                            <img
                                                                src={ImportantIcon}
                                                                alt="important"
                                                                style={{ marginLeft: "4px" }}
                                                            />
                                                        )}
                                                    </div>
                                                    {custom_deadline && (
                                                        <div className="deadline_date">
                                                            {moment(custom_deadline).format("DD/MM/YY HH:mm")}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="row_item">
                                                    {datetime_holding === "-"
                                                        ? datetime_holding
                                                        : moment(datetime_holding).format("DD/MM/YY HH:mm")}
                                                </div>
                                                <div className="row_item">
                                                    <span className="overflowed_text" title={date_delivery_goods}>
                                                        {date_delivery_goods || "-"}
                                                    </span>
                                                </div>
                                                <div className="row_item">
                                                    {formatNumber(parseFloat(start_price_contract).toFixed(2))}
                                                </div>
                                                <div className="row_item">{type || "-"}</div>
                                                <div className="row_item no_select">
                                                    <p
                                                        className="usable_text text_hover"
                                                        onClick={() => {
                                                            this.setState({ currentTender: id })
                                                            this.toggleDialog("toWork")
                                                        }}
                                                    >
                                                        В работу
                                                    </p>
                                                </div>
                                                {role === "admin" && (
                                                    <div className="row_item no_select">
                                                        <p
                                                            className="text_hover"
                                                            onClick={() => {
                                                                this.setState({ currentTender: id })
                                                                this.toggleDialog("reload")
                                                            }}
                                                        >
                                                            <img src={ReplayIcon} alt="replay" />
                                                        </p>
                                                        <p
                                                            className="text_hover"
                                                            onClick={() => {
                                                                this.setState({ currentTender: id })
                                                                this.toggleDialog("delete")
                                                            }}
                                                        >
                                                            <img src={DeleteIcon} alt="del" />
                                                        </p>
                                                    </div>
                                                )}
                                                <div
                                                    className="row_item no_select"
                                                    onClick={() => this.handleSelected(id)}
                                                >
                                                    <p className="text_hover">
                                                        <img
                                                            src={ExpandIcon}
                                                            alt=">"
                                                            className={clsx(
                                                                selectedElements.includes(id) && "transform_rotate"
                                                            )}
                                                        />
                                                    </p>
                                                </div>
                                            </div>
                                            <Collapse isOpened={selectedElements.includes(id)}>
                                                <div className="collapsed_block">
                                                    <div>
                                                        <div>
                                                            <span>Тип тендера</span>
                                                            <p title={request_type}>{request_type}</p>
                                                        </div>
                                                        <div>
                                                            <span>Категория</span>
                                                            <p>{category}</p>
                                                        </div>
                                                        <div>
                                                            <span>Сайт</span>
                                                            <p>
                                                                {link ? (
                                                                    <a
                                                                        className="text_hover"
                                                                        href={
                                                                            link.includes("http") ? link : `//${link}`
                                                                        }
                                                                        rel="noopener noreferrer"
                                                                        target="_blank"
                                                                    >
                                                                        {link}
                                                                    </a>
                                                                ) : (
                                                                    "-"
                                                                )}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div>
                                                            <span>Размер обеспечения заявки</span>
                                                            <p>
                                                                {request_provision_size
                                                                    ? formatNumber(
                                                                          parseFloat(
                                                                              request_provision_size
                                                                                  .toString()
                                                                                  .replace(",", ".")
                                                                          )
                                                                      )
                                                                    : "-"}
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <span>Размер обеспечения контракта</span>
                                                            <p>-</p>
                                                        </div>
                                                        <div>
                                                            <span>Электронная площадка</span>
                                                            <p>
                                                                {e_place ? (
                                                                    <a
                                                                        className="text_hover"
                                                                        href={
                                                                            e_place.includes("http")
                                                                                ? e_place
                                                                                : `//${e_place}`
                                                                        }
                                                                        rel="noopener noreferrer"
                                                                        target="_blank"
                                                                    >
                                                                        {e_place}
                                                                    </a>
                                                                ) : (
                                                                    "-"
                                                                )}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Collapse>
                                        </Fragment>
                                    )
                                )
                            ) : (
                                <div className="table_row">Извините, сейчас новых сделок нет</div>
                            )}
                        </div>
                        <hr />
                    </div>

                    <Pagination
                        active={page - 1}
                        pageCount={count / 50}
                        onChange={e => {
                            setDealsPage(e.selected + 1)
                            window.scrollTo(0, 100)
                        }}
                    />

                    <DialogComponent open={openDeleteDialog} onClose={() => this.toggleDialog("delete")}>
                        <div className="dashboard_dialog no_select">
                            <h2>Удалить сделку</h2>
                            <div className="dialog_description">Вы уверены, что хотите удалить эту сделку?</div>
                            <div className="dialog_buttons">
                                <DefaultButton
                                    variant="outlined"
                                    onClick={() => this.toggleDialog("delete")}
                                    classes="cancel_btn"
                                >
                                    Отмена
                                </DefaultButton>
                                <DefaultButton
                                    variant="contained"
                                    onClick={this.deleteTender}
                                    classes="confirm_btn"
                                    loading={reloading}
                                >
                                    Удалить
                                </DefaultButton>
                            </div>
                        </div>
                    </DialogComponent>
                    <DialogComponent open={openBulkDeleteDialog} onClose={() => this.toggleDialog("bulk-delete")}>
                        <div className="dashboard_dialog no_select">
                            <h2>Удалить выбранные заявки</h2>
                            <div className="dialog_description">Вы уверены, что хотите удалить эти заявки?</div>
                            <div className="dialog_buttons">
                                <DefaultButton
                                    variant="outlined"
                                    onClick={() => this.toggleDialog("bulk-delete")}
                                    classes="cancel_btn"
                                >
                                    Отмена
                                </DefaultButton>
                                <DefaultButton
                                    variant="contained"
                                    onClick={this.deleteTenders}
                                    classes="confirm_btn"
                                    loading={reloading}
                                >
                                    Удалить
                                </DefaultButton>
                            </div>
                        </div>
                    </DialogComponent>

                    <DialogComponent open={openReloadDialog} onClose={() => this.toggleDialog("reload")}>
                        <div className="dashboard_dialog no_select">
                            <h2>Вернуть во входящие</h2>
                            <div className="dialog_description">
                                Вы уверены, что хотите вернуть эту сделку в список входящих заявок?
                            </div>
                            <div className="dialog_buttons">
                                <DefaultButton
                                    variant="outlined"
                                    onClick={() => this.toggleDialog("reload")}
                                    classes="cancel_btn"
                                >
                                    Отмена
                                </DefaultButton>
                                <DefaultButton
                                    variant="contained"
                                    onClick={this.reloadTender}
                                    classes="confirm_btn"
                                    loading={reloading}
                                >
                                    Вернуть
                                </DefaultButton>
                            </div>
                        </div>
                    </DialogComponent>

                    <DialogComponent open={openBulkReturnDialog} onClose={() => this.toggleDialog("bulk-return")}>
                        <div className="dashboard_dialog no_select">
                            <h2>Вернуть во входящие</h2>
                            <div className="dialog_description">
                                Вы уверены, что хотите вернуть выбранные сделки в список входящих заявок?
                            </div>
                            <div className="dialog_buttons">
                                <DefaultButton
                                    variant="outlined"
                                    onClick={() => this.toggleDialog("bulk-return")}
                                    classes="cancel_btn"
                                >
                                    Отмена
                                </DefaultButton>
                                <DefaultButton
                                    variant="contained"
                                    onClick={this.bulkReturnTenders}
                                    classes="confirm_btn"
                                    loading={reloading}
                                >
                                    Вернуть
                                </DefaultButton>
                            </div>
                        </div>
                    </DialogComponent>
                    <DialogComponent open={openToWorkDialog} onClose={() => this.toggleDialog("toWork")}>
                        <div className="dashboard_dialog no_select">
                            <h2>Взять в работу</h2>
                            <div className="dialog_description">
                                Подтвердите, что Вы хотите взять эту сделку в работу.
                            </div>
                            <div className="dialog_buttons">
                                <DefaultButton
                                    variant="outlined"
                                    onClick={() => this.toggleDialog("toWork")}
                                    classes="cancel_btn"
                                >
                                    Отмена
                                </DefaultButton>
                                <DefaultButton
                                    variant="contained"
                                    onClick={this.takeToWork}
                                    classes="confirm_btn"
                                    loading={reloading}
                                >
                                    Подтвердить
                                </DefaultButton>
                            </div>
                        </div>
                    </DialogComponent>
                    <DialogComponent open={openBulkToWorkDialog} onClose={() => this.toggleDialog("bulk-toWork")}>
                        <div className="dashboard_dialog no_select">
                            <h2>Взять в работу</h2>
                            <div className="dialog_description">
                                Подтвердите, что Вы хотите взять выбранные сделки в работу.
                            </div>
                            <div className="dialog_buttons">
                                <DefaultButton
                                    variant="outlined"
                                    onClick={() => this.toggleDialog("bulk-toWork")}
                                    classes="cancel_btn"
                                >
                                    Отмена
                                </DefaultButton>
                                <DefaultButton
                                    variant="contained"
                                    onClick={this.bulkTakeToWork}
                                    classes="confirm_btn"
                                    loading={reloading}
                                >
                                    Подтвердить
                                </DefaultButton>
                            </div>
                        </div>
                    </DialogComponent>
                    <DialogComponent open={openErrorDialog} onClose={() => this.toggleDialog("error")}>
                        <div className="dashboard_dialog no_select">
                            <h2>Сделка уже занята</h2>
                            <div className="dialog_description">
                                Извините, но сделка уже закреплена за другим менеджером. Пожалуйста, выберите другую
                                сделку.
                            </div>
                        </div>
                    </DialogComponent>
                    <DialogComponent
                        open={openErrorMaxTendersDialog}
                        onClose={() => this.toggleDialog("errorMaxTenders")}
                    >
                        <div className="dashboard_dialog no_select">
                            <h2>Превышен лимит</h2>
                            <div className="dialog_description">
                                За Вами уже закреплено 3 сделки. Это максимальное количество одновременно активных
                                сделок. Пожалуйста, завершите одну из активных сделок, чтобы взять в работу новую.
                            </div>
                        </div>
                    </DialogComponent>
                </div>
                {this.props.selected.length > 0 && (
                    <div className="bulk-panel">
                        <div className="bulk-uncheck" onClick={this.props.clearSelectedDeals}>
                            <img src={UncheckIcon} alt="uncheck" />
                            <span className="bulk-uncheck-text">
                                <strong>{this.props.selected.length}</strong> выбрано
                            </span>
                        </div>
                        <div className="bulk-control">
                            {!(role === "manager" && this.props.selected.length > 3) && (
                                <button className="button-primary" onClick={() => this.toggleDialog("bulk-toWork")}>
                                    ВЗЯТЬ В РАБОТУ
                                </button>
                            )}
                            <button className="button-primary" onClick={() => this.toggleDialog("bulk-return")}>
                                ВЕРНУТЬ ВО ВХОДЯЩИЕ
                            </button>
                            {role === "admin" && (
                                <button className="button-delete" onClick={() => this.toggleDialog("bulk-delete")}>
                                    УДАЛИТЬ
                                </button>
                            )}
                        </div>
                        <div></div>
                    </div>
                )}
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        deals: state.dashboard.deals.results,
        options: state.dashboard.options,
        page: state.dashboard.dealsPage,
        order: state.dashboard.dealsOrder,
        count: state.dashboard.dealsCount,
        selected: state.dashboard.selectedDeals
    }
}
const mapDispatchToProps = {
    getDeals,
    patchTender,
    calculateDeal,
    setDealsPage,
    setDealsOrder,
    addSelectedDeal,
    removeSelectedDeal,
    clearSelectedDeals,
    bulkUpdateTenders
}
export default connect(mapStateToProps, mapDispatchToProps)(Deals)
