import * as types from "./constants.jsx";

export function getNumberOfNewNotifications() {
    return {
        type: types.GET_NUMBER_OF_NEW_NOTIFICATIONS,
        payload: {
            client: "default",
            request: {
                url: `/notifications/bell-summary/`,
                method: "get"
            }
        }
    };
}

export function patchNumberOfNewNotifications() {
    // const data = {
    //     new_count: 0,
        // unread_count: 0
    // };
    return {
        type: types.PATCH_NUMBER_OF_NEW_NOTIFICATIONS,
        payload: {
            client: "default",
            request: {
                url: `/notifications/clear-bell/`,
                method: "post"
            }
        }
    };
}

export function getNotifications(page) {
    return {
        type: types.GET_NOTIFICATIONS,
        payload: {
            client: "default",
            request: {
                url: `/notifications/?page=${page}`,
                method: "get"
            }
        }
    };
}

export function patchNotificationStatus(id) {
    return {
        type: types.PATCH_NOTIFICATION_STATUS,
        payload: {
            client: "default",
            request: {
                url: `/notifications/${id}/mark-read/`,
                method: "post"
            }
        }
    };
}

export function resetNotifications() {
    return {
        type: types.RESET_NOTIFICATIONS
    };
}