import * as types from "../actions/constants";

const INITIAL_STATE = {
    options: {},
    tenders: [],
    selectedTenders: {},
    tendersPage: 1,
    tendersOrder: "",
    tendersCount: 0,
    tender: {},
    deals: [],
    selectedDeals: [],
    dealsPage: 1,
    dealsOrder: "",
    dealsCount: 0,
    active_deals: [],
    selectedActiveDeals: [],
    activeDealsPage: 1,
    activeDealsOrder: "",
    activeDealsCount: 0,
    deal: {},
    managers: [],
    users: [],
    usersList: [],
    countriesList: [],
    searchedTenders: [],
    customers: [],
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.GET_OPTIONS_SUCCESS:
            return { ...state, options: action.payload.data };
        case types.GET_TENDERS_SUCCESS:
            return { ...state, tenders: action.payload.data.results, tendersCount: action.payload.data.count };
        case types.GET_TENDERS_BY_SEARCH_SUCCESS:
            return { ...state, searchedTenders: action.payload.data };
        case types.GET_TENDER_SUCCESS:
            return { ...state, tender: action.payload.data };
        case types.GET_MANAGERS_SUCCESS:
            return { ...state, managers: action.payload.data };
        case types.GET_DEALS_SUCCESS:
            return { ...state, deals: action.payload.data, dealsCount: action.payload.data.count };
        case types.GET_ACTIVE_DEALS_SUCCESS:
            return { ...state, active_deals: action.payload.data.results, activeDealsCount: action.payload.data.count };
        case types.GET_DEAL_SUCCESS:
            return { ...state, deal: action.payload.data };
        case types.GET_COUNTRIES_SUCCESS:
            return { ...state, countriesList: action.payload.data };
        case types.GET_USERS_SUCCESS:
            let temp_list = [];
            action.payload.data.forEach(el => el.fio && temp_list.push(`z${el.fio}`));
            return {
                ...state,
                users: action.payload.data,
                usersList: temp_list
            };

        case types.GET_OPTIONS_FAIL:
            localStorage.clear();
            document.location.reload();
            return { ...state };
        case types.SET_TENDERS_ORDER:
            return { ...state, tendersOrder: action.payload };
        case types.SET_TENDERS_PAGE:
            return { ...state, tendersPage: action.payload };
        case types.SET_DEALS_ORDER:
            return { ...state, dealsOrder: action.payload };
        case types.SET_DEALS_PAGE:
            return { ...state, dealsPage: action.payload };
        case types.SET_ACTIVE_DEALS_ORDER:
            return { ...state, activeDealsOrder: action.payload };
        case types.SET_ACTIVE_DEALS_PAGE:
            return { ...state, activeDealsPage: action.payload };
        case types.ADD_SELECTED_TENDER:
            return {
                ...state,
                selectedTenders: { ...state.selectedTenders, [action.payload.id]: action.payload.hasType }
            };
        case types.REMOVE_SELECTED_TENDER:
            const newSelectedTenders = { ...state.selectedTenders };
            delete newSelectedTenders[action.payload];
            return { ...state, selectedTenders: newSelectedTenders };
        case types.CLEAR_SELECTED_TENDERS:
            return { ...state, selectedTenders: {} };
        case types.ADD_SELECTED_DEAL:
            return {
                ...state,
                selectedDeals: [...state.selectedDeals, action.payload]
            };
        case types.REMOVE_SELECTED_DEAL:
            return { ...state, selectedDeals: state.selectedDeals.filter(obj => obj !== action.payload) };
        case types.CLEAR_SELECTED_DEALS:
            return { ...state, selectedDeals: [] };
        case types.ADD_SELECTED_ACTIVE_DEAL:
            let arr = typeof action.payload === "number" ? [action.payload] : action.payload,
                temp = [...state.selectedActiveDeals, ...arr],
                result = [];

            for (let str of temp) {
                if (!result.includes(str)) {
                    result.push(str);
                }
            }

            return {
                ...state,
                selectedActiveDeals: result
            };
        case types.REMOVE_SELECTED_ACTIVE_DEAL:
            return { ...state, selectedActiveDeals: state.selectedActiveDeals.filter(obj => obj !== action.payload) };
        case types.CLEAR_SELECTED_ACTIVE_DEALS:
            return { ...state, selectedActiveDeals: [] };
        case types.GET_CUSTOMERS_SUCCESS:
            return { ...state, customers: action.payload.data };
        default:
            return state;
    }
}
