import * as types from "./constants.jsx";

export function addProduct(data) {
    return {
        type: types.POST_ADD_PRODUCT,
        payload: {
            client: "default",
            request: {
                url: `/catalog/create-new/`,
                method: "POST",
                data
            }
        }
    };
}

export function getCertainProduct(id) {
    return {
        type: types.GET_CERTAIN_PRODUCT,
        payload: {
            client: "default",
            request: {
                url: `/catalog/${id}/`,
                method: "GET"
            }
        }
    };
}

export function getProducts(suppliers, categories, is_active, page, productSearch) {
    return {
        type: types.GET_PRODUCTS,
        payload: {
            client: "default",
            request: {
                url: `/catalog/?page=${page}${categories.length > 0 ? `&categories=${categories}` : ""}${
                    is_active.length > 0 ? `&is_active=${is_active}` : ""
                }${suppliers.length > 0 ? `&suppliers=${suppliers}` : ""}${productSearch.length > 0 ? `&search=${productSearch}` : ""}`,
                method: "get"
            }
        }
    };
}

export function getCategories(is_active) {
    return {
        type: types.GET_CATEGORIES,
        payload: {
            client: "default",
            request: {
                url: `/catalog/categories${is_active !== undefined ? `?is_active=${is_active}` : "/"}`,
                method: "get"
            }
        }
    };
}

export function getSuppliers() {
    return {
        type: types.GET_SUPPLIERS,
        payload: {
            client: "default",
            request: {
                url: `/catalog/suppliers/`,
                method: "get"
            }
        }
    };
}

//activity product change

export function changeProductActivity(id) {
    return {
        type: types.POST_CHANGE_PRODUCT_ACTIVITY,
        payload: {
            client: "default",
            request: {
                url: `/catalog/${id}/status/`,
                method: "POST"
            }
        }
    };
}

// product updating

export function patchProduct(id, data) {
    return {
        type: types.PATCH_PRODUCT,
        payload: {
            client: "default",
            request: {
                url: `/catalog/${id}/update/`,
                method: "PATCH",
                data
            }
        }
    };
}

export function getCharacteristics() {
    return {
        type: types.GET_CHARACTERISTICS,
        payload: {
            client: "default",
            request: {
                url: `/catalog/characteristics/`,
                method: "GET"
            }
        }
    };
}

export function addCharacteristic(data) {
    return {
        type: types.POST_NEW_CHARACTERISTIC,
        payload: {
            client: "default",
            request: {
                url: `/catalog/characteristics/add/`,
                method: "POST",
                data
            }
        }
    };
}

export function excelImport(data) {
    return {
        type: types.POST_EXCEL_ITEM,
        payload: {
            client: "default",
            request: {
                url: `/catalog/excell-import/`,
                method: "POST",
                data
            }
        }
    };
}
