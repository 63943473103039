import React from "react";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";

import "./SelectWithSearchComponent.scss";
import CheckIcon from "../../../assets/image/check.svg";

const SelectWithSearchComponent = ({
    items,
    item,
    handleChange,
    placeholder,
    handleSearch,
    resetChosenUsers,
    searchPlaceholder = false,
    open,
    searchValue,
    disabled
}) => {
    return (
        <div className="wrapper_multiselect mono">
            <span
                className={`select_placeholder ${item && item.length > 0 ? "actually_label" : ""}`}
                style={disabled ? { color: "rgba(0, 0, 0, 0.38)", opacity: "0.6" } : null}
            >
                {placeholder}
            </span>
            <Select
                disabled={disabled}
                open={open}
                labelId="multiple-checkbox-label"
                id="multiple-checkbox"
                value={item}
                onChange={handleChange}
                input={<Input />}
                renderValue={selected => {
                    return selected.slice(1);
                }}
                MenuProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                    },
                    getContentAnchorEl: null
                }}
            >
                <div className="custom_mu">
                    {searchPlaceholder && (
                        <div>
                            <input
                                value={searchValue}
                                type="text"
                                onClick={e => e.stopPropagation()}
                                onChange={handleSearch}
                                placeholder={searchPlaceholder}
                            />
                        </div>
                    )}
                    <button
                        className="btn_clear"
                        onClick={e => {
                            e.stopPropagation();
                            resetChosenUsers();
                        }}
                    >
                        zСбросить выбранное
                    </button>
                </div>
                {items.map(name => (
                    <MenuItem key={name} value={name}>
                        <ListItemText primary={name} />
                        {name === item && <img src={CheckIcon} alt="><" />}
                    </MenuItem>
                ))}
            </Select>
        </div>
    );
};

export default SelectWithSearchComponent;
