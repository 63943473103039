import React from "react";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import clsx from "clsx";

import "./PopperInfo.scss";

const PopperInfo = ({ id, open, anchorEl, clickAway, children, position = "bottom-end", modifiers, classes = "" }) => {
    return (
        <Popper
            id={id}
            style={{ zIndex: 10000 }}
            open={open}
            anchorEl={anchorEl}
            transition
            placement={position}
            modifiers={modifiers}
            disablePortal={true}
        >
            {({ TransitionProps }) => (
                <ClickAwayListener onClickAway={clickAway}>
                    <Grow {...TransitionProps} timeout={350}>
                        <div className={clsx("popper_wrapper", classes && classes)}>{children}</div>
                    </Grow>
                </ClickAwayListener>
            )}
        </Popper>
    );
};

export default PopperInfo;
