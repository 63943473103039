import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { products_statuses_list } from "../../helpers/functions"
import "./Suppliers.scss"
import SelectComponent from "../HelperComponents/SelectComponent/SelectComponent"

class SuppliersControls extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    //adding filters to url
    handleLocation = () => {
        const { history, chosen_suppliers_statuses } = this.props
        let queries = []
        if (chosen_suppliers_statuses) {
            if (chosen_suppliers_statuses.length > 0) {
                queries.push(encodeURIComponent(`chosen_suppliers_statuses=${chosen_suppliers_statuses}`))
            } else {
                queries.push(encodeURIComponent(""))
            }
        }
        queries.filter(el => el !== "").length > 0
            ? history.push(`/main/suppliers${queries.length && queries.length !== 0 ? "?" : ""}${queries.join("&")}`)
            : history.push(`/main/suppliers`)
    }

    //products statuses
    handleSelectChangePS = async e => {
        const { setSuppliersStatuses } = this.props
        await setSuppliersStatuses(e.target.value)
        this.handleLocation()
    }
    //products statuses

    render() {
        const { chosen_suppliers_statuses, history, role } = this.props

        return (
            <div className="controls_panel no_select">
                <div>
                    <SelectComponent
                        item={chosen_suppliers_statuses}
                        items={products_statuses_list}
                        handleChange={this.handleSelectChangePS}
                        placeholder={"Статус"}
                    />
                </div>
                {role !== "manager" && (
                    <div onClick={() => history.push(`/main/new-supplier`)} className="usable_text text_hover">
                        + Добавить
                    </div>
                )}
            </div>
        )
    }
}

export default withRouter(SuppliersControls)
