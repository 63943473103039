import * as types from "./constants.jsx";

export function postExcel(id, data) {
    return {
        type: types.POST_EXCEL,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/${id}/calculation/import/excel/`,
                method: "post",
                data
                // post file using form-data
                //      import_file: your-file-data
            }
        }
    };
}

export function sendAdditionals(id, idAdditional) {
    return {
        type: types.SEND_ADDITIONALS,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/${id}/calculation/additional/send-mail/${idAdditional}`,
                method: "post"
            }
        }
    };
}

export function addSellCalculation(id, data) {
    return {
        type: types.ADD_SELL_CALCULATION,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/${id}/calculation/sell/add/`,
                method: "post",
                data
            }
        }
    };
}

export function updateSellCalculation(tenderId, itemId, data) {
    return {
        type: types.ADD_SELL_CALCULATION,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/${tenderId}/calculation/sell/${itemId}/update/`,
                method: "patch",
                data
            }
        }
    };
}

export function deleteSellCalculation(tenderId, itemId) {
    return {
        type: types.ADD_SELL_CALCULATION,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/${tenderId}/calculation/sell/${itemId}/delete/`,
                method: "delete"
            }
        }
    };
}

export function searchCatalog(query) {
    return {
        type: types.SEARCH_CATALOG,
        payload: {
            client: "default",
            request: {
                url: `/catalog/search/?search=${query}`,
                method: "get"
                // Use trigram-similarity for search
            }
        }
    };
}

export function searchSuppliers(query) {
    return {
        type: types.SEARCH_SUPPLIERS,
        payload: {
            client: "default",
            request: {
                url: `/catalog/suppliers/search/?search=${query}`,
                method: "get"
            }
        }
    };
}

export function postSupplier(data) {
    return {
        type: types.POST_SUPPLIER,
        payload: {
            client: "default",
            request: {
                url: `/catalog/suppliers/add/`,
                method: "post",
                data
                // {
                //     "name": "Корпорация Нью Васюки Интернешнл"
                // }
            }
        }
    };
}

export function postCatalogItem(data) {
    return {
        type: types.POST_CATALOG_ITEM,
        payload: {
            client: "default",
            request: {
                url: `/catalog/add/`,
                method: "post",
                data
                // {
                //     "name" : "фломастер",
                //     "price": 120.12,
                //     "supplier_name": "ООО Рога и Копыта", // optional
                //     "supplier_info": "Телефон + 7 495 123 45 67", // optional
                //     "unit": "упаковка", // optional
                //     "category": "канцелярские принадлежности" // optional
                // }
            }
        }
    };
}

export function postBuyItem(tender_id, sell_item_id, data) {
    return {
        type: types.POST_BUY_ITEM,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/${tender_id}/calculation/sell/${sell_item_id}/buy/add/`,
                method: "post",
                data
                // {
                //     "name": "карандаши", // optional if catalog_item provided else required
                //     "price": 125, // optional if catalog_item provided else required
                //     "amount": 52, // optional, default 1
                //     "unit": "кг", // optional, default "шт.", if catalog_item provided will take from catalog_item
                //     "comment": "aaaa", // optional, but provider info will be added
                //     "total_price": 6500, // optional, will be calculated automatically if not set
                //     "catalog_item": 165, // optional, should be catalog item id
                // }
            }
        }
    };
}

export function patchBuyItem(tender_id, sell_item_id, buy_id, data) {
    return {
        type: types.PATCH_BUY_ITEM,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/${tender_id}/calculation/sell/${sell_item_id}/buy/${buy_id}/update/`,
                method: "patch",
                data
                // {
                //     "name": "карандаши", // optional if catalog_item provided else required
                //     "price": 125, // optional if catalog_item provided else required
                //     "amount": 52, // optional, default 1
                //     "unit": "кг", // optional, default "шт.", if catalog_item provided will take from catalog_item
                //     "comment": "aaaa", // optional, but provider info will be added
                //     "total_price": 6500, // optional, will be calculated automatically if not set - хуй в сраку
                //     "catalog_item": 165, // optional, should be catalog item id
                // }
            }
        }
    };
}

export function deleteBuyItem(tender_id, sell_item_id, buy_id) {
    return {
        type: types.DELETE_BUY_ITEM,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/${tender_id}/calculation/sell/${sell_item_id}/buy/${buy_id}/delete/`,
                method: "delete"
            }
        }
    };
}

export function postAdditionalItem(tender_id, data) {
    return {
        type: types.POST_ADDITIONAL_ITEM,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/${tender_id}/calculation/additional/add/`,
                method: "post",
                data
                // {
                //     "name": "доставка",
                //     "total_price": 2400,
                //     "comment": "телеофн водителя +7 123 456 78 90"
                // }
            }
        }
    };
}

export function patchAdditionalItem(tender_id, additional_item_id, data) {
    return {
        type: types.PATCH_ADDITIONAL_ITEM,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/${tender_id}/calculation/additional/${additional_item_id}/update/`,
                method: "patch",
                data
                // {
                //     "name": "доставка",
                //     "total_price": 2400.0,
                //     "comment": "телеофн водителя +7 123 456 78 90",
                // }
            }
        }
    };
}

export function deleteAdditionalItem(tender_id, additional_item_id) {
    return {
        type: types.DELETE_ADDITIONAL_ITEM,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/${tender_id}/calculation/additional/${additional_item_id}/delete/`,
                method: "delete"
            }
        }
    };
}

export function resetError() {
    return {
        type: types.RESET_FILE_ERROR
    };
}

export function patchSellItem(tender_id, sell_item_id, data) {
    return {
        type: types.PATCH_SELL_ITEM,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/${tender_id}/calculation/sell/${sell_item_id}/update/`,
                method: "patch",
                data
                // {
                //     "name": "карандаши",
                //     "price": 200.5,
                //     "amount": 12.5,
                //     "unit": "кг",
                //     "total_price": 2506.25,
                //     "comment": "ККК - красные кедровые карандаши",
                // }
            }
        }
    };
}

export function patchSellItemPrice(price, sell_item_id, data) {
    return {
        type: types.PATCH_SELL_ITEM_PRICE,
        payload: { item_id: sell_item_id, new_price: price, data: data }
    };
}

// export function deleteSellItem(tender_id, sell_item_id, data) {
//     return {
//         type: types.DELETE_SELL_ITEM,
//         payload: {
//             client: 'default',
//             request: {
//                 url: `/manager/tenders/${tender_id}/calculation/sell/${sell_item_id}/delete/`,
//                 method: "delete",
//                 data
//                 // {
//                 //     "name": "карандаши",
//                 //     "price": 200.5,
//                 //     "amount": 12.5,
//                 //     "unit": "кг",
//                 //     "total_price": 2506.25,
//                 //     "comment": "ККК - красные кедровые карандаши",
//                 // }
//             }
//         }
//     };
// }


// Получение списка предворительного расчета из бд
export function getPurchase(tender_id) {
    return {
        type: types.GET_PURCHASE,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/${tender_id}/calculation/purchase/`,
                method: "get"
            }
        }
    }
}

// Изменения или удаление данных у предварительного расчета в бд
export function changePurchase(tender_id, data) {
    return {
        type: types.CHANGE_PURCHASE,
        payload: {
            client: 'default',
            request: {
                url: `/manager/tenders/${tender_id}/calculation/purchase/update/`,
                method: "post",
                data
                // {
                //     "name": "карандаши",
                //     "price": 200.5,
                //     "amount": 12.5,
                //     "unit": "кг",
                //     "total_price": 2506.25,
                //     "comment": "ККК - красные кедровые карандаши",
                // }
            }
        }
    }
}

// Получение списка предворительных расчетов
export function getPurchases() {
    return {
        type: types.GET_PURCHASES,
        payload: {
            client: "default",
            request: {
                url: `/manager/tenders/calculation/purchases/`,
                method: "get"
            }
        }
    }
}
